import React, { useEffect, useState } from 'react';

import './superAdminLogin.css';
import { ArkatissLogo, bannerIcon, logo } from '../../images';

import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { InputComponent } from '../../commonComponents';
import { useDispatch, useSelector } from 'react-redux';
import { superAdminLogin } from '../../redux/superAdmin/action';
const SuperAdminLogin = () => {

    let [loginDetails,setLoginDetails] = useState({
        email:"",
        password:""
    })
    let loginStatus = useSelector((state)=>state.superAdminReducer?.superAdminloginStatus);

    let loginTokenData = useSelector((state)=>state.superAdminReducer);

    const [form] = Form.useForm();
    let navigate = useNavigate();

    let dispatch = useDispatch();
    let onFinish = (values) =>{
        if (values) {
            let payload = {
                "operation_type":"super_admin_login",
                "op_field_details":{
                    "username":loginDetails?.email?.toLowerCase(),
                    "password": loginDetails?.password
                }
            }
            dispatch(superAdminLogin(payload))
        }
    }


    useEffect(()=>{
        if (loginStatus) {
            console.log(loginStatus,"status")
            // navigate("/superAdminDashboard")
        }
    },[loginStatus])

    useEffect(()=>{
        if (loginTokenData.superAdminToken?.res_status == true) {
            console.log(loginTokenData?.superAdminToken?.role_type,"heytokenDate",loginTokenData.superAdminToken?.access_token?.access_token)
            // window.localStorage.setItem("role_Type",loginTokenData?.superAdminToken?.role_type)
            window.localStorage.setItem("role_Type","superadmin")
            window.localStorage.setItem("token",loginTokenData?.superAdminToken?.access_token?.access_token)
            navigate("/superAdminDashboard")
            window.location.reload()
        }
    },[loginTokenData])

    let handleChange = (e,type) =>{
        setLoginDetails({...loginDetails,[type.name]:e.target.value})
    }

    return (
        <div className='adminLoginContainer'>
        <div className='adminLoginBanner'>
        <img src= {bannerIcon} className='bannerImage'/>

        </div>

        <div className='adminLoginForm'>
            <div className='adminLogo'>
            <img src={ArkatissLogo}/>
            </div>
        <Form 
        autoComplete="off"
        layout="vertical"
        form={form}
          onFinish={onFinish}
          >
        
        <><div className='fields'>
                          <Form.Item label="Login"
                              name="email"
                              rules={[
                                  {
                                      required: true,
                                      message: 'Email is required',
                                      type: "email",
                                  },
                              ]}>
                              <InputComponent  name={"email"} className='inputField'
                                  placeholder="Enter email" value={loginDetails?.email}
                                  onChange={(e)=>handleChange(e,{name:"email"})}
                                   />

                          </Form.Item>

                      </div><div className='fields'>
                              <Form.Item label="Password"
                                  name="password"
                                  rules={[
                                      {
                                          required: true,
                                          message: 'Password is required',
                                      },
                                  ]}>
                                  <Input.Password 
                                  value={loginDetails?.password} name={"password"} className='inputField adminInputField'
                                      placeholder="Enter password"
                                       onChange={(e)=>handleChange(e,{name:"password"})}
                                       />

                              </Form.Item>

                          </div><div className='fields'>
                              <Form.Item>
                                  <Button htmlType='submit' primary type='primary' className='loginAdmin'>Login</Button>
                              </Form.Item>
                          </div></>
        
        </Form>
           
      
        </div>
        
    </div>
    );
}

export default SuperAdminLogin;
