import React from 'react';
import { Select, Space } from 'antd';

import './InputSelect.css';
const handleChange = (value) => {
  console.log(`selected ${value}`);
};
const InputSelect = ({id,options,onChange,defaultValue,initialValues,
  defaultActiveFirstOption,loading,ref,key,labelInValue}) => (
  <Space wrap>
    <Select
      style={{
        width: 120,
      }}
      onChange={onChange}
     options= {options}
     defaultValue={defaultValue}
     initialValues={initialValues}
     defaultActiveFirstOption={defaultActiveFirstOption}
     id={id}
     loading={loading}
     ref={ref}
     key={key}
    //  labelInValue={labelInValue}
    //  displayEmpty={displayEmpty}
    />
  </Space>
);
export default InputSelect;
