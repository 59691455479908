import {ADMIN_COURSE_PRICE_CRUD,GET_ALL_COURSE_PRICES} from "./actionTypes";

 const AdminCoursePriceReducer = (state = [],action)=>{

    switch (action.type) {
            case ADMIN_COURSE_PRICE_CRUD:

                return{
                    adminCoursePriceCrudStatus: action.payload
                }

            case GET_ALL_COURSE_PRICES:

                return{
                    adminCoursePrices: action.payload
                }
        default:
            return state;
    }
}

export  default AdminCoursePriceReducer;