import React from "react";

import moment from "moment";

let DemoClassViewDetails = (props) =>{
              
    console.log(props.studentRecord)

    return (
        <div className="retriveStudent">
                <div>
                    <p> ID  : {props?.studentRecord.id}</p>

                    <p>Instructor Name   : {props?.studentRecord.instructor_name}</p>
                    <p>Course Name    : {props?.studentRecord.course_name}</p>
                    <p>Class Date        : {moment(props?.studentRecord.date, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY')}</p>
                    <p>Meeting Link        : {props?. studentRecord.meeting_link}</p>
                  
                   
                </div>
        </div>
    )
}

export default DemoClassViewDetails