import React, { useState } from 'react';

import { Button, Form, Input,Switch,notification } from "antd";
import  banner from '../../../images/Object.svg';

import './adminRegistration.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AdminEmailVerify from '../adminEmailVerify/adminEmailVerify';
import { InputComponent, InputSelect } from '../../../commonComponents';
import { faceBookIcon, googleIcon ,logo} from '../../../images';
const AdminRegistration = () => {

    const [form] = Form.useForm();
    let navigate = useNavigate();

    let [verifyEmail,setVerifyEmail] = useState(false);
    let dispatch = useDispatch();

    let onFinish = (values) =>{
            console.log(values,"adminFormValues");

           navigate("/adminEmailVerify") 
    }

    // const handleVerify = () =>{
    //      setVerifyEmail(true)
    // }

   
    return (
       
    <div className='adminLoginContainer'>
    <div className='adminLoginBanner'>
    <img src= {banner} className='bannerImage'/>

    </div>

    <div className='adminLoginForm'>
        <div className='adminLogo'>
        <img src={logo}/>
        </div>
             <Form
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <div className="basicDetails">
                        <div className='firstName'>
                            <Form.Item
                                label="First name"
                                name="first_name"
                                rules={[
                                    {
                                        required: false,
                                        message: 'First name is required',
                                    },
                                ]}>
                                <InputComponent placeholder={"Enter email"} />
                            </Form.Item>
                        </div>
                        <div className='lastName'>
                            <Form.Item
                                label="First name"
                                name="first_name"
                                rules={[
                                    {
                                        required: false,
                                        message: 'First name is required',
                                    },
                                ]}>
                                <InputComponent placeholder={"Enter email"} />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='selectAdmin'>

                        <div className='gender'>
                            <Form.Item
                                label="Gender"
                                name="gender"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Gender is required',
                                    },
                                ]}>
                                <InputSelect defaultValue={"select"} />
                            </Form.Item>
                        </div>

                        <div className='age'>
                            <Form.Item
                                label="Age"
                                name="age"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Age is required',
                                    },
                                ]}>
                                <InputSelect defaultValue={"select"} />
                            </Form.Item>
                        </div>

                    </div>

                    <div className="basicDetails">
                        <div className='firstName'>
                            <Form.Item
                                label="Phone number"
                                name="phone_no"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Phone number is required',
                                    },
                                ]}>
                                <InputComponent placeholder={"Enter email"} />
                            </Form.Item>
                        </div>
                        <div className='lastName'>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Email is required',
                                        type: "email"
                                    },
                                ]}>
                                <InputComponent placeholder={"Enter email"} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className=''>
                        <Form.Item
                            label="Address "
                            name="address"
                            rules={[
                                {
                                    required: false,
                                    message: 'Address is required',
                                },
                            ]}>
                            <InputComponent placeholder={"Enter Address"} />
                        </Form.Item>
                    </div>
                    <div>
                        <div className='adminRegTerms'>
                            <Switch></Switch> <p className='textUnderLine'>Terms and conditions</p>
                        </div>
                    </div>

                    <div className='adminRegisterButton'>
                        <Form.Item>
                            <Button htmlType='submit' primary type='primary' className='loginAdmin'>Register</Button>

                        </Form.Item>
                    </div>


                </Form>
               
                <div className='text-center admiLoginWithOthers'>
                <p>Login with Others</p>
            </div>

            <div className='socialIcons admin'>
            <div className='facebookIcon'>
                <img src={faceBookIcon} />
            </div>
            <div className='googleIcon'>
                <img src={googleIcon} />
            </div>
        </div>

            <div className='text-center adminRegister'>
                <p>Don't have account ? <b>Register here</b></p>
            </div>
               
        </div>   
        </div>     
    );
}

export default AdminRegistration;
