import React from 'react'
import { InputComponent ,TextAreaComponent} from '../../../commonComponents'
import './addCategory.css';
import { Button, Form } from 'antd';
let AddCategory=(props)=> {
  return (
    <div className='addCatgeory'>
      <Form 
         autoComplete="off"
         layout="vertical"
         form={props.form}
         onFinish={props.onFinish}>
        <div className=''>
          <Form.Item
          label="Category Name"
          name={"course_category_name"}
          rules={[
            { required: true, message: 'Please enter category name' }
            ]}>

            <div>
                <InputComponent
                 placeholder={"Category Name"}
                 name={"course_category_name"}
                 value={props.categoryName}
                 onChange={(e)=>props.handleCategoryName(e,{name: "course_category_name"})}/>
            </div>
            </Form.Item>

        </div>


        <div className=''>
        <Form.Item
          label="Category Description"
          name={"course_category_description"}
          rules={[
            { required: true, message: 'Please enter description' }
            ]}>
            <div>
                <InputComponent
                 placeholder={"Category Description"}
                 name={"course_category_description"}
                 value={props.addCategoryDetails.course_category_description}
                 onChange={(e)=>props.handleChange(e,{name: "course_category_description"})}/>
            {/* <TextAreaComponent rows={10}
                   placeholder={"Category Description"}
                   name={"course_category_description"}
                   value={props.addCategoryDetails.course_category_description}
                   onChange={(e)=>props.handleChange(e,{name: "course_category_description"})}/>
            <p className='text-danger'>{props.errors?.descriptionError ? props.errors. descriptionError : ""}</p> */}

            </div>
            </Form.Item>
        </div>
        
        <div className='CourseDetailsSubmit'>
                    <Form.Item>
                    <Button htmlType='submit' primary type='primary'>Submit</Button>
                    </Form.Item>
                </div>

        </Form>
    </div>
  )
}

export default AddCategory