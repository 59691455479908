import { GET_TENANT_ADMINS,CREATE_UPDATE_TENANT_STATUS } from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';


export const getTenantAdmins = (data) =>{
    return {
        type:GET_TENANT_ADMINS,
        payload: data
    }
}

export const tenantAdminCreateUpdateDeleteStatus = (data)=>{
    return{
        type:CREATE_UPDATE_TENANT_STATUS,
        payload: data
    }
}


export const createTenantAdmin = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/lms_admin_user',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                // dispatch(getTenantAdmins(res?.data))
                console.log(res,"iocl")
                dispatch(tenantAdminCreateUpdateDeleteStatus(res?.data))
                dispatch(retrieveTenantAdminRecords())
            }

            if (res?.data?.res_status == false) {
                dispatch(tenantAdminCreateUpdateDeleteStatus(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveTenantAdminRecords = ()=>{
    let payload = {
        "operation_type":"retrieve"
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/lms_admin_user',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(getTenantAdmins(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}

export const deleteTenantAdmin = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/lms_admin_user',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(retrieveTenantAdminRecords());
                dispatch(tenantAdminCreateUpdateDeleteStatus(res?.data))

            }
          
            if (res?.data?.res_status == false) {
                dispatch(tenantAdminCreateUpdateDeleteStatus(res?.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}