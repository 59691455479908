import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, notification } from 'antd';
import { ButtonComponent } from '../../commonComponents';
import { resetAdminPassword } from '../../redux/instructorProfile/action';
import { useNavigate } from 'react-router-dom';



let AdminPasswordReset=()=> {

    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    let dispatch = useDispatch();

    let navigate = useNavigate();
    
    let passwordStatus = useSelector((state)=>state.instructorProfileReducer?.passwordStatus)

    let [profileValues,setProfileValues] = useState({
        current_password:"",
        new_password:"",
        confirm_password:"",
        profile_picture:""
    })

    let handleChange = (e,type)=>{
        if (e.target !== undefined) {
            const { name, value } = e.target;
            setProfileValues({ ...profileValues, [name]: value });
            form.setFieldsValue({[type.name]: e})
    
          } else {
            setProfileValues({ ...profileValues, [type.name]: e })
            form.setFieldsValue({[type.name]: e})
    
          }
          console.log(profileValues,"vaues")
    }

    let onFinish = (values)=>{
        console.log(values)
        // let payload =   {
        //     "operation_type":"change_password",
        //        "op_field_details":{
        //     "instructor_id": window.localStorage.getItem("instructor_id"),
        //     "current_password": profileValues?.current_password,
        //     "new_password": profileValues?.new_password,
        //     "confirm_password": profileValues?.confirm_password
        //        }
        // }


        let payload =  {
          "operation_type":"admin_change_password",
          "op_field_details": {
             "email": window.localStorage.getItem("adminEmail"),
              "current_password": profileValues?.current_password,
              "new_password": profileValues?.new_password,
              "confirm_password": profileValues?.confirm_password,
              "tenant_id": "1"
          }
      }
        dispatch(resetAdminPassword(payload))
    }

    useEffect(()=>{
        if (passwordStatus == true) {
          // alert("openTrue")
          navigate("/main")
        }
        if (passwordStatus == false) {
            // alert("openFalse")
        }
    },[passwordStatus])

    const cancelResetPassword = () =>{
      navigate("/adminProfile")
    }
  return (
    <div className='resetPasswordContainer'>
         <Form 
            autoComplete="off"
            layout="vertical"
            form={form}
            onFinish={onFinish}>
                <div className='studentPassword'>
                    <Form.Item
                    label="Current password"
                    name="current_password"
                     rules={[
                        {
                        required: true,
                        message: 'Current password is required',

                        },
                    ]}
                    >
                    <div className='studentPassword'>
                    <Input.Password  name={"current_password"} value={profileValues.current_password} 
                     placeholder="Current password"
                     onChange={(e)=>handleChange(e,{name: "current_password"})} 

                        />
                     </div>
                    </Form.Item>
                    </div>

                    <div className='studentPassword'>
                    <Form.Item
                     rules={[
                        {
                        required: true,
                        message: 'New password is required',

                        },
                        {
                          required:true,
                          min:8,
                          message: "Password should atleast 8 characters with special and uppercase",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('current_password') !== value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Current and new password should not same'));
                          },
                        }),
                    ]}
                    label="New password"
                    name="new_password">
                    <div className='studentPassword'>
                    <Input.Password  name={"new_password"} value={profileValues.new_password}
                     placeholder="New password"
                     onChange={(e)=>handleChange(e,{name: "new_password"})} 

                        />
                     </div>
                    </Form.Item>
                    </div>

                    <div className='studentPassword'>
                    <Form.Item
                        label="Confirm Password"
                        name="confirm_password"
                        dependencies={['new_password']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your password!',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('new_password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                          }),
                        ]}>
                    <div className='studentPassword'>
                    <Input.Password  name={"confirm_password"} 
                     placeholder="Confirm password"
                     onChange={(e)=>handleChange(e,{name: "confirm_password"})} 

                        />
                     </div>
                    </Form.Item>

                    <Form.Item>
              <div className="studentProfileSave">
              {/* <ButtonComponent value={'Cancel'} onClick={()=>cancelResetPassword()} type={"dashed"}/> */}

              <ButtonComponent value={'UPDATE'}  htmlType={'Submit'}type={"primary"}/>

                </div>
              </Form.Item>
                    </div>
            </Form>
    </div>
  )
}

export default AdminPasswordReset