import { GET_STUDENT_PROFILE_DATA,RESET_DETAILS_RESULT,RESET_STUDENT_DETAILS ,RESET_STUDENT_PASSWORD} from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';

const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    };


export const getStudentProfileData = (data)=>{
    return {
        type: GET_STUDENT_PROFILE_DATA,
        payload: data
    }
}


export const resetStudentDetails = (data)=>{
    return {
        type:RESET_STUDENT_DETAILS,
        payload: data
    }
}

export const resetStudentDetailsResult = (data)=>{
    console.log(data,"resultOFFFF")
        return {
            type: RESET_DETAILS_RESULT,
            payload:data
        }
}

export const getStudentResetPassword = (data)=>{
    return {
        type: RESET_STUDENT_PASSWORD,
        payload:data
    }
}

export const retrieveStudentProfileData = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/student_profile',payload
        ).then((res)=>{
            if (res.data.res_status == true) {
                dispatch(getStudentProfileData(res.data));
            }
            
        }).catch(err=>{
            console.log(err)
        })
    }
    }


export const uploadProfileDetails =(payload)=>{
    console.log("reset",payload)
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/student_profile',payload
            ).then((res)=>{
                if (res.data.res_status == true) {
                    dispatch(resetStudentDetails(res.data));
                    dispatch(resetStudentDetailsResult(res.data))

                }
                if (res.data.res_status == false) {
                    dispatch(resetStudentDetailsResult(res.data))
                }
            }).catch(err=>{
                console.log(err)
            })
    }
}

export const uploadStudentImage =(payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/student_profile',payload
            ).then((res)=>{
                // if (res.data.res_status == true) {
                //     dispatch(resetStudentDetails(res.data));
                // }
            }).catch(err=>{
                console.log(err)
            })
    }
}

export const resetStudentPassword = (data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/student_profile',data
            ).then((res)=>{
                if (res.data.res_status == true) {
                    dispatch(getStudentResetPassword(res.data));
                }
                if (res.data.res_status == false) {
                    dispatch(getStudentResetPassword(res.data));
                    
                }
            }).catch(err=>{
                console.log(err)
            })
    }
}