import {GET_BATCHES, GET_INSTRUCTORS_AND_COURSES, SET_BATCHES,BATCH_CRUD_STATUS
,GET_INSTRUCTORS_FOR_COURSE,CREATE_ZOOM_MEETING_FOR_BATCH,BATCH_CREATE_NOTIFICATION,
BATCH_UPDATE_NOTIFICATION} from './actionTypes';

import axios from 'axios';
import { push } from 'connected-react-router';
import apiConfig from '../../apiConfig/api';


const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    };


export const getInstructorCourseDetails = (data)=>{
    return {
        type: GET_INSTRUCTORS_AND_COURSES,
        payload: data.data
    }
}

export const getBatches = (data)=>{
    return {
        type: GET_BATCHES,
        payload: data
    }
}

export const getAllBatches = (data)=>{
    return {
        type: SET_BATCHES,
        payload: data
    }
}

export const batchCrudStatus = (data)=>{
    return {
        type:BATCH_CRUD_STATUS,
        payload:data
    }
}

export const getInstructorsBasedOnCourse = (data)=>{
    return{
        type:GET_INSTRUCTORS_FOR_COURSE,
        payload:data
    }
}

export const meetingCreationStatus = (data)=>{
    return {
        type:CREATE_ZOOM_MEETING_FOR_BATCH,
        payload:data
    }
}


export const retrieveInstructorsBasedOnCourse = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/batch",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getInstructorsBasedOnCourse(res?.data));
            }
            if (res?.data?.res_status == false) {
                dispatch(getInstructorsBasedOnCourse([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const  fetchInstructorCourseDetails = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/batch",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getInstructorCourseDetails(res));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

//create batch
const moment = require('moment');

export const createBatch=(payload)=>{
 
    console.log(payload)
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/batch",payload, {headers}
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getBatches(res));
                dispatch(retrieveBatches());
                dispatch(batchCrudStatus(res?.data))
                console.log(res?.data?.batch_data?.batch_id,res?.data?.batch_data,"impo")
                window.localStorage.setItem("batch_id",res?.data?.batch_data?.batch_id)
                window.localStorage.setItem("batch_name",res?.data?.batch_data?.batch_name)
                window.localStorage.setItem("start_time",res?.data?.batch_data?.timestamp)
                window.localStorage.setItem("duration",res?.data?.batch_data?.duration)
               
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

//getall batches

export const retrieveBatches = ()=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/batch",{"operation_type": "retrieve","tenant_id":window.localStorage.getItem("tenant_Id")}
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getAllBatches(res));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

//Delete batch

export const deleteBatch = (data) =>{ 
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/batch",data
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getAllBatches(res));
                dispatch(retrieveBatches())
                dispatch(batchCrudStatus(res?.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

//update batch

export const updateBatch = (data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/batch",data
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getAllBatches(res));
                dispatch(retrieveBatches());
                dispatch(batchCrudStatus(res?.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const createZoomLinkForBatch = (data)=>{
    console.log(window.localStorage.getItem("batch_id"),"payloadVerify")
    return(dispatch)=>{
        axios.get(apiConfig.baseUrl+`e_lms/v1/meetingAuthentication?code=${data?.zoomCode}&title=${window.localStorage.getItem("batch_name")}&duration=${window.localStorage.getItem("duration")}&start_time=${window.localStorage.getItem("start_time")}
        &batch_id=${window.localStorage.getItem("batch_id")}&tenant_id=${window.localStorage.getItem("tenant_Id")}`,data
        ).then((res)=>{
          if (res?.data?.meeting_link !=="") {
            dispatch(retrieveBatches())
            dispatch(meetingCreationStatus(res?.data))
            window.localStorage.removeItem("batch_id")
            window.localStorage.removeItem("batch_name")
            window.localStorage.removeItem("start_time")
            window.localStorage.removeItem("duration")
            dispatch(push('/allbatches'));
          }
        }).catch(err=>{
            console.log(err)
        })
}
}


//pushNotification 1-05-2024



export const batchPushNotification = (data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/push_notification",data
        ).then((res)=>{
            if (res.data?.res_status == true) {
               
            }
        }).catch(err=>{
            console.log(err)
        })
}
}
//end

export const getBatchCreateNotification = (data)=>{
    return{
        type:BATCH_CREATE_NOTIFICATION,
        payload:data
    }
}

export const batchCreateNotification = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/notification",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getBatchCreateNotification(res.data)); 
                // dispatch(retrieveCancelledClasses())   
            }else{

            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}


export const getUpdateBatchNotification = (data)=>{
    return{
        type:BATCH_UPDATE_NOTIFICATION,
        payload:data
    }
}

export const updateBatchNotification = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/notification",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getUpdateBatchNotification(res?.data));
                dispatch(retrieveBatches())

            }
            if (res?.data?.res_status == false) {
                dispatch(getUpdateBatchNotification([]))
                dispatch(retrieveBatches())

            }
        }).catch(err=>{
            console.log(err)
        })
}
}