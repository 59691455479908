import React, { useEffect, useState } from 'react'

import './addAnnouncement.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, notification,Radio, Select,Checkbox } from 'antd';
import { ButtonComponent, InputComponent, InputSelect } from '../../../commonComponents';
import { createAdminAnnouncement } from '../../../redux/adminAnnouncements/action';
import { course } from '../../../images';
import { AutoComplete } from 'antd';

let AddAnnouncement=(props)=> {

  
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    let [course,setCourse] = useState();

    let [batches,setBatches] = useState();


    let {courses} = props;

    console.log(props?.courses)
    let list=[]

    useEffect(()=>{
    
        if (props?.courses) {
            props?.courses && (props?.courses).map((i,Index)=>{
    
           let newObj= {};
           newObj["value"] = i.course_id;
           newObj["label"] = i.course_name;
         list.push(newObj)
         setCourse(list)
         })
         console.log(course,"abc")
        }
      },[props?.courses])


      useEffect(()=>{
    
        if (props?.allBatches) {
            props?.allBatches && (props?.allBatches).map((i,Index)=>{
    
           let newObj= {};
           newObj["value"] = i.batch_id;
           newObj["label"] = i.batch_name;
         list.push(newObj)
         setBatches(list)
         })
         console.log(course,"abc")
        }
      },[props?.allBatches])

    // console.log(courses.map((item,index)=>{
    //     console.log(item.course_id,item.course_name,"just")
    // }))

    console.log(courses,"allcourses")

    let dispatch = useDispatch();

    let navigate = useNavigate();

    React.useEffect(() => {
        window.addEventListener('touchmove', props?.selectChange);
        console.log("touchmove")
        return () => {
          window.removeEventListener('touchmove', props?.selectChange);
        };
      }, []);



    // let onFinish= (values)=>{
    //     console.log(values,"finishvalues")
    //     let payload = {
    //         "operation_type":"insert",
    //         "op_field_details":{
    //             "role_type": props?.fieldValues?.role_type,
    //             "course": props?.fieldValues?.course_name,
    //             "title": props?.fieldValues?.title,
    //             "description": props?.fieldValues?.description,
    //             "upload_files": props?.fieldValues?.uploadFiles,
    //             "tenant_id":1
    //         }
    //     }

    //     dispatch(createAdminAnnouncement(payload))
    // }



    const [selectedBatches, setSelectedBatches] = useState(props.fieldValues.batch_name ? props.fieldValues.batch_name : []);
    const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  
    const handleSelectChange = (value) => {
      setSelectedBatches(value);
      props.handleChange(value, { name: 'batches_name' });
      setIsSelectAllChecked(value.length === batches.length);
    };
  
    const handleSelectAllChange = (e) => {
      const { checked } = e.target;
      if (checked) {
        const allBatchValues = batches.map(batch => batch.value);
        setSelectedBatches(allBatchValues);
        props.handleChange(allBatchValues, { name: 'batches_name' });
      } else {
        setSelectedBatches([]);
        props.handleChange([], { name: 'batches_name' });
      }
      setIsSelectAllChecked(checked);
    };
    
  return (
    <div>
        <Form 
         autoComplete="off"
         layout="vertical"
         form={props.form}
         onFinish={props.onFinish}>
             <div className='announcementAddContainer'>
                <div>
                    <Form.Item
                    label="Select Role"
                    name="role_ty"

                    rules={[{ required: true, message: 'Please select role' }]}>
                    <Radio.Group value={props.fieldValues.role_type} name='role_type'
                    onChange={(e)=>props.handleChange(e,{name:"role_type"})}>
                    <Radio value={"Student"}>Student</Radio>
                    <Radio value={"Instructor"}>Instructor</Radio>
                    </Radio.Group>
                    </Form.Item>
                </div>

                <div className='courseName'>
                <Form.Item
                 label="Course Name"
                 name="course_name"
                  rules={[
                    { required: true, message: 'Please select a course!' }
                    ]}
                 >
                   
                        <InputSelect 
                  name={"course_name"}
                  onChange={(e)=>props.handleChange(e,{name: "course_name"})}
                  defaultValue={props.fieldValues.course_name  ? props.fieldValues.course_name :"Select"}
                //   value={props.fieldValues.course_name  ? props.fieldValues.course_name :"select"}
                     options={course}/>

                </Form.Item>
                </div>

                {/* <div className='courseName'>
                <Form.Item
                 label="Batches"
                 name="batches_name"
                  rules={[
                    { required: true, message: 'Please select a batches!' }
                    ]}
                 >
                   
                        <InputSelect 
                            mode="multiple"

                  name={"batches_name"}
                  onChange={(e)=>props.handleChange(e,{name: "batches_name"})}
                  defaultValue={props.fieldValues.batch_name  ? props.fieldValues.batch_name :"select"}
                //   value={props.fieldValues.course_name  ? props.fieldValues.course_name :"select"}
                     options={batches}/>

                </Form.Item>
                </div> */}


                <div className='courseName'>

                <Form.Item
  label="Batches"
  name="batches_name"
  rules={[{ required: true, message: 'Please select batches!' }]}
>  <Checkbox
        checked={isSelectAllChecked}
        onChange={handleSelectAllChange}
      >
        Select All
      </Checkbox>
  <Select
    mode="multiple"
    name="batches_name"
    // onChange={(value) => props.handleChange(value, { name: 'batches_name' })}
    // defaultValue={props.fieldValues.batch_name ? props.fieldValues.batch_name : []}
    onChange={handleSelectChange}
    value={selectedBatches}
    options={batches}
  />
</Form.Item>
                </div>


                <div>
                <Form.Item
                 label="Title"
                 name={"title"}
                 className='disabled'
                 rules={[
                     {
                     required: true,
                     message: 'Title is required',

                     },
                 ]}>
                    <div>
                        <InputComponent value={props.fieldValues.title}
                        name={"title"}
                         onChange={(e)=>props.handleChange(e,{name:"title"})}/>
                    </div>
                </Form.Item>
                </div>

                <div>
                <Form.Item
                 label="Description"
                 name={"description"}
                 className='disabled'
                 rules={[
                     {
                     required: true,
                     message: 'Description is required',

                     },
                 ]}>
                    <div>
                        <InputComponent name={"description"}
                        onChange={(e)=>props.handleChange(e,{name:"description"})}/>
                    </div>
                </Form.Item>
                </div>


                <div>
                <Form.Item
                 label="Upload"
                 name={"uploadFiles"}
                 className='disabled'
                 rules={[
                     {
                     required: true,
                     message: 'File is required',

                     },
                 ]}>
                    <div>
                        <InputComponent type={"file"} name={"uploadFiles"}
                        onChange={(e)=>props.onFileChange(e,{name:"uploadFiles"})}/>
                    </div>
                </Form.Item>
                </div>
               

                <Form.Item>
                <div className='addAnnouncementBtn'>

                <Button type={"secondary"}  className="cancel" onClick={props?.closeModal}>Cancel</Button>
                <div className='alignRight'> 
                <ButtonComponent value={'Submit'}  htmlType={'Submit'} type={"primary"}/>
                </div>

                </div>
                </Form.Item>
            </div>

        </Form>
        
       
    </div>
  )
}

export default AddAnnouncement