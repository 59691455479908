import { STUDENT_RELATED_COURSES,ADD_ATTENDANCE_STUDENT} from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const relatedCoursesReducer = (state = initialStates,action)=>{
    switch (action.type) {
        case  STUDENT_RELATED_COURSES:
            return {
                relatedCourses: action.payload,
            }

            case  ADD_ATTENDANCE_STUDENT:
                return {
                    addAttendanceStudent: action.payload,
                }
        
        default:
            return state;
    }
}

export default  relatedCoursesReducer;