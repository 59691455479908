import { Button, Form, Input, Select } from 'antd';
import React from 'react';
import { InputComponent, InputSelect } from '../../../../commonComponents';

import './addtenantApproval.css'
const AddtenantApproval = (props) => {
    console.log(props.tenantRecords,"tenants")
    return (
        <div className='tenantAdminContainer'>
            <Form
             autoComplete="off"
             layout="vertical"
             form={props.form}
             onFinish={props.onFinish}>

                <Form.Item
                 label="First Name"
                 name="firstName"
                 rules={[
                     { required: true, message: 'Please enter first name' }
                     ]}>
                    
                    <InputComponent placeholder={'First name'}
                    value={props.firstName} onChange={(e)=>props.handleChange(e,{name:"firstName"})}/>
                </Form.Item>

                <Form.Item
                 label="Last Name"
                 name="lastName"
                 rules={[
                     { required: true, message: 'Please enter last name' }
                     ]}>
                    
                    <InputComponent placeholder={'Last name'}
                    value={props.lastName} onChange={(e)=>props.handleChange(e,{name:"lastName"})}/>
                </Form.Item>

                <div className='adminTenant'>
                <Form.Item
                 label="Tenant"
                 name="tenantId"
                 rules={[
                     { required: true, message: 'Please select tenant' }
                     ]}>
                   {/* <InputSelect defaultValue={"select"}/> */}
            <Select name={"tenantId"} value={props.tenantId} 
                defaultValue={props.tenantId  ? props.tenantId :"select"}
                    onChange={(e)=>props.handleChange(e,{name:"tenantId"})}>
                    {props.tenantRecords && props.tenantRecords.map((item,index)=>{
                    return  <Select.Option value={item.tenant_id}>{item.tenant_name}</Select.Option>
                    })}
                </Select>
                </Form.Item>
                </div>
              

                <Form.Item
                 label="Email"
                 name="email"
                 rules={[
                     { required: true, message: 'Please enter email',type:"email" }
                     ]}>
                    
                    <InputComponent placeholder={'Email'}
                    value={props.email} onChange={(e)=>props.handleChange(e,{name:"email"})}/>
                </Form.Item>

                <Form.Item
                 label="Phone number"
                 name="phoneNumber"
                 rules={[
                     { required: true, message: 'Please enter phone number' }
                     ]}>
                    
                    <InputComponent placeholder={'phoneNumber'} name="phoneNumber"
                    value={props.phoneNumber} onChange={(e)=>props.handleChange(e,{name:"phoneNumber"})}/>
                </Form.Item>

                <Form.Item
                 label="Password"
                 name="password"
                 rules={[
                     { required: true, message: 'Please enter password' }
                     ]}>
                    
                    <Input.Password placeholder={'password'} name ="password"
                    value={props.password} onChange={(e)=>props.handleChange(e,{name:"password"})}/>
                </Form.Item>

                
                <Form.Item
                 label="Confirm Password"
                 name="confirmPassword"
                 rules={[
                     { required: true, message: 'Please enter confirm password' }
                     ]}>
                    
                    <Input.Password placeholder={'confirm password'} name='confirmPassword'
                    value={props.confirmPassword} onChange={(e)=>props.handleChange(e,{name:"confirmPassword"})}/>
                </Form.Item>

                <Form.Item>
                <div className='addTenantFooterBtns'>
                <div>
                <Button type={"secondary"}  className="cancel">Cancel</Button>
                </div>
                <div className='alignRight'>
                <Button type={"primary"}  className="submit" htmlType="Submit">Submit</Button>
                </div>
                </div>
                </Form.Item>

            </Form>
        </div>
    );
}

export default AddtenantApproval;
