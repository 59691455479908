import React from 'react';
import { Select, Space } from 'antd';

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const optionsinitial = [];
for (let i = 10; i < 36; i++) {
    optionsinitial.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}
const MultiSelect = ({options,onChange,defaultValue}) => (
  <Space wrap>
    <Select
      style={{
        width: 120,
      }}
      onChange={onChange}
     options= {options}
     defaultValue={defaultValue}
     mode='multiple'
    />
  </Space>
);
export default MultiSelect;
