import { GET_CATEGORY, SET_CATEGORY,EMAIL_EXISTS, CATEGORY_SUCCESS, ADMIN_CATEGORY_CRUD } from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const categoryReducer = (state = initialStates,action)=>{

    switch (action.type) {
        case  SET_CATEGORY:
            return {
                ...state,
                data: action.payload,
            }

            case GET_CATEGORY:

            return{
                category: action.payload,
            }

            case EMAIL_EXISTS:
                return{
                    ...action.payload,
                    verify: action.payload
                }
            
            case CATEGORY_SUCCESS:
                return{
                    ...action.payload,
                    categorySuccess: action.payload
                }
            
                case ADMIN_CATEGORY_CRUD:
                    return{
                        adminCategoryCrud: action.payload
                    }
        default:
            return state;
    }
}

export  default categoryReducer;