export const SET_STUDENT_REGISTRATION = "SET_STUDENT_REGISTRATION";

export const GET_STUDENT_REGISTRATION = "GET_STUDENT_REGISTRATION";

export const SET_VERIFY_EMAIL = "SET_VERIFY_EMAIL";

export const GET_VERIFY_EMAIL = "GET_VERIFY_EMAIL";

export const SET_PASSWORDS = "SET_PASSWORDS";

export const SET_EMAIL = "SET_EMAIL";

export const RETRIEVE_INTERESTED_COURSES = "RETRIEVE_INTERESTED_COURSES";


export const RETRIEVE_COURSE_DETAILS = "RETRIEVE_COURSE_DETAILS";

export const PRIVACY_POLICY_DETAILS = "PRIVACY_POLICY_DETAILS";