import {GET_INSTRUCTORS_AND_COURSES,GET_BATCHES, SET_BATCHES,BATCH_CRUD_STATUS, 
    GET_INSTRUCTORS_FOR_COURSE,CREATE_ZOOM_MEETING_FOR_BATCH,BATCH_CREATE_NOTIFICATION,
    BATCH_UPDATE_NOTIFICATION} from "./actionTypes";

const initialStates = {
    loading:true,
    batchDetails:[],
}

 const batchReducer = (state = initialStates,action)=>{

    switch (action.type) {
        case GET_INSTRUCTORS_AND_COURSES:
            return {
                ...state,
                data: action.payload
            }

        case GET_BATCHES:
            return {
                ...state,
                allBatches: action.payload
            }
        
       case SET_BATCHES:
        return {
            ...state,
            batches: action.payload
        }

        case BATCH_CRUD_STATUS:
            return{
                batchCrudStatus: action.payload
            }
        
        case GET_INSTRUCTORS_FOR_COURSE:
            return {
                courseInstructors:action.payload
            }
        
        case CREATE_ZOOM_MEETING_FOR_BATCH:
            return {
                batchMeetingStatus:action.payload
            }
            case BATCH_CREATE_NOTIFICATION:
                return {
                    ...state,
                    batchCreateNotification: action.payload
                }

                case BATCH_UPDATE_NOTIFICATION:
                    return {
                        batchUpdateNotification:action.payload
                    }
                // return {
                //     batchCreateNotification:action.payload
                // }
         default:
            return state;
    }
}

export  default batchReducer;