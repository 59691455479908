import React from 'react';

import { logo ,tenantEnquiryBg} from '../../images';

import { ButtonComponent, InputComponent } from '../../commonComponents';


import { Button, Form } from 'antd';

import './tenantEnquiry.css';
import { useDispatch } from 'react-redux';
const TenantEnquiry = () => {

    const [form] = Form.useForm();
    let dispatch = useDispatch()

    let onFinish = (values) =>{
        console.log(values)
    }

    let handleChange = (e,type) =>{

    }

    const validatePhoneNumber = (_, value) => {
        if (!value || /^\d{10}$/.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Phone number must be 10 digit'));
      };

    return (
        <div className='tenantEnquiryContainer' style={{backgroundImage: `url(${tenantEnquiryBg} )`, height:"100vh"}}>
                <div className='tenantLogoContainer'>
                    <img src={logo}/>
                    <h3 className='tenantEnquiryText'>Tenant Enquiry</h3>
                <div>
                    <Form
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}>

                <div>
                <Form.Item label="First name"
                 name="first_name"
                  rules={[
                    { required: true, message: 'Please enter first name!' }
                    ]}>
                    <div>
                        <InputComponent 
                        name={"first_name"}
                         onChange={(e)=>handleChange(e,{name:"first_name"})}/>
                    </div>
                </Form.Item>
                </div>
                

                <div>
                <Form.Item label="Last name"
                 name="last_name"
                  rules={[
                    { required: true, message: 'Please enter last name!' }
                    ]}>
                    <div>
                        <InputComponent 
                        name={"last_name"}
                         onChange={(e)=>handleChange(e,{name:"last_name"})}/>
                    </div>
                </Form.Item>
                </div>

                <div>
                <Form.Item label="Phone number"
                 name="phone_no"
                 type="password"
                  rules={[
                    { required: true, message: 'Please enter mobile number' },
                    {
                        validator: validatePhoneNumber,
                      },
                    ]}
                    hasFeedback
                    >
                    <div>
                        <InputComponent 
                        name={"phone_no"}
                         onChange={(e)=>handleChange(e,{name:"phone_no"})}/>
                    </div>
                </Form.Item>
                </div>

                <div>
                <Form.Item label="Email"
                 name="email"
                  rules={[
                    { required: true, message: 'Please enter email!' }
                    ]}>
                    <div>
                        <InputComponent 
                        name={"email"}
                         onChange={(e)=>handleChange(e,{name:"email"})}/>
                    </div>
                </Form.Item>
                </div>

                <div>
                <Form.Item label="Short note"
                 name="short_note"
                  rules={[
                    // { required: true, message: 'Please enter first name!' }
                    ]}>
                    <div>
                        <InputComponent 
                        name={"first_name"}
                         onChange={(e)=>handleChange(e,{name:"short_note"})}/>
                    </div>
                </Form.Item>
                </div>
                

                <div className='enquirySumitBtn'>
                    <Form.Item>
                    <Button htmlType='submit' primary type='primary'>Submit</Button>

                    </Form.Item>
                </div>
                    </Form>
                </div>
        </div>
        </div>

    );
}

export default TenantEnquiry;
