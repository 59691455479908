import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveStudentReports } from '../../../redux/adminReports/studentReports/action.js';

import { TableComponent } from '../../../commonComponents';
import { Table } from 'antd';
import moment from 'moment';
import { excel, plus, upload, searchIcon } from "../../../images";

import * as XLSX from 'xlsx';
import ExportToExcel from '../../../commonComponents/XlsxFile/ExportToExcel'
const StudentReports = (props) => {

    let dispatch = useDispatch()

    const allStudentAdminReports = useSelector((state)=>state?.StudentReportsReducer?.studentsReportsData)

    const [adminStudentReports,setAdminStudentReports] = useState();
    useEffect(()=>{
        let payload = {
            "operation_type":"studentreports_view",
            "tenant_id": window.localStorage.getItem("tenant_Id")
        }
        dispatch(retrieveStudentReports(payload))
    },[])



    //studentReport
    useEffect(()=>{
        if (allStudentAdminReports) {
            // console.log(allStudentAdminReports?.data,"studentsReports")
            setAdminStudentReports(allStudentAdminReports?.data)
        }
    },[allStudentAdminReports])
//studentReport

const columns = [
    {
        title: 'Student ID',
        dataIndex: 'student_id',
        sorter:true,
        sorter: (a, b) => a.student_id -(b.student_id),
  
      },
      {
        title: 'Student Name',
        dataIndex: 'student_name',
        sorter:true,
        sorter: (a, b) => a.student_name .localeCompare(b.student_name),
  
      },
      {
        title: 'Enrolled Course',
        dataIndex: 'enrolled_course',
        sorter:true,
        sorter: (a, b) => a.enrolled_course .localeCompare(b.enrolled_course),
  
      },
      {
        title: 'Start Date',
        dataIndex: 'batch_start_date',
        sorter:true,
        sorter: (a, b) => a.batch_start_date .localeCompare(b.batch_start_date),
        render: (text, record) => (
          <div className="actionIcons">
              <span>{(moment(record.batch_start_date, "DD-MM-YYYY HH:mm:ss"))
              ? (moment(record.batch_start_date, "DD-MM-YYYY HH:mm:ss")).format("DD-MM-YYYY") : "" }</span>
          </div>
         ),
  
      },
      {
        title: 'End Date',
        dataIndex: 'batch_end_date',
        sorter:true,
        sorter: (a, b) => a.batch_end_date .localeCompare(b.batch_end_date),
        render: (text, record) => (
          <div className="actionIcons">
              <span>{(moment(record.batch_end_date, "DD-MM-YYYY HH:mm:ss"))
              ? (moment(record.batch_end_date, "DD-MM-YYYY HH:mm:ss")).format("DD-MM-YYYY") : "" }</span>
          </div>
         ),
      },
      {
        title: 'Course Status',
        dataIndex: 'completed_courses',
        sorter:true,
        sorter: (a, b) => a.completed_courses .localeCompare(b.completed_courses),
  
      },
]






const exportToExcel = (data, fileName) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};


const transformedItems = adminStudentReports?.map((item) => {
 

  return { 
     STUDENT_ID: item.student_id,
     STUDENT_NAME:  item.student_name,
    ENROLLED_COURSE:  item.enrolled_course,
    BATCH_START_DATE: item.batch_start_date,
    BATCH_END_DATE:  item.batch_end_date,
   COMPLETED_COURSE:  item.completed_courses,
  
  
  };
});

let handleCategoryModal1 = (type,id) =>{
   
  exportToExcel(transformedItems, 'student_report');

  
}

    return (
        <div>
          <div style={{display:'flex' ,justifyContent:'flex-end',marginBottom:'10px'}}>

<img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

</div>
          <TableComponent columns={columns} data={adminStudentReports}/>
        </div>
    );
}

export default StudentReports;
