import React,{useEffect, useState} from "react";
import { NavLink, Outlet, redirect, useNavigate } from "react-router-dom";
import { ButtonComponent, PopOverComponent } from "../../../commonComponents";

import { Breadcrumb, Layout, Menu } from 'antd';

import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
  } from '@ant-design/icons';
import './header.css';

import {bell,
    messageIcon,
    profileIcon,
    information,
    searchIcon,logo,dashboard,
    allStudents,
    allInstructors,
    announcements,
    accounts,
    roles,
    settings,
    schedules,
    allCourses,new_info} from '../../../images';


import { Button, ConfigProvider,theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { retrieveAllNotification,notificationUpdate,retrieveAllNotificationCount, setToken } from "../../../redux/studentLogin/action";
import PrivateRoute from "../../privateRoute";
import notification from "./notification";
import NotificationComponent from "./notification";
import { retrieveAnnouncementTypeNotification ,} from "../../../redux/commonGroupRetrieve/action";
let HeaderComponent = (props) =>{




  const { defaultAlgorithm, darkAlgorithm } = theme;

  let dispatch = useDispatch()
  const [isDarkMode, setIsDarkMode] = useState(false);

  let [roleType,setRoleType] = useState("");
  const [announcementData,setAnnouncementData] = useState();
  const [notificationCountValue,setNotificationCountValue] = useState();


  let navigate = useNavigate();

  let isAuthenticated = useSelector((state)=>state.studentLoginReducer?.studentLogin?.data?.access_token);

  let announcementTypeNotification = useSelector((state)=>state.groupRetrieveReducer?.announcementTypeNotification)
  
  let retrieveGroupChartDetails = useSelector((state)=>state.studentLoginReducer?.retrieveNotificationDetails)
  // let handleLogout =()=>{
  //   let data= isAuthenticated.access_token = undefined
  //   // dispatch(setToken(""))
  //   redirect("/") 
  // }

  // useEffect(()=>{
  //   if (isAuthenticated == "") {
  //     redirect("/") 
  //   }
  //   console.log(isAuthenticated.access_token,"ooo")
  // },[isAuthenticated])

  
useEffect(()=>{
  if (retrieveGroupChartDetails) {
    setAnnouncementData(retrieveGroupChartDetails)
  }
},[retrieveGroupChartDetails])




  useEffect(()=>{
    let roleType = window.localStorage.getItem("role_Type")
    setRoleType(roleType)
    console.log(roleType,"roleType")
  },[])


      let popUp= [
        <div className="userProfile">
            <div className="profile">
              {roleType && roleType == "student" ? 
                <div>
                    <NavLink  to={'/studentProfile'}>Profile</NavLink>
                </div>
                :""}

              {roleType && roleType == "instructor" ?
                <div>
                  <NavLink to ={'/instructorProfile'}>Profile</NavLink>
                </div>
                :""}

{roleType && roleType == "admin" ?
                <div>
                  <NavLink to ={'/adminProfile'}>Profile</NavLink>
                </div>
                :""}
            </div>
            <div>
                <div>
                    <div  className="logout" onClick={props.handleLogout}>Logout</div>
                </div>
            </div>
        </div>
      ]

      let notificationPopUp = [
        <NotificationComponent data={announcementData} notificationCountValue={notificationCountValue}/>
      ]

      let img = [
        <img src={profileIcon} className='messageIcon'/>
      ]

    let notificationBellIcon =[
      <img src={bell} className='messageIcon'/>
      
    ]

    let informationIcon =[
      <img src={new_info} className='messageInformation' style={{height:"20px",width:'20px'}}/>
      
    ]

    let informationPopUp = [
      // <NotificationComponent data={announcementData}/>

      <div>
      <p>Your browser's notification permissions are denied.</p>
      <p>Please follow these steps to update your notification settings:</p>
      <ol>
        <li>Click on the three-dot menu icon in the top right corner of your browser window.</li>
        <li>Choose "Settings" from the dropdown menu.</li>
        <li>Scroll down and click on "Advanced" to expand the settings menu.</li>
        <li>Under "Privacy and security," click on "Site settings."</li>
        <li>Scroll down and find "Notifications."</li>
        <li>Adjust the settings to allow or block notifications as desired.</li>
      </ol>
    </div>
    ]


    //window.localStorage.getItem("instructorEmail")
    // window.localStorage.getItem("tenant_Id")

    //window.localStorage.getItem("adminEmail")
    // window.localStorage.getItem("tenant_Id")

    let notificationCount = useSelector((state)=>(state.studentLoginReducer?.notificationCountDetails));


    useEffect(()=>{

      if(window.localStorage.getItem("role_Type") == 'admin'){
        let payloadFireBase = {
          "operation_type":"count_is_seen",
          "username":window.localStorage.getItem("adminEmail"),
          "tenant_id":window.localStorage.getItem("tenant_Id")
        };
        dispatch(retrieveAllNotificationCount(payloadFireBase));
      }else if(window.localStorage.getItem("role_Type") == 'instructor'){
        let payloadFireBase = {
          "operation_type":"count_is_seen",
          "username":window.localStorage.getItem("instructorEmail"),
          "tenant_id":window.localStorage.getItem("tenant_Id")
        };
        dispatch(retrieveAllNotificationCount(payloadFireBase));
      }else if(window.localStorage.getItem("role_Type") == 'student'){
        let payloadFireBase = {
          "operation_type":"count_is_seen",
          "username":window.localStorage.getItem("userEmail"),
          "tenant_id":window.localStorage.getItem("tenant_Id")
        };
        dispatch(retrieveAllNotificationCount(payloadFireBase));
      }
     
  
    },[])

    useEffect(()=>{
      // console.log(notificationCount,'notificationCount')

      if(notificationCount){
        setNotificationCountValue(notificationCount.data[0].result)
        console.log(notificationCount.data[0].result,'notificationCount')
      }else{

      }
     
     
  
    },[notificationCount])


    const clickBell = ()=>{



      if(window.localStorage.getItem("role_Type") == 'admin'){


        let retrievePayload ={
        
          "operation_type":"retrieve",
          "username":window.localStorage.getItem("adminEmail"),
          "tenant_id":window.localStorage.getItem("tenant_Id")
      
  }
  dispatch(retrieveAllNotification(retrievePayload))


  let notificationUpdatePayload ={
    "operation_type":"update_is_seen",
    "username":window.localStorage.getItem("adminEmail"),
    "is_seen": true,
    "tenant_id":window.localStorage.getItem("tenant_Id")

  }
  dispatch(notificationUpdate(notificationUpdatePayload))


  let payloadFireBase = {
    "operation_type":"count_is_seen",
    "username":window.localStorage.getItem("adminEmail"),
    "tenant_id":window.localStorage.getItem("tenant_Id")
  };
  dispatch(retrieveAllNotificationCount(payloadFireBase));
    
      }else if(window.localStorage.getItem("role_Type") == 'instructor')
        {

          let retrievePayload ={
            "operation_type":"retrieve",
            "username":window.localStorage.getItem("instructorEmail"),
            "tenant_id":window.localStorage.getItem("tenant_Id")
             }
            dispatch(retrieveAllNotification(retrievePayload))

            let notificationUpdatePayload ={
              "operation_type":"update_is_seen",
              "username":window.localStorage.getItem("instructorEmail"),
              "is_seen": true,
              "tenant_id":window.localStorage.getItem("tenant_Id")
          
            }
            dispatch(notificationUpdate(notificationUpdatePayload))


            let payloadFireBase = {
              "operation_type":"count_is_seen",
              "username":window.localStorage.getItem("instructorEmail"),
              "tenant_id":window.localStorage.getItem("tenant_Id")
            };
            dispatch(retrieveAllNotificationCount(payloadFireBase));
      
        }else if(window.localStorage.getItem("role_Type") == 'student'){

          console.log(window.localStorage.getItem("userEmail"),'userEmail')

          let retrievePayload ={
        
            "operation_type":"retrieve",
            "username":window.localStorage.getItem("userEmail"),
            "tenant_id":window.localStorage.getItem("tenant_Id")
        
          }
          dispatch(retrieveAllNotification(retrievePayload))


          let notificationUpdatePayload ={
            "operation_type":"update_is_seen",
            "username":window.localStorage.getItem("userEmail"),
            "is_seen": true,
            "tenant_id":window.localStorage.getItem("tenant_Id")
        
          }
          dispatch(notificationUpdate(notificationUpdatePayload))


          let payloadFireBase = {
            "operation_type":"count_is_seen",
            "username":window.localStorage.getItem("userEmail"),
            "tenant_id":window.localStorage.getItem("tenant_Id")
          };
          dispatch(retrieveAllNotificationCount(payloadFireBase));
      
        }

    
    
    }

    const[unknown,setUnkown] = useState()

    useEffect(() => {
      // Add event listener to handle messages from service worker
      const handleMessage = (event) => {
        if (event.data && event.data.type === 'background-message') {
          // Handle background message received from service worker
          const payload = event.data.payload;
          // console.log('Background message received in component:', payload);
  
          // Assuming payload structure matches your expected format
          const { body, sender_name, peer_time } = payload.data;
  
          // setDummy(payload?.data)
          const isCurrentUserSender = payload?.data?.sender_name === window.localStorage.getItem('userEmail');
  



          
      if(window.localStorage.getItem("role_Type") == 'admin'){
        let payloadFireBase = {
          "operation_type":"count_is_seen",
          "username":window.localStorage.getItem("adminEmail"),
          "tenant_id":window.localStorage.getItem("tenant_Id")
        };
        dispatch(retrieveAllNotificationCount(payloadFireBase));
      }else if(window.localStorage.getItem("role_Type") == 'instructor'){
        let payloadFireBase = {
          "operation_type":"count_is_seen",
          "username":window.localStorage.getItem("instructorEmail"),
          "tenant_id":window.localStorage.getItem("tenant_Id")
        };
        dispatch(retrieveAllNotificationCount(payloadFireBase));
      }else if(window.localStorage.getItem("role_Type") == 'student'){
        let payloadFireBase = {
          "operation_type":"count_is_seen",
          "username":window.localStorage.getItem("userEmail"),
          "tenant_id":window.localStorage.getItem("tenant_Id")
        };
        dispatch(retrieveAllNotificationCount(payloadFireBase));
      }

          // const payloadObject = {
          //   message: payload?.data?.body,
          //   name: payload?.data?.sender_name,
          //   isSender: isCurrentUserSender,
          //   time: (payload?.data?.peer_time), // Define formatTime function as needed
          //   listData: false
          // };
          if (payload?.data?.peer_name == props?.batchname) {

          setUnkown(payload?.data)
          }
        }
      };
  
      navigator.serviceWorker.addEventListener('message', handleMessage);
      
      // Clean up event listener when component unmounts
      return () => {
        navigator.serviceWorker.removeEventListener('message', handleMessage);
      };
      
    }, []);


    const [notificationPermission, setNotificationPermission] = useState('');

    useEffect(() => {
      // Check if the browser supports notifications
      if (!('Notification' in window)) {
        console.log('This browser does not support desktop notification');
      } else {
        // Check if permission is granted for notifications
        if (Notification.permission === 'granted') {
          setNotificationPermission('granted');
        } else if (Notification.permission !== 'denied') {
          // If permission is not denied, request permission
          Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
              setNotificationPermission('granted');
            } else {
              setNotificationPermission('denied');
            }
          });
        } else {
          setNotificationPermission('denied');
        }
      }
    }, []);
    
    // const history = useHistory(); 
    
    
    
    function openNotificationSettings() {
      // window.open('chrome://settings/content/notifications');
    
      const url = 'cedge://settings/content/notifications';
        window.open(url, '_blank');
    }
    
    
    const handleRequestPermission = () => {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          setNotificationPermission('granted');
        } else {
    
          openNotificationSettings()
          setNotificationPermission('denied');
        
    
        }
    
        console.log(permission,'DAMODAR')
      });
    
    
    };
    





    return  (
      <ConfigProvider theme={{
        algorithm: props.isDarkMode ? darkAlgorithm : defaultAlgorithm,
      }}>
        <div className={props.isDarkMode ? 'dark header' :'header'}>
        <div>
        <img src={logo} className='dashboardLogo' style={{width: props.collapsed ? 100:200}}/>
       
        </div>
        <div className="">
        <Button
            type="text"
            className="hamButton"
            icon={props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={props.handleCollapsed}
            style={{
              fontSize: '16px',
              // width: 64,
              // height: 64,
              Background: "none",
              bottom:"10px"
            }}
          />
        </div>
        
        <div className='headerEnd'>
                  
            <div className='searchCourses'>
                  <div>{props.roleType}</div>

                  { notificationPermission === 'denied' ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginBottom: '20px', marginRight: '10px' }}>
            <p>Notifications are blocked or not supported</p>
        </div>
        <div style={{ marginBottom: '2px', marginRight: '5px' }}>

        {/* <img src={messageIcon} className='messageIcon' alt='Message Icon' /> */}

        <PopOverComponent popUp={informationPopUp} element = {informationIcon} trigger="click"/>


        </div>
    </div>
) : (
    <p></p>
)}

                 
               
   

                    {/* <div><img src={messageIcon} className='messageIcon'/></div> */}

                    <div onClick={clickBell} style={{ position: 'relative' }}>
  {notificationCountValue !== 0 && <div className="notificationDot"></div>}
  <PopOverComponent element={notificationBellIcon} popUp={notificationPopUp} trigger="click" />
</div>

                    {/* <div onClick={clickBell}>
                      <PopOverComponent element={notificationBellIcon}
                        popUp={notificationPopUp} trigger="click"/>
                    </div> */}
                    <PopOverComponent popUp={popUp} element = {img} trigger="click"/>
            </div>
        </div>
    </div>    
    </ConfigProvider>    
    )
};

export default HeaderComponent