import { RETRIEVE_DEMO_CLASS,DELETE_CLASS_DETAILS,RETRIEVE_ALL_COURSES,
    RETRIEVE_DEMO_CLASS_REGISTARTION
} from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';

export const getAllDemoClass = (data) =>{
    return {
        type: RETRIEVE_DEMO_CLASS,
        payload: data
    }
}

export const deleteClassDetails = (data) =>{
    return {
        type: DELETE_CLASS_DETAILS,
        payload: data
    }
}

export const getllCourses = (data) =>{
    console.log(data,'data')
    return {
        type: RETRIEVE_ALL_COURSES,
        payload: data
    }
}




export const retrieveAllDemoClass = ()=>{
    let payload = {
        "operation_type":"retrieve",
        "tenant_id": parseInt(window.localStorage.getItem("tenant_Id"))
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/demo_class",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getAllDemoClass((res?.data)));
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}

export const deleteClass = (payload)=>{
   
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/demo_class",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(deleteClassDetails((res?.data)));
                dispatch(retrieveAllDemoClass());

            }

            if (res.data?.res_status == false) {
                dispatch(deleteClassDetails((res?.data)));
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveAllCourse = ()=>{
    let payload = {
        "operation_type":"course_retrieve",
        "tenant_id": parseInt(window.localStorage.getItem("tenant_Id"))
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/demo_class",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getllCourses((res?.data)));
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}


export const addClass = (payload)=>{
   
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/demo_class",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(deleteClassDetails((res?.data)));
                dispatch(retrieveAllDemoClass());

            }

            if (res.data?.res_status == false) {
                dispatch(deleteClassDetails((res?.data)));
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}

export const updateClass = (payload)=>{
   
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/demo_class",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(deleteClassDetails((res?.data)));
                dispatch(retrieveAllDemoClass());

            }

            if (res.data?.res_status == false) {
                dispatch(deleteClassDetails((res?.data)));
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}


export const getAllDemoClassRegistartion = (data) =>{
    return {
        type: RETRIEVE_DEMO_CLASS_REGISTARTION,
        payload: data
    }
}


export const retrieveAllDemoClassRegistartion = ()=>{
    let payload = {
        "operation_type":"retrieve",
        "tenant_id": parseInt(window.localStorage.getItem("tenant_Id"))
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/demo_class_registration",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getAllDemoClassRegistartion((res?.data)));
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}


export const deleteClassRegistration = (payload)=>{
   
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/demo_class_registration",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(deleteClassDetails((res?.data)));
                dispatch(retrieveAllDemoClassRegistartion());

            }

            if (res.data?.res_status == false) {
                dispatch(deleteClassDetails((res?.data)));
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}


export const updateClassRegistration = (payload)=>{
   
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/demo_class_registration",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(deleteClassDetails((res?.data)));
                dispatch(retrieveAllDemoClassRegistartion());

            }

            if (res.data?.res_status == false) {
                dispatch(deleteClassDetails((res?.data)));
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}