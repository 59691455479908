import React, { useEffect, useState } from 'react';
import { avatarPic } from '../../images';



const ReceiverRowView = ({ receiverMessage, time, isListData ,name,checkEmail}) => {

  const [fullName,setFullName] = useState();

  useEffect(()=>{
    if (receiverMessage) {
    }
  },[receiverMessage])

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' ,alignItems:'start'}}>
      
       <div style={{ flex: 10 }}>
          <img src={avatarPic} alt="Icon" width="35" height="35" />
        </div>
      <div style={{ flex: 72 }}>
        <div style={{ display: 'flex', flexDirection: 'Column' , alignItems: 'flex-start' }}>
          <div style={{ margin: '8px 8px 0px 0px', padding: '9px', backgroundColor: '#f0f0f0', borderRadius: '0px 15px 15px 15px' ,padding: '10px'}}>
        
          <p style={{ margin: '0', fontFamily: 'Roboto', fontSize: '12px', color: 'grey' }}>{name}</p>
          <div style={{ margin: '2px 2px 2px 2px'}}>
          </div>
          <p style={{ margin: '0', fontFamily: 'Roboto', fontSize: '14px', color: 'black' }}>{receiverMessage !== '' ? receiverMessage : 'Update'}</p>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <p style={{ margin: '0', fontFamily: 'Roboto', fontSize: '10px', color: 'black' }}>{receiverMessage !== '' ? time : ''}</p>
       
          </div>
          </div>
        </div>
      </div>
      <div style={{ flex: 50 }}></div>
    </div>
  );
};


export default ReceiverRowView;


