import React, { useEffect, useState } from 'react';

import { NavLink, Outlet, redirect, useNavigate } from "react-router-dom";

import { ConfigProvider, theme, Button, Card } from "antd";

import { jwtDecode } from "jwt-decode";

import {bell,
    messageIcon,
    profileIcon,
    searchIcon,logo,dashboard,
    allStudents,
    allInstructors,
    announcements,
    accounts,
    roles,
    settings,
    schedules,
    allCourses} from '../../images';

    import { InputComponent, SwitchComponent } from '../../commonComponents';
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu ,notification} from 'antd';


import {
    BrowserRouter as Router,
    Routes,
    Route,
    useRoutes,
    Switch
  } from "react-router-dom";

import './layout.css';
import HeaderComponent from './HeaderComponent/header';

import SiderMenuComponent from './SideMenu/sidermenu';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginRole, logoutUser, setToken, studentMenus, studentRecordDetails } from '../../redux/studentLogin/action';
import { getInstructorLoginRole, instructorMenus, setInstructorToken } from '../../redux/instructorLogin/action';
import { getSuperAdminLoginData, retrieveSuperAdminMenus } from '../../redux/superAdmin/action';
import { checkAdminLogin, retrieveAdminMenus } from '../../redux/admin/action';
const { Header, Content, Sider } = Layout;

const LayoutComponent = (props) => {

  let dispatch = useDispatch();

  let [studentId,setStudentId] = useState();

  const [api, contextHolder] = notification.useNotification();

  let jwtConvert = jwtDecode 
  let [expiryTime,setExpiryTime] = useState();

  let [roleType,setRoleType] = useState("")

  const { defaultAlgorithm, darkAlgorithm } = theme;

  const [isDarkMode, setIsDarkMode] = useState(false);

  const [studentMenusData,setStudentMenusData] = useState([]);

  const [instructorMenusData,setInstructorMenusData] = useState([]);

  const [superAdminMenusList,setSuperAdminMenusList] = useState([]);

  const [adminMenusList,setAdminMenusList] = useState([]);

  let loginStatus = useSelector((state)=>state.studentLoginReducer?.token?.access_token?.access_token);
  
  let loginInstructror = useSelector((state)=>state.instructorLoginReducer?.token?.access_token?.access_token)

  // let check = useSelector((state)=>state.studentLoginReducer?.token);
  let studentMenusList = useSelector((state)=>state.studentLoginReducer?.stuMenus);

  let instructorList = useSelector((state)=>state.instructorLoginReducer?.instructorMenus);

  let checkAdminLoginData = useSelector((state)=>state.adminReducer.adminLoginData)


  let superAdminMenus = useSelector((state)=>state.superAdminReducer?.superAdminMenus);

  let adminMenus = useSelector((state)=>state.adminReducer?.adminMenus)

  // let studentRecordId = useSelector((state)=>state.studentLoginReducer?.studentRecord);

  let profileStatus = useSelector((state)=>state.instructorProfileReducer?.profileStatus);

  let passwordStatus = useSelector((state)=>state.instructorProfileReducer?.passwordStatus)

  let loginTokenData = useSelector((state)=>state.superAdminReducer);

  let studentLoginRequest = useSelector((state)=>state.studentLoginReducer?.token);

  useEffect(()=>{
    if (adminMenus) {
      console.log(adminMenus,'adminMenus')
      setAdminMenusList(adminMenus)
    }
  },[adminMenus])

  useEffect(()=>{
    if (studentMenusList) {
      setStudentMenusData(studentMenusList);

    }
  },[studentMenusList]);

  // useEffect(()=>{
  //     if (profileStatus == true) {
  //       profileNotification(true)
  //     }
  //     if (profileStatus == false) {
  //       profileNotification(false)
  //     }
  // },[profileStatus])

  // useEffect(()=>{
  //   if (passwordStatus == true) {
  //     passwordResetNotification(true)
  //   }

  //   if (passwordStatus == false) {
  //     passwordResetNotification(false)
  //   }
  // },[passwordStatus])

  useEffect(()=>{
    if (loginStatus) {
      console.log(loginStatus,"loginStatus")
      let role = window.localStorage.getItem("role_Type")
   
    if (role !="") {
      dispatch(studentMenus())

    }
    console.log(loginStatus,"imp1")
    }
  },[loginStatus])

  

  useEffect(()=>{
    let role = window.localStorage.getItem("role_Type");
    
    if (role ="student") {
      dispatch(studentMenus())
    }

  },[window.localStorage.getItem("role_Type")])

  

//newCode
let studentRole = useSelector((state)=>state?.studentLoginReducer?.loginRole)

let instructorRole = useSelector((state)=>state?.instructorLoginReducer?.instructorLoginRole)

  useEffect(()=>{
    if (studentRole) {
      if (studentRole == "student") {
        dispatch(studentMenus())
      }
    }
  },[studentRole])


  useEffect(()=>{
    if (instructorRole) {
      if (instructorRole =="instructor") {
        dispatch(instructorMenus())
      }
    }
  },[instructorRole])

  //newCode

// useEffect(()=>{
//   let role = window.localStorage.getItem("role_Type");
//   console.log(role,"chekrole")
//       let superAdminMenusPayload ={
//         "idm_roles": [
//             role
//         ],
//         "appl_name": "LMS"
//     }
//     dispatch(retrieveSuperAdminMenus(superAdminMenusPayload))
    
// },[])


useEffect(()=>{
  if (superAdminMenus) {
    setSuperAdminMenusList(superAdminMenus)
  }
},[superAdminMenus])

  useEffect(()=>{
    setInstructorMenusData(instructorList);
  },[instructorList]);

  useEffect(()=>{
    if (loginInstructror) {
      let roleType = window.localStorage.getItem("role_Type")
    if (roleType !="") {
      dispatch(instructorMenus())
    }  
    }
    console.log(loginInstructror,"imp2")
},[loginInstructror])


useEffect(()=>{
  if (checkAdminLoginData) {
    
    console.log(window.localStorage.getItem("role_Type"));
    let roleType = window.localStorage.getItem("role_Type")
  //   let adminMenusPayload = {
  //     "idm_roles": [
  //       roleType
  //     ],
  //     "appl_name": "LMS"
  // }


      let adminMenusPayload = {
        "idm_roles": [
          "lms_tenant_admin"
      ],
      "appl_name": "Lms_Dev"
  }
    dispatch(retrieveAdminMenus(adminMenusPayload))
  }
},[checkAdminLoginData?.role_type=="admin"])


   const { token: { colorBgContainer, borderRadiusLG },}= theme.useToken();

  const [render, updateRender] = useState(1);

  const handleMenuClick = menu => {
    updateRender(menu.key);

    console.log(menu.key,'menu')
  };



  useEffect(()=>{

    ///
    // /

    if(window.localStorage.getItem("role_Type") == 'admin'){
      updateRender('/main');

    }else if(window.localStorage.getItem("role_Type") == 'instructor'){
      updateRender('/instructorDashboard');

    }else if(window.localStorage.getItem("role_Type") == 'student'){
      updateRender('/studentDashboard');

    }

},[])

  const [collapsed, setCollapsed] = useState(false);

  let handleCollapsed = () =>{
    setCollapsed(!collapsed)
  }

  const handleClick = () => {
    setIsDarkMode((previousValue) => !previousValue);
   };

   let navigate = useNavigate()

   let handleLogout = () =>{
    dispatch(setToken(undefined))
    dispatch(setInstructorToken(undefined))
    dispatch(getSuperAdminLoginData(undefined))
    dispatch(checkAdminLogin(undefined))
    // window.localStorage.clear()
    
    // window.sessionStorage.clear();
    dispatch(studentMenus())
    dispatch(instructorMenus())
    let role=window.localStorage.getItem("role_Type")

    if (role == "superadmin") {
      window.localStorage.clear()
      navigate("/superadmin")
    }else{
      window.localStorage.clear()

      navigate("/")

    }

    if (role == "admin") {
      window.localStorage.clear();
      
      navigate("/tenantLogin")
    }

    if (role  == "student") {
      window.localStorage.removeItem("student_id");
      window.localStorage.removeItem("role_Type");
      window.localStorage.removeItem("fcmToken")

      navigate("/")
      window.localStorage.clear();
      dispatch(getInstructorLoginRole(null))
      window.location.reload()
      dispatch(instructorMenus(null))
    }

    if (role  == "instructor") {
      window.localStorage.removeItem("instructor_id");
      window.localStorage.removeItem("role_Type")
      navigate("/")
      window.localStorage.clear();
      dispatch(getLoginRole(null))
      window.location.reload()
      dispatch(studentMenus(null))

    }
   }
  

  useEffect(()=>{
      if (studentLoginRequest) {
         openNotification(studentLoginRequest?.msg,studentLoginRequest)
      }
  },[studentLoginRequest,loginStatus])

  const openNotification = (msg,Rstatus)=>{
    if (msg  && Rstatus == true) {
      api.info({
        type: 'error',
        description:
          `${msg}`,
      });
    }
  }

  const profileNotification = (status) => {
    if (status == true) {
      api.info({
        // message: `Category ${details.course_category_name}`,
        description:
          'Profile Successfully updated',
      });
    }

    if (status == false) {
      api.info({
        // message: `Category ${details.course_category_name}`,
        description:
          'Error during profile update',
      });
    }
  
  };

  const passwordResetNotification = (status) => {
    if (status == true) {
      api.info({
        // message: `Category ${details.course_category_name}`,
        description:
          'Password Successfully updated',
      });
    }

    if (status == false) {
      api.info({
        // message: `Category ${details.course_category_name}`,
        description:
          'Error during password update',
      });
    }
  
  };


  //push notifications 1-05-2024
   const [studentNotificationCount,setStudentNotificationCount] = useState()
  useEffect(() => {
    // Add event listener to handle messages from service worker
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'background-message') {
        // Handle background message received from service worker
        const payload = event.data.payload;
        // console.log('Background message received in component:', payload);

        
        // Assuming payload structure matches your expected format
        console.log(payload,"push")

        if (payload) {
          setStudentNotificationCount(studentNotificationCount+1)
        }

        window.localStorage.setItem("studentNotificationCount",studentNotificationCount+1)
        // if (payload) {
        //   const notificationTitle = payload?.data?.notification?.title
        //   const notificationOptions = {
        //         body: payload?.data?.notification?.body,
        //         title: payload?.data?.notification?.title
        //       };
            
        //       return self.registration.showNotification(notificationTitle, notificationOptions);
        //     };
        


        if (payload?.data !=undefined) {
          const { body, sender_name, peer_time } = payload.data;
          
        }

        const isCurrentUserSender = payload?.data?.sender_name === window.localStorage.getItem('userEmail');
      
      }
    };

    navigator.serviceWorker.addEventListener('message', handleMessage);
    
    // Clean up event listener when component unmounts
    return () => {
      navigator.serviceWorker.removeEventListener('message', handleMessage);
    };
    
  }, []);

  //end
  return (
    <>
    <ConfigProvider theme={{
      algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
    }}>
    <Layout>
      <Header>
        <HeaderComponent collapsed={collapsed} handleCollapsed={handleCollapsed}
        handleLogout={handleLogout} isDarkMode={isDarkMode} studentId={studentId}/>
      </Header>
      {contextHolder}

      <Layout>
        <Sider


          width={collapsed ? 50 : 250}


          style={{
            // margin:"10px",

          
            marginLeft:"10px",

            padding:"10px",
            background: colorBgContainer,
            borderRadius: '25px',
            border: "none", // Ensures there's no border around the Sider
            boxShadow: "none", // Removes any box shadow if present
            overflow: "hidden", // Ensures no extra content spills out
            display: "flex", // Ensures proper flexbox behavior
            flexDirection: "column",
            // Setting border-radius to 50px

          }}
        >
         <SiderMenuComponent handleClick={handleMenuClick} menus={studentMenusData}
         adminMenus = {adminMenusList}
          instructorMenus={instructorMenusData}
           superAdminMenusList={superAdminMenusList} 
           current= {render}/>
        </Sider>
        <Layout
          style={{
            padding: '0 10px 10px',
          }}
        >
            <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 550,
              maxHeight:860,
              overflow:"auto",
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
          <Outlet/>
        </Content>
        </Layout>
      </Layout>
      </Layout>
      </ConfigProvider>
    </>
  );
};
export default LayoutComponent;
