import React, { useState } from 'react';
import { Button, Popover } from 'antd';
const PopOverComponent = (props) => {
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  return (
    <Popover
      content={<>{props.popUp}</>}
      title={""}
      trigger="click"
      open={props.open}
      onOpenChange={props.handleOpenChange}
    >
        {props.element}
    </Popover>
  );
};
export default PopOverComponent;