
export const SET_INSTRUCTOR_DETAILS = "SET_INSTRUCTOR_DETAILS";

export const GET_INSTRUCTOR_DETAILS = "GET_INSTRUCTOR_DETAILS";

export const SET_INSTRUCTOR_EMAIL = "SET_INSTRUCTOR_EMAIL";

export const INSTRUCTOR_EMAIL_VERIFY = "INSTRUCTOR_EMAIL_VERIFY";

export const SET_PASSWORDS = "SET_PASSWORDS";
