import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retireveActiveUserReports } from '../../../redux/adminReports/activeUserReports/action.js';

import  TableComponent from '../../../commonComponents/TableComponent/TableComponent.js'
import { Table } from 'antd';
import { excel, plus, upload, searchIcon } from "../../../images";
import * as XLSX from 'xlsx';
import ExportToExcel from '../../../commonComponents/XlsxFile/ExportToExcel'
const ActiveUserReports = () => {

    let dispatch = useDispatch();

    let adminActiveUserReports = useSelector((state)=>state?.ActiveUserReportsReducer?.adminActiveUserReports);

    useEffect(()=>{
        let payload = {
            "operation_type":"active_view",
            "is_active":"true",

            "tenant_id": window.localStorage.getItem("tenant_Id")
        }
        dispatch(retireveActiveUserReports(payload))
    },[])

    useEffect(()=>{
        if (adminActiveUserReports) {
            console.log(adminActiveUserReports?.data,"activeReprts")
        }
    },[adminActiveUserReports])

    const columns = [
        {
            title: 'Student Name',
            dataIndex: 'user_name',
            sorter:true,
           sorter: (a, b) => a.user_name .localeCompare(b.user_name),
      
          },
          {
            title: 'Status',
            dataIndex: 'is_active',
            sorter:true,
            sorter: (a, b) => a.is_active - b.is_active,
            render: (text, record1) => (
                <div className="actionIcons">
                  {record1.is_active == 'true' ? 
                      <div className={"statusActive"}><span class="dotActive"></span> Active</div>
                            
                  : <div className={"statusInactive"}><span class="dotInactive"></span> In Active</div>}
                    {/* <p className={record1.is_active == true ? "activeClass" :"inActiveClass"}>{record1.is_active == true ? "ACTIVE" : "INACTIVE"}</p> */}
                </div>
               ),
          },

    ]


    const exportToExcel = (data, fileName) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
      };
      
      
      const transformedItems = adminActiveUserReports?.data?.map((item) => {
       
      
        return { 
           STUDENT_NAME:  item.user_name,
           STATUS:  'ACTIVE',
         
        
        
        };
      });
    let handleCategoryModal1 = (type,id) =>{
   
        exportToExcel(transformedItems, 'active_user_report');

  
    }
    return (
        <div>
              <div style={{display:'flex' ,justifyContent:'flex-end',marginBottom:'10px'}}>

<img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

</div>
             <TableComponent columns={columns} data={adminActiveUserReports?.data}/>
         </div>
    );
}

export default ActiveUserReports;
