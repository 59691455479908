import { SET_STUDENT_REGISTRATION,
    GET_STUDENT_REGISTRATION,
     SET_VERIFY_EMAIL,SET_PASSWORDS,SET_EMAIL,RETRIEVE_INTERESTED_COURSES,RETRIEVE_COURSE_DETAILS,
     PRIVACY_POLICY_DETAILS } from "./actionTypes";

import axios from 'axios';
import apiConfig from '../../apiConfig/api';

const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    };

export const studentRegistrtionSuccess=(data)=>{
    return{
        type: SET_STUDENT_REGISTRATION,
        payload: data
    }
}

export const setEmail = (data) =>{
    return{
        type: SET_EMAIL,
        payload: data
    }
}

export const emailVerificationStatus = (data)=>{
    return {
        type: SET_VERIFY_EMAIL,
        payload: data
    }
}

export const passwordStatus = (data)=>{
    return{
        type:SET_PASSWORDS,
        payload:data
    }
}


export const retrieveInterestedCoursesDetails = (data)=>{
    return{
        type:RETRIEVE_INTERESTED_COURSES,
        payload:data
    }
}
export const studentRegistration = (data) =>{

    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/student_enquiry",data,{headers}
        ).then((res)=>{
            if (res.data?.res_status== true) {
                dispatch(studentRegistrtionSuccess(res?.data))
                dispatch(setEmail(res?.data.email))
            }
            else{
                dispatch(studentRegistrtionSuccess(res?.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const verifyRegisterEmail = (data) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/student",data,{headers}
        ).then((res)=>{
            console.log(res.data?.res_status,"status")
            if (res.data?.res_status== true) {
                dispatch(emailVerificationStatus(res?.data?.res_status))
            }
            else{
                dispatch(emailVerificationStatus(res?.data?.res_status))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const passwordConfirmation = (data) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/student",data,{headers}
        ).then((res)=>{
            console.log(res.data?.res_status,"status")
            if (res.data?.res_status== true) {
                dispatch(passwordStatus(res?.data?.res_status))
            }
            else{
                dispatch(passwordStatus(res?.data?.res_status))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const retrieveInterestedCourses = (data) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/student_enquiry",data,{headers}
        ).then((res)=>{
            console.log(res.data?.res_status,"status")
            if (res.data?.res_status== true) {
                dispatch(retrieveInterestedCoursesDetails(res?.data))
            }
            else{
                dispatch(retrieveInterestedCoursesDetails([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const courseDetails = (data)=>{
    return{
        type:RETRIEVE_COURSE_DETAILS,
        payload:data
    }
}

export const courseNameBasedOnCourseCategory = (data) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor_admin",data,{headers}
        ).then((res)=>{
            console.log(res.data?.res_status,"status")
            if (res.data?.res_status== true) {
                dispatch(courseDetails(res?.data))
            }
            else{
                dispatch(courseDetails([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const privacyPolicyDetails = (data)=>{
    return{
        type:PRIVACY_POLICY_DETAILS,
        payload:data
    }
}

export const privacyPolicy = (data) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/terms_and_condition",data,{headers}
        ).then((res)=>{
            console.log(res.data?.res_status,"status")
            if (res.data?.res_status== true) {
                dispatch(privacyPolicyDetails(res?.data))
            }
            else{
                dispatch(privacyPolicyDetails([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const privacyPolicyDetailsAll = (data) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/terms_and_condition",data,{headers}
        ).then((res)=>{
            console.log(res.data?.res_status,"status")
            if (res.data?.res_status== true) {
                dispatch(privacyPolicyDetails(res?.data))
            }
            else{
                dispatch(privacyPolicyDetails([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const privacyPolicyUpdateDetails = (data) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/terms_and_condition",data,{headers}
        ).then((res)=>{
            console.log(res.data?.res_status,"status")
            if (res.data?.res_status== true) {
                dispatch(studentRegistrtionSuccess(res?.data))
                let registrationPayload = {
                    "operation_type":"retrieve_all",
                    "tenant_id":1
                }
                dispatch(privacyPolicyDetailsAll(registrationPayload))
            }
            else{
                dispatch(studentRegistrtionSuccess([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}