import React, { useEffect, useState } from 'react'

import { Group, chessSample, maskgroup, upcomingClasses } from '../../../images'
import { ButtonComponent,ModalComponent } from '../../../commonComponents';

import RelatedCoursersDetails from '../../../components/studentRegisteredCourses/relatedCourses/relatedCourseDetails';

import './relatedCourses.css';
let RelatedCourses=(props)=> {
  console.log(props?.list?.data,"pppp")
  let [studentRecord,setStudentRecord] = useState([]);
  let [studentDetails,setStudentDetails] = useState(false);


  let handleViewStudent = (Id) =>{
    // setStudentId(Id);

    console.log(Id,'id')

    props?.list?.data?.filter((item,index)=>{
      if(item.course_id == Id){
        setStudentRecord(item)
      }
    })
    setStudentDetails(true)
    }

    let handleStudentCancel = () =>{
        setStudentDetails(false)
       
      }


  return (
    <>

<div className='relatedCourses'>
        <div className="relatedCourseHeader">
        <div>
            <h3>RELATED COURSES</h3>
        </div>

        <div className='alignRight'>
            <p className='underLine'>View all</p>
        </div>
        </div>
        <div className='relatedCoursesContainer'>
    {props?.list?.data && props?.list?.data.map((item,index)=>{
            return (

               <div className='relatedCoursesList'>
                <div className='relatedCourse'>
                  <div>
                  <img src={item.course_thumbnail} className='relatedCourseImage'/>
                  </div>
    
                  <div>
                    <div className='relatedHeader'>
                        <div>
                            <p>{item.course_category}</p>
                        </div>
                        <div className='alignRight'>
                          <p>90 days</p>
                        </div>
                    </div>
                    <h3>{item.course_name}</h3>

                    <div style={{marginBottom:'10px' ,display: 'flex', justifyContent: 'flex-end',}}>

                    <ButtonComponent value={"View"} className={"viewBtnStudent"} onClick={()=> handleViewStudent(item.course_id)} />

                    </div>
                    {/* <h4>By Satya krishna</h4>
                    <p>Mon & Wed 10pm to 5pm</p> */}
                  </div>
                </div>
                </div>
            )
    })}
        

              {/* <div className='relatedCoursesList'>
            <div className='relatedCourse'>
              <div>
              <img src={chessSample} className='relatedCourseImage'/>

              </div>

              <div>
                <div className='relatedHeader'>
                    <div>
                        <p>Music</p>
                    </div>
                    <div className='alignRight'>
                      <p>90 days</p>
                    </div>
                </div>
                <h3>Violin</h3>
                <h4>By Satya krishna</h4>
                <p>Mon & Wed 10pm to 5pm</p>
              </div>
            </div>
            </div> */}


            {/* <div className='relatedCoursesList'>
            <div className='relatedCourse'>
              <div>
              <img src={chessSample} className='relatedCourseImage'/>

              </div>

              <div>
                <div className='relatedHeader'>
                    <div>
                        <p>Music</p>
                    </div>
                    <div className='alignRight'>
                      <p>90 days</p>
                    </div>
                </div>
                <h3>Violin</h3>
                <h4>By Satya krishna</h4>
                <p>Mon & Wed 10pm to 5pm</p>
              </div>
            </div>
            </div> */}


            {/* <div className='relatedCoursesList'>
            <div className='relatedCourse'>
              <div>
              <img src={chessSample} className='relatedCourseImage'/>

              </div>

              <div>
                <div className='relatedHeader'>
                    <div>
                        <p>Music</p>
                    </div>
                    <div className='alignRight'>
                      <p>90 days</p>
                    </div>
                </div>
                <h3>Violin</h3>
                <h4>By Satya krishna</h4>
                <p>Mon & Wed 10pm to 5pm</p>
              </div>
            </div>
            </div> */}



        </div>
    </div>


    {studentDetails && (
      <ModalComponent
        title={"Course Detail"}
        content={<RelatedCoursersDetails studentRecord={studentRecord} />}
        open={studentDetails}
        handleCancel={handleStudentCancel}
      />
    )}
    </>
   
  )
}

export default RelatedCourses