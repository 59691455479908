import React from 'react';

import './adminTodo.css';
import { Button, DatePicker, Form } from 'antd';
import { InputComponent } from '../../../commonComponents';

const AdminTodo = (props) => {
    return (
        <div>
           <Form
            autoComplete="off"
            layout="vertical"
            form={props.form}
            onFinish={props.onFinish}>
            <Form.Item
            label="Date"
            name={"todoDate"}
            >
                <DatePicker name='todoDate'
                      onChange={(e,dateString)=>props.handleOnchangeTodo(dateString,{name:"todoDate"})}

                        defaultValue={(props.todoDate)}
                        />
            </Form.Item>

            <Form.Item
            label="Title"
            name={"todoDescription"}>
                <InputComponent placeholder={"Enter title"}
                 value={props?.todoDescription}
                 onChange={(e)=>props.handleOnchangeTodo(e,{name:"todoDescription"})}/>
            </Form.Item>

            <Form.Item>
            <div className='coursePriceSubmit'>
                
                <div>
                <Button type={"secondary"}  className="cancel" onClick={props?.closeModal}>Cancel</Button>

                </div>
              <div className='alignRight'>
              <Button type={"primary"} htmlType='submit'  primary value={'Submit'}   className={'saveBatch'} >Submit</Button>

            </div>  

        </div>
            </Form.Item>
           </Form>
        </div>
    );
}

export default AdminTodo;
