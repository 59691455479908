import { SET_STUDENT_LOGIN,GET_STUDENT_LOGIN_DATA, SET_TOKEN,
    GET_STUDENT_MENUS,SET_LOGIN_ROLE,SET_STUDENT_RECORD, GET_STUDENT_LOGIN_STATUS,
    SET_CURRENT_STUDENT_GROUPS,
    GET_CURRENT_STUDENT_GROUP_INFORMATION,
    INSERT_GROUP_CHART,
    INSERT_GROUP_CHART_FIRE_BASE,
    GET_STUDENT_MESSAGES,
    RETRIEVE_GROUP_CHART_DETAILS,
    LATEST_GROUP,RETRIEVE_NOTIFICATION_DETAILS,NOTIFICATION_COUNT,STUDENT_CONTACT_US,GET_TENANT_ID,
    RETRIEVE_DATE_DETAILS,
    RETRIEVE_REGISTRATION_COURSE_NAME,RETRIEVE_TIMING} from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const studentLoginReducer = (state = initialStates,action)=>{

    switch (action.type) {

        case GET_STUDENT_LOGIN_DATA:
            return {
                studentLoginData: action.payload,
            }

        case SET_TOKEN:
            return{
                token: action.payload
            }
        
        case GET_STUDENT_MENUS:
            return{
                stuMenus: action.payload
            }

        case SET_LOGIN_ROLE:
            return{
                loginRole: action.payload,
            }

        case SET_STUDENT_RECORD:
            return{
                studentRecord: window.localStorage.getItem("student_id")
            }

        case GET_STUDENT_LOGIN_STATUS:
            return {
                loginRequest: action.payload
            }
        
        case SET_CURRENT_STUDENT_GROUPS:
            return{
                studentGroups: action.payload
            }

        case GET_CURRENT_STUDENT_GROUP_INFORMATION:
            return{
                currentStudentGroupInfo: action.payload
            }

        case INSERT_GROUP_CHART:
            return{
                insertGroupChartDetailsList: action.payload
            }
            case INSERT_GROUP_CHART_FIRE_BASE:
            return{
                insertFireBaseGroupChartDetailsList: action.payload
            }

        case GET_STUDENT_MESSAGES:
            return{
                studentMessages:action.payload
            }

            case RETRIEVE_GROUP_CHART_DETAILS:
                return{
                    retrieveGroupChartDetails: action.payload
                }
            
                case LATEST_GROUP:
                    return{
                        lastGroup:action.payload
                    }

                    case RETRIEVE_NOTIFICATION_DETAILS:
                        return{
                            retrieveNotificationDetails:action.payload
                        }
                        
                    case NOTIFICATION_COUNT:
                        return{
                            notificationCountDetails:action.payload
                        }

                        case STUDENT_CONTACT_US:
                            return{
                                studentContactUs:action.payload
                            }

                            case GET_TENANT_ID:
                                return{
                                    getTenantDetailsList:action.payload
                                }

                                case RETRIEVE_REGISTRATION_COURSE_NAME:
                                    return{
                                        retrieveRegistrationCourseName:action.payload
                                    }

                                    case RETRIEVE_DATE_DETAILS:
                                        return{
                                            retrieveDateDetails:action.payload
                                        }

                                        case RETRIEVE_TIMING:
                                            return{
                                                retrieveTimingDetails:action.payload
                                            }
        default:
            return state;
    }
}

export  default studentLoginReducer;