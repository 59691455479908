export const  SET_STUDENT_LOGIN = "SET_STUDENT_LOGIN";

export const GET_STUDENT_LOGIN_DATA = "GET_STUDENT_LOGIN_DATA";

export const SET_TOKEN = "SET_TOKEN";

export const GET_STUDENT_MENUS = "GET_STUDENT_MENUS";

export const SET_LOGIN_ROLE = "SET_LOGIN_ROLE";

export const SET_STUDENT_RECORD = "SET_STUDENT_RECORD";

export const GET_STUDENT_LOGIN_STATUS = "GET_STUDENT_LOGIN_STATUS"

//StudentGroups
export const SET_CURRENT_STUDENT_GROUPS = "SET_CURRENT_STUDENT_GROUPS"

export const GET_CURRENT_STUDENT_GROUP_INFORMATION = "GET_CURRENT_STUDENT_GROUP_INFORMATION"
//end

export const INSERT_GROUP_CHART = "INSERT_GROUP_CHART"

export const INSERT_GROUP_CHART_FIRE_BASE = "INSERT_GROUP_CHART_FIRE_BASE";

export const GET_STUDENT_MESSAGES = "GET_STUDENT_MESSAGES";


export const RETRIEVE_GROUP_CHART_DETAILS = "RETRIEVE_GROUP_CHART_DETAILS";


export const LATEST_GROUP = "LATEST_GROUP"

export const RETRIEVE_NOTIFICATION_DETAILS = "RETRIEVE_NOTIFICATION_DETAILS"

export const NOTIFICATION_COUNT = "NOTIFICATION_COUNT"

export const STUDENT_CONTACT_US = "STUDENT_CONTACT_US"

export const GET_TENANT_ID = "GET_TENANT_ID"

export const RETRIEVE_REGISTRATION_COURSE_NAME = "RETRIEVE_REGISTRATION_COURSE_NAME"

export const RETRIEVE_DATE_DETAILS = "RETRIEVE_DATE_DETAILS"

export const RETRIEVE_TIMING = "RETRIEVE_TIMING"