import React from 'react';

import ReactDatePicker from 'react-datepicker';
import './rescheduleClass.css';
import { TextAreaComponent } from '../../../../commonComponents';
import { Button, Form, TimePicker,DatePicker } from 'antd';

import moment from 'moment';
const InstructorRescheduleClass = (props) => {

    const disabledDate = (current) => {
        // Can not select days before today
        return current && current < moment().startOf('day');
      };
    return (
        <div className='instructorCancelClassContainer'>
            <div>
                <p>Dear instructor, if you want to reschedule the class  
                    please give a valid reason below !!</p>
            </div>

            <Form
                onFinish={props.onFinish}
                form={props.form}
                autoComplete="off"
                layout="vertical">

               
            <div className='inScheduleTimings'>
               {/* <Form.Item
                label="Actual Date"
                name="scheduled_date"

                 rules={[
                   { required: true, message: 'Please enter actual date!' }
                   ]}>
                <div>
                    <ReactDatePicker selected={props.rescheduleClassDetails?.scheduled_date?
                props.rescheduleClassDetails?.scheduled_date :""} minDate={new Date()}
                    onChange={(e)=>props.handleChange(e,{name:"scheduled_date"})}
                    value={props.rescheduleClassDetails?.scheduled_date?
                        props.rescheduleClassDetails?.scheduled_date :""}/>
                </div>
                </Form.Item> */}


                    <div>
                    <Form.Item
                label="Actual Date"
                name="scheduled_date"
                           rules={[
                             { required: true, message: 'Please enter actual date!' }
                             ]}>
                        <div >
                        <DatePicker name={"scheduled_date"}
                        mode='date'
                        disabledDate={disabledDate}
                        defaultValue={props.rescheduleClassDetails?.scheduled_date ?
                            moment(props.rescheduleClassDetails?.scheduled_date) :""}
                            onChange={(e)=>props.handleChange(e,{name:"scheduled_date"})}
                            />

                          </div>
                          </Form.Item>
                    </div>
                




















                {/* <Form.Item
                label="Reschedule Date"
                name="request_date"
                 rules={[
                    { required: true,
                     message: 'Please enter reschedule date!'
                    }
                   ]}
                   >
                <div>
                    <ReactDatePicker selected={props.rescheduleClassDetails?.request_date?
                props.rescheduleClassDetails?.request_date :""} minDate={new Date()}
                    onChange={(e)=>props.handleChange(e,{name:"request_date"})}
                    value={props.rescheduleClassDetails?.request_date}/>
                </div>
                </Form.Item> */}


<div>
<Form.Item
                label="Reschedule Date"
                name="request_date"
                           rules={[
                             { required: true, message: 'Please enter reschedule date!' }
                             ]}>
                        <div >
                        <DatePicker name={"request_date"}
                        mode='date'
                        disabledDate={disabledDate}
                        defaultValue={props.rescheduleClassDetails?.request_date ?
                            moment(props.rescheduleClassDetails?.request_date ):""}
                            onChange={(e)=>props.handleChange(e,{name:"request_date"})}
                            />

                          </div>
                          </Form.Item>
</div>
               












               
            </div>




      
            <div className='inScheduleTimings'>
              


              














                <div className='instructorRescheduleStartTime'>

                   <Form.Item
                   label="Start Time"
                   name="request_timing"
                    rules={[
                       { required: true, message: 'Please enter Start time' }
                      ]}>

                    <TimePicker use12Hours format={'hh:mm A'} name={"request_timing"}
                       onChange={(e)=>props.handleChange(e,{name:"request_timing"})}
                       defaultValue={props.request_timing ? props.request_timing : ""}
                     />
               
                   </Form.Item>
                   </div>

                <div className='instructorRescheduleStartTime'>
                <Form.Item
                   label="End Time"
                   name="batch_end_time"
                    rules={[
                       { required: true, message: 'Please enter End time' }
                      ]}>
                        
                    <TimePicker use12Hours format={'hh:mm A'} name={"batch_end_time"}
                    onChange={(e)=>props.handleChange(e,{name:"batch_end_time"})}
                    defaultValue={props.batch_end_time ? props.batch_end_time : ""}/>
                    </Form.Item>
                </div>
            </div>



            <div>
            <Form.Item
            label="Reason"
            name="reason"
            rules={[
                 { required: true, message: 'Please enterReason' }
                ]}
            >
            <TextAreaComponent name={"reason"} onChange={(e)=>props.handleChange(e,{name:"reason"})}/>
            </Form.Item>
            </div>
            <Form.Item>
            <div className='submitCancelClass'>
            <Button htmlType='submit' primary type='primary'>Submit</Button>
            </div>
            </Form.Item>
            </Form>
        </div>
    );
}

export default InstructorRescheduleClass;
