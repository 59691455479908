import React from 'react'
import { Group, chessSample, maskgroup, upcomingClasses } from '../../../images'

import './relatedCourses.css';
let RelatedCourses=(props)=> {
  console.log(props?.list?.data,"iiiii")
  return (
    <div className='relatedCourses'>
        <div className="relatedCourseHeader">
        <div>
            <h3>RELATED COURSES</h3>
        </div>

        <div className='alignRight'>
            <p className='underLine'>View all</p>
        </div>
        </div>
        <div className='relatedCoursesContainer'>

    {props.list?.data && props.list?.data.map((item,index)=>{
      return(

       <div className='relatedCoursesList'>
        <div className='relatedCourse'>
          <div>
          <img src={item.course_thumbnail} className='relatedCourseImage'/>
          </div>

          <div>
            <div className='relatedHeader'>
                <div>
                    <p>{item.course_category}</p>
                </div>
                <div className='alignRight'>
                  <p>90 days</p>
                </div>
            </div>
            <h3>{item.course_name}</h3>
            {/* <h4>By Satya krishna</h4>
            <p>Mon & Wed 10pm to 5pm</p> */}
          </div>
        </div>
        </div>
      )
    })}
        

              {/* <div className='relatedCoursesList'>
            <div className='relatedCourse'>
              <div>
              <img src={chessSample} className='relatedCourseImage'/>

              </div>

              <div>
                <div className='relatedHeader'>
                    <div>
                        <p>Music</p>
                    </div>
                    <div className='alignRight'>
                      <p>90 days</p>
                    </div>
                </div>
                <h3>Violin</h3>
                <h4>By Satya krishna</h4>
                <p>Mon & Wed 10pm to 5pm</p>
              </div>
            </div>
            </div> */}


            {/* <div className='relatedCoursesList'>
            <div className='relatedCourse'>
              <div>
              <img src={chessSample} className='relatedCourseImage'/>

              </div>

              <div>
                <div className='relatedHeader'>
                    <div>
                        <p>Music</p>
                    </div>
                    <div className='alignRight'>
                      <p>90 days</p>
                    </div>
                </div>
                <h3>Violin</h3>
                <h4>By Satya krishna</h4>
                <p>Mon & Wed 10pm to 5pm</p>
              </div>
            </div>
            </div> */}


            {/* <div className='relatedCoursesList'>
            <div className='relatedCourse'>
              <div>
              <img src={chessSample} className='relatedCourseImage'/>

              </div>

              <div>
                <div className='relatedHeader'>
                    <div>
                        <p>Music</p>
                    </div>
                    <div className='alignRight'>
                      <p>90 days</p>
                    </div>
                </div>
                <h3>Violin</h3>
                <h4>By Satya krishna</h4>
                <p>Mon & Wed 10pm to 5pm</p>
              </div>
            </div>
            </div> */}



        </div>
    </div>
  )
}

export default RelatedCourses