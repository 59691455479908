import React, { useEffect, useState } from 'react'
import { Card,Spin } from 'antd';
import { chessSample, tutor,scheduleimage } from '../../../images';
import './studentCoursesList.css';

let StudentCoursesList=(props)=> {
    let data = props?.coursesData
    let [singleCourse,setSingleCourse] = useState([])
    console.log(props?.coursesData,props?.singleCourse,"llllllllllllll")

    // let handleViewCourse = (record)=>{
    //     console.log(record,"id")
    //     if (record) {
    //         let getCourse =[];
    //        let filtered = props?.coursesData && props?.coursesData.filter((course)=>course.course_id == record)
    //             setSingleCourse(filtered)
    //     }
    //     console.log(singleCourse,"checkSingleCourse")
    // }

  return (
    <>
    {props?.coursesData ?
    <div className='listOfStudentCourses'>
        {props?.coursesData && props?.coursesData.map((item,index)=>{
    return ( <Card
            
            hoverable
            cover={<img alt="example" src={item?.course_thumbnail} className='cardImage'
            style={{height:"210px"}} />}
            className='coursesCard'
            onClick={()=>props.handleViewCourse(item.course_id)}
        >
    <div className='cardContentContainer'>
        <div className='subContent'>
        <div>
            <b>{item.course_name}</b>
        </div>
        {/* <div className='alignRight'>
            <b>$ 32</b>
        </div> */}
        </div>
        <div className='cardData'>
            <p>{item.course_description}
            </p>
           
        </div>

        <div className='Info'>
            <div><img src={tutor}/></div>
            <div><p>{item.instructor_names[0]}</p></div>
        </div>

        {/* <div className='Info'>
            <div><img src={scheduleimage}/></div>
            <div><p>10Nov - 23Dec(2 to 4pm)</p></div>
        </div> */}
        
        <div className='regCategory'>
            <p>{item?.course_category}</p>
        </div>
    </div>
  </Card>
        )
        })}
            
    </div>
    :
    <div className='alignSpinner'>
        <Spin/>
    </div>}
    </>
  )
}

export default StudentCoursesList