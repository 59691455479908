import React, { useEffect, useState } from 'react'

import { Group, chessSample, maskgroup, upcomingClasses } from '../../images'

import { Card,Button ,notification} from 'antd';
import { Calendar, momentLocalizer } from 'react-big-calendar';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import  './studetDashboard.css';
import { ButtonComponent, InputSelect ,ModalComponent} from '../../commonComponents';
import RegisteredCourses from './registeredCourses/registeredCourses';
import RelatedCourses from './relatedCourses/relatedCourses';
import GoingCourses from './goingCourses/goingCourses';
import moment from 'moment';
import TaskProgress from './taskProgress/taskProgress';
import UpcomingCourses from './upcomingCourses/upcomingCourses';
import RegisteredClassesCarousel from './registeredClassesCarousel/registeredClassesCarousel';
import { retrieveRelatedCourses } from '../../redux/studentDashboard/relatedCourses/action';
import { useDispatch, useSelector } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import {addClassStudnetAttendance} from '../../redux/studentDashboard/relatedCourses/action.js';
import {  insertFirebaseTokenForAdmin } from '../../redux/admin/action';
import { createRecordByFirebaseTokenStudent } from "../../redux/studentLogin/action";



import { retriveStudentSchedules ,retrieveStudentCalenderDetails} from '../../redux/studentViewAllCourses/action';
let StudentDashboard=()=> {
    
  
    let getRelatedCourses = useSelector((state)=>state.relatedCoursesReducer?.relatedCourses);
    let [calendarData,setCalendarData] = useState();

    let [relatedCourses,setRelatedCourses] = useState();

    const localizer = momentLocalizer(moment);
//dashboard calendarEvents
let result = useSelector((state)=>state.studentAllCoursesReducer?.viewAllSchedules);

  useEffect(()=>{
    dispatch(retriveStudentSchedules())
  },[])

  useEffect(()=>{
    if (result) {
        console.log(result,"dashboardSchedules")
    }
  },[result])


//dashboard calendarEvents end
console.log(window.localStorage.getItem("fcmToken"),'fcmTokenDAMODAR');


//for date picker
const [startDate, setStartDate] = useState(new Date());
const [isOpen, setIsOpen] = useState(true);




useEffect(()=>{



  let data ={
    "username": window.localStorage.getItem("userEmail"),
    "fcmtype": "web",
    "fcmtoken": window.localStorage.getItem("fcmToken"),
    "tenant_id": window.localStorage.getItem("tenant_Id"),
    "project_name": "lms",
    "full_name":window.localStorage.getItem("studentFullName")
  }
  dispatch(createRecordByFirebaseTokenStudent(data))
  dispatch(insertFirebaseTokenForAdmin(data))
 

},[]);


function renderExtraFooter() {
    return (
      <div style={{ textAlign: 'center', height:"450px" ,overflow:"auto"}}>
        <Calendar
         localizer={localizer}
        views={['day']} // Set the views prop to only include the day view
        defaultView='day' // Set the default view to day view
        selectable={true} // Optional: Allow selecting dates
        events={[]} // Optional: Provide events data if needed
      />      
      </div>
    );
  }
//
    


    let dispatch = useDispatch();
    useEffect(()=>{
        let payload =
        {
            "operation_type":"related_courses",
            "student_id":window.localStorage.getItem("student_id"),
            "tenant_id":window.localStorage.getItem("tenant_Id")
        }
        dispatch(retrieveRelatedCourses(payload))    
    },[])

    useEffect(()=>{
        if (getRelatedCourses) {
        setRelatedCourses(getRelatedCourses)
        }
    },[getRelatedCourses])
        // return (
        //   <div className='dtCalendar'>
        //     <p>{moment(momentDate).format('YYYY-MM-DD')}</p>
        //     {times.map((time, index) => (
        //       <p key={index}>{time}</p>
        //     ))}
        //   </div>
        // );

        let[studentEvents,setStudentEvents] = useState();
        let [schedulesDates,setSchedulesDates] = useState();
        let [singleEvent,setSingleEvent] = useState("");
        let [scheduleModal,setScheduleModal] = useState(false);


        let dateTableData = useSelector((state)=>state.studentAllCoursesReducer?.retrieveStudentDetailsCalender);

useEffect(()=>{

  const currentDate = new Date();
  const day1 = currentDate.getDate();
  const month1 = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
  const year1 = currentDate.getFullYear();

  // Format day and month to have leading zeroes if needed
  const formattedDay = day1 < 10 ? '0' + day1 : day1;
  const formattedMonth = month1 < 10 ? '0' + month1 : month1;

  // Construct the date string in the desired format
  const formattedDate = `${formattedDay}-${formattedMonth}-${year1}`;

  const dateString = formattedDate;
  const [day, month, year] = dateString.split('-');
  const dateObject = new Date(`${year}-${month}-${day}`);

  setDisplayedDate(dateObject);

  let payload={
   

    "operation_type":"view_stud_dashboard_schedules",
    "student_id":window.localStorage.getItem("student_id"),
    "tenant_id":window.localStorage.getItem("tenant_Id"),
    "date":formattedDate
}
  dispatch(retrieveStudentCalenderDetails(payload))
},[])

        const handleDateChange = (date) => {
            const newDateFormat = formatDate(date);

            const dateString = newDateFormat;
            const [day, month, year] = dateString.split('-');
            const dateObject = new Date(`${year}-${month}-${day}`);

            setDisplayedDate(dateObject);

          
            // Generate new date format and update the localizer
          
            // Update the localizer state
            // setLocalizer(newLocalizer);
          
            // Dispatch API call with the new date format
            let payload = {
              "operation_type": "view_stud_dashboard_schedules",
              "student_id": window.localStorage.getItem("student_id"),
              "tenant_id": window.localStorage.getItem("tenant_Id"),
              "date": newDateFormat
            };
            dispatch(retrieveStudentCalenderDetails(payload));
          
            console.log("Selected Date:", newDateFormat); // Output the selected date to the console
          };


          const [displayedDate, setDisplayedDate] = useState(new Date()); // Initialize with current date

          const formatDate = (date) => {
            return date.toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-');
          };


          useEffect(()=>{
            if (dateTableData) {
              setStudentEvents();
          
              setSchedulesDates(dateTableData?.data)
          
          
             }
          },[dateTableData])
          
          useEffect(()=>{
            if (schedulesDates) {
              // setSchedulesDates(dateTableData?.data)
          
          
          
              let eventsData = []
              schedulesDates && schedulesDates?.map((item,index)=>{
               
                eventsData.push({ start: (item.date)+" "+item.course_start_time, end: item.date+" "+item.course_end_time ,event:item.course_name,batch_name:item.batch_name,meeting_link: item.meeting_link,batch_id:item.batch_id})
              })
              setStudentEvents(eventsData) 
             }
          },[schedulesDates])
          
          const events = [];
          
          
          useEffect(()=>{
            if (studentEvents) {
          
              studentEvents && studentEvents.forEach(dateObj => {
                const { start: startDateString, end: endDateString ,event} = dateObj;
                
                // Convert date strings to Date objects
                const startDate = parseDateString(startDateString);
                const endDate = parseDateString(endDateString);
              
                console.log(dateObj?.batch_id,'batch_id')
                // Create a new event object
                const newEvent = {
                  id: events.length + 1, 
                  title: event,
                  start: startDate,
                  end: endDate,
                  batch_id:dateObj?.batch_id,
                  batch_name: dateObj?.batch_name,
                  meeting_link: dateObj?.meeting_link
                };
                // Add the new event to the events array
                events.push(newEvent);
              });
          
              setCalendarData(events);
          
             }
          },[studentEvents])
          
          
           
          
            function parseDateString(dateString) {
              const dateParts = dateString.split(' ');
              const dateComponents = dateParts[0].split('-');
              const timeComponents = dateParts[1].split(':');
              const year = parseInt(dateComponents[2], 10);
              const month = parseInt(dateComponents[1], 10) - 1;
              const day = parseInt(dateComponents[0], 10);
              const hours = parseInt(timeComponents[0], 10) + (dateParts[2] === 'PM' ? 12 : 0);
              const minutes = parseInt(timeComponents[1], 10);
            
              return new Date(year, month, day, hours, minutes);
            }


            let openModalPopUp = (event) =>{
              setSingleEvent(event)
              setScheduleModal(true)
            }
          
            let closeModal = () =>{
              setScheduleModal(false)
            }


            
    const [api, contextHolder] = notification.useNotification();

    let addStudentAttendance = useSelector((state)=>state.relatedCoursesReducer?.addAttendanceStudent);
    let [meetingLink,setMeetingLink] = useState();
    let [isActive,setIsActive] = useState(false);

    useEffect(()=>{
      if(addStudentAttendance){
          //res_status
          console.log(addStudentAttendance?.msg,'addStudentAttendance')

          openNotification(addStudentAttendance?.msg,addStudentAttendance?.res_status)
      }
    },[addStudentAttendance])

    const openNotification=(msg,status)=>{
      if (status == true) {   

          console.log(meetingLink,'meetingLink')
          

          if(isActive == true){
              window.location.href = meetingLink;

          }else{

          }

          setIsActive(false)
        }
        if (status == false) {

          if(isActive == true){
              api.info({
                  description:
                    `${msg}`,
                });
          }else{

          }
         

          setIsActive(false)

        }
  }


  const joinClass = (e,idd) => {

    setMeetingLink(idd.meeting_link)
    setIsActive(true)
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns month from 0-11
    const day = String(today.getDate()).padStart(2, '0');
  
    const formattedDate = `${year}-${month}-${day}`;

    console.log(formattedDate,'formattedDate')


    const today1 = new Date();
    let hours = today1.getHours();
    const minutes = String(today1.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;

    console.log(formattedTime,'formattedTime')


    let payload =
    {
       

        "operation_type": "check_in",
        "op_field_details": {
            "role_type": "student",
            "student_id": window.localStorage.getItem("student_id"),
            "batch_id": idd.batch_id,
            "tenant_id": window.localStorage.getItem("tenant_Id"),
            "date":formattedDate,
            "check_in_time":formattedTime
        }
    }
    dispatch(addClassStudnetAttendance(payload)) 
  };
      
            
  return (
    <div className='stuDashboardContainer'>
       {contextHolder}
        <div className='firstRow'>
            <div className='imageCarousel'>
               <GoingCourses/>
            </div>

            <div className='grid'>
                <div className='grid1'>
                    <div className='gridHeader'>
                        <div>
                            <h3>Vedic Math</h3>
                        </div>
                        <div className='alignRight'>
                            <InputSelect defaultValue={"Today"}/>
                        </div>
                    </div>

                    <div className='gridContent'>
                        <div className='gridContent1'>
                            <p className='contentPara'>Monday,28June 2023</p>
                            <p className='contentPara'>02.00-03.00 PM</p>
                        </div>
                        <div className='gridContet2'>
                            <p className='contentPara'>Mr.Mark Jefferson</p>
                            <p className='contentPara'>Zoom</p>
                        </div>
                        <div className='gridBody'>
                            <div className='gridContentImg'>
                                <img src={maskgroup} className='smallImg'/>
                                <img src={maskgroup} className='smallImg'/>
                            </div>
                            <div className='gridContet2'>
                            <p>25+ people joined in this class</p>
                        </div>          
                        </div>
                    </div>

                    
                </div>
                <div className='grid2'>
                    <TaskProgress/>
                </div>
            </div>

            {/* start */}
            

            <div className='grid'>
                <div className='grid1'>
                    
                </div>
                <div className='grid2'>
                    <UpcomingCourses/>
                </div>
            </div>

        <div className='grid'>
        <div className='imageCarousel'>
               <RegisteredClassesCarousel/>
            </div>
        </div>

        <div className='grid'>
        <RelatedCourses list={relatedCourses}/>
        </div>
        </div>

        <>
        <div className="adminDashboardCaledar">
            <div className='admindashboardDatePicker'>
        <ReactDatePicker
              onChange={handleDateChange}

            format="YYYY-MM-DD HH:mm"
            open={true}
            renderExtraFooter={renderExtraFooter}
            style={{height:"50px",position:"static",overflow:"auto"}}
            showToday={false}
            size="100px"
            
            
        />
        </div>
        <div style={{ textAlign: 'center', height:"450px" ,overflow:"auto",backgroundColor:"#fff"}}>
        <Calendar
         localizer={localizer}
         events={calendarData}
        views={['day']} 
        defaultView='day' 
        selectable={true} 
        toolbar={false}
        date={displayedDate} 
        onSelectEvent={openModalPopUp}

      />   



      </div>
        <div>
        </div>
            </div>
    
            {scheduleModal ?
        <ModalComponent title={`Batch name: ${(singleEvent?.batch_name).toUpperCase()}`}
      content={<div>
        <b>Title:</b> {singleEvent?.title}
        {/* <div><b>Meeting: </b><a href={singleEvent?.meeting_link}>
        {singleEvent?.meeting_link}</a></div> */}


<Button type="primary" onClick={(e) => joinClass(e, { batch_id: singleEvent?.batch_id,meeting_link: singleEvent?.meeting_link })}>
        Join class
      </Button>
      </div>}
      open={scheduleModal} 
      handleCancel={closeModal}
     />
     : ""}
       
       </>
    </div>
  )
}

export default StudentDashboard