import React, { useEffect, useState } from 'react'
// import { Calendar ,Row, Col, TimePicker,List } from 'antd';

import { useDispatch,useSelector } from 'react-redux';
import moment from 'moment';

import { Button,notification } from 'antd';

import { Calendar, momentLocalizer  } from 'react-big-calendar';
// import './studentSchedules.css';
import { retriveStudentSchedules } from '../../redux/studentViewAllCourses/action';

import {retireveInstructorSchedules} from '../../redux/instructorSchedules/action';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ModalComponent } from '../../commonComponents';
// import moment from 'moment'
import {addClassStudnetAttendance} from '../../redux/studentDashboard/relatedCourses/action.js';




let InstructorSchedules=()=> {
  const localizer = momentLocalizer(moment)

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  let [schedulesDates,setSchedulesDates] = useState();

  let[studentEvents,setStudentEvents] = useState();
  let [scheduleModal,setScheduleModal] = useState(false);
  let [singleEvent,setSingleEvent] = useState("");

  let dispatch = useDispatch();

  let result = useSelector((state)=>state.instructorScheduleReducer?.instructorSchedules);

  useEffect(()=>{
    dispatch(retireveInstructorSchedules())
  },[])

  useEffect(()=>{
    console.log(result?.data,"schedulesCom")
    if (result) {
        setSchedulesDates(result?.data)
    }
  },[result])


  useEffect(()=>{
      if (schedulesDates) {
        let eventsData = []
        schedulesDates && schedulesDates.map((item,index)=>{
          let myObj={};
          // myObj["title"] = item.course_name;
          // myObj["start"] = moment(item.date).toDate();
          // myObj["end"] = moment(item.date).toDate();

          eventsData.push({ start: item.date+" "+item.course_start_time, end: item.date+" "+item.course_end_time ,event:item.course_name,batch_name:item.batch_name,meeting_link: item.meeting_link,batch_id:item.batch_id})
        })
        setStudentEvents(eventsData)

      }
  },[schedulesDates])

  let openModalPopUp = (event) =>{
    setSingleEvent(event)
    setScheduleModal(true)
  }

  let closeModal = () =>{
    setScheduleModal(false)
  }
  const events = [];
  studentEvents && studentEvents.forEach(dateObj => {
    const { start: startDateString, end: endDateString ,event} = dateObj;
  
    // Convert date strings to Date objects
    const startDate = parseDateString(startDateString);
    const endDate = parseDateString(endDateString);
  
    // Create a new event object
    const newEvent = {
      id: events.length + 1, 
      title: event,
      start: startDate,
      end: endDate,
      batch_id: dateObj?.batch_id,

      batch_name: dateObj?.batch_name,
      meeting_link: dateObj?.meeting_link
    };
  
    // Add the new event to the events array
    events.push(newEvent);
  });
  
  // Print the updated events array
  console.log(events);
  
  // Function to parse date string and return Date object
  function parseDateString(dateString) {
    const dateParts = dateString.split(' ');
    const dateComponents = dateParts[0].split('-');
    const timeComponents = dateParts[1].split(':');
    const year = parseInt(dateComponents[2], 10);
    const month = parseInt(dateComponents[1], 10) - 1;
    const day = parseInt(dateComponents[0], 10);
    // const hours = parseInt(timeComponents[0], 10) + (dateParts[2] === 'PM' ? 12 : 0);
    const hours = parseInt(timeComponents[0], 10) + (dateParts[2] === 'PM' ? 12 : dateParts[2] === 'AM' ? 24 : 0);//0

    const minutes = parseInt(timeComponents[1], 10);
  
    return new Date(year, month, day, hours, minutes);
  }

  const handleDateChange = (momentObj) => {
    setSelectedDate(momentObj);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  const onPanelChange = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
  };

  console.log(studentEvents,"stuEvents")



  
  const [api, contextHolder] = notification.useNotification();

  let addStudentAttendance = useSelector((state)=>state.relatedCoursesReducer?.addAttendanceStudent);
  let [meetingLink,setMeetingLink] = useState();
  let [isActive,setIsActive] = useState(false);

  useEffect(()=>{
    if(addStudentAttendance){
        //res_status
        console.log(addStudentAttendance?.msg,'addStudentAttendance')

        openNotification(addStudentAttendance?.msg,addStudentAttendance?.res_status)
    }
  },[addStudentAttendance])

  const openNotification=(msg,status)=>{
    if (status == true) {   

        console.log(meetingLink,'meetingLink')
        

        if(isActive == true){
            window.location.href = meetingLink;

        }else{

        }

        setIsActive(false)
      }
      if (status == false) {

        if(isActive == true){
            api.info({
                description:
                  `${msg}`,
              });
        }else{

        }
       

        setIsActive(false)

      }
}


const joinClass = (e,idd) => {

  setMeetingLink(idd.meeting_link)
  setIsActive(true)
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns month from 0-11
  const day = String(today.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  console.log(formattedDate,'formattedDate')


  const today1 = new Date();
  let hours = today1.getHours();
  const minutes = String(today1.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'
  const formattedTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;

  console.log(formattedTime,'formattedTime')


  let payload =
  {
     

    "operation_type": "check_in",
    "op_field_details": {
        "role_type": "instructor",
        "instructor_id": window.localStorage.getItem("instructor_id"),
        "batch_id": idd.batch_id,
        "tenant_id": window.localStorage.getItem("tenant_Id"),
        "date":formattedDate,
        "check_in_time":formattedTime
    }
  }
  dispatch(addClassStudnetAttendance(payload)) 
};

    return (
      <>
      <div className='studentCalendar'>
      {contextHolder}
       <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
      endAccessor="end"
        defaultView='month'
        style={{ height: 550 }}
        onSelectEvent={openModalPopUp}

      />

        </div>
        {scheduleModal ?
        <ModalComponent title={`Batch name: ${(singleEvent?.batch_name).toUpperCase()}`}
      content={<div>
        <b>Title:</b> {singleEvent?.title}
        {/* <div><b>Meeting: </b><a href={singleEvent?.meeting_link} target="_blank">
        {singleEvent?.meeting_link}</a></div> */}

<div>
<Button type="primary" onClick={(e) => joinClass(e, { batch_id: singleEvent?.batch_id,meeting_link: singleEvent?.meeting_link })}>
        Start class
      </Button>
</div>

      </div>}
      open={scheduleModal} 
      handleCancel={closeModal}
     />
     : ""}
        </>
  )
}

export default InstructorSchedules