import { GET_STUDENT_REG_COURSES} from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const studentRegCoursesReducer = (state = initialStates,action)=>{
    switch (action.type) {
        case  GET_STUDENT_REG_COURSES:
            return {
                studentRegCourses: action.payload,
            }
        
        default:
            return state;
    }
}

export  default studentRegCoursesReducer;