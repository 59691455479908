import React, { useEffect, useState } from 'react'

import { useDispatch,useSelector } from 'react-redux';
import { chessSample,carouselLeft,carouselRight,static1,static2,static3,Group } from '../../../images';
import { ButtonComponent, TableComponent } from '../../../commonComponents';

import {addClassStudnetAttendance} from '../../../redux/studentDashboard/relatedCourses/action.js';

import { Card,Spin,Button ,notification} from 'antd';

import './goingCourses.css';
import { retriveLiveOngoingCourses } from '../../../redux/studentDashboard/liveCourses/action';
let GoingCourses=() =>{

    let dispatch = useDispatch();
    let goingCourses = useSelector((state)=>state.liveStudentCoursesReducer?.liveCourses);
    const [api, contextHolder] = notification.useNotification();

    let addStudentAttendance = useSelector((state)=>state.relatedCoursesReducer?.addAttendanceStudent);
    let [meetingLink,setMeetingLink] = useState();
    let [isActive,setIsActive] = useState(false);


    useEffect(()=>{
        if(addStudentAttendance){
            //res_status
            console.log(addStudentAttendance?.msg,'addStudentAttendance')

            openNotification(addStudentAttendance?.msg,addStudentAttendance?.res_status)
        }
      },[addStudentAttendance])

    let [liveCoursesData,setLiveCoursesData] = useState([]);

    useEffect(()=>{
        let data = window.localStorage.getItem("student_id")
        let payload =  {
            "operation_type":"view_upcoming_classes",
            "student_id": window.localStorage.getItem("student_id")
        }
       dispatch(retriveLiveOngoingCourses(payload))
    },[])





    const openNotification=(msg,status)=>{
        if (status == true) {   

            console.log(meetingLink,'meetingLink')
            

            if(isActive == true){
                window.location.href = meetingLink;

            }else{

            }

            setIsActive(false)
          }
          if (status == false) {

            if(isActive == true){
                api.info({
                    description:
                      `${msg}`,
                  });
            }else{

            }
           

            setIsActive(false)

          }
    }

    useEffect(()=>{
        if (goingCourses) {
            console.log(goingCourses.data,"gngCourses");
            setLiveCoursesData(goingCourses.data)
        }
    },[goingCourses])
        
        const [arrowDisable,setArrowDisable] = useState(false)
          const [currentSlide, setCurrentSlide] = useState(0);
          const[count,setCount] = useState(4)
          const slidesPerPage = 4;
        
          const nextSlide = () => {
            setCurrentSlide((prevSlide) => prevSlide + slidesPerPage);
            setCount(count+slidesPerPage+currentSlide)
            console.log(currentSlide,"sli",liveCoursesData?.length)
            // if (count >= liveCoursesData.length) {
            //     setCurrentSlide(0)
            //     setCount(0)
            // }
          };

          useEffect(()=>{
                if(currentSlide-1 >= liveCoursesData.length){
                    setArrowDisable(true)
                    setCurrentSlide(0)
                }else{
                    setArrowDisable(false)
                }
          },[currentSlide])

          useEffect(()=>{
            if ( currentSlide == liveCoursesData.length) {
                setCurrentSlide(0)
            }
          },[currentSlide])

          const prevSlide = () => {
            setCurrentSlide((prevSlide) => Math.max(0, prevSlide - slidesPerPage));
            setArrowDisable(false)
          };

         


          const joinClass = (e,idd) => {

            setMeetingLink(idd.meeting_link)
            setIsActive(true)
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns month from 0-11
            const day = String(today.getDate()).padStart(2, '0');
          
            const formattedDate = `${year}-${month}-${day}`;
      
            console.log(formattedDate,'formattedDate')


            const today1 = new Date();
            let hours = today1.getHours();
            const minutes = String(today1.getMinutes()).padStart(2, '0');
            const ampm = hours >= 12 ? 'PM' : 'AM';
          
            hours = hours % 12;
            hours = hours ? hours : 12; // The hour '0' should be '12'
            const formattedTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
      
            console.log(formattedTime,'formattedTime')


            let payload =
            {
               

                "operation_type": "check_in",
                "op_field_details": {
                    "role_type": "student",
                    "student_id": window.localStorage.getItem("student_id"),
                    "batch_id": idd.batch_id,
                    "tenant_id": window.localStorage.getItem("tenant_Id"),
                    "date":formattedDate,
                    "check_in_time":formattedTime
                }
            }
            dispatch(addClassStudnetAttendance(payload)) 
          };

  return (
    <div>
         {contextHolder}
            <div className='carouselText'>
                <div>
                <h3>UPCOMING COURSES</h3>
                </div>

                <div className='carouselNavigation alignRight'>
                <div onClick={prevSlide} className='carouselLeft'><img src={carouselLeft}/></div>
                 <div onClick={nextSlide} className='courselRight'><img src={carouselRight}/></div>

                
                </div>
            </div>
            {liveCoursesData ? 
            <div className='coursesCarousel'>
            {liveCoursesData.slice(currentSlide, currentSlide + slidesPerPage).map((key, index) =>{
                return(
                <div className='carouselImages'>
                <Card
                hoverable
                //  style={{height:"100px",width:"220px"}}
                cover={<img alt="example" src={key.course_thumbnail} className='cardImageLive' />}
                className='dashboardCard'
            >
                <div className='cardBody'>
                    <div className='bodyContent'>
                        <div>
                            {key.course_name}
                        </div>
                        <div className='courseTimings' style={{height:'30px',fontSize:'10px'}}>
                            Timings: {key.new_week_days} : {key.course_time} 
                        </div>

                        <div style={{borderRadius: '8px',marginLeft:'10px',marginRight:'10px'
                        ,marginTop:'5px',marginBottom:'10px',padding:'0px',flexDirection:'column',display: 'flex'
   }} >

                                {/* <div style={{flexDirection:'row', display: 'flex', alignItems: 'center', marginLeft:'10px',padding:'0px'}}>
                                <img src={Group} style={{height:'12px'}}/>
                                <p style={{color:"#000", marginLeft:'3px',height:'20px'}}>{key.count} students</p>
                                </div> */}

                                {/* <div style={{flexDirection:'row' ,marginLeft:'10px',display: 'flex', alignItems: 'center',}}>
                                <img src={Group} style={{height:'12px'}}/>
                                <p style={{color:"#000", marginLeft:'3px',height:'20px'}}>90 days</p>
                                </div> */}


                                <div style={{marginLeft:"10px",marginRight:"10px",marginBottom:"10px" ,marginTop:'10px'}}>
                                {/* <ButtonComponent value={"Join Class"} type={"primary"}/> */}

                                <Button type="primary" onClick={(e) => joinClass(e, { batch_id: key?.batch_id,meeting_link: key?.meeting_link })}>
        Join class
      </Button>
                                {/* <Button type="primary" primary  onChange={(e) => joinClass(e, { name: "password" })}>
                <a href={key?.meeting_link}>Join class</a>

                <a  onClick={joinClass(key.batch_id)}>Join class</a>

                </Button> */}

                                </div>


                           
                           
                        </div>
                        {/* <div className='courseDetailBtn'>
                            <div className='courseDetailText alignLeft'>
                                <img src={Group} className='group'/>
                                <p className='courseDetailText'>{key.count} students</p>
                            </div>
                            <div className='courseDetailText alignRight'>
                            <img src={Group} className='group'/>
                                <p className='courseDetailText'>90 days</p>
                            </div>
                        </div> */}
                    </div>
                </div>
                </Card>
                </div>
                )
            })}
                

            </div>
            :<div className='alignSpinnerLiveCourses'>
            <Spin/>
            </div>}
    </div>
  )
}

export default GoingCourses