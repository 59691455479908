import React from "react";

import moment from "moment";

let ContactUppopUpDetails = (props) =>{
              
    console.log(props.studentRecord)
    return (
        <div className="retriveStudent">
                <div>
                    <p>Enquiries ID  : {props?.studentRecord.id}</p>

                    <p>First Name   : {props?.studentRecord.first_name}</p>
                    <p>Last Name    : {props?.studentRecord.last_name}</p>
                    <p>Email        : {props?.studentRecord.email}</p>
                    <p>Phone        : {props?. studentRecord.phone}</p>
                    <p>Role Type: {props?.studentRecord.role_type}</p>
                    <p>Message       : {props?.studentRecord.message}</p>
                    <p>Created Date       : {(moment(props?.studentRecord.created_date, "DD-MM-YYYY HH:mm:ss")).format("DD-MM-YYYY hh:mm:ss A")}</p>

                    <p>Status          : {props?.studentRecord.status == true
                                         ? 'Completed' : 'Pending'}</p>

                </div>
        </div>
    )
}

export default ContactUppopUpDetails