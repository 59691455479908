import React, { useEffect, useState } from 'react'
import { InputComponent, TextAreaComponent,InputSelect } from '../../commonComponents';

import { Button, Form, notification,Radio, Select,Checkbox } from 'antd';
import ReactDatePicker from 'react-datepicker';
const AdminRegistrationFormDetail = (props) => {

    let [course,setCourse] = useState();

    let list=[]



    useEffect(()=>{
    
        if (props?.allRegistrationCourses) {
            props?.allRegistrationCourses && (props?.allRegistrationCourses).map((i,Index)=>{
    
           let newObj= {};
           newObj["value"] = i.course_id;
           newObj["label"] = i.course_name;
         list.push(newObj)
         setCourse(list)
         })
         console.log(course,"abc")
        }
      },[props?.allRegistrationCourses])
   
      let [allDateDetails,setAllDetails] = useState();

      let listDate=[]

      useEffect(()=>{
      
          if (props?.alldate) {
              props?.alldate && (props?.alldate).map((i,Index)=>{
      
             let newObj= {};
             newObj["value"] = i.date;
             newObj["label"] = i.date;
             listDate.push(newObj)
             setAllDetails(listDate)
           })
          }
        },[props?.alldate])





        let [allTimingDetails,setTimingDetails] = useState();

        let listTimning=[]
  
        useEffect(()=>{
        
            if (props?.allTiming) {
                props?.allTiming && (props?.allTiming).map((i,Index)=>{
        
               let newObj= {};
               newObj["value"] = i.date;
               newObj["label"] = i.date;
               listTimning.push(newObj)
               setTimingDetails(listTimning)
             })
            }
          },[props?.allTiming])
  
    return (
      
        <div className='instructorCancelClassContainer'>
         
            <div className='cancelClassContainer'>
        <Form
         initialValues={props.handleCloseModalRegistration}

        form3={props.form}
        onFinish={props?.onFinish}
        autoComplete="off"
        layout="vertical">

<div className="studentNames">
                <div className="studentInput">
                  <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[
                    { required: true, 
                      message: 'Please enter first name'
                     }
                    ]}>
                    <div className="InstructorInput">
                      <InputComponent placeholder={"First Name"}
                       name={"first_name"} 
                       value={props.first_name}
                        onChange={(e)=>props.handleClassChangeRegistration(e,{name:"first_name"})}/>
                    </div>
                    </Form.Item>
                </div>
                <div className="studentInput">
                  <Form.Item
                  label="Last Name"
                  name={"last_name"}
                  rules={[
                    { required: true, message: 'Please enter last name' }
                    ]}>
                    <div className="InstructorInput">
                      <InputComponent placeholder={"Last Name"}
                       name={"last_name"} value={props.last_name}
                       onChange={(e)=>props.handleClassChangeRegistration(e,{name:"last_name"})}/></div>
                </Form.Item>
                    </div>
            </div>


            <div className="studentNames">
                <div className="studentInput">
                  <Form.Item
                  label="Mobile Number"
                  name="phone"
                  rules={[
                    {
                        required: true,
                        message: 'Phone number is required only numbers allowed',
                        pattern: new RegExp(/^[0-9]+$/),
                       
                      },
                      {
                       max: 10,
                       min:10,
                       message: "10 digit mobile number is required",
                     },
                    ]}>
                    <div className="InstructorInput">
                      <InputComponent placeholder={"Mobile Number"}
                       name={"phone"} 
                       value={props.phone}
                        onChange={(e)=>props.handleClassChangeRegistration(e,{name:"phone"})}/>
                    </div>
                    </Form.Item>
                </div>
                <div className="studentInput">
                  <Form.Item
                  label="Email"
                  name={"email"}
                  rules={[
                    { required: true, message: 'Please enter email' }
                    ]}>
                    <div className="InstructorInput">
                      <InputComponent placeholder={"Email"}
                       name={"email"} value={props.email}
                       onChange={(e)=>props.handleClassChangeRegistration(e,{name:"email"})}/></div>
                </Form.Item>
                    </div>
            </div>

            <div className="studentNames">
                <div className="studentInput">
                <Form.Item
                 label="Course Name"
                 name="course_name"
                  rules={[
                    { required: true, message: 'Please select a course!' }
                    ]}
                 >
                   
                        <InputSelect 
                  name={"course_name"}
                  onChange={(e)=>props.handleClassChangeRegistration(e,{name: "course_name"})}
                  defaultValue={props.fieldValues.course_name  ? props.fieldValues.course_name :"Select"}
                //   value={props.fieldValues.course_name  ? props.fieldValues.course_name :"select"}
                     options={course}/>

                </Form.Item>
                </div>
                <div className="studentInput">
                <Form.Item
                 label="Date"
                 name="date"
                  rules={[
                    { required: true, message: 'Please select a date!' }
                    ]}
                 >
                   
                        <InputSelect 
                  name={"date"}
                  onChange={(e)=>props.handleClassChangeRegistration(e,{name: "date"})}
                  defaultValue={props.fieldValues.date  ? props.fieldValues.date :"Select"}
                //   value={props.fieldValues.course_name  ? props.fieldValues.course_name :"select"}
                     options={allDateDetails}/>

                </Form.Item>
                    </div>
                    <div className="studentInput">
                <Form.Item
                 label="Timing"
                 name="timing"
                  rules={[
                    { required: true, message: 'Please select a timing!' }
                    ]}
                 >
                   
                        <InputSelect 
                  name={"timing"}
                  onChange={(e)=>props.handleClassChangeRegistration(e,{name: "timing"})}
                  defaultValue={props.fieldValues.timing  ? props.fieldValues.timing :"Select"}
                //   value={props.fieldValues.course_name  ? props.fieldValues.course_name :"select"}
                     options={allTimingDetails}/>

                </Form.Item>
                    </div>
            </div>

                    
            
                
            <div>

          


                  

            <Form.Item
             label="Remark"
             name="remark"
              rules={[
                { required: true, message: 'Please enter remark' }
                ]}>
            <TextAreaComponent name={"remark"} 

            
                       value={props.registrationClassDetails?.remark == ''  ? props.registrationClassDetails?.remark : ""}
                       onChange={(e)=>props.handleClassChangeRegistration(e,{name:"remark"})}/>
            </Form.Item>

            </div>
            
            
            <div className='submitCancelClass'>
            <Form.Item>
            <Button htmlType='submit' primary type='primary'>Submit</Button>
            </Form.Item>
            </div>
        </Form>
            
            </div>
            
        </div>
    );
}

export default AdminRegistrationFormDetail;
