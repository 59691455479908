import React from 'react';
import { Button,Flex } from 'antd';

import './button.css';
const ButtonComponent = ({className,value,type, onClick, htmlType}) => {
  const handleClick = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  return (
<Button type={type}  htmlType={ htmlType}  className={className}  onClick={onClick}>
      {value}
    </Button> 
       
   
)};
export default ButtonComponent;