export const INSTRUCTOR_RESCHEDULE_CLASS = "INSTRUCTOR_RESCHEDULE_CLASS";

export const GET_ALL_RESCHEDULE_CLASSES = "GET_ALL_RESCHEDULE_CLASSES";

export const GET_ALL_CANCELLED_CLASSED = "GET_ALL_CANCELLED_CLASSED";

export const GET_STATUS_REJECTED =  "GET_STATUS_REJECTED";

export const GET_FCMDATA_FOR_RESCHEDULECLASS = "GET_FCMDATA_FOR_RESCHEDULECLASS"

export const GET_FCMDATA_FOR_RESCHEDULECLASS_REJECTED = "GET_FCMDATA_FOR_RESCHEDULECLASS_REJECTED"

export const CLASS_RESCHEDULE_NOTIFICATION = "CLASS_RESCHEDULE_NOTIFICATION"

export const CLASS_CANCEL_NOTIFICATION = "CLASS_CANCEL_NOTIFICATION"