import { ADMIN_LOGIN, ADMIN_MENUS,SET_ADMIN_LOGIN_ROLE, SET_CURRENT_ADMIN_GROUPS,
    GET_CURRENT_ADMIN_GROUPINFORMATION,
    ADMIN_INSERT_GROUP_CHART,
    ADMIN_INSERT_GROUP_CHART_FIRE_BASE,
    RETRIEVE_GROUP_CHART_DETAILS} from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const adminReducer = (state = initialStates,action)=>{

    switch (action.type) {
        case  ADMIN_LOGIN:
            return {
                ...state,
                adminLoginData: action.payload,
            }

        case ADMIN_MENUS:
           return{
            adminMenus: action.payload
           }

        case SET_ADMIN_LOGIN_ROLE:
            return{
                adminLoginRole:action.payload
            }
        
            
            
        case SET_CURRENT_ADMIN_GROUPS:
            return{
                currentAdminGroups: action.payload
            }
        
        case GET_CURRENT_ADMIN_GROUPINFORMATION:
            return{
                currentAdminGroupInfo:action.payload
            }

            case ADMIN_INSERT_GROUP_CHART:
                return{
                    insertGroupChartDetailsList: action.payload
                }
                
            case ADMIN_INSERT_GROUP_CHART_FIRE_BASE:
            return{
                insertFireBaseGroupChartDetailsList: action.payload
            }
            
            case RETRIEVE_GROUP_CHART_DETAILS:
                return{
                    retrieveGroupChartDetails: action.payload
                }
        default:
            return state;
    }
}

export  default adminReducer;