import { GET_STUDENT_COURSE_PROGRESS } from "./actionTypes";

import axios from "axios";
import apiConfig from '../../../apiConfig/api';

const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
};


export const getCourseProgress = (data)=>{
    return{
        type: GET_STUDENT_COURSE_PROGRESS,
        payload: data
    }
}

export const studentCourseProgress = (data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/student_page",data
        ).then((res)=>{
            dispatch(getCourseProgress(res.data));
        }).catch(err=>{
            console.log(err)
        })
}
}