import { DELETE_TENANT, GET_TENANT_MANAGEMENT_RECORDS,GET_TENANT_RECORDS, TENANT_CREATION_FAILED } from "./actionTypes";

 const tenantManagementreducer = (state = [],action)=>{
    switch (action.type) {
        case  GET_TENANT_MANAGEMENT_RECORDS:
            return {
                tenantAdminCreationStatus: action.payload,
            }
        
        case GET_TENANT_RECORDS:
            return {
                getTenantManagementRecords: action.payload
            }

        case TENANT_CREATION_FAILED:
        return{
            failedToCreateTenant: action.payload
        }

        case DELETE_TENANT:
          return {
            tenantDeleted: action.payload
          }
        default:
            return state;
    }
}

export  default tenantManagementreducer;