import React from 'react'
import { ButtonComponent, InputComponent, TextAreaComponent } from '../../../../commonComponents'
import { Button, Upload} from 'antd'
import { UploadOutlined } from '@ant-design/icons';

import './addAdditionalDetail.css'
let AddCourseAdditionalDetails=(props)=> {
  console.log(props.additionalDetails.uploadImg,"image")
  return (
    <div className='additionalDetails'>
        <div>
            <div>
               <div className='labelName'> <label >Course Thumbnail <span className="formStar">*</span></label></div>
                <div className='fileGrid'>
                  {/* <Upload name={'uploadImg'} value={props.additionalDetails.uploadImg}
                  onChange={(e)=>props.handleAdditionalDetails(e)}>
                    <Upload {...props}>
    <Button icon={<UploadOutlined />}>Click to Upload</Button>
  </Upload>
                  </Upload> */}
                  <div className=''>
                    {props.additionalDetails.uploadImg ?
                     <img alt='noimage' 
                    //  src={`data:image/png;base64+${props.additionalDetails.uploadImg }`}
                     src={props.modalType!="Edit Course" ?`${(props.additionalDetails.uploadImg)}` :
                     `${props.additionalDetails.uploadImg}`}
                    width='200px' height={"100px"}/> : ""}
                  </div>
                  <div>
                    <span>Upload your course thumbnail here Note 12:8 Ratio</span>
                  <InputComponent type="file" name={"uploadImg"}  style={{"display": "none"}}
                     onChange={(e)=>props.onFileChange(e,{name: "uploadImg"})}
                     value={props.uploadImg}/>
                             <p className='text-danger'>{props.additionDetailError?.imgError ? "Please select image" : ""}</p>

    {/* <p className='text-danger'>{props.additionDetailError?.imgError ? props.additionDetailError?.imgError : ""} </p> */}
                  </div>

                    
                </div>
            </div>

            

            <div >
            <div className='labelName'><label >Course Description <span className="formStar">*</span></label></div>
                <TextAreaComponent   placeholder="Enter Description" 
   name={"courseDescriptionText"}  value={props.additionalDetails.courseDescriptionText}
               onChange={(e)=>props.handleAdditionalDetails(e,{name: "courseDescriptionText"})}/>
    <p className='text-danger'>{props.additionDetailError?.courseDescriptionError ? props.additionDetailError?.courseDescriptionError : ""} </p>
            </div>


            <div>
              <div className='labelName'><label>What you will teach in course? <span className="formStar">*</span></label></div>
                <InputComponent value={props.additionalDetails.course_teach ?
                props.additionalDetails.course_teach : ""}
                onChange={(e)=>props.handleAdditionalDetails(e,{name: "course_teach"})}/>
        <p className='text-danger'>{props.additionDetailError?.learnCourseError ? props.additionDetailError?.learnCourseError : ""}</p>
            </div>

            <div>
              <div className='labelName'><label>Course Requirements <span className="formStar">*</span></label></div>
              <InputComponent value={props.additionalDetails.course_requirements ?
                props.additionalDetails.course_requirements : ""}
                onChange={(e)=>props.handleAdditionalDetails(e,{name: "course_requirements"})}/>
        <p className='text-danger'>{props.additionDetailError?.courseReqError ? props.additionDetailError?.courseReqError : ""}</p>

            </div>
        </div>

        <div>

        </div>
    </div>
  )
}

export default AddCourseAdditionalDetails