import { GET_STUDENT_PROFILE_DATA,RESET_DETAILS_RESULT,RESET_STUDENT_PASSWORD } from "./actionTypes";

 const studentProfileReducer = (state = [],action)=>{

    switch (action.type) {
        case  GET_STUDENT_PROFILE_DATA:
            return {
                studentProfile: action.payload,
            }
        
            case RESET_DETAILS_RESULT:
                return{
                    resetStatus: action.payload
                }

             case RESET_STUDENT_PASSWORD:
                return {
                    passwordStatus: action.payload
                }
        default:
            return state;
    }
}

export  default studentProfileReducer;