import React, { useEffect, useState } from 'react'
import { TableComponent } from '../../commonComponents';

import { useSelector,useDispatch } from 'react-redux';
import { retrieveAllInstructorBatches, retrieveBatchRelatedStudents } from '../../redux/instructorBatches/action';
import moment from 'moment';
import './instructorBatches.css';
import { Table } from 'antd';
import { collapse, expand, settings, tutor } from '../../images';
let InstructorBatches=()=> {
  let dispatch = useDispatch();

  let[batches,setBatches] = useState();

  let[studentBatches,setStudentBatches] = useState();

  let [batchId,setBatchId] = useState("");

  let [uniqueBatchId,setUniqueBatchId] = useState("");
  let allBatches = useSelector((state)=>state.instructorBatchReducer.allBatches?.data);

  let batchRelatedStudents = useSelector((state)=>state.instructorBatchReducer?.allBatchRelatedStudents?.data);


  const [expandedKey, setExpandedKey] = useState(null);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(()=>{
    let payload= {
      "operation_type": "view_batches",
      "instructor_id": window.localStorage.getItem("instructor_id")
    }
    dispatch(retrieveAllInstructorBatches(payload))
  },[])

  useEffect(()=>{
    if (allBatches) {
      setBatches(allBatches)   
    }
   
    console.log(batches,"insBatches")
  },[allBatches])

  useEffect(()=>{
    if (batchRelatedStudents) {
      setStudentBatches(batchRelatedStudents);
    }
  },[batchRelatedStudents])

  let nestedColumns = [
    {
      title: 'Student ID',
      dataIndex: 'student_id',
      key: 'student_id',
      sorter: true,
      sorter: (a, b) => a.student_id - b.student_id,

    },
    {
      title: 'Student Name',
      dataIndex: 'student_name',
      key: 'student_name',
      sorter: true,
      sorter: (a, b) => a.student_name .localeCompare(b.student_name),


    },
    {
      title: 'Course Name',
      dataIndex: 'course_name',
      key: 'course_name',
      sorter: true,
      sorter: (a, b) => a.course_name .localeCompare(b.course_name),

    },
    {
      title: 'Batch Start Date',
      dataIndex: 'batch_start_date',
      key: 'batch_start_date',
      sorter: true,

      render: (text, record) => (
        <div className="actionIcons">
            <span>{moment(record.batch_start_date).format("MM-DD-YYYY")}</span>
        </div>
       ),
       sorter: (a, b) => a.batch_start_date - (b.batch_start_date),

    },
    {
      title: 'Batch End Date',
      dataIndex: 'batch_end_date',
      key: 'batch_end_date',
      sorter: true,

      render: (text, record) => (
        <div className="actionIcons">
            <span>{moment(record.batch_end_date).format("MM-DD-YYYY")}</span>
        </div>
       ),
       sorter: (a, b) => a.batch_end_date - (b.batch_end_date),

    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      sorter: true,
      sorter: (a, b) => a.duration - (b.duration),

    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,

      render: (text, record) => (
        <div className="actionIcons">
            <span>{record?.status? "Active": "Inactive"}</span>
        </div>
       ),
       sorter: (a, b) => a.status - (b.status),

    },
  ];


  useEffect(()=>{
    if (batchId) {
      let payload= {
        "operation_type": "view_batch_related_students",
        "batch_id": batchId
    }
       dispatch(retrieveBatchRelatedStudents(payload))
       setBatchId("")
    }
  },[batchId])

  let handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([])
      setExpandedRowKeys([record.batch_id]);
      setBatchId(record.batch_id)
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.batch_id));
    }
  };
  const expandedRowRender = (record,expanded) => {  
    return (
      <Table dataSource={studentBatches} columns={nestedColumns} pagination={false}
       />
    );
  };

  const columns1 = [
    {
      title: 'Batch ID ',
      dataIndex: 'batch_id',
      key: 'batch_id',
      sorter: true,
      sorter: (a, b) => a.batch_id - b.batch_id,

    },
    {
      title: 'Batch Name',
      dataIndex: 'batch_name',
      key: 'batch_name',
      sorter: true,
      sorter: (a, b) => a.batch_name .localeCompare(b.batch_name),


    },
    {
      title: 'Student Count',
      dataIndex: 'student_count',
      key: 'student_count',
      sorter: true,
      sorter: (a, b) => a.student_count - (b.student_count),

    },
    {
      title: 'Enrolled Date',
      dataIndex: 'enrolled_dates',
      key: 'enrolled_dates',
      sorter: true,

      render: (text, record) => (
        <div className="actionIcons">
            <span>{moment(record.enrolled_dates).format("MM-DD-YYYY")}</span>
        </div>
       ),
       sorter: (a, b) => a.enrolled_dates - (b.enrolled_dates),

    },
    {
      title: 'Progress',
      dataIndex: 'course_completion_percentage',
      key: 'course_completion_percentage',
      sorter: true,
      
      render: (text, record) => (
        <div className="actionIcons">
            <span>{(record.course_completion_percentage)+"%"}</span>
        </div>
       ),
       sorter: (a, b) => a.course_completion_percentage - (b.course_completion_percentage),

    },
  ];

  return (
    <div className='instructorBatches'>
       <Table
        dataSource={batches}
        columns={columns1}
        pageSize={10}
        expandable={{expandedRowRender,expandedRowKeys, expandIcon: ({ expanded, onExpand, record }) =>
        expanded ? (
         
          <img src={expand} style={{ fontSize: '20px' }} className='instructorBatchesExpand'
          onClick={(e) => onExpand(record, e)}/>
        ) : (
          <img src={collapse} style={{ fontSize: '20px' }} className='instructorBatchesExpand'
          onClick={(e) => onExpand(record, e)}/>
        ),}}
        rowKey= {'batch_id'}
        onExpand={handleExpand}
        size="small"
      />
    </div>
  )
}

export default InstructorBatches