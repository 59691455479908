import React, { useEffect, useState } from 'react'
import { avatarPic } from '../../../images'
import { InputComponent } from '../../../commonComponents'

import './instructorList.css';
import { Spin } from 'antd';

const InstructorList=(props)=> {

    let {instructorGroupList} = props

    const [data,setData] = useState();

    useEffect(()=>{
        if (props?.instructorGroupList) {
            setData(props?.instructorGroupList)
        }else{
            setData([])

        }
    },[props?.instructorGroupList])

    const handleSearch = (e) => {
        const searchQuery = e?.target?.value.toLowerCase(); // Convert search query to lowercase for case-insensitive search
    
        if (searchQuery) {
            // Filter the studentGroupList based on the search query
            const filteredData = props?.instructorGroupList.filter(item =>
                item?.batch_name.toLowerCase().includes(searchQuery)// Adjust 'id' according to your data structure
            );
    
            // Update the data state with the filtered result
            setData(filteredData);
        } else {
            // If search query is empty, reset data to the original studentGroupList
            setData(props?.instructorGroupList);
        }
    };
    

    return (
    <div>
        <div className='chatHeader'>
            <div><h3  style={{ fontSize: '20px', fontWeight: 'bold' }}>Chat</h3></div>
                <div>
                <InputComponent placeholder={"Search"}  onChange={(e)=>handleSearch(e)}/>
                </div>
            </div>

        <div className='listOfUsers'>
            {data !=undefined ? 
        data.map((item,index)=>{
            return (
                <div className='allUsers' onClick={(e)=>props.handleGroupChat(item)}>
                <div>
                    <img src={item?.course_thumbnail? item?.course_thumbnail : avatarPic}
                    style={{width:'30px', height:"25px",borderRadius:"4px"}}/>
                </div>
    
                <div className='dataContent'>
                        <div><b>{item.batch_name}</b></div>
                        <div></div>
                </div>
    
                <div className='chatTimeDetails'>
                    <div>
                        <p></p>
                    </div>
                    <div>
                        <p></p>
                    </div>
                </div>
            </div>
            )
        })
        :<div className='text-center' style={{marginTop:"10px", display:"flex",flexDirection:"row",justifyContent:"center"}}><Spin/></div>}
    </div>
    </div>
  )
}

export default InstructorList