import React ,{useEffect} from 'react';
import { InputComponent, TextAreaComponent } from '../../../../commonComponents';

import './cancelClass.css';
import { Button, Form, TimePicker,DatePicker } from 'antd';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

const InstructorCancelClass = (props) => {


    
    const disabledDate = (current) => {
        // Can not select days before today
        return current && current < moment().startOf('day');
      };
      

    return (
        <div className='instructorCancelClassContainer'>
            <div>
                <p>Dear instructor, if you want to cancel the class  
                    please give a valid reason below !!</p>
            </div>
            <div className='cancelClassContainer'>
        <Form
         initialValues={props.cancelClassDetails}

        form={props.form}
        onFinish={props?.onFinish}
        autoComplete="off"
        layout="vertical">

            <div className='instructorCancelClassTimes'>
            <div>

                    <Form.Item
                    label="Start time"
                    name="cancel_batch_start_time"
                     rules={[
                       { required: true, message: 'Please enter Start time' }
                       ]}>

                    <TimePicker use12Hours format={'hh:mm A'} name={"cancel_batch_start_time"}
                       onChange={(e)=>props.handleClassChange(e,{name:"cancel_batch_start_time"})}
                       value={props.cancelClassDetails?.cancel_batch_start_time}
                    //    defaultValue={props.cancelClassDetails?.cancel_batch_start_time ? props.cancelClassDetails?.cancel_batch_start_time : ""}
                     />
                    </Form.Item>
                </div>

                <div>
                    <Form.Item
                    label="End time"
                    name="cancel_batch_end_time"
                     rules={[
                       { required: true, message: 'Please enter cancel time' }
                       ]}>

                    <TimePicker use12Hours format={'hh:mm A'} name={"cancel_batch_end_time"}
                       onChange={(e)=>props.handleClassChange(e,{name:"cancel_batch_end_time"})}
                       value={props.cancelClassDetails?.cancel_batch_end_time}
                    //    defaultValue={props.cancelClassDetails?.cancel_batch_end_time ? props.cancelClassDetails?.cancel_batch_end_time : ""}
                     />
                    </Form.Item>
                </div>


            </div>
                
            <div>


            <div className='classCancelledDate'>
           

            <Form.Item
                       label="Cancelled Date"
                       name="canceled_date"
                           rules={[
                             { required: true, message: 'Please select cancelled date' }
                             ]}>
                        <div className='batchDate'>
                        <DatePicker name={"canceled_date"}
                        mode='date'
                        disabledDate={disabledDate}
                        defaultValue={props.cancelClassDetails?.canceled_date ?
                            moment(props.cancelClassDetails?.canceled_date) :""}
                            onChange={(e)=>props.handleClassChange(e,{name:"canceled_date"})}
                        />

                          </div>
                          </Form.Item>

            </div>


            <Form.Item
             label="Reason"
             name="reason"
              rules={[
                { required: true, message: 'Please enter valid reason' }
                ]}>
            <TextAreaComponent name={"reason"} 

            
                       value={props.cancelClassDetails?.reason == ''  ? props.cancelClassDetails?.reason : ""}
                       onChange={(e)=>props.handleClassChange(e,{name:"reason"})}/>
            </Form.Item>

            </div>
            
            
            <div className='submitCancelClass'>
            <Form.Item>
            <Button htmlType='submit' primary type='primary'>Submit</Button>
            </Form.Item>
            </div>
        </Form>
            
            </div>
            
        </div>
    );
}

export default InstructorCancelClass;
