import React, { useState } from 'react'
import { Row, Col, TimePicker } from 'antd';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { retrieveAdminSchedules } from '../../redux/adminSchedules/action';
import { ModalComponent } from '../../commonComponents';
let AdminSchedule=()=> {

  const localizer = momentLocalizer(moment)

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  let [schedulesDates,setSchedulesDates] = useState();

  let[studentEvents,setStudentEvents] = useState();

  let [scheduleModal,setScheduleModal] = useState(false);

  let [singleEvent,setSingleEvent] = useState("");


  let dispatch = useDispatch();

  let adminSchedules = useSelector((state)=>state.AdminScheduleReducer?.adminSchedules);

  useEffect(()=>{
    dispatch(retrieveAdminSchedules())
  },[])

  useEffect(()=>{
    console.log(adminSchedules?.data,"schedulesCom")
    if (adminSchedules) {
        setSchedulesDates(adminSchedules?.data)
    }
  },[adminSchedules])


  useEffect(()=>{
      if (schedulesDates) {
        let eventsData = []
        schedulesDates && schedulesDates.map((item,index)=>{
          let myObj={};
          // myObj["title"] = item.course_name;
          // myObj["start"] = moment(item.date).toDate();
          // myObj["end"] = moment(item.date).toDate();

          eventsData.push({ start: (item.date)+" "+item.course_start_time, end: item.date+" "+item.course_end_time ,event:item.course_name,batch_name:item.batch_name,meeting_link: item.meeting_link})
        })
        setStudentEvents(eventsData)

      }
  },[schedulesDates])

  let openModalPopUp = (event) =>{
    setSingleEvent(event)
    setScheduleModal(true)
  }

  let closeModal = () =>{
    setScheduleModal(false)
  }
  
  const events = [];
  studentEvents && studentEvents.forEach(dateObj => {
    const { start: startDateString, end: endDateString ,event} = dateObj;
    
    console.log(dateObj,"inbet")
    // Convert date strings to Date objects
    const startDate = parseDateString(startDateString);
    const endDate = parseDateString(endDateString);
  
    // Create a new event object
    const newEvent = {
      id: events.length + 1, 
      title: event,
      start: startDate,
      end: endDate,
      batch_name: dateObj?.batch_name,
      meeting_link: dateObj?.meeting_link
    };
    // Add the new event to the events array
    events.push(newEvent);
  });
  
  // Print the updated events array
  console.log(events);
  
  // Function to parse date string and return Date object
  function parseDateString(dateString) {
    const dateParts = dateString.split(' ');
    const dateComponents = dateParts[0].split('-');
    const timeComponents = dateParts[1].split(':');
    const year = parseInt(dateComponents[2], 10);
    const month = parseInt(dateComponents[1], 10) - 1;
    const day = parseInt(dateComponents[0], 10);
    // const hours = parseInt(timeComponents[0], 10) + (dateParts[2] === 'AM' ? 12 : 12);//0
    const hours = parseInt(timeComponents[0], 10) + (dateParts[2] === 'PM' ? 12 : dateParts[2] === 'AM' ? 24 : 0);//0

    const minutes = parseInt(timeComponents[1], 10);
  
    return new Date(year, month, day, hours, minutes);
  }


  console.log(events,"stuEvents");
 
    return (
      <>
      <div className='studentCalendar'>
       <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultView='month'
        style={{ height: 550 }}
        onSelectEvent={openModalPopUp}
      />

        </div>
        {scheduleModal ?
        <ModalComponent title={`Batch name: ${(singleEvent?.batch_name).toUpperCase()}`}
      content={<div>
        <b>Title:</b> {singleEvent?.title}
        <div><b>Meeting: </b><a href={singleEvent?.meeting_link}>
        {singleEvent?.meeting_link}</a></div>
      </div>}
      open={scheduleModal} 
      handleCancel={closeModal}
     />
     : ""}
        </>
  )
}

export default AdminSchedule