import { GET_ANNOUNCEMNTS_TYPE_NOTIFICATION, GET_FULL_NAME_FROM_BATCH} from "./actionTypes";

const groupRetrieveReducer = (state = [],action)=>{

   switch (action.type) {
       case  GET_FULL_NAME_FROM_BATCH:
           return {
               fullNames: action.payload,
           }
        
        case GET_ANNOUNCEMNTS_TYPE_NOTIFICATION:
            return{
                announcementTypeNotification: action.payload
            }
              
       default:
           return state;
   }
}

export  default groupRetrieveReducer;