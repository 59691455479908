import {DELETE_TENANT, GET_TENANT_MANAGEMENT_RECORDS,GET_TENANT_RECORDS,
    TENANT_CREATION_FAILED} from './actionTypes';

import axios from 'axios';
import apiConfig from '../../apiConfig/api';


export const createTenantRecord = (data)=>{
    return {
        type: GET_TENANT_MANAGEMENT_RECORDS,
        payload: data
    }
}

export const getTenantManagementRecords = (data) =>{
    return {
        type:GET_TENANT_RECORDS,
        payload:data
    }
}

export const tenantCreationFailed = (data) =>{
    return {
        type:TENANT_CREATION_FAILED,
        payload:data
    }
}

export const deleteTenant = (data) =>{
        return{
            type:DELETE_TENANT,
            payload: data
        }
}

export const retrieveTenantRecords = () =>{
    let payload = {
        "operation_type": "select"
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/tenantmaster",payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                 dispatch(getTenantManagementRecords(res?.data))
            }
           
            
        }).catch(err=>{
            console.log(err)
        })
}
}

export const createTenantManagement = (data) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/tenantmaster",data
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                //  dispatch(createTenantRecord(res?.data?.res_status))
                 dispatch(retrieveTenantRecords());
                 dispatch(tenantCreationFailed(res?.data))

            }
           
            if (res?.data?.res_status == false) {
                dispatch(tenantCreationFailed(res?.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const deleteTenantByID = (data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/tenantmaster",data
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                 dispatch(deleteTenant(res?.data?.res_status))
                 dispatch(retrieveTenantRecords())
                 dispatch(tenantCreationFailed(res?.data))

            }
           
        }).catch(err=>{
            console.log(err)
        })
}
}

export const updateTenantRecord = (data) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/tenantmaster",data
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                 dispatch(retrieveTenantRecords())
                 dispatch(tenantCreationFailed(res?.data))

            }
           
        }).catch(err=>{
            console.log(err)
        })
}
}