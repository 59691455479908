import { INSTRUCTOR_CURRENT_BATCHES, INSTRUCTOR_VIEW_BATCH_DETAIL,INSTRUCTOR_RETRIEVE_CLASS} from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const instructorDashboardReducer = (state = initialStates,action)=>{
    switch (action.type) {
       
            case INSTRUCTOR_CURRENT_BATCHES:
                return{
                    instructorCurrentBatches: action.payload
                }
                
            case INSTRUCTOR_VIEW_BATCH_DETAIL:
                return{
                    viewBatchDetails: action.payload
                }

                case INSTRUCTOR_RETRIEVE_CLASS:
                    return{
                        retrieveData: action.payload
                    }
        default:
            return state;
    }
}

export  default instructorDashboardReducer;