import { SET_INSTRUCTOR_DETAILS,SET_INSTRUCTOR_EMAIL,INSTRUCTOR_EMAIL_VERIFY,SET_PASSWORDS } from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const instructorRegisterReducer = (state = initialStates,action)=>{

    switch (action.type) {
        case  SET_INSTRUCTOR_DETAILS:
            return {
                ...state,
                instructorReg: action.payload,
            }

        case SET_INSTRUCTOR_EMAIL:
            return{
                ...state,
                email: action.payload
            }

        case INSTRUCTOR_EMAIL_VERIFY:
            return{
                emailStatus: action.payload
            }

        case SET_PASSWORDS:
            return{
                ...state,
                passwordMatch: action.payload
            }
        default:
            return state;
    }
}

export  default instructorRegisterReducer;