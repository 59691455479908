import { CREATE_INSTRUCTOR_CANCEL_CLASS,GET_ALL_INSTRUCTOR_CANCEL_CLASS,CHANGE_CANCEL_CLASS_STATUS,CANCEL_CALSS_STUDENT_NOTIFICATION,CANCEL_CLASS_REJECTED,} from "./actionTypes";

 const instructorCancelClassReducer = (state = [],action)=>{
    switch (action.type) {
       
            case CREATE_INSTRUCTOR_CANCEL_CLASS:
                return{
                    instructorCreateCancelClass: action.payload
                }
            
                case GET_ALL_INSTRUCTOR_CANCEL_CLASS:
                    return{
                        getAllInstructorCanceledClass: action.payload
                    }
                
                    case CHANGE_CANCEL_CLASS_STATUS:
                        return{
                            cancelClassStatus: action.payload
                        }

                        case CANCEL_CALSS_STUDENT_NOTIFICATION:
                            return{
                                cancelClassStudentNotification: action.payload
                            }

                            case CANCEL_CLASS_REJECTED:
                                return{
                                    cancelClassRejectedd: action.payload
                                }
                
        default:
            return state;
    }
}

export  default instructorCancelClassReducer;