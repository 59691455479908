import React from 'react';
import OverallTalentAcquisition from './overAllTalentAcquisition/overallTalentAcquisition';
import SuperAdminUserCharts from './superAdminUserCharts/superAdminUserCharts';

import './dashboard.css';
const SuperAdminDashboard = () => {
    return (
        <div className='superAdminDashboard'>
            <div>
                <SuperAdminUserCharts/>
            </div>

            <div className=''>
                <OverallTalentAcquisition/>
            </div>
        </div>
    );
}

export default SuperAdminDashboard;
