import './App.css';

import { useState } from 'react';

import { NavLink } from "react-router-dom";

import  banner from '../src/images/Object.svg';

import logo from '../src/images/logo.svg';


import { faceBookIcon } from './images';

import googleIcon from '../src/images/google.svg'

import InputComponent from './commonComponents/InputComponent/input';

import SwitchComponent from './commonComponents/switchComponent/switch';

import InputPassword from './commonComponents/InputPassword/inputPassword';

import ButtonComponent from './commonComponents/ButtonComponent/button'

import { Outlet } from 'react-router-dom';

import LoginComponent from './components/Login/login';

import StudentComponent from './components/Student/student';
import InstructorComponent from './components/Instructor/login';
import { useEffect } from 'react';
let App=()=> {
  
  let [checked, setChecked] = useState(true);

  let [studentDetails,setStudentDetails] = useState(true);

  let [instructorDetails,setInstructorDetails] = useState(false);

  let [email,setEmail] = useState(" ");

  let [password,setPassword] = useState(" ");

  let [studentRegisterForm,setStudentRegisterForm] = useState(false)




  let studentForm = () =>{
    setInstructorDetails(false);
    setStudentDetails(true)
  }

  let instructorForm = () =>{
    setInstructorDetails(true);
    setStudentDetails(false)
  }

  // let studentRegister = () =>{
  //   setStudentRegisterForm(true)
  // }

  return (
    <div className='rootLogin'>
        
    <div className="App">
      <div className='logo'>
         <img src= {banner} className='bannerImage'/>
      </div>
      <div className='login'>
        <div className='form'>
          <div className='formLogo'>
          <img src={logo}/>
            <div className='buttonSet'>
            <div className='buttonGroup'>
              <div onClick={studentForm}>
                <ButtonComponent value={"Student"} className={studentDetails ? 'studentFormEnable':'studentForm'}></ButtonComponent>
              </div>
              <div  onClick={instructorForm}>
              <ButtonComponent value={"Instructor"}className={instructorDetails ? 'instructorFormEnable':'instructorForm'}></ButtonComponent>
                </div>


                
            </div>
            </div>
          </div>
          
              <>
              { !instructorDetails ? <LoginComponent /> : <InstructorComponent/>}
              </>
        </div>
      </div>
    </div>
         <Outlet />
    </div>

  );
}

export default App;
