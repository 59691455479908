import { GET_STUDENT_UPCOMING_COURSES} from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const upcomingCoursesReducer = (state = initialStates,action)=>{
    switch (action.type) {
        case  GET_STUDENT_UPCOMING_COURSES:
            return {
                upcomingCourses: action.payload,
            }
        
        default:
            return state;
    }
}

export default  upcomingCoursesReducer;