import React, { useEffect, useMemo, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { ButtonComponent, ModalComponent, TableComponent } from "../../commonComponents";
import "./courseCategory.css";
import CourseTypes from "../courseTypes/courseTypes";
import AddCategory from "./addCategory/addCategory";
import { deleteIcon, edit } from "../../images";
import categoryValidation from "./addCategory/categoryValidation";
import { createCategory, deleteCategory, deleteCategoryByID, getCategories, updateCategory } from "../../redux/allCategories/action";
import { Button, Form, notification } from "antd";
import { type } from "@testing-library/user-event/dist/type";
import * as XLSX from 'xlsx';
import ExportToExcel from '../../commonComponents/XlsxFile/ExportToExcel'
let CourseCategory=()=> {
    let dispatch = useDispatch();
    let allCategories = useSelector((state)=> state.categories?.category?.data);


    let checkStatus = useSelector((state)=> state.categories?.verify);

    let checkCategory = useSelector((state)=> state.categories?.categorySuccess);

    let categoryCrudStatus = useSelector((state)=>state.categories?.adminCategoryCrud);

    useEffect(()=>{
        if (categoryCrudStatus) {
          openNotification(categoryCrudStatus?.msg,categoryCrudStatus)
        }
    },[categoryCrudStatus])
    console.log(checkStatus,"categoryStatus")
    const [api, contextHolder] = notification.useNotification();

    let [addCategoryModal,setAddCategoryModal] = useState(false);

    let [deleteCategoryModal,setDeleteCategoryModal] = useState(false);

    let [modalType,setModalType] = useState(" ");

    let [errors,setErrors] = useState();

    let [categoryExists,setCategoryExists] = useState("")

    let[categories,setCategories] = useState();

    let [categoryId,setCategoryID] = useState();

    let [addCategoryDetails,setAddCategoryDetails] = useState({
        course_category_name: '',
        course_category_description: ''
    })

    let [status,setStatus] = useState()

    useEffect(()=>{
        dispatch(getCategories());
    },[])

    useEffect(()=>{
    if (allCategories) {
        setCategories(allCategories);
    }else{
      setCategories([]);

    }
    },[allCategories])


    const [categoryName,setCategoryName] = useState("");

    const handleCategoryName = (e,type)=>{
      if (type.name = "course_category_name") {
        form.setFieldsValue({["course_category_name"]:e.target.value})
        setCategoryName(e.target.value)
      }
    }
   

    let handleChange = (e,type)=>{
        if (e.target !== undefined) {
            const { name, value } = e.target;
            setAddCategoryDetails({ ...addCategoryDetails, [name]: value });
            form.setFieldsValue({["course_category_name"]:e})

          } else {
            setAddCategoryDetails({ ...addCategoryDetails, [type.name]: e });
            form.setFieldsValue({["course_category_description"]:e})
          }
    }

    let handleCategoryModal = (type,id) =>{
        setAddCategoryModal(true)
        setDeleteCategoryModal(false)
        setModalType(type)
        setCategoryID(id)
        if (id) {
            setCategoryID(id)
            allCategories.filter((item,index)=>{
                if (item.course_category_id == id) {
                    console.log(item.course_category,"iii")
                  setAddCategoryDetails({
                    course_category_name: categoryName,
                    course_category_description : item.course_category_description
                  })
                  setCategoryName(item.course_category)
                  form.setFieldsValue({["course_category_name"]:item.course_category});
                  form.setFieldsValue({["course_category_description"]:item.course_category_description}) 
                }
            })
        }

    }

    let closeModal = ()=>{
        setAddCategoryModal(false);
        setAddCategoryDetails({
          course_category_name:"",
          course_category_description:""
        })
        setErrors({})
        setDeleteCategoryModal(false)
        setCategoryName("")
    }
    const [form] = Form.useForm();

    let categorySubmit = () =>{
      setAddCategoryDetails({...addCategoryDetails,["course_category_description"]:""})
      form.setFieldsValue({["course_category_description"]:""});
      form.setFieldsValue({["course_category_name"]:""})
      setCategoryName("")
            if (modalType == "Add Category") {
                let categoryPayload={
                "operation_type": "insert",
                "op_field_details": {
                    "course_category": categoryName,
                    "course_category_description": addCategoryDetails.course_category_description,
                    "tenant_id": window.localStorage.getItem("tenant_Id")
                }
            }
            dispatch(createCategory(categoryPayload));
            setErrors({})

        }
        if(modalType == "Edit Category"){
            let editPayload={
                "operation_type": "update",
                "op_field_details": {
                    "course_category_id": categoryId,
                    "course_category": categoryName,
                    "course_category_description": addCategoryDetails.course_category_description,
                    "tenant_id": window.localStorage.getItem("tenant_Id")
                }
            }
            dispatch(updateCategory(editPayload))
            setAddCategoryModal(false)
            setAddCategoryDetails({...addCategoryDetails,["course_category_name"]:""});
            setAddCategoryDetails({...addCategoryDetails,["course_category_description"]:""})
            form.setFieldsValue({["course_category_description"]:""});
            form.setFieldsValue({["course_category_name"]:""})
            setCategoryName("")

        }
        
    }

    let handleDeleteModal=(value)=>{
        setAddCategoryModal(false)
         setDeleteCategoryModal(true)
         setCategoryID(value)
        console.log(categoryId)
        setModalType("Delete Category")
    }

    let categoryDelete = ()=>{
        let deletePayload= {
            "operation_type": "delete",
            "op_field_details": {
                "course_category_id": categoryId
            }
        }
          dispatch(deleteCategory(deletePayload))
          setDeleteCategoryModal(false)
    }

    useEffect(()=>{
        if(checkStatus == false){
          setAddCategoryModal(true)
          setCategoryExists({newError: "Category already exists"})
        }
        if(checkStatus == true){
          openNotification(modalType,checkStatus,addCategoryDetails)
            setAddCategoryModal(false)
          setCategoryExists({newError: ""})
        //   setFormValues({})
        setAddCategoryDetails({})
        dispatch(getCategories())
        }
      },[checkStatus])

     
    let closeDeleteModal = () =>{
        setDeleteCategoryModal(false);
        setErrors({})
    }
    const columns = [

        {
          title: 'ID',
          dataIndex: 'course_category_id',
          sorter: true,
          sorter: (a, b) => a.course_category_id - b.course_category_id,
  
        },

        {
            title: 'Name',
            dataIndex: 'course_category',
            sorter: true,
            sorter: (a, b) => a.course_category .localeCompare(b.course_category),
    
          },
          
        {
            title: 'Description',
            dataIndex: 'course_category_description',
            sorter: true,
            sorter: (a, b) => a.course_category_description .localeCompare(b.course_category_description),
    
          },

          {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
              <div className="actionIcons">
                 <img src={edit} onClick={()=>handleCategoryModal("Edit Category",record.course_category_id)} />
                 <img src={deleteIcon} onClick={() => handleDeleteModal(record.course_category_id)} />
              </div>
            ),
          }
    ]

    

    let categoryFooter = [
        <div className="footerCategory">
          <div>
          <ButtonComponent type={"secondary"} 
          default value={'Cancel'}
           className={'cancel ant-btn-default'} onClick={closeModal}>
            Cancel</ButtonComponent>
          </div>

          <div>
          <Button type={"primary"}
             value={'Submit'}
            className={'submit'}
             onClick={categorySubmit}>Submit</Button>
            
        </div>
      </div>
    ]

    let deleteFooter = [
        <div className="footerCategory">
          <div>
          <ButtonComponent type={"secondary"} 
          default value={'Cancel'}
           className={'cancel ant-btn-default'} onClick={closeModal}>
            Cancel</ButtonComponent>
          </div>

          <div className="alignRight">
          <Button type={"primary"}
            danger value={'Delete'} 
            className={'submit'}
             onClick={categoryDelete}>Delete</Button>
        </div>
      </div>
    ]

    const openNotification = (msg,adminStatus) => {
      if (msg && adminStatus?.res_status == true ) {
        setAddCategoryModal(false)
        api.info({
          description:
            `${msg}`,
        });
      }

      if (msg && adminStatus?.res_status == false ) {
        api.info({
          description:
            `${msg}`,
        });
      }
      
    };


    const exportToExcel = (data, fileName) => {
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    };


    const transformedItems = categories?.map((item) => {
      // Transform each item as needed
      return { 
         COURSE_ID: item.course_category_id,
         COURSE_NAME:  item.course_category,
        COURSE_DESC:  item.course_category_description,
      
      
      };
    });


    let handleCategoryModal1 = (type,id) =>{
   
      exportToExcel(transformedItems, 'course_category_details');


  }

  return (
    <div className="courseCategory">
           {contextHolder}
            <div className="allCoursesSelections">
            <h3>Course Category</h3>

            <CourseTypes handleModal={() => handleCategoryModal("Add Category", null)}
            handleModal1={() => handleCategoryModal1("Add Category", null)}  />
        </div>
        <div className="courseTable">
            <TableComponent columns={columns} data={categories ? categories : []} sorter={true}
            loading={!categories ? true : false}
         />
        </div>

     {addCategoryModal?
      <ModalComponent title={modalType}
      content={<AddCategory form={form} onFinish = {categorySubmit}
      handleChange={handleChange}
      errors={errors}
      addCategoryDetails={addCategoryDetails} 
      categoryExists= {categoryExists} handleCategoryName={handleCategoryName}
      categoryName={categoryName}/>}
      open={addCategoryModal}
      handleCancel={closeModal}
      
     />
     : ""} 


    {deleteCategoryModal? 
    <ModalComponent title={"Delete Category"}
    content={"Are you sure you want to delete category? "} 
    open={deleteCategoryModal}
    handleCancel={closeDeleteModal} 
    footer= {deleteFooter}/>
        : ""} 
        
        
    </div>
  )
}

export default CourseCategory;