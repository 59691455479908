import { GET_LIVE_ONGOING_COURSES } from "./actionTypes";
import axios from "axios";
import apiConfig from '../../../apiConfig/api';

const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
};


export  const getLiveOngoingCourses=(data)=>{
    return {
        type: GET_LIVE_ONGOING_COURSES,
        payload: data
    }
}


export const retriveLiveOngoingCourses=(data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/student_page",data
        ).then((res)=>{
            dispatch(getLiveOngoingCourses(res.data));
        }).catch(err=>{
            console.log(err)
        })
}
}
