import React, { useEffect, useState } from 'react';
import { NavLink ,Outlet} from "react-router-dom";

import './adminReports.css';
import { Select } from 'antd';
import StudentReports from './studentReports/studentReports';
import EnrolledReports from './enrolledReports/enrolledReports';
import { excel, plus, upload, searchIcon } from "../../images";

import AllReports from './All Reports/allReports'
import { useDispatch, useSelector } from 'react-redux';
import { retrieveStudentReports } from '../../redux/adminReports/studentReports/action';
import { retrieveAdminEnrolledReports } from '../../redux/adminReports/enrolledReports/action';
import ActiveUserReports from './activeUserReports/activeUserReports';

import StudentAttendanceReports from './studentAttendance/studentAttendanceDetails';
import InStructoreAttendanceReports from './studentAttendance/instructoreAttendanceDetails';

const AdminReports = () => {

     const dispatch = useDispatch();
    const reportTypes= [
        // {label:"ALL",value:"ALL Reports",path:"/allReports"},
        {label:"Student Reports",value:"Student Reports",path:"/studentReports"},
        {label:"Enrollment Reports",value:"Enrollment Reports",path:"/enrollmentReports"},
        {label:"Active User Reports",value:"Active User Reports",path:"/activeUserReports"},
        {label:"Student Attendance",value:"Student Attendance",path:"/studentAttendance"},
        {label:"Instructor Attendance",value:"Instructor Attendance",path:"/instructorAttendance"}

    ]


    const monthList= [
        // {label:"ALL",value:"ALL Reports",path:"/allReports"},
        {label:"January",value:"January"},
        {label:"February",value:"February"},
        {label:"March",value:"March"},
        {label:"April",value:"April"},
        {label:"May",value:"May"},
        {label:"June",value:"June"},
        {label:"July",value:"July"},
        {label:"August",value:"August"},
        {label:"September",value:"September"},
        {label:"October",value:"October"},
        {label:"November",value:"November"},
        {label:"December",value:"December"},


    ]


    const yearList= [
        // {label:"ALL",value:"ALL Reports",path:"/allReports"},
        {label:"2024",value:"2024"},
        {label:"2023",value:"2023"},
        {label:"2022",value:"2022"},
        {label:"2021",value:"2021"},
        {label:"2020",value:"2020"},



    ]

    const [selectedReport, setSelectedReport] = useState("Student Reports");

    const [selectedMonth, setSelectedMonth] = useState("Select Month");

    const [selectedYear, setSelectedYear] = useState("Select Yaer");

    const [selectExcelvalue, setExcelValue] = useState("Select Value");




    const [allReportsVisible,setAllReportsVisible] = useState(false);

    const [studentReportsVisible,setStudentReportsVisible] = useState(false);

    const [enrolledReportsVisible,setEnrolledReportsVisible] = useState(false);

    const [activeReports,setActiveReports] =  useState(false);

    const [studentAttendance,setStudentAttendance] =  useState(false);
    const [instructorAttendance,setInstructorAttendance] =  useState(false);

    const [studentDetailsVisible,setStudentDetailsVisible] =  useState(false);
    const [instructorDetailsVisible,setInstructorDetailsVisible] =  useState(false);


    const currentYear = new Date().getFullYear();

    console.log(currentYear,'currentYear')

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      const currentMonth = monthNames[new Date().getMonth()];

      console.log(currentMonth,'currentMonth')


      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns month from 0-11
      const day = String(today.getDate()).padStart(2, '0');
    
      const formattedDate = `${year}-${month}-${day}`;

      console.log(formattedDate,'formattedDate')

      const today1 = new Date();
      let hours = today1.getHours();
      const minutes = String(today1.getMinutes()).padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
    
      hours = hours % 12;
      hours = hours ? hours : 12; // The hour '0' should be '12'
      const formattedTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;

      console.log(formattedTime,'formattedTime')

    //   setSelectedYear(currentYear)
    //   setSelectedMonth(currentMonth)


    useEffect(()=>{
          setSelectedYear(currentYear)
      setSelectedMonth(currentMonth)
    },[])

    const handleSelectChange = (event) => {
        setSelectedReport(event);
        setExcelValue(event)

        if(event == 'Student Attendance'){
            setStudentDetailsVisible(true)
        }else{
            setStudentDetailsVisible(false)

        }

        if(event == 'Instructor Attendance'){
            setInstructorDetailsVisible(true)
        }else{
            setInstructorDetailsVisible(false)

        }

        console.log(event,'event')

    };

  

    const handleSelectChangeMonth = (event) => {
        setSelectedMonth(event);
        // setStudentDetailsVisible(true)

    };

    const handleSelectChangeYear = (event) => {
        setSelectedYear(event);
        // setStudentDetailsVisible(true)




    };

    useEffect(()=>{
        selectedReport == "ALL Reports" ? setAllReportsVisible(true):setAllReportsVisible(false);
        selectedReport == "Student Reports" ? setStudentReportsVisible(true):setStudentReportsVisible(false);
        selectedReport == "Enrollment Reports" ? setEnrolledReportsVisible(true):setEnrolledReportsVisible(false)
        selectedReport == "Active User Reports" ? setActiveReports(true):setActiveReports(false)
        selectedReport == "Student Attendance" ? setStudentAttendance(true):setStudentAttendance(false)
        selectedReport == "Instructor Attendance" ? setInstructorAttendance(true):setInstructorAttendance(false)

    },[selectedReport])


    let handleCategoryModal1 = (type,id) =>{
   
  
  
    }


    return (
        <div className='reportsContainer' >
              <div style={{width:"200dp"}}>
                <h3>Reports</h3>


<div style={{flexDirection:'row',display:'flex', justifyContent: 'space-between', alignItems: 'center' 
}}>

<div>

<div style={{display: 'flex', gap: '10px'}}>
<Select defaultValue={"Select Value"} onChange={(e)=>handleSelectChange(e)}>
    {reportTypes && reportTypes.map((item,index)=>{
        return (
            <Select.Option key={item.value} value={item.value} >
                    {item.value}
            </Select.Option>
        )
    })}
</Select>

{studentAttendance  && <Select defaultValue={"Select Year"} onChange={(e)=>handleSelectChangeYear(e)}>
    {yearList && yearList.map((item,index)=>{
        return (
            <Select.Option key={item.value} value={item.value} >
                    {item.value}
            </Select.Option>
        )
    })}
</Select>}

{instructorAttendance  && <Select defaultValue={"Select Year"} onChange={(e)=>handleSelectChangeYear(e)}>
    {yearList && yearList.map((item,index)=>{
        return (
            <Select.Option key={item.value} value={item.value} >
                    {item.value}
            </Select.Option>
        )
    })}
</Select>}

{studentAttendance  && <Select defaultValue={"Select Month"} onChange={(e)=>handleSelectChangeMonth(e)}>
    {monthList && monthList.map((item,index)=>{
        return (
            <Select.Option key={item.value} value={item.value} >
                    {item.value}
            </Select.Option>
        )
    })}
</Select>}

{instructorAttendance  && <Select defaultValue={"Select Month"} onChange={(e)=>handleSelectChangeMonth(e)}>
    {monthList && monthList.map((item,index)=>{
        return (
            <Select.Option key={item.value} value={item.value} >
                    {item.value}
            </Select.Option>
        )
    })}
</Select>}






</div>
</div>

{/* <img src={excel} className="exportIcons" handleModal1={() => handleCategoryModal1("Add Category", null)}  /> */}

</div>

           


          


        </div>
        <div style={{height:"15px",marginTop:"10px"}}>
           {/* {allReportsVisible && <AllReports/>} */}
           {studentReportsVisible && <StudentReports selectExcelvalue={selectExcelvalue}/>}
           {enrolledReportsVisible && <EnrolledReports selectExcelvalue={selectExcelvalue}/>}
           {activeReports && <ActiveUserReports selectExcelvalue={selectExcelvalue}/>}
           {studentDetailsVisible && <StudentAttendanceReports  selectedMonth={selectedMonth}
           selectedYear={selectedYear} selectExcelvalue={selectExcelvalue}/>}
 {instructorDetailsVisible && <InStructoreAttendanceReports  selectedMonth={selectedMonth}
           selectedYear={selectedYear} selectExcelvalue={selectExcelvalue}/>}




        </div>
        </div>
      
    );
}

export default AdminReports;
