import {SET_INSTRUCTOR_DETAILS,GET_INSTRUCTOR_DETAILS,
    INSTRUCTOR_EMAIL_VERIFY, SET_INSTRUCTOR_EMAIL,SET_PASSWORDS } from "./actionTypes";
import axios from 'axios';
import apiConfig from '../../apiConfig/api';

const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    };


export const setInstructroDetails = (data)=>{
    return{
        type: SET_INSTRUCTOR_DETAILS,
        payload: data
    }
}

export const setInstructorEmail = (data)=>{
    return{
        type: SET_INSTRUCTOR_EMAIL,
        payload: data
    }
}

export const emailVerificationStatus = (data)=>{
    console.log(data,"vv")
    return{
        type: INSTRUCTOR_EMAIL_VERIFY,
        payload: data
    }
}

export const passwordStatus = (data)=>{
    return{
        type:SET_PASSWORDS,
        payload:data
    }
}
export const createInstructor = (data) =>{
    console.log(data,"createPayload")
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor_enquiry",data,{headers}
        ).then((res)=>{
            if (res.data?.res_status== true) {
                dispatch(setInstructroDetails(res?.data))
                // dispatch(setInstructorEmail(res?.data.email))
                console.log(res.data,'dama')
            }
            else{
                dispatch(setInstructroDetails(res?.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const verifyInstructorRegisterEmail = (data) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/student",data,{headers}
        ).then((res)=>{
            console.log(res.data?.res_status,"status")
            if (res.data?.res_status== true) {
                dispatch(emailVerificationStatus(res?.data?.res_status))
            }
            else{
                dispatch(emailVerificationStatus(res?.data?.res_status))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const passwordConfirmationInstructor = (data) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/student",data,{headers}
        ).then((res)=>{
            console.log(res.data?.res_status,"status")
            if (res.data?.res_status== true) {
                dispatch(passwordStatus(res?.data?.res_status))
            }
            else{
                dispatch(passwordStatus(res?.data?.res_status))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}