import React from 'react';
import { Input } from 'antd';
import './textAreaComponent.css';

const { TextArea } = Input;
let TextAreaComponent = ({onChange,className,rows,cols,value }) =>{
    return (
        <>
        <TextArea value={value} rows={rows } cols={cols} maxLength={550}
         className={className} placeholder="Enter Description" 
         onChange={onChange} />
      </>
    )
}

export default TextAreaComponent