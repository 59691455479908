import {  GET_ALL_INSTRUCTOR_BATCHES, GET_BATCH_RELATED_STUDENTS } from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const instructorBatchReducer = (state = initialStates,action)=>{

    switch (action.type) {
        case  GET_ALL_INSTRUCTOR_BATCHES:
            return {
                ...state,
                allBatches: action.payload,
            }
        
            case GET_BATCH_RELATED_STUDENTS:
                
            return{
                allBatchRelatedStudents: action.payload
            }
        default:
            return state;
    }
}

export  default instructorBatchReducer;