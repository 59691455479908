import React, { useState } from 'react';
import { TableEyeIcon, deleteIcon, edit, excel, plus, upload } from '../../../images';
import { InputSelect, ModalComponent, TableComponent } from '../../../commonComponents';
import CourseTypes from '../../courseTypes/courseTypes';
import AddTenant from './addTenant/addTenant';
import { useDispatch, useSelector } from 'react-redux';
import { Form, message, notification } from 'antd';
import { createTenantManagement, deleteTenantByID, retrieveTenantRecords, updateTenantRecord } from '../../../redux/tenantManagement/action';
import moment from 'moment';
import { useEffect } from 'react';

const TenentManagement = () => {
    const [api, contextHolder] = notification.useNotification();

    let dispatch = useDispatch()
    const [form] = Form.useForm();
    let [addTenantModal,setAddTenantModal] = useState(false);

    let [modalType,setModalType] = useState("");

    let [tenantDataRecords,setDataTenantRecords] = useState("");

    let [tenantId,setTenantId] = useState();

    let [tenantName,setTenantName] = useState("")

    let [tenantStatus,setTenantStatus] = useState("");
    let [joinedDate,setJoinedDate] = useState("")
    let [addTenantData,setAddTenantData] = useState({
        tenant_name:"",
        // join_date:"",
        expirey_date:""
    })

    let tenantRecords = useSelector((state)=>state.tenantManagementreducer?.getTenantManagementRecords?.data)


    let tenantCreationStatus = useSelector((state)=>state.tenantManagementreducer?.tenantAdminCreationStatus)

    let checkTenantCreation = useSelector((state) =>state.tenantManagementreducer?.failedToCreateTenant);

    useEffect(()=>{
        if (tenantRecords) {
            setDataTenantRecords(tenantRecords)
            console.log(tenantDataRecords,"oppp")

        }
    },[tenantRecords])

    useEffect(()=>{
        
        dispatch(retrieveTenantRecords())
    },[])

    useEffect(()=>{
        if (tenantCreationStatus == true) {
            setAddTenantModal(false)
            // openNotification(tenantCreationStatus)
        }
    },[tenantCreationStatus])


    useEffect(()=>{
        if (checkTenantCreation?.res_status == false) {
            openNotification(checkTenantCreation?.msg,checkTenantCreation)

        }

        if (checkTenantCreation?.res_status == true) {
            openNotification(checkTenantCreation?.msg,checkTenantCreation)
        }
    },[checkTenantCreation])


    const openNotification = (msg,checkTenantCreation) => {
        if (msg !="" && checkTenantCreation?.res_status == false) {
          api.info({
            type: 'error',
            description:
              `${msg}`,
          });
        }
        if (msg && checkTenantCreation) {
            if (checkTenantCreation?.res_status == true) {
                setAddTenantModal(false);    
            }
            
            api.info({
                description:
                `${msg}`,

              });
        }
    }
    
    let openTenantModalForm = (type,id) =>{
        setTenantId(type)
        setAddTenantModal(true)
        if (id == undefined) {
            setModalType("Add Tenant")
        }else{
            setModalType(id.name)

        }
        console.log(type,"id")

        {tenantRecords && tenantRecords.map((item,index)=>{
            if (item.tenant_id == type) {

                // setAddTenantData({item})
                console.log(moment(item.joined_date).format("YYYY-MM-DD"),item.expired_date,item,"joinedate")
                setAddTenantData({...addTenantData,["tenant_name"]: item.tenant_name})
                setAddTenantData({...addTenantData,["join_date"]:item.joined_date})
                setAddTenantData({...addTenantData,"expirey_date": item.expired_date})

                setJoinedDate(item.joined_date)
                setTenantName(item.tenant_name)
                form.setFieldsValue({["tenant_name"]: item.tenant_name});
                form.setFieldsValue({["expirey_date"]: item.expired_date})
                form.setFieldsValue({["join_date"]: item.joined_date})

                
                console.log(addTenantData,"afterbinding")
            }
    })}
    }

    let closeTenantModal = () =>{
        setAddTenantModal(false);
        setAddTenantData({...addTenantData,"expirey_date":""});
        setAddTenantData({...addTenantData,"tenant_name":""});
        setJoinedDate("")

    }

    let handleChange = (e,type)=>{
        if (type.name == "tenant_name") {
            setAddTenantData({...addTenantData,"tenant_name":e.target.value})
            setTenantName(e.target.value)
            form.setFieldsValue({[type.name]: e.target.value})

        }
        if(type.name == "join_date"){
            setAddTenantData({...addTenantData,"join_date":e})
            form.setFieldsValue({[type.name]: e})
            setJoinedDate(e)
        }

        if(type.name == "expirey_date"){
            setAddTenantData({...addTenantData,"expirey_date":e})
            form.setFieldsValue({[type.name]: e})

        }
        if (type.name == "tenantStatus") {
            setTenantStatus(e)
            form.setFieldsValue({[type.name]: e})

        }
        console.log(addTenantData,"formVals")
    }

    let onFinish =(values) =>{
        console.log(values)

        if (modalType != "Edit tenant") {
            let payload =
        {
            "operation_type": "insert",
            "op_field_details": {
                "tenant_name":tenantName,
                "joined_date": moment(joinedDate).format('YYYY-MM-DD'),
                "expired_date": moment(addTenantData?.expirey_date).format('YYYY-MM-DD')
            }
        }
         dispatch(createTenantManagement(payload))
        console.log(payload,"create")
        }
        
        if (modalType == "Edit tenant") {
            
            let payload = {
                "operation_type": "update",
                "op_field_details": {
                    "tenant_id":tenantId,
                    "tenant_name": tenantName,
                    "joined_date": moment(joinedDate).format('YYYY-MM-DD'),
                    "expired_date": moment(addTenantData?.expirey_date).format('YYYY-MM-DD'),
                    "status": tenantStatus
                }
            }
            dispatch(updateTenantRecord(payload))

        }
    }

    let handleTenantDelete = (id) =>{
        let deletePayload =
        {
            "operation_type": "delete",
            "op_field_details": {
                "tenant_id": id
            }
        }
         dispatch(deleteTenantByID(deletePayload))
        // console.log(deletePayload,"dele")
    }

    const columns = [

        {
            title: 'Tenant Id',
            dataIndex: 'tenant_id',
            sorter: true,
          },

        {
          title: 'Tenant name',
          dataIndex: 'tenant_name',
          sorter: true,
        },
        
          {
            title: 'Tenant Joined date',
            dataIndex: 'joined_date',
            sorter: true,
            render: (text, record) => (
                <div className="actionIcons">
                    <span>{moment(record.joined_date).format("MM-DD-YYYY")}</span>
                </div>
               ),
          },
          {
            title: 'Tenant Expirey date',
            dataIndex: 'expired_date',
            sorter: true,
            render: (text, record) => (
                <div className="actionIcons">
                    <span>{moment(record.expired_date).format("MM-DD-YYYY")}</span>
                </div>
               ),
          },
         
          {
            title: 'status',
            dataIndex: 'status',
            sorter: true,
          },
          {
            title: 'Action',
            dataIndex: 'status',
            sorter: true,
            render: (text, record) => (
                <div className="actionIcons">
                    <img src={edit}  onClick={()=>openTenantModalForm(record.tenant_id,{name:"Edit tenant"})}/>
                    <img src={deleteIcon} onClick={()=>handleTenantDelete(record.tenant_id)} />
                    {/* <img src={TableEyeIcon}/> */}
                </div>
               ),
          },
    ]
    return (
        <div className='tenantManagementContainer'>
                    {contextHolder}
           <h3>TENANT MANAGEMENT</h3>
           <div className='tenantManagementActions'>
           <div className="allCoursesSelections">
           <><div className="courseRecords">
            <div className="record">All(45)</div>
            <div className="record">Active(20)</div>
            <div className="record">INactive(20)</div>
            <div className="record">Trash(25)</div>
        </div>
            <div className="bulkActions">
                <InputSelect defaultValue={"Select"} />
            </div>
            <div className="exportCourseIcons">
                {/* <img src={excel} className="exportIcons" /> */}
                <img src={plus} className="exportIcons" onClick={()=>openTenantModalForm({name:"Add Tenant"})}  />
                {/* <img src={upload} className="exportIcons" /> */}
            </div></>
            </div>
            </div> 

            <div className='tenantManagementTable'>
                <TableComponent columns={columns} data={tenantDataRecords}/>
            </div>

            {addTenantModal ? 
            <ModalComponent
            title={modalType} content = {<AddTenant addTenantData={addTenantData}
            form={form} onFinish={onFinish}
            tenantStatus={tenantStatus} modalType={modalType} joinedDate={joinedDate} tenantName={tenantName}
            handleChange={handleChange}/>}
        open={addTenantModal} handleCancel={closeTenantModal}/>
        : ""}
        </div>
    );
}

export default TenentManagement;
