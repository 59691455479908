let publishCourseValidation=(values)=> {
    let isPublish = true;
    let publishDetailError ={}
        if (values.welcomeMsgText == "") {
            // publishDetailError.welcomeMsgError = "Welcome message is required";
            // isPublish = false
        }else{
            publishDetailError.welcomeMsgError = "";
        }   

        if (values.congratulationsMsgText == "") {
            // publishDetailError.congratulationsMsgError = "Congratualations message is required";
            // isPublish = false
        }else{
            publishDetailError.congratulationsMsgError = ""
        }

        return {
            isPublish, publishDetailError
        }
}

export default publishCourseValidation