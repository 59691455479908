import React from "react";

import moment from "moment";

let ViewBatchesDetails = (props) =>{
              
    return (
        <div className="retriveStudent">
                <div>
                    <p>Batch ID  : {props?.studentRecord.batch_id}</p>

                    <p>Batch Name   : {props?.studentRecord.batch_name}</p>
                    <p>Start Date    : {props?.studentRecord.batch_start_date}</p>
                    <p>End Date        : {props?.studentRecord.batch_end_date}</p>
                    <p>Instructor Name        : {props?. studentRecord.instructor_name}</p>
                    <p>Course Name: {props?.studentRecord.course_name}</p>
                    <p>Start Time          : {props?. studentRecord.start_time}</p>
                    <p>End Time       : {props?.studentRecord.end_time}</p>
                    <p>Student Count  : {props?.studentRecord.student_count}</p>
          
                </div>
        </div>
    )
}

export default ViewBatchesDetails