import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { retrieveBatchRelatedStudents } from '../../../../redux/instructorBatches/action';
import { useParams } from 'react-router-dom';

import { TableComponent } from "../../../../commonComponents";
let BatchStudentTable=(props)=> {

    let [studentsInfo,setStudentsInfo] = useState([]);
    const params = useParams()
    let dispatch = useDispatch();
    let batchRelatedStudents = useSelector((state)=>state.instructorBatchReducer?.allBatchRelatedStudents);

    let columns = [
        {
            title: 'Student ID',
            dataIndex: 'student_id',
            sorter: true,
            sorter: (a, b) => a.student_id - b.student_id,

          },
          {
            title: 'Student Name',
            dataIndex: 'student_name',
            sorter: true,
            sorter: (a, b) => a.student_name .localeCompare(b.student_name),

  
          },
          // {
          //   title: 'Progress',
          //   dataIndex: 'progress',
          //   sorter: true,
          // },
          {
            title: 'Contact',
            dataIndex: 'phone_no',
            sorter: true,
            sorter: (a, b) => a.phone_no .localeCompare(b.phone_no),

            
          },
          {
            title: 'Location',
            dataIndex: 'location',
            sorter: true,
            sorter: (a, b) => a.location .localeCompare(b.location),

          },
    ]

    useEffect(()=>{
        let retrieveStudentsPayload={
            "operation_type": "view_batch_related_students",
            "batch_id": params.id
        }
        dispatch(retrieveBatchRelatedStudents(retrieveStudentsPayload))
        setStudentsInfo([])
    },[])
    
    useEffect(()=>{
        if (batchRelatedStudents) {
            setStudentsInfo(batchRelatedStudents)
        }
    },[batchRelatedStudents])
  return (
    <div>
        <TableComponent columns={columns} data={studentsInfo?.data}/>
    </div>
  )
}

export default BatchStudentTable