import { GET_ALL_INSTRUCTOR_STUDENTS } from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';

export const getAllInstructorStudents = (data) =>{
    return {
        type: GET_ALL_INSTRUCTOR_STUDENTS,
        payload: data
    }
}

export const retrieveAllInstructorStudents = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor_page",payload
        ).then((res)=>{
            dispatch(getAllInstructorStudents(res.data));
        }).catch(err=>{
            console.log(err)
        })
}
}