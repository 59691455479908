import React, { useEffect, useState } from 'react';
import { deleteIcon, edit, plus } from '../../../images';
import { InputSelect, ModalComponent, TableComponent } from '../../../commonComponents';
import AddtenantApproval from './addtenantApproval/addtenantApproval';
import { Form, notification } from 'antd';
import { type } from '@testing-library/user-event/dist/type';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveTenantRecords } from '../../../redux/tenantManagement/action';
import { createTenantAdmin, deleteTenantAdmin, retrieveTenantAdminRecords } from '../../../redux/tenantAdmin/action';

const TenentApproval = () => {

    const [api, contextHolder] = notification.useNotification();

    const handleEdit = () =>{

    }

    const [dialogBoxView,setDialogBoxView] = useState();


    const handleDelete = (userId,tenantId) =>{
        let payload ={
            "operation_type":"delete",
            "op_field_details":{
                "tenant_id": tenantId,
                "user_id":[userId]
            }
        }
        dispatch(deleteTenantAdmin(payload))
    }

    let columns = [
        {
            title: 'First name',
            dataIndex: 'first_name',
            sorter: true,
          },

          {
            title: 'Last name',
            dataIndex: 'last_name',
            sorter: true,
          },

          {
            title: 'Email',
            dataIndex: 'email',
            sorter: true,
          },
          {
            title: 'Phone Number',
            dataIndex: 'phone',
            sorter: true,
          },
          {
            title: 'Action',
            dataIndex: 'status',
            sorter: true,
            render: (text, record) => (
                <div className="actionIcons">
                    {/* <img src={edit}  onClick={()=>openTenantAdminForm(record.user_id,{name:"Edit tenant admin"})}/> */}
                    <img src={deleteIcon} onClick={()=>handleDelete(record.user_id,record.tenant_id)} />
                    {/* <img src={TableEyeIcon}/> */}
                </div>
               ),
          },
          
    ]

    const [form] = Form.useForm();
    let [tenantAdminModal,setTenantAdminModal] = useState(false);

    let dispatch = useDispatch();

    let [modalType,setModalType] = useState("");

    const [firstName,setFirstName] = useState("");

    const[lastName,setLastName] = useState("");

    const[email,setEmail] = useState("");

    const[phoneNumber,setPhoneNumber] = useState("");

    const[password,setPassword] = useState("");

    const[confirmPassword,setConfirmPassword] = useState("");

    const[tenantId,setTenantId] = useState("");

    //tableState

    const [tenantAdminRecords,setTenantAdminRecords] = useState()

    let tenantRecords = useSelector((state)=>state.tenantManagementreducer?.getTenantManagementRecords?.data)

    let tenantAdminData = useSelector((state)=>state.tenantAdminReducer?.tenantAdmins);

    let tenantRequestStatus = useSelector((state) =>state.tenantAdminReducer?.tenantCreateUpdateDeleteStatus);
    
        useEffect(()=>{
            if (tenantRequestStatus) {
                openNotification(tenantRequestStatus?.msg,tenantRequestStatus)
            }
            console.log(tenantRequestStatus,"tenantRequestState")
        },[tenantRequestStatus])
    
        const openNotification = (msg,checkTenantCreation) => {
            if (msg !="" && checkTenantCreation?.res_status == false) {
              api.info({
                type: 'error',
                description:
                  `${msg}`,
              });
            }
            
            if (msg && checkTenantCreation) {
                if (checkTenantCreation?.res_status == true) {
                    setTenantAdminModal(false);
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setPhoneNumber("");
                    setPassword("");
                    setConfirmPassword("") ;
                    setTenantId("")
                }
                
                api.info({
                    description:
                    `${msg}`,
    
                  });
            }
        }
    useEffect(()=>{
        dispatch(retrieveTenantRecords())
    },[])

    useEffect(()=>{
        dispatch(retrieveTenantAdminRecords())
    },[])

    useEffect(()=>{
        if (tenantAdminData) {
            setTenantAdminRecords(tenantAdminData)
        }
        console.log(tenantAdminData,"check")
    },[tenantAdminData])

    const openTenantAdminForm = (id,name) =>{
        setTenantAdminModal(true)
        if (id == undefined) {
            setModalType("Add Tenant Admin")
        }
        else{
            setModalType("Edit Tenant Admin")
            tenantAdminRecords?.data?.filter((item,index)=>{
                if(item.user_id == id){
                  setFirstName(item.first_name);
                  setLastName(item.last_name);
                  setEmail(item.email);
                  setPhoneNumber(item.phone);
                  setTenantId(item.tenant_id);
    

                  form.setFieldsValue({["firstName"]:item.first_name})
                  form.setFieldsValue({["lastName"]:item.last_name})
                  form.setFieldsValue({["email"]:item.email})
                  form.setFieldsValue({["phoneNumber"]:item.phone})
                  form.setFieldsValue({["tenantId"]:item.tenant_id})

                  }
              })

        }

        // console.log(id,'id')

       
    }

    const closeTenantAdminModal = ()=>{
        setTenantAdminModal(false)
    }

    const handleChange = (e,type) =>{
        if (type.name == "firstName") {
            setFirstName(e.target.value)
        }

        if (type.name == "lastName") {
            setLastName(e.target.value)
        }

        if (type.name == "email") {
            setEmail(e.target.value)
        }

        if (type.name == "phoneNumber") {
            setPhoneNumber(e.target.value)
        }

        if (type.name == "password") {
            setPassword(e.target.value)
        }

        if (type.name == "confirmPassword") {
            setConfirmPassword(e.target.value)
        }

        if (type.name == "tenantId") {
            setTenantId(e)
        }
        // console.log(password,phoneNumber,"formValues")
    }
    const onFinish = (values)=>{
            console.log(values)
        let payload ={
                "operation_type":"insert",
                "op_field_details":{
                    "first_name":firstName,
                    "last_name":lastName,
                    "email":email?.toLowerCase(),
                    "phone":phoneNumber,
                    "password":password,
                    "confirm_password":confirmPassword,
                    "tenant_id":tenantId,
                    "role_name":"admin"
                }
            }
            console.log(payload,"payload")
           dispatch(createTenantAdmin(payload))
    }
    return (
        <div className='tenantAdminContainer'>
                {contextHolder}
            <h3>TENANT ADMIN</h3>
            <div className='tenantManagementActions'>
           <div className="allCoursesSelections">
           <><div className="courseRecords">
            <div className="record">All(45)</div>
            <div className="record">Active(20)</div>
            <div className="record">INactive(20)</div>
            <div className="record">Trash(25)</div>
        </div>
            <div className="bulkActions">
                <InputSelect defaultValue={"Select"} />
            </div>
            <div className="exportCourseIcons">
                {/* <img src={excel} className="exportIcons" /> */}
                <img src={plus} className="exportIcons" onClick={()=>openTenantAdminForm(undefined,{name:"Add Tenant"})}  />
                {/* <img src={upload} className="exportIcons" /> */}
            </div></>
            </div>
            </div> 

            <div className='tenantManagementTable'>
                <TableComponent columns={columns} data={tenantAdminRecords?.data}/>
            </div>

            {tenantAdminModal ? 
            <ModalComponent
            title={modalType} content = {<AddtenantApproval 
            form={form}
            onFinish={onFinish}
            handleChange={handleChange}
            firstName={firstName}
            lastName={lastName}
            email={email}
            phoneNumber={phoneNumber}
            password={password}
            confirmPassword={confirmPassword}
            tenantId = {tenantId}
            tenantRecords={tenantRecords}/>}
        open={tenantAdminModal} handleCancel={closeTenantAdminModal}/>
        : ""}
        </div>
    );
}

export default TenentApproval;
