import { type } from "@testing-library/user-event/dist/type";
import { ADMIN_LOGIN,ADMIN_MENUS,SET_ADMIN_LOGIN_ROLE,SET_CURRENT_ADMIN_GROUPS
,GET_CURRENT_ADMIN_GROUPINFORMATION,
ADMIN_INSERT_GROUP_CHART,
ADMIN_INSERT_GROUP_CHART_FIRE_BASE,
RETRIEVE_GROUP_CHART_DETAILS} from "./actionTypes";

import { getMessaging, getToken, onMessage} from "firebase/messaging";

import { initializeApp } from "firebase/app";

import axios from "axios";
import { getFirebaseToken } from "../../firebase";
import apiConfig from '../../apiConfig/api';

const firebaseConfig = {
    apiKey: "AIzaSyAELDRetplfLWTx0VPUIGQWm87tQaNHdlY",
    authDomain: "e-learning-67962.firebaseapp.com",
    projectId: "e-learning-67962",
    storageBucket: "e-learning-67962.appspot.com",
    messagingSenderId: "742660303601",
    appId: "1:742660303601:web:d48476fedb12f2739b5750",
    measurementId: "G-52QYLN2K92"
  };

initializeApp(firebaseConfig);


// Initialize Firebase Messaging
const messaging = getMessaging();

// Request permission and get token
export const requestForToken = async () => {
let currentToken = '';
try {
currentToken = await getToken(messaging, { vapidKey: 'BGHeP5PiqA2a_eWM_ACZnTluTr0xUFW-G2-tOuXZ7KXliqZyzMcS0sjTt0t9oxoeJnp6kjATdDv7ojb0Uf8Qgpw' });
if (currentToken) {
  console.log('FCM Token:', currentToken);
  localStorage.setItem('fcmToken',currentToken)
} else {
  console.log('No registration token available. Request permission to generate one.');
}
} catch (error) {
console.error('An error occurred while retrieving token. ', error);
}
return currentToken;
};

export const  checkAdminLogin = (data)=>{
    return {
        type: ADMIN_LOGIN,
        payload:data
    }
}

export const getAdminMenus = (data) =>{
    console.log(data,'datadatadata')
    return {
        type:ADMIN_MENUS,
        payload: data.result?.auth_objects
    }
}

export const getAdminLoginRole = (data)=>{
    return{
        type:SET_ADMIN_LOGIN_ROLE,
        payload: data
    }
}

export const getCurrentAdminGroups=(data)=>{
    return {
        type:SET_CURRENT_ADMIN_GROUPS,
        payload:data
    }
}

export const getCurrentAdminGroupInformation = (data)=>{
    return{
        type:GET_CURRENT_ADMIN_GROUPINFORMATION,
        payload:data
    }
}

export const insertFirebaseTokenForAdmin = (payload)=>{ 
    return(dispatch)=>{
    axios.post(apiConfig.baseUrl+"e_lms/v1/fcm",payload
    ).then((res)=>{
        console.log(res?.data,"exit")
            
    }).catch(err=>{
        console.log(err)
    })
}
}


export const createRecordByFirebaseTokenStudent = (payload)=>{
    return(dispatch)=>{
      axios.post(apiConfig.baseUrl1+"comm_hub/firebase_registration",payload
      ).then((res)=>{
          console.log(res?.data,"exit")
              
      }).catch(err=>{
          console.log(err)
      })
}
  }

  
export const loginAsAdmin=(payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/login",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {

                getFirebaseToken()
                .then((firebaseToken) => {
                  if (firebaseToken) {
                    // alert('Firebase token: ' + firebaseToken);
                      console.log(firebaseToken,'firebaseToken')  // Using alert to display the token
                    window.localStorage.setItem("fcmToken",firebaseToken)
                  } else {
                    alert('No Firebase token received.');  // Inform user if no token received
                  }
                })
                .catch((err) => {
                  console.error('An error occurred while retrieving the Firebase token. ', err);
                  alert('An error occurred while retrieving the Firebase token.');  // Alerting the user about the error
                });
                  //  dispatch(studentMenus())
                   dispatch(createRecordByFirebaseTokenStudent())
                   dispatch(checkAdminLogin(res));

               

            }
            
           if (res.data?.res_status == false) {
            dispatch(checkAdminLogin(res.data))
           }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveAdminMenus = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"security_auth/authorization/list_menus",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {

                console.log(res.data,'res.data')
                dispatch(getAdminMenus(res.data));
            }
           
        }).catch(err=>{
            console.log(err)
        })
}
}


export const retrieveCurrentAdminGroups = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/batch",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getCurrentAdminGroups(res.data));
            }
           
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrirveAdminGroupInformation = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/fcmtoken",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getCurrentAdminGroupInformation(res.data));
            }
           
        }).catch(err=>{
            console.log(err)
        })
}
}

export const insertGroupChart = (data)=>{
    return{
      type:ADMIN_INSERT_GROUP_CHART,
      payload:data
    }
  }
  
  export const insertFireBaseGroupChart = (data)=>{
  return{
    type:ADMIN_INSERT_GROUP_CHART_FIRE_BASE,
    payload:data
  }
  }
  
  export const insertChartDetails = (payload)=>{
  
    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/groupchat",payload
      ).then((res)=>{
  
          if (res?.data.res_status == true) {
  
              dispatch(insertGroupChart(res))
          }
  
          if (res?.data.res_status == false) {
              dispatch(insertGroupChart())
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }
  
  export const insertFireBaseChartDetails = (payload)=>{
  
    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"ecomm/v2/firebase_chat",payload
      ).then((res)=>{
  
          if (res.data.res_status == true) {
  
              dispatch(insertFireBaseGroupChart(res))
          }
  
          if (res?.data.res_status == false) {
              dispatch(insertFireBaseGroupChart())
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }
  
  export const retrieveGroupChartDetails = (data)=>{
    return{
      type:RETRIEVE_GROUP_CHART_DETAILS,
      payload:data
    }
  }
  
  export const retrieveGroupCharList = (payload)=>{
  
    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/groupchat",payload
      ).then((res)=>{
  
  
  
          if (res?.data?.res_status === true) {
  
  
            // console.log(res?.data.res_status,'AVNI B')
  
            // console.log(res?.data)
              dispatch(retrieveGroupChartDetails(res.data))
          }
  
          if (res?.data?.res_status === false) {
              dispatch(retrieveGroupChartDetails([]))
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }


  //Test
  