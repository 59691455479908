import { GET_INSTRUCTOR_DETAILS,INSTRUCTOR_PROFILE_UPDATE,INSTRUCTOR_PASSWORD_RESET} from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const instructorProfileReducer = (state = initialStates,action)=>{
    switch (action.type) {
       
            case GET_INSTRUCTOR_DETAILS:
                return{
                    instructorDetails: action.payload
                }
            
            case INSTRUCTOR_PROFILE_UPDATE:
                return{
                    profileStatus: action.payload
                }

            case INSTRUCTOR_PASSWORD_RESET:
                return{
                    passwordStatus: action.payload
                }

        default:
            return state;
    }
}

export  default instructorProfileReducer;