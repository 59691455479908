import { GET_TENANT_ADMINS,CREATE_UPDATE_TENANT_STATUS } from "./actionTypes";


 const tenantAdminReducer = (state = [],action)=>{
    switch (action.type) {
        case  GET_TENANT_ADMINS:
            return {
                tenantAdmins: action.payload,
            }
        
        case CREATE_UPDATE_TENANT_STATUS:
            return {
                tenantCreateUpdateDeleteStatus: action.payload
            }
        default:
            return state;
    }
}

export  default tenantAdminReducer;