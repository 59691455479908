import { GET_ENROLLED_REPORTS } from "./actionTypes";

import axios from "axios";
import apiConfig from '../../../apiConfig/api';

export const getEnrolledReports = (data)=>{
    return{
        type: GET_ENROLLED_REPORTS,
        payload: data
    }
}


export const retrieveAdminEnrolledReports = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/reports",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getEnrolledReports(res?.data));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}