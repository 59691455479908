import React from 'react';

import './addCoursePrice.css';
import { Button, DatePicker, Form, Select } from 'antd';
import { InputComponent } from '../../../commonComponents';
import moment from 'moment';
const AddCoursePrice = (props) => {
    // function disabledDate(current) {
    //     return current && (current < moment().startOf('day') || current > moment().endOf('day'));
    // }

    const disabledDate = (current) => {
        // Can not select days before today
        return current && current < moment().startOf('day');
      };
      
    return (
        <div className='addCoursePriceContainer'>
            <Form
             autoComplete="off"
             layout="vertical"
             form={props.form}
             onFinish={props.onFinish}>
            <div>
                <Form.Item
                label={"Course Name"}
                name={"courseId"}
                rules={[
                    { required: true, message: 'Please select course' }
                    ]}>
                        <div>
                        <Select name={"courseId"} value={props.courseId} 
                        defaultValue={props.courseId  ? props.courseId :"Select"}
                        onChange={(e)=>props.handlePriceAndCourse(e,{name:"courseId"})}
                         >
                          {props.allCourses && props.allCourses.map((item,index)=>{
                          return  <Select.Option value={item.course_id} key={item.course_id}>{item.course_name}</Select.Option>
                          })}
                        </Select>
                        </div>
                   
                </Form.Item>
            </div>

            <div>
            <Form.Item
                label={"Price"}
                name={"price"}
                rules={[
                    { required: true, message: 'Please enter price' }
                    ]}>
                    <div>
                        <InputComponent placeholder={"Price"}
                        value={props?.price}
                        onChange={(e)=>props.handlePriceAndCourse(e,{name:"price"})}
                        />
                    </div>
                </Form.Item> 
            </div>

            <div>
            <Form.Item
                label={"Start Date"}
                name={"startDate"}
                rules={[
                    { required: true, message: 'Please select start date' }
                    ]}>
                    <div>
                    <DatePicker name='startDate'
                                        disabledDate={disabledDate}

                      onChange={(e,dateString)=>props.handleDates(dateString,{name:"startDate"})}

                        defaultValue={(props.startDate)}

                        placeholder="Select Date"

                        />
                    </div>
                </Form.Item> 
            </div>
            {props.modalType == "Edit Course Fee" ? 
            <div>
            <Form.Item
                label={"End Date"}
                name={"endDate"}
                rules={[
                     { required: true, message: 'Please select end date' }
                    ]}>
                    <div>
                    <DatePicker name='endDate'
                    disabledDate={disabledDate}
                      onChange={(e,dateString)=>props.handleDates(dateString,{name:"endDate"})}

                        //  onChange={(date,dateString)=>setStartDate(dateString)}
                        defaultValue={(props.endDate)}

                        placeholder="Select Date"

                        />
                    </div>
                </Form.Item> 
            </div>
            :""}

                <Form.Item>
            <div className='coursePriceSubmit'>
                
                    <div>
                    <Button type={"secondary"}  className="cancel" onClick={props?.closeModal}>Cancel</Button>

                    </div>
                  <div className='alignRight'>
                  <Button type={"primary"} htmlType='submit'  primary value={'Submit'}   className={'saveBatch'} >Submit</Button>

                </div>  

            </div>
            </Form.Item>

            </Form>
        </div>
    );
}

export default AddCoursePrice;
