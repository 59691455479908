import {INSTRUCTOR_PROFILE_UPDATE,INSTRUCTOR_PASSWORD_RESET,GET_INSTRUCTOR_DETAILS} from './actionTypes';

import axios from 'axios';
import apiConfig from '../../apiConfig/api';


export const instructorProfileStatus = (data)=>{
    return {
        type: INSTRUCTOR_PROFILE_UPDATE,
        payload: data
    }
}


export const instructorPasswordResetStatus = (data)=>{
    return {
        type: INSTRUCTOR_PASSWORD_RESET,
        payload: data
    }
}

export const getInstructorDetails = (data)=>{
    return {
        type: GET_INSTRUCTOR_DETAILS,
        payload: data
    }
}


export const uploadInstructorProfile = (payload)=>{

    console.log(payload,"notwork")
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/intsructor_profile",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(instructorProfileStatus(res.data?.res_status));    
            }
            if(res.data?.res_status == false){
                dispatch(instructorProfileStatus(res.data?.res_status))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const resetInstructorPassword = (payload)=>{

    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/intsructor_profile",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(instructorPasswordResetStatus(res.data?.res_status));    
            }
            if (res.data?.res_status == false) {
                dispatch(instructorPasswordResetStatus(res.data?.res_status))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveInstructorDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/intsructor_profile",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getInstructorDetails(res.data));    
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const retrieveAdminProfileDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/adminprofile",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getInstructorDetails(res.data));    
            }
        }).catch(err=>{
            console.log(err)
        })
}
}



export const uploadadminProfile = (payload)=>{

    console.log(payload,"notwork")
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/adminprofile",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(instructorProfileStatus(res.data?.res_status));    
            }
            if(res.data?.res_status == false){
                dispatch(instructorProfileStatus(res.data?.res_status))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}




export const resetAdminPassword = (payload)=>{

    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/adminprofile",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(instructorPasswordResetStatus(res.data?.res_status));    
            }
            if (res.data?.res_status == false) {
                dispatch(instructorPasswordResetStatus(res.data?.res_status))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}