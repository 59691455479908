import React from 'react';
import { avatarPic } from '../../images';


const SenderRowView = ({ senderMessage, time }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end',alignItems:'start' }}>
        <div style={{ flex: 15 }}></div>
        <div style={{ flex: 72 }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {/* <div style={{ margin: '8px 5px 2px 8px', padding: '9px', backgroundColor: 'blue', borderRadius: '25px 25px 25px 0' }}>
              <span style={{ fontFamily: 'Roboto', fontSize: '14px', color: 'white' }}>{senderMessage}</span>
              <span style={{ fontFamily: 'Roboto', fontSize: '10px', color: 'white' }}> {time}</span>
            </div> */}

<div style={{ margin: '8px 8px 0px 0px', padding: '9px', backgroundColor: '#DCF8C6', borderRadius: '15px 0px 15px 15px' ,padding: '10px'}}>
        
       
        <p style={{ margin: '0', fontFamily: 'Roboto', fontSize: '14px', color: 'black' }}>{senderMessage !== '' ? senderMessage : 'Update'}</p>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        
         <div style={{ margin: '2px 2px 2px 2px'}}>
        </div>
        <p style={{ margin: '0', fontFamily: 'Roboto', fontSize: '10px', color: 'black' }}>{senderMessage !== '' ? time : ''}</p>
     
        </div>
        </div>
          </div>
        </div>
        <div style={{ flex: 2 }}>
          {/* <img src={avatarPic} alt="Profile" width="35" height="35" /> */}
        </div>
      </div>
    );
  };

  export default SenderRowView;
