import React ,{useEffect} from 'react';
import { InputComponent, TextAreaComponent } from '../../commonComponents';

import { Button, Form, TimePicker } from 'antd';
import ReactDatePicker from 'react-datepicker';
const ForgotPassWordPopUp = (props) => {


    
   

  
    return (
      
        <div className='instructorCancelClassContainer'>
         
            <div className='cancelClassContainer'>
        <Form
         initialValues={props.forgotPasswordDetails}

        form={props.form1}
        onFinish={props?.onFinish2}
        autoComplete="off"
        layout="vertical">


<div>

<Form.Item
                  label="Email"
                  name={"email"}
                  rules={[
                    { required: true, message: 'Please enter email' }
                    ]}>
                    <div >
                      <InputComponent placeholder={"Email"}
                       name={"email"} value={props.email}
                       onChange={(e)=>props.handleClassChange2(e,{name:"email"})}/></div>
                </Form.Item>
</div>


<Form.Item>
                <div className='addTenantFooterBtns'>
                <div>
                <Button type={"secondary"}  className="cancel" onClick={props?.handleCloseModal2}></Button>
                </div>
                <div className='alignRight'>
                <Button type={"primary"}  className="submit" htmlType="Submit">Submit</Button>
                </div>
                </div>
                </Form.Item>
         


        </Form>
            
            </div>
            
        </div>
    );
}

export default ForgotPassWordPopUp;
