import {GET_ACTIVE_USER_REPORTS,GET_STUDENT_ATTENDANCE} from'./actionTypes';

import axios from 'axios';
import apiConfig from '../../../apiConfig/api';

export const getActiveUserReports = (data)=>{
    return {
        type: GET_ACTIVE_USER_REPORTS,
        payload: data
    }
}

export const retireveActiveUserReports = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/reports",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getActiveUserReports(res?.data));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveStudentAttendanceDeails = (data)=>{
    return {
        type: GET_STUDENT_ATTENDANCE,
        payload: data
    }
}

export const retrieveStudentAttendance = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/class_checkin",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getActiveUserReports(res?.data));
            }

            if (res.data?.res_status == false) {
                dispatch(getActiveUserReports([]));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}