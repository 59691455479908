import {GET_ADMIN_ALL_REPORTS} from "./actionTypes";

 const AllReportsReducer = (state =[],action)=>{

    switch (action.type) {
        case GET_ADMIN_ALL_REPORTS:

            return{
                adminAllUserReports: action.payload
            }

         default:
            return state;
    }
}

export  default AllReportsReducer;