
let  basicDetailsValidation=(values)=> {
    let isValid = true;
    let formErrors = {}
    if (values.courseName == "") {
        formErrors.courseNameError = "Course Name is required";
        isValid = false
    }else{
        formErrors.courseNameError = ""
    }

    if (values.courseCategory == "") {
        formErrors.courseCategoryError = "Category is required";
        isValid =false
    }else{
        formErrors.courseCategoryError = ""
    }

    if (values.courseTopic == "") {
        formErrors.courseTopicError = "Course topic is required";
        isValid = false
    }else{
        formErrors.courseTopicError = ""
    }

    if (values.courseLanguage == "") {
        formErrors.courseLanguageError = "Course language is required"
        isValid = false
    }else{
        formErrors.courseLanguageError = ""
    }

    if (values.courseLevel == "") {
        formErrors.courseLevelError = "Course level is required"
        isValid = false
    }else{
        formErrors.courseLevelError = ""
    }

    // if (values.courseDuration == "") {
    //     formErrors.courseDurationError = "Course duration is required"
    //     isValid = false
    // }else{
    //     formErrors.courseDurationError = ""
    // }

    return {
        isValid,
        formErrors
    }
}

export default basicDetailsValidation;