import React from "react";

import './studentDetail.css'
import moment from "moment";

let StudentDetail = (props) =>{
              
    console.log(props.studentRecord)
    return (
        <div className="retriveStudent">
                <div>
                    <p>Student ID  : {props?.studentRecord.student_id}</p>

                    <p>First Name   : {props?.studentRecord.first_name}</p>
                    <p>Last Name    : {props?.studentRecord.last_name}</p>
                    <p>Email        : {props?.studentRecord.email}</p>
                    <p>Phone        : {props?. studentRecord.phone_no}</p>
                    <p>Guardian Name: {props?.studentRecord.guardian_name}</p>
                    <p>Age          : {props?. studentRecord.calculated_age}</p>
                    <p>Gender       : {props?.studentRecord.gender}</p>
                    <p>Course Name  : {props?.studentRecord.course_name}</p>
                    {/* <p>DOB          : {props?.studentRecord.birth_date}</p> */}
                    <p>DOB          : { (moment(props?.studentRecord.birth_date, "DD-MM-YYYY")).format("DD-MM-YYYY")}</p>

                    <p>Address      : {props?.studentRecord.address.toUpperCase()}</p>
                    <p>Status          : {props?.studentRecord.is_active == 'true'
                                         ? 'Active' : props?.studentRecord.is_active == 'false' ? 'In Active'  :  'Pending'}</p>

                </div>
        </div>
    )
}

export default StudentDetail