import React, { useEffect, useState } from 'react'

import { jwtDecode } from 'jwt-decode';
import { useSelector,useDispatch } from 'react-redux';
import { chessSample,Group,tutor,carouselLeft,carouselRight,searchIcon } from '../../images';
import './studentRegisteredCourses.css'
import { studentRecordDetails } from '../../redux/studentLogin/action';
import { retrieveRegCourses } from '../../redux/studentRegisteredCourses/action';
import { Card,Spin ,Button,notification} from 'antd';

import './studentRegisteredCourses.css';
import { InputComponent, TableComponent } from '../../commonComponents';
import { retriveLiveOngoingCourses } from '../../redux/studentDashboard/liveCourses/action';
import UpcomingCourses from './upcomingCourseDetails/upcomingCourses';
import { retrieveUpcomingCourses } from '../../redux/studentDashboard/upcomingCourses/action';
import RelatedCourses from './relatedCourses/relatedCourses';
import { retrieveRelatedCourses } from '../../redux/studentDashboard/relatedCourses/action';

import {addClassStudnetAttendance} from '../../redux/studentDashboard/relatedCourses/action.js';

let StudentRegisteredCourses=()=> {

  let dispatch = useDispatch();
  let regCourses = useSelector((state)=>state.getStudentRegCourses?.studentRegCourses?.data);

  let liveOnGoing = useSelector((state)=>state.liveStudentCoursesReducer?.liveCourses);

  let upcomingCourses = useSelector((state)=>state.upcomingCoursesReducer?.upcomingCourses)

  let [liveCoursesData,setLiveCoursesData] = useState([]);

  let [liveCoursesDataStore,setLiveCoursesDataStore] = useState([]);


  let [ongoingCourses,setOngoingCourses] = useState([]);

  let [upcomingCoursesList,setUpcomingCoursesList] = useState([]);
  

  let getRelatedCourses = useSelector((state)=>state.relatedCoursesReducer?.relatedCourses);

  let [relatedCourses,setRelatedCourses] = useState();


  useEffect(()=>{
      let payload = 
      {
          "operation_type":"view_student_registered_courses",
          "student_id": window.localStorage.getItem("student_id")
      }
     dispatch(retrieveRegCourses(payload));
     console.log(window.sessionStorage.getItem("role_Type"),"rolessssss")
  },[])

  useEffect(()=>{
    let livePayload ={
      "operation_type":"current_live_class_of_student",
      "student_id": window.localStorage.getItem("student_id")
  }
  dispatch(retriveLiveOngoingCourses(livePayload))
  },[])

useEffect(()=>{
  let upcomingPayload=
  {
      "operation_type":"view_upcoming_classes",
      "student_id": window.localStorage.getItem("student_id")
  }
  dispatch(retrieveUpcomingCourses(upcomingPayload))
},[])

  useEffect(()=>{
      if (regCourses) {
          setLiveCoursesData(regCourses)
          setLiveCoursesDataStore(regCourses)
      }
      console.log(regCourses,"registeredCourses")
  },[regCourses])


  useEffect(()=>{
      if (liveOnGoing) {
        setOngoingCourses(liveOnGoing?.data)
        console.log(liveOnGoing,"LIVEEEEEEEEEE")
      }
  },[liveOnGoing])


  useEffect(()=>{
      if (upcomingCourses) {
        setUpcomingCoursesList(upcomingCourses)
      }
  },[upcomingCourses])


  useEffect(()=>{
    let payload =
    {
        "operation_type":"related_courses",
        "student_id":window.localStorage.getItem("student_id"),
        "tenant_id":window.localStorage.getItem("tenant_Id")
    }
    dispatch(retrieveRelatedCourses(payload))    
  },[])

  useEffect(()=>{
      if (getRelatedCourses) {
        setRelatedCourses(getRelatedCourses)
      }
      console.log(relatedCourses,"RRR")
  },[getRelatedCourses])
      
        const [currentSlide, setCurrentSlide] = useState(0);
        const slidesPerPage = 4;
      
        const nextSlide = () => {
          setCurrentSlide((prevSlide) => prevSlide + slidesPerPage);
        };

        useEffect(()=>{
          if(currentSlide-1 >= liveCoursesData.length){
              setCurrentSlide(0)
          }
    },[currentSlide])

    useEffect(()=>{
      if ( currentSlide == liveCoursesData.length) {
          setCurrentSlide(0)
      }
    },[currentSlide])
      
        const prevSlide = () => {
          setCurrentSlide((prevSlide) => Math.max(0, prevSlide - slidesPerPage));
        };

        let[searchEntry,setSearchEntry] = useState("");

        const searchStudentByEmail = (e) =>{
          let data = e.target.value;

          console.log(data,'search')
      setSearchEntry(data)

      const filteredItems = liveCoursesData.filter((item) => {
        return (
          item.course_name.toLowerCase().includes(searchEntry.toLowerCase()) ||
          item.course_description.toLowerCase().includes(searchEntry.toLowerCase()) ||
          item.batch_name.toLowerCase().includes(searchEntry.toLowerCase()) ||
          item.instructor_name.toLowerCase().includes(searchEntry.toLowerCase()) 
        ); });

        if(data){
          setLiveCoursesData(filteredItems);

        }else{
          setLiveCoursesData(liveCoursesDataStore);

        }

     
        }



        const joinClass = (e,idd) => {

          setMeetingLink(idd.meeting_link)
          setIsActive(true)
          const today = new Date();
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns month from 0-11
          const day = String(today.getDate()).padStart(2, '0');
        
          const formattedDate = `${year}-${month}-${day}`;
      
          console.log(formattedDate,'formattedDate')
      
      
          const today1 = new Date();
          let hours = today1.getHours();
          const minutes = String(today1.getMinutes()).padStart(2, '0');
          const ampm = hours >= 12 ? 'PM' : 'AM';
        
          hours = hours % 12;
          hours = hours ? hours : 12; // The hour '0' should be '12'
          const formattedTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
      
          console.log(formattedTime,'formattedTime')
      
      
          let payload =
          {
             
      
              "operation_type": "check_in",
              "op_field_details": {
                  "role_type": "student",
                  "student_id": window.localStorage.getItem("student_id"),
                  "batch_id": idd.batch_id,
                  "tenant_id": window.localStorage.getItem("tenant_Id"),
                  "date":formattedDate,
                  "check_in_time":formattedTime
              }
          }
          dispatch(addClassStudnetAttendance(payload)) 
        };



        const [api, contextHolder] = notification.useNotification();

        let addStudentAttendance = useSelector((state)=>state.relatedCoursesReducer?.addAttendanceStudent);
        let [meetingLink,setMeetingLink] = useState();
        let [isActive,setIsActive] = useState(false);
    
        useEffect(()=>{
          if(addStudentAttendance){
              //res_status
              console.log(addStudentAttendance?.msg,'addStudentAttendance')
    
              openNotification(addStudentAttendance?.msg,addStudentAttendance?.res_status)
          }
        },[addStudentAttendance])
    
        const openNotification=(msg,status)=>{
          if (status == true) {   
    
              console.log(meetingLink,'meetingLink')
              
    
              if(isActive == true){
                  window.location.href = meetingLink;
    
              }else{
    
              }
    
              setIsActive(false)
            }
            if (status == false) {
    
              if(isActive == true){
                  api.info({
                      description:
                        `${msg}`,
                    });
              }else{
    
              }
             
    
              setIsActive(false)
    
            }
      }

  return (
    <>
    <div>
            <div className='carouselText'>

            {contextHolder}
                <div>
                <h3>REGISTERED COURSES</h3>
                </div>
                <div className='alignRight'>
                  {/* <InputComponent placeholder={"Search"}/> */}

                  <div className="searchCourse">
    <div className="searchC"><InputComponent
     className='searchField' placeholder={'Search'}
     onChange={(e)=>searchStudentByEmail(e)}/></div>
    <img src={searchIcon} className="searchIconStudents"/>
</div>
                </div>
               
            </div>
            <div className='paginateCarousel alignRight'>
            <div className='carouselNavigation alignRight'>
                <div onClick={prevSlide} className='carouselLeft'><img src={carouselLeft}/></div>
                <div onClick={nextSlide} className='courselRight'><img src={carouselRight}/></div>
                </div>
            </div>
            {liveCoursesData ? 
            <div className='coursesCarouselReg'>
            {liveCoursesData.slice(currentSlide, currentSlide + slidesPerPage).map((key, index) =>{
                return(
                    <div className='carouselImages' style={{margin:'10px',}}>
                <Card
                hoverable
                style={{height:"100px",width:"220px"}}
                cover={<img alt="example" src={key.course_thumbnail} className='cardImageLive' />}
                className='dashboardCardReg'
            >
                <div className='cardBody1'>
                    <div className='bodyContent1'>
                        <div>
                            <b>{key.course_name}</b>
                        </div>
                        <div className='courseTimings'>
                            Timings: {key.new_week_days} : {key.course_time} 
                        </div>
                        <div className='courseTimings'>
                        <span><img src={tutor}/>{key.instructor_name}</span>
                        </div>
                        {/* <div className='courseDetailBtn'>
                            <div className='courseDetailText alignLeft'>
                                <img src={Group}/>
                                <p className='courseDetailText'>{key.count} students</p>
                            </div>
                            <div className='courseDetailText alignRight'>
                            <img src={Group}/>
                                <p className='courseDetailText'>90 days</p>
                            </div>
                        </div> */}
                    </div>
                    <div className='regCategory'>
                        <p>{key.course_category}</p>
                    </div>

                </div>
                </Card>
                </div>
                )
            })}
                

            </div>
            :<div className='alignSpinnerLiveCourses'>
            <Spin/>
            </div>}

            <div className='bgOnGoing' style={{marginTop:"220px"}}>
              <h2>Current Ongoing Class</h2>
              <div className='onGoing'>
                  {ongoingCourses && Object.values(ongoingCourses).map((item,index)=>{
                    return(
                      <div className='liveReport'> 
                      <div>
                        <img src={item.course_thumbnail} className='liveImage'/>
                      </div>
                      
                      <div className='liveCardData'>
                        <h3>{item.course_name}</h3>
                        <div>
                          {item.course_description}
                        </div>
                        <div>
                          <h3>Tutor: {item.instructor_name}</h3>
                        </div>
                        <div>
                          <h3>Scheduled Time: {item.new_week_days}:{item.course_time}</h3>
                        </div>

                        <div className="">
                    <Button type="primary" onClick={(e) => joinClass(e, { batch_id: item?.batch_id,meeting_link: item?.meeting_link })}>
        Join class
      </Button>
{/* <Button type="primary" primary>
                <a href={record?.meeting_link}>Join class</a>
                </Button>          */}
              </div>
                     
                      </div>
                      </div>

                    )
                  })}
              </div>
              
            </div>

            <div>
             <UpcomingCourses list={upcomingCoursesList}/>
            </div>
            <div>
              <RelatedCourses list={relatedCourses}/>
            </div>
    </div>
    </>
  )
}

export default StudentRegisteredCourses