import React, { useEffect, useState } from 'react'

import './allStudentCourses.css'
import { InputComponent } from '../../commonComponents'
import StudentCoursesList from './studentCoursesList/studentCoursesList'
import { useDispatch,useSelector } from 'react-redux'
import { getSingleCourse, retrieveStudentAllCourses } from '../../redux/studentViewAllCourses/action'
import SingleCourse from './singleStudentCourse/singleCourse';
import { useNavigate } from "react-router-dom";
import { chessSample,Group,tutor,carouselLeft,carouselRight,searchIcon } from '../../images';


let AllStudentCourses=()=> {
    let[coursesData,setCoursesData] = useState();

    let dispatch = useDispatch();
    let navigate = useNavigate();

    let [liveCoursesDataStore,setLiveCoursesDataStore] = useState([]);


    let result = useSelector((state)=>state.studentAllCoursesReducer?.viewAllCourses?.data);

    let [singleCourse,setSingleCourse] = useState({})
    useEffect(()=>{
        let payload = 
        {
            "operation_type":"view_all_courses",
             "tenant_id":window.localStorage.getItem("tenant_Id")
        }
        dispatch(retrieveStudentAllCourses(payload))
    },[])

    useEffect(()=>{
        console.log(result,"allCourses")
        if (result) {
            setCoursesData(result)

            setLiveCoursesDataStore(result)
        }
    },[result])

    let handleViewCourse = (record)=>{
        console.log(record,"id")
        if (record) {
        let filtered = result && result.filter((item,index)=> item.course_id == record);
        // dispatch(getSingleCourse(filtered))
        navigate(`/studentViewCourse/${record}`)
        }
    }

    useEffect(()=>{
        setSingleCourse(singleCourse)
    },[handleViewCourse()]);

    let navigateCourses=()=>{
        setSingleCourse("")
    }
    let[searchEntry,setSearchEntry] = useState("");


    const searchStudentByEmail = (e) =>{
        let data = e.target.value;

        console.log(data,'search')
    setSearchEntry(data)

    const filteredItems = coursesData.filter((item) => {
      return (
        item.course_name.toLowerCase().includes(searchEntry.toLowerCase()) ||
        item.course_description.toLowerCase().includes(searchEntry.toLowerCase()) ||
        item.what_you_will_teach_the_course.toLowerCase().includes(searchEntry.toLowerCase()) ||
        item.course_requirements.toLowerCase().includes(searchEntry.toLowerCase()) ||

        item.course_category.toLowerCase().includes(searchEntry.toLowerCase()) 
      ); });

      if(data){
        setCoursesData(filteredItems);

      }else{
        setCoursesData(liveCoursesDataStore);

      }

   
      }
   

  return (
    <>
    {/* {!singleCourse ?  */}
    <div className='studentCoursesContainer'>
        <div className='StudentcoursesSearch'>
            <div>
                <h3>ALL COURSES</h3>
            </div>
            <div className='alignRight'>
                {/* <InputComponent placeholder={"Search"}/> */}

                <div className="searchCourse">
    <div className="searchC"><InputComponent
     className='searchField' placeholder={'Search'}
     onChange={(e)=>searchStudentByEmail(e)}/></div>
    <img src={searchIcon} className="searchIconStudents"/>
</div>
            </div>
        </div>

        <div>
            
            <div>
            <StudentCoursesList  coursesData = {coursesData} handleViewCourse={handleViewCourse} singleCourse={singleCourse}/>
            </div>
        </div>
    </div>
      {/* :<SingleCourse list={singleCourse}/>}  */}
    </>
  )
}

export default AllStudentCourses