import React from 'react'

import './notification.css'
const  NotificationComponent=(props)=> {
  console.log(props?.data,"noti")
  return (
    <div className='notificationContainer'>
        {props?.data?.data&& props?.data?.data.map((item,index)=>{
          return(
            <>

{/* <p style={{ fontWeight: 'bold', color: 'black',fontSize:"15px" }} >{props?.notificationCountValue}</p> */}


<p style={{  fontWeight: 'bold', color: item?.is_seen !== false ? 'grey' : 'black',fontSize:"15px" }} >{item?.notification_type}</p>

            {/* <span className='notificationSenderName'>{item?.sender_name}</span> */}
            {/* <p className='notificationBody'>{item?.note}</p> */}
            <div className='notification'>
      <p className='notificationBody'>{item?.note}</p>
    </div>
            <span className='notificationSenderName'>{item?.created_on}</span>
            <hr></hr>
            </>
          )
        })}
    </div>
  )
}

export default NotificationComponent