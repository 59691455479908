const apiConfig = {
    baseUrl: 'https://lmsuat.arkatiss.com/',
      // baseUrl: 'https://botpi.arkatiss.com/',

    baseUrl1: 'https://demoapps.arkatiss.com/',


    
  };
  
  export default apiConfig;

  // baseUrl: 'https://botpi.arkatiss.com/',
