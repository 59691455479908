import { type } from "@testing-library/user-event/dist/type";
import { INSTRUCTOR_RESCHEDULE_CLASS,
    GET_STATUS_REJECTED,
    GET_ALL_RESCHEDULE_CLASSES,
    GET_ALL_CANCELLED_CLASSED, 
    GET_FCMDATA_FOR_RESCHEDULECLASS,GET_FCMDATA_FOR_RESCHEDULECLASS_REJECTED,
    CLASS_RESCHEDULE_NOTIFICATION,CLASS_CANCEL_NOTIFICATION} from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';


export const rescheduleClass = (data) =>{
    return{
        type: INSTRUCTOR_RESCHEDULE_CLASS,
        payload: data
    }
}

export const getAllRescheduleClasses = (data) =>{
    return{
        type:GET_ALL_RESCHEDULE_CLASSES,
        payload:data
    }
}

export const updateToReject = (data)=>{
        return{
            type:GET_STATUS_REJECTED,
            payload:data
        }
}
export const createRescheduleClass = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/reschedule",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(rescheduleClass(res.data));    
            }


            
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveRescheduleClasses = ()=>{
    let payload = {
        "operation_type": "retrieve",
        "tenant_id":window.localStorage.getItem("tenant_Id")
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/reschedule",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getAllRescheduleClasses(res.data));    
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}

export const changeStatusToReject = (payload) => {
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/reschedule",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(updateToReject(res.data));    
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}

export const getFcmDataForRescheduleClass = (data)=>{
    return{
        type:GET_FCMDATA_FOR_RESCHEDULECLASS,
        payload:data
    }
}

export const retrieveFcmDataForRescheduleClass=(payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/notification",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                console.log(res?.data)
                // dispatch(getFcmDataForRescheduleClassRejected(res?.data))
                dispatch(getFcmDataForRescheduleClass(res?.data))

                
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}


export const getFcmDataForRescheduleClassRejected = (data)=>{
    return{
        type:GET_FCMDATA_FOR_RESCHEDULECLASS_REJECTED,
        payload:data
    }
}


export const retrieveFcmDataForRescheduleClassRejected=(payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/notification",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                console.log(res?.data)
                dispatch(getFcmDataForRescheduleClassRejected(res?.data))
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}

export const classCancelRescheduleNotification = (data) =>{
    return{
        type: CLASS_RESCHEDULE_NOTIFICATION,
        payload: data
    }
}

export const createRescheduleClassNotification = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/notification",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(classCancelRescheduleNotification(res.data));    
            }

            
            
        }).catch(err=>{
            console.log(err)
        })
}
}


export const classCancelNotification = (data) =>{
    return{
        type: CLASS_CANCEL_NOTIFICATION,
        payload: data
    }
}

export const classCancelNotificationDetails = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/notification",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(classCancelNotification(res.data));    
            }

            
            
        }).catch(err=>{
            console.log(err)
        })
}
}