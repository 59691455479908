import {GET_ADMIN_STUDENTS_REPORTS} from "./actionTypes";

const StudentReportsReducer = (state = [],action)=>{

    switch (action.type) {
        case GET_ADMIN_STUDENTS_REPORTS:
            return {
                studentsReportsData: action.payload
            }

         default:
            return state;
    }
}

export  default StudentReportsReducer;