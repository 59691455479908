import React, { useEffect, useState } from 'react'
import { ButtonComponent, InputComponent, InputSelect, ModalComponent, TableComponent } from '../../commonComponents'

import './allAnnouncements.css';
import { Form, notification,Spin } from 'antd';

import { deleteIcon, searchIcon,excel } from '../../images';
import AddAnnouncement from './addAnnouncement/addAnnouncement';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses,getBatches } from '../../redux/allCourses/action';
import { type } from '@testing-library/user-event/dist/type';
import { announcementCrudStatuss, announcementFirebaseInsert, announcementInserPushDetails, createAdminAnnouncement, createPushAnnouncement, deleteAnnouncementRecord, retrieveAdminAnouncements,
   retrievemenetFcmDataForAnnouncements,retrievemenetFcmDataForAnnouncementss } from '../../redux/adminAnnouncements/action';
import moment from 'moment';
import {insertFireBaseChartDetails} from '../../redux/studentLogin/action';
import * as XLSX from 'xlsx';
import ExportToExcel from '../../commonComponents/XlsxFile/ExportToExcel'
let  AllAnnouncements=()=> {

    let dispatch = useDispatch()
    const [form] = Form.useForm();

    let [addAnnouncementModal,setAddAnnouncementModal] = useState(false);

    let [courses,setCourses] = useState([]);

    let [allBatches,setBatches] = useState([]);


    let [announcements,setAnnouncements] = useState();

    let [modalType,setModalType] = useState(false);

    let [announcementId,setAnnouncementId] = useState("");

    let [searchEntry,setSearchEntry] = useState("");

    const [buttonclicked,setButtonClicked] = useState(true)

    let getAllCourses = useSelector((state)=>state.courses.courses);

    let getAllBatches = useSelector((state)=>state.courses.allBatches);

    let [listVisible,setListVisible] = useState(false);


    let [announcementDetails,setAnnouncementDetails] = useState({
      role_type:"",
      course_name: "",
      title:"",
      description:"",
      uploadFiles:"",
      batches_name:""
  })

    let adminAnnouncements = useSelector((state)=>state.adminAnnouncementReducer?.adminAnnouncements?.data);

    let announcementStatus = useSelector((state)=>state.adminAnnouncementReducer?.announcementCreateStatus);

    let announcementCrudStatus = useSelector((state)=>state.adminAnnouncementReducer?.announcementCrudStatus);

    let getFcmResult = useSelector((state)=>state.adminAnnouncementReducer?.fcmData);

    const [fcmResult,setFcmResult] = useState()

    const [fcmTokens,setFcmTokens] = useState([])

    const [userNames,setUserNames] = useState([])
    useEffect(()=>{
        if (getFcmResult) {
          console.log(getFcmResult,"fcmResult")
        //   setFcmResult(getFcmResult)
        //   let fcm= ["fpFQA-NzYUHEc3fgv-_tFw:APA91bEFUlcXXMzf007cARoDYgD0WPB7mLluyFgLwKnJOcuwuP7d1ce0ylqwso9a7eUQWWm4B5M7V49YKvOXnFx2ykLHRzKPUboExl3mkb5IaDuv--bwsuTu2aWBGv1WH2Sr92cJHtbZ"]

        //   let users= []

        //   {getFcmResult?.map((item,index)=>{
        //     fcm.push(item?.fcmtoken)
        //     setFcmTokens(fcm)
        //   })}

        //   {getFcmResult?.map((item,index)=>{
        //     users.push(item?.username)
        //     setUserNames(users)
        //   })}
        //   console.log(fcmTokens,userNames,"datahere")

        
        const fcmToken = getFcmResult?.data&&Object.values(getFcmResult?.data).map(item => item.fcmtoken);
    
        const usernames = getFcmResult?.data&&Object.values(getFcmResult?.data).map(item => item.username).join(',');
        
    

        let payloadFireBase = {
          "sender_name": window.localStorage.getItem("adminEmail"),
          "receiver_name":usernames,
          "peer_name": 'Announcements',//formValues.batch_name
          "peer_chat_type": "Announcements",
          "input": "New Announcements",
          "firebase_tokens": fcmToken, 
          "msg_type": "insert",
          "peer_type": "insert"
        };
        dispatch(insertFireBaseChartDetails(payloadFireBase));


        }
    },[getFcmResult])

    useEffect(()=>{
        if (announcementStatus == true) {
            setAddAnnouncementModal(false);
            
        }
    },[announcementStatus])
    
    useEffect(()=>{
        
        dispatch(retrieveAdminAnouncements())
    },[])


    useEffect(()=>{
      console.log(adminAnnouncements,'adminAnnouncements')
      console.log(adminAnnouncements?.res_status,'adminAnnouncements')


        if (adminAnnouncements) {

            setAnnouncements(adminAnnouncements);

            setListVisible(true)

            // if(adminAnnouncements.res_status == true){
            // }else{

            // }
        }else{
          setAnnouncements([]);

        }
    },[adminAnnouncements])


    const searchAnnouncement =(e)=>{
      setSearchEntry(e.target.value)
    }
    const columns = [

        {
          title: 'Date',
          dataIndex: 'created_date',
          sorter: true,
          render: (text, record) => (
            <div className="actionIcons">
                <span>{(moment(record.created_date, "DD-MM-YYYY HH:mm:ss"))
                ? (moment(record.created_date, "DD-MM-YYYY HH:mm:ss")).format("DD-MM-YYYY") : "" }</span>
            </div>
           ),

           sorter: true,
      filteredValue:[searchEntry],
      onFilter: (value,record)=>{
        return String(record.created_date).toLowerCase().includes(value.toLowerCase())||
               String(record.role_type).toLowerCase().includes(value.toLowerCase())||
               String(record.course_name).toLowerCase().includes(value.toLowerCase())||
               String(record.title).toLowerCase().includes(value.toLowerCase())||
               String(record.description).toLowerCase().includes(value.toLowerCase())
             },
             sorter: (a, b) => a.created_date.localeCompare(b.created_date),

        },
        
        {
            title: 'Created For',
            dataIndex: 'role_type',
            sorter: true,
            sorter: (a, b) => a.role_type.localeCompare(b.role_type),

          },
          {
            title: 'Course',
            dataIndex: 'course_name',
            sorter: true,
            sorter: (a, b) => a.course_name.localeCompare(b.course_name),

          },
          {
            title: 'Title',
            dataIndex: 'title',
            sorter: true,
            sorter: (a, b) => a.title.localeCompare(b.title),

          },

          {
            title: 'Description',
            dataIndex: 'description',
            sorter: true,
            sorter: (a, b) => a.description.localeCompare(b.description),

          },
          {
            title: "Actions",
            dataIndex: 'action',
            render: (text, record) => (
              <div className="actionIcons">
                <img src={deleteIcon} onClick={() => deleteAnnouncement(record.announcement_id)} />
              </div>
            ),
          }

    ]


    useEffect(()=>{
        dispatch(getCourses())

        

      },[])

    useEffect(()=>{
        if (getAllCourses) {
            console.log(getAllCourses,"pppppppppppppppppp");
            setCourses(getAllCourses?.data)
        }
    },[getAllCourses])

    useEffect(()=>{
      if (getAllBatches) {
          console.log(getAllBatches,"pppppppppppppppppp");
          setBatches(getAllBatches?.data)
      }
  },[getAllBatches])

  

    React.useEffect(() => {
      window.addEventListener('scroll', selectChange);
      return () => {
        window.removeEventListener('scroll', selectChange);
      };
    }, []);

    const deleteAnnouncement = (id)=>{
      console.log(id)
      let deletePayload =
      {
          "operation_type":"delete",
          "op_field_details":{
              "announcement_id":[id],
              "tenant_id":window.localStorage.getItem("tenant_Id")
          }
      }
      dispatch(deleteAnnouncementRecord(deletePayload))
    }

    let onFinish= (values)=>{

      if(values){
        let payload = {
          "operation_type":"insert",
          "op_field_details":{
              "role_type": announcementDetails.role_type,
              "course_id": announcementDetails.course_name,
              "title": announcementDetails.title,
              "description": announcementDetails.description,
              "upload_files": announcementDetails.uploadFiles,
              "tenant_id": window.localStorage.getItem("tenant_Id"),
              "batch_ids":announcementDetails.batches_name
          }
      }


      console.log(payload,"payload")
      dispatch(createAdminAnnouncement(payload))   


      let payload1 = {
        "notification_type":"announcement",
    "project_name":"lms",
    "role_type":announcementDetails.role_type,
    "tenant_id":window.localStorage.getItem("tenant_Id"),
    "batch_id": announcementDetails.batches_name
    }


    console.log(payload,"payload")
    dispatch(retrievemenetFcmDataForAnnouncementss(payload1))  

      setButtonClicked(false)
      }else{
        
      }
     


    //       let pushPayload = {
    //   "sender_name": window.localStorage.getItem("adminEmail"),
    //    "receiver_name": userNames.join(","),
    //   "peer_name": "announcement",
    //   "peer_chat_type": "announcement",
    //   "input": announcementDetails.description,
    //   "msg_type": "insert",
    //   "peer_type": "insert"
    // };
    // dispatch(announcementInserPushDetails(pushPayload));

    // let payloadFireBase = {
    //   "sender_name": window.localStorage.getItem("adminEmail"),
    //    "receiver_name": userNames.join(","),
    //   "peer_name": "announcement",
    //   "peer_chat_type": "announcement",
    //   "input": announcementDetails.description,
    //   "firebase_tokens": fcmTokens, // Use newFcmToken here
    //   "msg_type": "insert",
    //   "peer_type": "insert"
    // };
    // dispatch(announcementFirebaseInsert(payloadFireBase));
      // }
  }

    let selectChange = (data) =>{
      console.log(data,"check")
       setAnnouncementDetails({...announcementDetails,"course_name":data});
      console.log(announcementDetails,"id")
    }

    let handleChange = (e,type)=>{
        console.log(e)
        if (e) {
            setAnnouncementDetails({...announcementDetails,[type.name]:e});
            form.setFieldsValue({[type.name]: e})
            }

            if (type.name === "role_type") {
              form.setFieldsValue({[type.name]:e});
              setAnnouncementDetails({...announcementDetails,"role_type":e});

            }

            if (type.name == "course_name") {
            
              let batchesPayload =   {
                "operation_type":"view_batch",
                "course_id":e,
                "tenant_id":window.localStorage.getItem("tenant_Id")
            }
              dispatch(getBatches(batchesPayload))



              setAnnouncementDetails({...announcementDetails,"course_name":e});
               form.setFieldsValue({[type.name]: e})
               let getFcmTokensForAnnouncement = {
                  "notification_type":"announcement",
                  "project_name":"lms",
                  "role_type":"Student",
                  "tenant_id":window.localStorage.getItem("tenant_Id"),
                  "course_id":e
                }
                  // dispatch(retrievemenetFcmDataForAnnouncements(getFcmTokensForAnnouncement))
            }
       
        if (e.target !== undefined) {
            const { name, value } = e.target;
            setAnnouncementDetails({ ...announcementDetails, [name]: value });
            form.setFieldsValue({[type.name]: e})
            
          } else {
            setAnnouncementDetails({ ...announcementDetails, [type.name]: e })
            form.setFieldsValue({[type.name]: e})

          }
          console.log(announcementDetails,"vaues")
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          if (file) {
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
              resolve(fileReader.result);
            };
          }
         
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };
  
       let  onFileChange = async(event,type)=>{
        const file = event.target.files[0];
      const base64 = await convertToBase64(file);
      console.log(base64,"ppp")
      setAnnouncementDetails({ ...announcementDetails, [type.name]: base64});
        // decodeBase64(base64)
          console.log(announcementDetails,'additional Details')
       }

    let handleAnnouncementModal = (type,id) =>{
        setAddAnnouncementModal(true)
        setModalType(type)
        setAnnouncementId(id)
        setBatches([])


    }


    let closeModal = ()=>{
        setAddAnnouncementModal(false);
        setAnnouncementDetails({
            role_type:"",
            course_name:"",
            title:"",
            description:"",
            uploadFiles:"",
            batches_name:"",
            
        })
      form.setFieldsValue({["role_type"]:""})
      form.setFieldsValue({["course_name"]:""})
      form.setFieldsValue({["title"]:""})
      form.setFieldsValue({["description"]:""})
      form.setFieldsValue({["uploadFiles"]:""})
      form.setFieldsValue({["role_ty"]:""})
      form.setFieldsValue({["batches_name"]:""})

      dispatch(retrieveAdminAnouncements())


    }

    const [api, contextHolder] = notification.useNotification();

    useEffect(()=>{
        if (announcementCrudStatus) {
          openNotification(announcementCrudStatus?.msg,announcementCrudStatus?.res_status);


          if(announcementCrudStatus.res_status == true)
            {
              setAddAnnouncementModal(false);

              setAnnouncementDetails({
                role_type:"",
                course_name:"",
                title:"",
                description:"",
                uploadFiles:""
            })
          form.setFieldsValue({["role_type"]:""})
          form.setFieldsValue({["course_name"]:""})
          form.setFieldsValue({["title"]:""})
          form.setFieldsValue({["description"]:""})
          form.setFieldsValue({["uploadFiles"]:""})
          form.setFieldsValue({["role_ty"]:""})
            }

         
          dispatch(announcementCrudStatuss(undefined));
        }
    },[announcementCrudStatus])

    const openNotification = (msg,announcementCrudStatus) => {
      if (msg && announcementCrudStatus== false) {
          api.info({
              description:
              `${msg}`,
            });
      }

      if (msg && announcementCrudStatus == true) {
        api.info({
            description:
            `${msg}`,
          });
          setAddAnnouncementModal(false)
          setButtonClicked(true)
          setAnnouncementDetails({
            role_type:"",
            course_name: "",
            title:"",
            description:"",
            uploadFiles:""
        })
        form.setFieldsValue({["role_type"]:""})
        form.setFieldsValue({["course_name"]:""})
        form.setFieldsValue({["title"]:""})
        form.setFieldsValue({["description"]:""})
        form.setFieldsValue({["uploadFiles"]:""})


    }
    }


    const exportToExcel = (data, fileName) => {
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    };
    
    
    const transformedItems = adminAnnouncements?.map((item) => {
     
    
      return { 
         CREATED_DATE: item.created_date,
         ROLE_TYPE:  item.role_type,
        COURSE_NAME:  item.course_name,
        TITLE: item.title,
        DESCRIPTION:  item.description,
      
      
      };
    });
    
    let handleCategoryModal1 = (type,id) =>{
       
      exportToExcel(transformedItems, 'announcements');
    
      
    }
  return (
    <div>
        {contextHolder}
        <div className='announcementContainer'>
                <div className='searchAnnouncement'>
                  <div>
                  <h3>All Announcements</h3>

                  </div>
                    {/* <div>
                        <InputSelect defaultValue={"Select"}/>
                       
                    </div> */}

                    <div className='announcementBtn'>
                        <div className='announcement'>

                        <div className="searchCourse" >
                <div className="searchC"><InputComponent className='searchField' style={{marginBottom:"20px"}}
                 placeholder={'Search'} onChange={(e)=>searchAnnouncement(e)}/></div>
                <img src={searchIcon} className="searchIcon" style={{marginBottom:'45px',marginLeft:"10px"}}/>
            </div>




                            <div style={{marginTop:'10px'}}>
                            <ButtonComponent type={"primary"}  className={"addAnnouncement"}
                           primary value={"Add Announcement"}
                           onClick={() => handleAnnouncementModal("Add Announcement", null)}/>
                            </div>


                            <div style={{marginTop:"18px"}}>

<img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

</div>
                          
                            </div> 
                            <div>

                        </div>
                    </div>
                </div>
                <div>
                    <TableComponent columns={columns} data={adminAnnouncements}   loading={adminAnnouncements ? false: false}/>

                   

                </div>
        </div>

        {addAnnouncementModal?
      <ModalComponent title={modalType}
      content={<AddAnnouncement form={form} fieldValues = {announcementDetails}
      onFinish={onFinish} selectChange={selectChange}
      handleChange={handleChange} closeModal={closeModal} 
      allBatches={allBatches} courses={courses} onFileChange={onFileChange}/>}
      open={addAnnouncementModal} 
      handleCancel={closeModal}
     />
     : ""} 
    </div>

    
  )
}

export default AllAnnouncements