import React from 'react';
import { Input } from 'antd';

import './input.css';

const InputComponent = ({placeholder,value,onChange,name,type,maxLength,disabled,title}) => {
    return (
        <Input type={type == 'file' ? 'file' :'text'}  disabled={disabled} title={ title}
        maxLength={maxLength} placeholder={placeholder} value={value} onChange={onChange} name={name}/>
    )
}
    

export default InputComponent;