import { INSTRUCTOR_RESCHEDULE_CLASS,GET_ALL_RESCHEDULE_CLASSES, GET_STATUS_REJECTED, 
    GET_FCMDATA_FOR_RESCHEDULECLASS,GET_FCMDATA_FOR_RESCHEDULECLASS_REJECTED,
    CLASS_RESCHEDULE_NOTIFICATION,CLASS_CANCEL_NOTIFICATION} from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const instructorRescheduleClassReducer = (state = initialStates,action)=>{
    switch (action.type) {
       
            case INSTRUCTOR_RESCHEDULE_CLASS:
                return{
                    instructorSchedulesCreated: action.payload
                }
            
                case GET_ALL_RESCHEDULE_CLASSES:
                    return{
                        reScheduledClasses: action.payload
                    }
                
            case GET_STATUS_REJECTED:
            return{
                rejectedRescheduledClasses: action.payload
            }

            case GET_FCMDATA_FOR_RESCHEDULECLASS:
                return{
                    fcmDataForRescheduleClass: action.payload
                }

                case GET_FCMDATA_FOR_RESCHEDULECLASS_REJECTED:
                    return{
                        fcmDataForRescheduleClassRejected: action.payload
                    }

                    case CLASS_RESCHEDULE_NOTIFICATION:
                        return{
                            getClassRescheduleNotification: action.payload
                        }

                        case CLASS_CANCEL_NOTIFICATION:
                            return{
                                getClassCancelNotification: action.payload
                            }
        default:
            return state;
    }
}

export  default instructorRescheduleClassReducer;