import {  GET_ALL_INSTRUCTOR_STUDENTS } from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const instructorStudentReducer = (state = initialStates,action)=>{

    switch (action.type) {
        case  GET_ALL_INSTRUCTOR_STUDENTS:
            return {
                ...state,
                allStudents: action.payload,
            }

        default:
            return state;
    }
}

export  default instructorStudentReducer;