import React, { useEffect, useState } from 'react';
import { Route, Redirect, Outlet, Navigate, useNavigate, redirect } from 'react-router-dom';
import App from '../App';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ element, ...rest }) => {
    // let isAuthenticated = window.localStorage.getItem("token")

    console.log(element,"element")
    
    const isAuthenticated = () => {
      return !! window.localStorage.getItem('role_Type');
    };

  let navigate = useNavigate()
  const authenticated = isAuthenticated();

  return (
    authenticated ? <Outlet/> : <Navigate to="/tenantlogin" replace />
  )

}
  

export default PrivateRoute;