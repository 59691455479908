import { GET_ADMIN_SCHEDULES } from "./actionTypes";


 const AdminScheduleReducer = (state = [],action)=>{

    switch (action.type) {
        case  GET_ADMIN_SCHEDULES:
            return {
                adminSchedules: action.payload,
            }

            
        default:
            return state;
    }
}

export  default AdminScheduleReducer;