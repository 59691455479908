import React, { useEffect, useState } from 'react'

import { useDispatch,useSelector } from 'react-redux';
import { chessSample,carouselLeft,carouselRight,static1,static2,static3,Group, tutor } from '../../../images';

import { Card,Spin } from 'antd';

import './registeredClassesCarousel.css';
import { retrieveRegCourses } from '../../../redux/studentRegisteredCourses/action';
let RegisteredClassesCarousel=() =>{

    
    let dispatch = useDispatch();
    let regCourses = useSelector((state)=>state.getStudentRegCourses?.studentRegCourses?.data);

    let [liveCoursesData,setLiveCoursesData] = useState([])
    useEffect(()=>{
        let payload = 
        {
            "operation_type":"view_student_registered_courses",
            "student_id": window.localStorage.getItem("student_id"),
        }
       dispatch(retrieveRegCourses(payload))
    },[])

    useEffect(()=>{
        if (regCourses) {
            console.log(regCourses,"REGcOURSES");
            setLiveCoursesData(regCourses)
        }
    },[regCourses])
        
          let [currentSlide, setCurrentSlide] = useState(0);
          const [arrowDisable,setArrowDisable] = useState(false);
          let [count,setCount] = useState(4)
          let slidesPerPage = 4;
        
          const nextSlide = () => {
            setCurrentSlide((prevSlide) => prevSlide + slidesPerPage);
            console.log(currentSlide,"sli",liveCoursesData?.length)

            //setCount(count+slidesPerPage+currentSlide)

          };

          useEffect(()=>{
            if(currentSlide-1 >= liveCoursesData.length){
                setArrowDisable(true)
                setCurrentSlide(0)
            }else{
                setArrowDisable(false)
            }
      },[currentSlide])


      useEffect(()=>{
        if ( currentSlide == liveCoursesData.length) {
            setCurrentSlide(0)
        }
      },[currentSlide])
         
          const prevSlide = () => {
            setCurrentSlide((prevSlide) => Math.max(0, prevSlide - slidesPerPage));
          };

         
         
  return (
    <div>
            <div className='carouselText'>
                <div>
                <h3>REGISTERED COURSES</h3>
                </div>

                <div className='carouselNavigation alignRight'>
                <div onClick={prevSlide} className='carouselLeft'><img src={carouselLeft}/></div>
                <div onClick={nextSlide} className='courselRight'><img src={carouselRight}/></div>
                </div>
            </div>
            {liveCoursesData ? 
            <div className='coursesCarousel'>
            {liveCoursesData.slice(currentSlide, currentSlide + slidesPerPage).map((key, index) =>{
                return(
            <div className='carouselImages'>
                <Card
                hoverable
                // style={{height:"100px",width:"220px"}}
                cover={<img alt="example" src={key.course_thumbnail} className='cardImageLive' />}
                className='dashboardCard'
            >
                <div className='cardBody1'>
                    <div className='bodyContent1'>
                        <div>
                            {key.course_name}
                        </div>
                        <div className='courseTimings'>
                            Timings: {key.new_week_days} : {key.course_time} 
                        </div>
                        <div>
                        <div className='tutorRegCourses'>
                        <div><img src={tutor} className='tutorReg'/></div>
                        <div>{key.instructor_name}</div>
                        </div>
                        </div>
                        {/* <div className='courseDetailBtn'>
                            <div className='courseDetailText alignLeft'>
                                <img src={Group}/>
                                <p className='courseDetailText'>{key.count} students</p>
                            </div>
                            <div className='courseDetailText alignRight'>
                            <img src={Group}/>
                                <p className='courseDetailText'>90 days</p>
                            </div>
                        </div> */}
                    </div>
                </div>
                </Card>
                </div>
                )
            })}
                

            </div>
            :<div className='alignSpinnerLiveCourses'>
            <Spin/>
            </div>}
    </div>
  )
}

export default RegisteredClassesCarousel