import React from 'react'

import './instructorChatBlank.css'
import { groupChat } from '../../../images';

const InstructorChatBlank=()=> {
  return (
    <div className='blankUserChat'>
        <div>
        <h1>GroupChat</h1>

        </div>
        <div>
        <img src={groupChat} className='groupChatIcon'/>

        </div>
        </div>
  )
}

export default InstructorChatBlank