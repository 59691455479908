import React from "react";

import moment from "moment";

let RelatedCoursersDetails = (props) =>{
              
    console.log(props.studentRecord,'studentRecord')
    return (
        <div className="retriveStudent">
                <div>
                    <p>Course ID  : {props?.studentRecord.course_id}</p>

                    <p>Course Name   : {props?.studentRecord.course_name}</p>
                    <p>Course Category    : {props?.studentRecord.course_category}</p>
                    <p>Duration        : {props?.studentRecord.course_duration}</p>
                
                  
                   
                </div>
        </div>
    )
}

export default RelatedCoursersDetails