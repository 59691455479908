import { GET_STUDENT_REG_COURSES } from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';

const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    };

export const getStudentRegCourses = (data) =>{
    return {
        type:GET_STUDENT_REG_COURSES,
        payload: data
    }
}

export const retrieveRegCourses=(data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/student_page",data
        ).then((res)=>{
            dispatch(getStudentRegCourses(res.data));
        }).catch(err=>{
            console.log(err)
        })
}
}