import { redirect } from "react-router-dom";
import { GET_STUDENT_LOGIN_DATA,SET_STUDENT_LOGIN, 
    SET_STUDENT_RECORD,SET_TOKEN,GET_STUDENT_MENUS,SET_LOGIN_ROLE, GET_STUDENT_LOGIN_STATUS,
    SET_CURRENT_STUDENT_GROUPS,
    GET_CURRENT_STUDENT_GROUP_INFORMATION,
    INSERT_GROUP_CHART_FIRE_BASE,
    INSERT_GROUP_CHART,
    GET_STUDENT_MESSAGES,
    RETRIEVE_GROUP_CHART_DETAILS,
    LATEST_GROUP,RETRIEVE_NOTIFICATION_DETAILS,NOTIFICATION_COUNT,STUDENT_CONTACT_US,GET_TENANT_ID,
    RETRIEVE_REGISTRATION_COURSE_NAME,RETRIEVE_DATE_DETAILS,RETRIEVE_TIMING
  } from "./actionTypes";

import { jwtDecode } from "jwt-decode";

import { getMessaging, getToken,onMessage } from "firebase/messaging";

import { initializeApp} from "firebase/app";

import apiConfig from '../../apiConfig/api';

import 'firebase/messaging';
import axios from "axios";
import { useEffect } from "react";
import { getFirebaseToken, onForegroundMessage, onMessageListener } from "../../firebase";
import { type } from "@testing-library/user-event/dist/type";

// import firebaseConfig from '../firebaseConfig/firebase-messaging-sw';
let jwtConvert = jwtDecode 

let role_Type = window.localStorage.getItem("role_Type")



const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    };

    const firebaseConfig = {
      apiKey:    "AIzaSyAELDRetplfLWTx0VPUIGQWm87tQaNHdlY",
      authDomain: "e-learning-67962.firebaseapp.com",
      projectId: "e-learning-67962",
      storageBucket: "e-learning-67962.appspot.com",
      messagingSenderId: "742660303601",
      appId: "1:742660303601:web:d48476fedb12f2739b5750",
      measurementId: "G-52QYLN2K92"
      };
    
const app = initializeApp(firebaseConfig);

  
// Initialize Firebase Messaging
let messaging = getMessaging(app);

//   console.log(onMessage(messaging, (payload) => {
//     console.log('Foreground message received:', payload);
//   }),"lkk")

// Request permission and get token
export const requestForToken = async () => {
  let currentToken = '';
  try {
    currentToken = await getToken(messaging, {vapidKey: 'BGHeP5PiqA2a_eWM_ACZnTluTr0xUFW-G2-tOuXZ7KXliqZyzMcS0sjTt0t9oxoeJnp6kjATdDv7ojb0Uf8Qgpw'});
    if (currentToken) {
      console.log('FCM Token:', currentToken);
      localStorage.setItem('fcmToken',currentToken)
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (error) {
    console.error('An error occurred while retrieving token. ', error);
  }
  // onMessage(messaging, (payload) => {
  //   console.log("Message received. ", payload);
  //   // ...
  // });
  return currentToken;
};


//genarate callback for firebase
export const callbackurl = async () => {
  let currentStatus = "";
  try {
    // const ss = onForegroundMessage();
    //.then((payload) => {
    //   console.log('Received foreground message: ', payload);
    // })
    // .catch(err => console.log('An error occured while retrieving foreground message. ', err));

  } catch (error) {
      console.error('An error occurred while retrieving token. ', error);
  }
  return currentStatus;
};
//

export const getStudentLogin = (data) =>{
    return{
        type: GET_STUDENT_LOGIN_DATA,
        payload: data
    }
}

export const setToken = (data)=>{
    return{
        type: SET_TOKEN,
        payload: data?.data
    }
}

export const getStudentMenus = (data)=>{
    return{
        type: GET_STUDENT_MENUS,
        payload: data.result?.auth_objects
    }
}

export const logoutUser = (navigate) => () => {

    return(dispatch)=>{
        dispatch(setToken(undefined))
        // navigate("/")
        redirect("/")
        window.localStorage.clear()
    }
  };

  export const getLoginRole = (data)=>{
    console.log(data,"role")
       return{
            type: SET_LOGIN_ROLE,
            payload: data?.role_Type
       }
  }

  export const getCurrentStudentGroups = (data)=>{
      return{
        type:SET_CURRENT_STUDENT_GROUPS,
        payload:data
      }
  }

  export const getCurrentGroupInformation = (data)=>{
      return{
        type:GET_CURRENT_STUDENT_GROUP_INFORMATION,
        payload:data
      }
  }

  export const getStudentLoginStatus = (data) =>{
    console.log(data)
    return {
        type: GET_STUDENT_LOGIN_STATUS,
        payload:data
    }
  }

  export const studentRecordDetails = (data)=>{
        return{
            type: SET_STUDENT_RECORD,
            payload: data
        }
  }

  
  export const retrieveCurrentStudentGroups = (payload)=>{
    return(dispatch)=>{
      axios.post(apiConfig.baseUrl+"e_lms/v1/batch",payload
      ).then((res)=>{
        // dispatch(studentMenus())
          if (res.data?.res_status == true) {
            console.log(res?.data)
              dispatch(getCurrentStudentGroups(res?.data))
              // dispatch(studentMenus())

          }

          if (res?.data?.res_status == false) {
              dispatch(getCurrentStudentGroups([]))
              // dispatch(studentMenus())
          }
               
      }).catch(err=>{
          console.log(err)
      })
}
  }

  export const getCurrentStudentGroupInformation = (payload)=>{

    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/fcmtoken",payload
      ).then((res)=>{
          if (res.data?.res_status == true) {
            console.log(res?.data)
              dispatch(getCurrentGroupInformation(res?.data))
          }

          if (res?.data?.res_status == false) {
              dispatch(getCurrentGroupInformation([]))
          }
               
      }).catch(err=>{
          console.log(err)
      })
}
  }

  export const studentMenus = (payload)=>{
    let role = window.localStorage.getItem("role_Type");
    console.log(role,"abc")
    let studentPayload


    if(window.localStorage.getItem("role_Type") == 'admin'){

      studentPayload ={
        "idm_roles": [
            "lms_tenant_admin"
        ],
        "appl_name": "Lms_Dev"
    }
    }else{
      if (payload !==null) {
        studentPayload ={
         "idm_roles": [
           role
         ],
         "appl_name": "LMS"
     }
     }
    }

   
    
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl1+"security_auth/authorization/list_menus",studentPayload,{headers}
        ).then((res)=>{
            
            if (res.data?.res_status == true) {
                dispatch(getStudentMenus(res?.data))
            }
                 
        }).catch(err=>{
            console.log(err)
        })
}
  }


  export const createRecordByFirebaseTokenStudent = (payload)=>{
    return(dispatch)=>{
      axios.post(apiConfig.baseUrl1+"comm_hub/firebase_registration",payload
      ).then((res)=>{
          console.log(res?.data,"exit")
              
      }).catch(err=>{
          console.log(err)
      })
}
  }

export const studentLogin = (data)=>{

    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/login",data
        ).then((res)=>{
            console.log(res?.data,"hhhh")
            if (res.data?.res_status == true) {
                      // requestForToken()
                    getFirebaseToken()
                  .then((firebaseToken) => {
                    if (firebaseToken) {
                      // alert('Firebase token: ' + firebaseToken);
                        console.log(firebaseToken)  // Using alert to display the token
                      window.localStorage.setItem("fcmToken",firebaseToken)
                    } else {
                      alert('No Firebase token received.');  // Inform user if no token received
                    }
                  })
                  .catch((err) => {
                    console.error('An error occurred while retrieving the Firebase token. ', err);
                    alert('An error occurred while retrieving the Firebase token.');  // Alerting the user about the error
                  });
                     dispatch(createRecordByFirebaseTokenStudent())

                     dispatch(getStudentLogin(res))

                    console.log(apiConfig.baseUrl,'apiConfig')

            }
               
                 if (res.data?.res_status == false) {
                    dispatch(getStudentLogin(res))

                 }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const insertGroupChart = (data)=>{
  return{
    type:INSERT_GROUP_CHART,
    payload:data
  }
}

export const insertFireBaseGroupChart = (data)=>{
return{
  type:INSERT_GROUP_CHART_FIRE_BASE,
  payload:data
}
}

export const insertChartDetails = (payload)=>{

  return(dispatch)=>{
    
    axios.post(apiConfig.baseUrl+"e_lms/v1/groupchat",payload
    ).then((res)=>{

        if (res?.data.res_status == true) {
            dispatch(insertGroupChart(res))
        }

        if (res?.data.res_status == false) {
            dispatch(insertGroupChart())
        }
             
    }).catch(err=>{
        console.log(err)
    })
}
}


//Today
export const getCurrentGroupFromresult = (data)=>{
  console.log(data,"data1")
  return {
    type:LATEST_GROUP,
    payload:data
  }
}


//end

export const insertFireBaseChartDetails = (payload)=>{

  return(dispatch)=>{
    
    axios.post("https://botpi.arkatiss.com/ecomm/v2/firebase_chat",payload,{headers}
    ).then((res)=>{

        if (res.data.res_status == true) {

            dispatch(insertFireBaseGroupChart(res))
            //  callbackurl()

             dispatch(getCurrentGroupFromresult((res)))

          //      let getPayload={
          //     "peer_type": "retrieve",
          //     "peer_name": payload?.peer_name
          // }
          // dispatch (retrieveGroupCharList(getPayload))

    }

        if (res?.data.res_status == false) {
            dispatch(insertFireBaseGroupChart())
        }
             
    }).catch(err=>{
        console.log(err)
    })
}
}


    export const retrieveGroupChartDetails = (data)=>{
      console.log("new+",data)
      return{
        type:RETRIEVE_GROUP_CHART_DETAILS,
        payload:data
      }
    }

export const retrieveGroupCharList = (payload)=>{
  console.log(payload,"new")
  return(dispatch)=>{
    
    axios.post(apiConfig.baseUrl+"e_lms/v1/groupchat",payload
    ).then((res)=>{

        if (res?.data?.res_status === true) {

            dispatch(retrieveGroupChartDetails(res.data))
        }

        if (res?.data?.res_status === false) {
            dispatch(retrieveGroupChartDetails([]))
        }
             
    }).catch(err=>{
        console.log(err)
    })
}
}


  export const getList = (payload)=>{
    console.log(payload,"new")
    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/groupchat",payload
      ).then((res)=>{
  
          if (res?.data?.res_status === true) {
  
  
            // console.log(res?.data.res_status,'AVNI B')
  
            // console.log(res?.data)
              dispatch(retrieveGroupChartDetails(res.data))
          }
  
          if (res?.data?.res_status === false) {
              dispatch(retrieveGroupChartDetails([]))
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }


  export const retrieveAllNotificationDetails = (data)=>{
    console.log("new+",data)
    return{
      type:RETRIEVE_NOTIFICATION_DETAILS,
      payload:data
    }
  }



  export const retrieveAllNotification = (payload)=>{
    console.log(payload,"new")
    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/notification_data",payload
      ).then((res)=>{
  
          if (res?.data?.res_status === true) {
  
              dispatch(retrieveAllNotificationDetails(res.data))
          }
  
          if (res?.data?.res_status === false) {
              dispatch(retrieveAllNotificationDetails([]))
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }


  export const notificationUpdate = (payload)=>{
    console.log(payload,"new")
    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/notification_data",payload
      ).then((res)=>{
  
          // if (res?.data?.res_status === true) {
  
          //     dispatch(retrieveAllNotificationDetails(res.data))
          // }
  
          // if (res?.data?.res_status === false) {
          //     dispatch(retrieveAllNotificationDetails([]))
          // }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }


  export const retrieveNotificationCountDetails= (data)=>{
    console.log("new+",data)
    return{
      type:NOTIFICATION_COUNT,
      payload:data
    }
  }

  export const retrieveAllNotificationCount = (payload)=>{
    console.log(payload,"new")
    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/notification_data",payload
      ).then((res)=>{
  
          if (res?.data?.res_status === true) {
  
              dispatch(retrieveNotificationCountDetails(res.data))
          }
  
          if (res?.data?.res_status === false) {
              dispatch(retrieveNotificationCountDetails([]))
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }


  export const contactUsStudentDetails = (data)=>{
    return{
      type:STUDENT_CONTACT_US,
      payload:data
    }
  }


  export const contactUsStudent = (payload)=>{

    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/contact_us",payload
      ).then((res)=>{
  
          if (res?.data.res_status == true) {
              dispatch(contactUsStudentDetails(res))
          }
  
          if (res?.data.res_status == false) {
              dispatch(contactUsStudentDetails(res))
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }


  export const forgotPasswordApiCall = (payload)=>{

    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/forgot_password",payload
      ).then((res)=>{
  
          if (res?.data.res_status == true) {
              dispatch(contactUsStudentDetails(res))
          }
  
          if (res?.data.res_status == false) {
              dispatch(contactUsStudentDetails(res))
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }


  export const forgotPasswordApiCallInstructor = (payload)=>{

    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/forgot_password",payload
      ).then((res)=>{
  
          if (res?.data.res_status == true) {
              dispatch(contactUsStudentDetails(res))
          }
  
          if (res?.data.res_status == false) {
              dispatch(contactUsStudentDetails(res))
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }


  export const getTanantDetails = (data)=>{
    return{
      type:GET_TENANT_ID,
      payload:data
    }
  }

  export const getTenantIdBasedOnName = (payload)=>{

    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/tenant",payload
      ).then((res)=>{
  
          if (res?.data.res_status == true) {
              dispatch(getTanantDetails(res))

              console.log(res?.data?.data[0]['tenant_id'],'tenant_idD')

              window.localStorage.setItem("login_tenant_id", res?.data?.data[0]['tenant_id'],'tenant_idD')

          }
  
          if (res?.data.res_status == false) {

            // window.localStorage.setItem("student_id", student_id)

              dispatch(getTanantDetails())
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }


  export const registrationCourseName = (data)=>{
    return{
      type:RETRIEVE_REGISTRATION_COURSE_NAME,
      payload:data
    }
  }

  export const retristrationCourseNameDetails = (payload)=>{

    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/demo_class_registration",payload
      ).then((res)=>{
  
          if (res?.data.res_status == true) {
              dispatch(registrationCourseName(res?.data))
          }
  
          if (res?.data.res_status == false) {

            // window.localStorage.setItem("student_id", student_id)

              dispatch(registrationCourseName())
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }


  export const retrieveDateDetails = (data)=>{
    return{
      type:RETRIEVE_DATE_DETAILS,
      payload:data
    }
  }

  export const retrieveDate = (payload)=>{

    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/demo_class_registration",payload
      ).then((res)=>{
  
          if (res?.data.res_status == true) {
              dispatch(retrieveDateDetails(res?.data))
          }
  
          if (res?.data.res_status == false) {

            // window.localStorage.setItem("student_id", student_id)

              dispatch(retrieveDateDetails())
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }


  export const retrieveTimingDetails = (data)=>{
    return{
      type:RETRIEVE_TIMING,
      payload:data
    }
  }

  export const retrieveTiming = (payload)=>{

    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/demo_class_registration",payload
      ).then((res)=>{
  
          if (res?.data.res_status == true) {
              dispatch(retrieveTimingDetails(res?.data))
          }
  
          if (res?.data.res_status == false) {

            // window.localStorage.setItem("student_id", student_id)

              dispatch(retrieveTimingDetails())
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }