import React, { useEffect, useState } from 'react'

import { useDispatch,useSelector } from 'react-redux';

import { ButtonComponent,ModalComponent } from '../../../commonComponents';
import { upcomingClasses } from '../../../images';
import './upcomingCourses.css';
import  UpcomingCoursersDetails  from '../../../components/studentDashboard/upcomingCourses/upcomingCoursesDetailsPopup';

import { retrieveUpcomingCourses } from '../../../redux/studentDashboard/upcomingCourses/action';
let UpcomingCourses=()=> {

    let dispatch = useDispatch();

    let upcomingCoursesData = useSelector((state)=>state.upcomingCoursesReducer?.upcomingCourses)

    let [coursesData,setCoursesData] = useState([])
    let [studentDetails,setStudentDetails] = useState(false);
    let [studentRecord,setStudentRecord] = useState([]);
    let [studentId, setStudentId] = useState();


    useEffect(()=>{
        let id = window.localStorage.getItem("student_id")
        let upcomingClassesPayload ={
            "operation_type":"view_upcoming_classes",
            "student_id":window.localStorage.getItem("student_id")
        }
        dispatch(retrieveUpcomingCourses(upcomingClassesPayload))
    },[])

    useEffect(()=>{
        if (upcomingCoursesData) {
            setCoursesData(upcomingCoursesData.data)
        }
        console.log(upcomingCoursesData?.data,"upc")
    },[upcomingCoursesData])

    
    let handleViewStudent = (Id) =>{
        // setStudentId(Id);

        console.log(Id,'id')

        coursesData?.filter((item,index)=>{
          if(item.batch_id == Id){
            setStudentRecord(item)
          }
        })
        setStudentDetails(true)
        }

        let handleStudentCancel = () =>{
            setStudentDetails(false)
           
          }

        //   const handleClear = (value) => {
        //     setStudentDetails(true)
        //     handleViewStudent(value)

        //   };

  return (
    <>
    <div>
      <h3 className='upcomingClassesText'>Upcoming Schedule Classes</h3>
      {coursesData && coursesData.map((item, index) => (
        <div key={index} className='upcomingClasses'>
          <img src={item.course_thumbnail} alt="Course Thumbnail" className='upcomingCourseThumbnail'/>
          <div className='upcomingRecords'>
            <p>{item.course_name}</p>
            <p>Nov-18th ({item.course_time})</p>
          </div>
          <div className='alignRight'>
            <ButtonComponent value={"View"} className={"viewBtnStudent"} onClick={()=> handleViewStudent(item.batch_id)} />
          </div>
        </div>
      ))}
    </div>

    {studentDetails && (
      <ModalComponent
        title={"Upcoming Schedule Classes"}
        content={<UpcomingCoursersDetails studentRecord={studentRecord} />}
        open={studentDetails}
        handleCancel={handleStudentCancel}
      />
    )}
  </>

  )
}

export default UpcomingCourses