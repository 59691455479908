import { SET_CATEGORY,GET_CATEGORY,EMAIL_EXISTS, CATEGORY_SUCCESS,ADMIN_CATEGORY_CRUD } from "./actionTypes";

import apiConfig from '../../apiConfig/api';

import axios from 'axios';


const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    };

export const categorySuccess = (data) =>{
    return{
        type: CATEGORY_SUCCESS,
        payload: data
    }
}

export const setCourseDetail =(data)=>{
    return {
        type: SET_CATEGORY,
        payload: data
    }
}


export const getAllCategories = (data)=>{
    return {
        type: GET_CATEGORY,
        payload: data
    }
}

export const emailExistss = (data)=>{
    return {
        type: EMAIL_EXISTS,
        payload: data
    }
}

export const adminCategoryCrud = (data) =>{
    return {
        type:ADMIN_CATEGORY_CRUD,
        payload:data
    }
}

export const createCategory = (data) =>{
    return (dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/coursecategory",data, {headers}
            ).then((res)=>{
                console.log(res.data.res_status,'hello')
                
                    if (res.data?.res_status == false) {
                        console.log(res.data,'validate')
                        dispatch(emailExistss(res.data?.res_status));
                        // dispatch(categorySuccess(res.data?.res_status));
                        dispatch(adminCategoryCrud(res?.data))

                    }else{
                        dispatch(setCourseDetail(res));
                        dispatch(getCategories())
                        dispatch(emailExistss(res.data?.res_status))
                        // dispatch(categorySuccess(res.data?.res_status));
                        dispatch(adminCategoryCrud(res?.data))


                }
    
            }).catch(err=>{
                console.log(err)
            })
    }
}


//Get all categories
export const  getCategories = () =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/coursecategory",{"operation_type": "select","tenant_id":window.localStorage.getItem("tenant_Id")}
        ).then((res)=>{
            dispatch(getAllCategories(res.data));
            // if (res.data.res_status == true) {
            //  categorySuccess(res.data.res_status);
            // }
        }).catch(err=>{
            console.log(err)
        })
}
    
}

//Get all categories
export const  getCategories1 = () =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/coursecategory",{"operation_type": "select","tenant_id":window.localStorage.getItem("login_tenant_id")}
        ).then((res)=>{
            dispatch(getAllCategories(res.data));
            // if (res.data.res_status == true) {
            //  categorySuccess(res.data.res_status);
            // }
        }).catch(err=>{
            console.log(err)
        })
}
    
}

// Delete instructor by id

export const deleteCategory=(data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/coursecategory",data
        ).then((res)=>{
            dispatch(getCategories());
            if (res.data.res_status == true) {
                dispatch(emailExistss(res.data?.res_status));
                dispatch(adminCategoryCrud(res?.data))

            }
        }).catch(err=>{
            console.log(err)
        })
    }
}

//update instructor

export const updateCategory = (data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/coursecategory",data
        ).then((res)=>{
           
            if (res.data.res_status == true) {
                dispatch(emailExistss(res.data?.res_status));
                dispatch(getCategories());
                dispatch(adminCategoryCrud(res?.data))

            }
        }).catch(err=>{
            console.log(err)
        })
    }
}
