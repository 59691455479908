export const GET_ALL_ADMIN_STUDENTS = "GET_ALL_ADMIN_STUDENTS";

export const STUDENT_CRUD_STATUS = "STUDENT_CRUD_STATUS";

export const RESET_STUDENT_ADMIN_PASSWORD = "RESET_STUDENT_ADMIN_PASSWORD";

export const STUDENT_REQUEST_APPROVED_ACTION = "STUDENT_REQUEST_APPROVED_ACTION";

export const STUDENT_REQUEST_REJECTED_ACTION = "STUDENT_REQUEST_REJECTED_ACTION";

export const CONTACT_US_DETAILS = "CONTACT_US_DETAILS";


