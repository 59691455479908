import React, { useEffect, useState ,useRef} from 'react'
import { ModalComponent, CheckboxComponent, InputComponent, InputSelect, TableComponent } from '../../commonComponents'
import './allbatches.css';
import { deleteIcon, edit, excel, plus, searchIcon, upload,cancelImage ,TableEyeIcon} from '../../images';
import { Button, DatePicker,Card, Form, Select,ConfigProvider, notification } from 'antd';
import { useSelector,useDispatch } from 'react-redux';
import { loadStudents } from '../../redux/student/action';
import { getCategories } from '../../redux/allCategories/action';
import  ViewBatchesDetails  from '../../components/AllBatches/viewBatchesDetails';

import { batchCrudStatus, batchPushNotification, createBatch, createZoomLinkForBatch, 
  deleteBatch, fetchInstructorCourseDetails, retrieveBatches, retrieveInstructorsBasedOnCourse,
   updateBatch ,batchCreateNotification,updateBatchNotification} from '../../redux/batches/action';
import moment from 'moment';
import { Space, TimePicker } from 'antd';
// import {insertFireBaseChartDetails} from '../../../redux/studentLogin/action';

import {insertFireBaseChartDetails} from '../../redux/studentLogin/action';

import * as XLSX from 'xlsx';
import ExportToExcel from '../../commonComponents/XlsxFile/ExportToExcel'

import enUS from 'antd/es/calendar/locale/en_US';
// import 'antd/dist/antd.css';

import dayjs from 'dayjs';

import validateBatch from './validateBatch';

import DatePickerReact from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { push } from 'connected-react-router';









let  Allbatches=()=> {
  const [form] = Form.useForm();

  let navigate = useNavigate();


//Google meet link create 
  const [gapiInited, setGapiInited] = useState(false);
  const [gisInited, setGisInited] = useState(false);
  const [tokenClient, setTokenClient] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(true);
  const [events, setEvents] = useState([]);

  const CLIENT_ID = '184526489019-g3v8chejamc09fu2ctefuvv8vuheukpl.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyDTQNTRbeHEa-wpa3lM0OIBoX9Z9_QbvmE';
  const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
  const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events';




  let [studentRecord,setStudentRecord] = useState([]);



  let[startTime,setStartTime] = useState("");

    let[endTimes,setEndTimes] = useState("");

    let [startDate,setStartDate] = useState("");

    let [endDate,setEndDate] = useState("");

  let [studentSearch,setStudentSearch] = useState();

  let [isDeleteModalVisible,setIsDeleteModalVisile] = useState(false);

  let [weekDays,setWeekDays] = useState([
    {label:"Mon",value:"Mon"},
    {label:"Tue",value:"Tue"},
    {label:"Wed",value:"Wed"},
    {label:"Thu",value:"Thu"},
    {label:"Fri",value:"Fri"},
    {label:"Sat",value:"Sat"},
    {label:"Sun",value:"Sun"}
  ])

  const todayDate = moment();

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < todayDate.startOf('day');
  }


    let[searchEntry,setSearchEntry] = useState("")
    let [showForm,setShowForm] = useState(false);

    let [studentsList,setStudentsList] = useState();

    let [categoryDropdown,setCategoryDropdown] = useState();

    let allStudents = useSelector((state) => state.student?.students?.data);

    let allCategories = useSelector((state)=> state.categories?.category?.data);

    let allCourseInstructorDetails = useSelector((state)=>state.batchcourseDetails?.data);

    let retrieveBatcheRecords = useSelector((state)=>state.batchcourseDetails?.batches?.data.data);

    let adminBatchCrud = useSelector((state)=>state.batchcourseDetails?.batchCrudStatus);

    let batchUpdateNotification = useSelector((state)=>state.batchcourseDetails?.batchUpdateNotification);


    let allAdminInstructors = useSelector((state)=>state?.AdminInstructor?.adminInstructors);

    let batchMeeting = useSelector((state)=>state.batchcourseDetails?.batchMeetingStatus)

    let [selectedStudents,setSelectedStudents] = useState([]);
    
   
    let [instructorDetails,setInstructorDetails] = useState([]);

    let [courseData,setCourseData] = useState([]);

    let [studentd,setStudentd] = useState();

    let [studentDetailList,setStudentDetailList] = useState([]);

    let [allBatches,setAllBatches] = useState();

    let [modalType,setModalType] = useState();

    let [batchId,setBatchId] = useState();

    
    let [errors,setErrors] = useState();

    let [formValues,setFormValues] = useState({
      batch_name:"",
      course_category_id:"",
      course_id:"",
      instructor_id:"",
      start_date:"",
      end_date:"",
      course_time:"",
      start_time:"",
      end_time:"",
      week_days:"",
      meeting_link:"",
      course_name:"",
      instructor_name:"",
      course_end_time:""
    })
    let [addStudents,setAddStudents] = useState();
    let dispatch = useDispatch();

    useEffect(() =>{
      if (searchEntry == "") {
        setAllBatches(retrieveBatcheRecords)
      }
  },[searchEntry])

  const [availableStudents,setAvailableStudents] = useState("");
  useEffect(()=>{
    if (allStudents) {
      setAvailableStudents(allStudents?.data)
    }
  },[allStudents])
  

    useEffect(()=>{
      dispatch(getCategories())
    },[])

    useEffect(()=>{
      if (allCategories) {
       setCategoryDropdown(allCategories)
      }
    },[allCategories])


    useEffect(()=>{
      dispatch(loadStudents())
      dispatch(retrieveBatches())
    },[])

    useEffect(()=>{
      dispatch(retrieveBatches())
    },[showForm])

    useEffect(()=>{
      if(retrieveBatcheRecords){
        setAllBatches(retrieveBatcheRecords)

      }else{
        setAllBatches([])

      }

    },[retrieveBatcheRecords])

    let handleInputChange = (e, type) => {

      if (e.target !== undefined) {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        form.setFieldsValue({[type.name]: e})
      } else {
        setFormValues({ ...formValues, [type.name]: e })

      }
    }
    
    const [insertStartDate,setInsertStartDate] = useState()

    const [insertEndDate,setInsertEndDate] = useState();

    let handleDateChange = (e,type)=>{

      if (type.name == "end_date") {
        setEndDate(e)
      form.setFieldsValue({ endDate: e })  
      }
      if (type.name == "start_date") {
        setStartDate(e)
        form.setFieldsValue({ startDate: e })  
      }
        
    }

    useEffect(()=>{
      if (allCourseInstructorDetails) {
        setInstructorDetails(allCourseInstructorDetails?.instructor_data)
        setCourseData(allCourseInstructorDetails?.course_data)
      }
    },[allCourseInstructorDetails])

    

    let handleCategoryChange = (e,type) =>{
        // setInstructorDetails([])
        // setCourseData([])
      // form.setFieldsValue({[type.name]: e})
        if (e) {
          setFormValues({...formValues,[type.name]:e});
          form.setFieldsValue({[type.nam]:e})
        }
      

      if (type.name == "startTime") {

        if (e) {
          const startTime  = dayjs(e).format('hh:mm A')
          setStartTime(startTime)
          setInsertStartTime(startTime);
          form.setFieldsValue({[type.name]:e})
        }
      

      }

      if (type.name == "endTimes") {

        if (e) {
          const endTimee  = dayjs(e).format('hh:mm A')

        setEndTimes(endTimee);
        setInsertEndTime(endTimee);
        form.setFieldsValue({[type.name]:e})
        }
      
      }

      if (type.name == "course_category_id") {
        form.setFieldsValue({[type.name]:e})

       let payload={  
        "operation_type": "retrieve_data_on_category",
        "course_category_id":e
      }
      dispatch(fetchInstructorCourseDetails(payload))
      setFormValues({...formValues,"course_id":"Select","instructor_id":"Select","course_category_id":e});
      }

      if(type.name == "week_days"){
        setFormValues({...formValues,[type.name]:e})
        form.setFieldsValue({[type.name]:e})
      }

      if (type.name == "batch_name") {
        setFormValues({...formValues,[type.name]:e.target.value});
        form.setFieldsValue({[type.name]:e.target.value})

      }

      if (type.name == "course_id") {
        setFormValues({...formValues,[type.name]:e})
        form.setFieldsValue({[type.name]:e})
        setFormValues({...formValues,"instructor_id":"Select","course_id":e});

      }

      if (type.name == "instructor_id") {
        setFormValues({...formValues,[type.name]:e})
        form.setFieldsValue({[type.name]:e})

      }
        console.log(formValues,"batchForm")
    }


    //course Related Instructors
    let allCourseInstructors = useSelector((state)=>state.batchcourseDetails?.courseInstructors);

    const [coursRelatedInstructors,setCourseRelatedInstructors] = useState()
    useEffect(()=>{
        if (formValues?.course_id) {

        const payload ={
          "operation_type":"view_instructor_on_course",
          "course_id":formValues?.course_id,
          "tenant_id":window.localStorage.getItem("tenant_Id")
      }
          dispatch(retrieveInstructorsBasedOnCourse(payload))
          
        }
    },[formValues?.course_id])

    useEffect(()=>{
      if (allCourseInstructors) {
        console.log(allCourseInstructors,"anc")
        setCourseRelatedInstructors(allCourseInstructors?.data)
      }else{
        setCourseRelatedInstructors([])
      }
    },[allCourseInstructors])

    //end for courseRelated instructors

    // useEffect(()=>{
    //   handleDateChange(startDate,"start_date")
    //   handleDateChange(endDate,"end_date")
    // },[modalType == "Edit batch"])



    useEffect(()=>{
      if (allStudents) {
        let objStudent=[]
        allStudents && Object.values(allStudents).map((i,Index)=>{
         let newObj= {};
         newObj["student_name"] = i.fullname;
         newObj["student_id"] = i.student_id;
         objStudent.push(newObj)
       setStudentsList(objStudent)
       })
      }
    },[allStudents])

    useEffect(()=>{
        if (batchId) {
          setSelectedStudents([])
          dispatch(loadStudents())

          console.log(batchId,"idhereee")
          allBatches && allBatches.filter((item,index)=>{
            if (item.batch_id == batchId) {
              console.log("yes",item)
              item.student_ids && item.student_ids.map((item,index)=>{
                console.log(item,"hello")
                changeCheckbox(item)
              })
            }
          })
          
        }
    },[batchId])
   

    let handleModal = (type,id) =>{
      setStudentd([])
      setFormValues({...formValues,["week_days"]:""});
      setSelectedStudents([])
        setShowForm(true)
        setModalType(type)
        if (type == "Edit Batch") {
          setBatchId(id)
        }else{
          let objStudent=[]
          allStudents && Object.values(allStudents).map((i,Index)=>{
           let newObj= {};
           newObj["student_name"] = i.fullname;
           newObj["student_id"] = i.student_id;

           objStudent.push(newObj)
         setStudentsList(objStudent)
        //  changeCheckbox(i.student_id)
         })
        }
        console.log(studentDetailList,"editlist")
    }

    let [isSelectedBatchId,setIsSelectedBatchId] = useState(false);


    let handleDeleteBatch = (id) =>{
      setIsSelectedBatchId(id)
      setIsDeleteModalVisile(true);

  }

    let handleViewTable = ()=>{
      // setFormValues({})
      form.setFieldsValue({["batch_name"]:""})
      form.setFieldsValue({["meeting_link"]:""})
      form.setFieldsValue({["startTime"]:""})
      form.setFieldsValue({["endTimes"]:""})
      setFormValues({
        batch_name:"",
        course_category_id:"",
        course_id:"",
        instructor_id:"",
        // start_date:"",
        // end_date:"",
        // course_time:"",
        // start_time:"",
        // end_time:"",
        week_days:"",
        // meeting_link:"",
        course_name:"",
        instructor_name:"",
        // course_end_time:""
      })
      // setStudentsList([])
      setStudentd([])
      setSelectedStudents([]);
      setStartDate("");
      setEndDate("")
      setEndTimes("");
      setStartTime("");
      setInsertStartTime("");
      setInsertEndTime("");
      setShowForm(!showForm)
      setFormValues({...formValues,["batch_name"]:""})
      setFormValues({...formValues,["course_category_id"]:"Select"});
      setFormValues({...formValues,["course_id"]:"Select"})
      setFormValues({...formValues,["instructor_id"]:"Select"})
      setFormValues({...formValues,["meeting_link"]:""})
      setFormValues({...formValues,["week_days"]:""})
      setStartDate("");
      setEndDate("")
      setStartTime("");
      setEndTimes("");
  
      form.setFieldsValue({["instructor_id"]:""});
      form.setFieldsValue({["course_id"]:""});
      form.setFieldsValue({["course_category_id"]:""})
      form.setFieldsValue({["batch_name"]:""})
      form.setFieldsValue({["startDate"]:""});
      form.setFieldsValue({["endTimes"]:""});
      form.setFieldsValue({["meeting_link"]:""});
      form.setFieldsValue({["week_days"]:""});
      form.setFieldsValue({["startDate"]:""});
      form.setFieldsValue({["endDate"]:""})
      // form.setFieldsValue({["startDate"]:""});
      // form.setFieldsValue({["endTimes"]:""})
      setFormValues({...formValues,"course_id":"Select","instructor_id":"Select","course_category_id":"Select"});
      form.setFieldsValue({["startTime"]:""})
      form.setFieldsValue({["endTimes"]:""})
    }
    useEffect(()=>{
      let result = validateBatch(formValues)
      setErrors(result.batchErrors)
    },[showForm])

    let changeCheckbox = (student_id)=>{
      // dispatch(loadStudents())
      setSelectedStudents((prevCheckedStudents) => ({
        ...prevCheckedStudents,
        [student_id]: !prevCheckedStudents[student_id],
      }));
    }


    let addStudentList = () =>{

      console.log('NEW BATCH')
      let trueValues= Object.keys(selectedStudents).filter((key) => selectedStudents[key] === true);
      const studentIDS = trueValues.map((strNum) => Number(strNum));
      setStudentd(studentIDS)

    }

    useEffect(()=>{
        let data=[]

        studentd && studentd.map((item,index)=>{
            allStudents && allStudents.map((a,b)=>{
              if (a.student_id == item) {
                data.push(a)
              }
            })
        })
        setAddStudents(data)
    },[studentd])

    useEffect(()=>{
        let obj=[]
        addStudents && Object.values(addStudents).map((i,Index)=>{
        let newObj= {};
        newObj["student_id"] = i.student_id;
      obj.push(newObj)
      setStudentDetailList(obj)
      })
    },[addStudents])

    let removeFormStudentList = (id) =>{
      let data = studentd.filter(item => item !== id)
        setStudentd(data)
        changeCheckbox(id)
        let array=studentDetailList && studentDetailList .filter(item=> item.student_id != id)
        setStudentDetailList(array)
      }

    let clearAllRecords = () =>{
      setStudentd(0)
      setSelectedStudents([])
      setStudentDetailList([])
      setAddStudents([])
    }

    const [insertStartTime,setInsertStartTime] = useState();

    const [insertEndTime,setInsertEndTime] = useState();

    let[batchName,setBatchName] = useState("")

    const [allBatchId,setAllBatchId] = useState()
    const [allStudentId,setAllStudentId] = useState()
    
    let handleEditModal = (type,id) =>{
      setModalType(type);
      setBatchId(id)
      setShowForm(!showForm)
      // dispatch(loadStudents())
      setAvailableStudents(allStudents?.data)
      setSelectedStudents([])
      allBatches.map((item,index)=>{
        if (item.batch_id == id) {

          setAllBatchId(item.batch_id)
          setAllStudentId(item.student_ids)

          console.log(item.meeting_link,"anilanaparthi")
          setFormValues({
            batch_name: item.batch_name,
            course_category_id: item.course_category_id,
            course_id: item.course_id,
            instructor_id: item.instructor_id,
            week_days: item.week_days,
            meeting_link: item.meeting_link
          })
          setStudentd(item?.student_ids)
          form.setFieldsValue({["batch_name"]: item.batch_name})

          form.setFieldsValue({["meeting_link"]: item.meeting_link})
          // setFormValues({...formValues,['meeting_link']:item.meeting_link})
          // form.setFieldsValue({['meeting_link']:meetLink})

          form.setFieldsValue({["course_category_id"]:item.course_category_id});

          form.setFieldsValue({["course_id"]:item.course_id});

          form.setFieldsValue({["instructor_id"]:item.instructor_id});

          form.setFieldsValue({["week_days"]:item.week_days});

          form.setFieldsValue({["startDate"]:item.batch_start_date});

          form.setFieldsValue({["endDate"]:item.batch_end_date});

             setStartDate(dayjs(item.batch_start_date,"DD-MM-YYYY"));
             setEndDate(dayjs(item.batch_end_date,"DD-MM-YYYY"));

            setInsertStartDate(item.batch_start_date)
            setInsertEndDate(item.batch_end_date)
               
            setInsertStartTime(item.start_time);
            setInsertEndTime(item.end_time);

            setStartTime(dayjs(item.start_time,'hh:mm A'));
            setEndTimes(dayjs(item.end_time,'hh:mm A'));
            
            form.setFieldsValue({["startTime"]:dayjs(item.start_time,'hh:mm A')})
            form.setFieldsValue({["endTimes"]:dayjs(item.end_time,'hh:mm A')})
            
          item.student_ids && item.student_ids.map((item,index)=>{
            changeCheckbox(item)
          })
        }
      })
      console.log(formValues.batch_name,startDate,endDate,startTime,endTimes,insertStartTime,insertEndTime,"editForm")
     
    }

    useEffect(()=>{
      dispatch(loadStudents())
    },[selectedStudents])

    useEffect(()=>{
      if (startDate) {
        console.log(startDate,"newDatepicker")
      }
    },[startDate])

    useEffect(()=>{
      if (formValues.course_category_id) {
        let payload={  
          "operation_type": "retrieve_data_on_category",
          "course_category_id":formValues.course_category_id
        }
        dispatch(fetchInstructorCourseDetails(payload))
      }
    },[formValues.course_category_id])

    useEffect(()=>{
      if (studentDetailList) {

        studentDetailList && Object.values(studentDetailList).map((item,index)=>{
          changeCheckbox(item)
        })
      }
    },[studentDetailList])

    let batchesCreateNotification = useSelector((state)=>(state.batchcourseDetails?.batchCreateNotification));


    useEffect(()=>{

      console.log(batchesCreateNotification,'NOTIFICATION')
      // setStudentsClassCancelNotification()
      // setStudentsClassCancelNotification(batchesCreateNotification)
  
      if(batchesCreateNotification){
  
        const fcmToken = batchesCreateNotification?.data&&Object.values(batchesCreateNotification?.data).map(item => item.fcmtoken);
    
        const usernames = batchesCreateNotification?.data&&Object.values(batchesCreateNotification?.data).map(item => item.username).join(',');
        
    
  
      let payloadFireBase = {
        "sender_name": window.localStorage.getItem("adminEmail"),
        "receiver_name":usernames,
        "peer_name": 'Batch Enrollment!',//formValues.batch_name
        "peer_chat_type": "batches create",
        "input": "You have been added to "+formValues.batch_name,
        "firebase_tokens": fcmToken, 
        "msg_type": "insert",
        "peer_type": "insert"
      };
      dispatch(insertFireBaseChartDetails(payloadFireBase));
  
      }else{
        console.log(batchesCreateNotification,'NOTIFICATION NO')

      }
  
    },[batchesCreateNotification])






    useEffect(() => {

      // const authorizeButton = document.getElementById('authorize_button');
      // if (authorizeButton) {
      //   authorizeButton.style.visibility = 'hidden';
      // } else {
      //   console.warn('Authorize button not found');
      // }
      // Load the gapi script
      const gapiScript = document.createElement('script');
      gapiScript.src = 'https://apis.google.com/js/api.js';
      gapiScript.onload = () => gapiLoaded();
      document.body.appendChild(gapiScript);
  
      // Load the GIS script
      const gisScript = document.createElement('script');
      gisScript.src = 'https://accounts.google.com/gsi/client';
      gisScript.onload = () => gisLoaded();
      document.body.appendChild(gisScript);
    }, []);
  
    const gapiLoaded = () => {
      window.gapi.load('client', initializeGapiClient);
    };
  
    const initializeGapiClient = async () => {
      await window.gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      }).then(() => {
        checkSignInStatus();
      });
      setGapiInited(true);
      maybeEnableButtons();
    };

    const checkSignInStatus = () => {
      if (window.gapi.client.getToken() === null) {
        console.log('No DAMODAR');
        setIsSignedIn(true);
      } else {
        console.log('Yes DAMODAR');
        setIsSignedIn(false);
      }
    };



  
    const gisLoaded = () => {
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: '', // defined later
      });
      setTokenClient(client);
      setGisInited(true);
      maybeEnableButtons();
    };
  
    const maybeEnableButtons = () => {
      if (gapiInited && gisInited) {
        document.getElementById('authorize_button');//.style.visibility = 'visible'
      }
    };

    // useEffect(() => {

    //   // tokenClient.callback = async (resp) => {
    //   //   if (resp.error !== undefined) {
    //   //     setIsSignedIn(false);

    //   //   }else{
    //   //     setIsSignedIn(true);

    //   //   }
    //   // };
  
    //   if (window.gapi?.client.getToken() === null) {

    //     console.log('Yes DAMODAR')
    //     setIsSignedIn(true);
    //   } else {
    //     console.log('No DAMODAR')

    //     setIsSignedIn(false);
    //   }
    // }, []);


    
  
    const handleAuthClick = () => {
      tokenClient.callback = async (resp) => {
        if (resp.error !== undefined) {
          throw (resp);
        }
        setIsSignedIn(false);

        document.getElementById('signout_button');//.style.visibility = 'visible'
        // document.getElementById('authorize_button').innerText = 'Refresh';
        await listUpcomingEvents();
      };
  
      if (window.gapi.client.getToken() === null) {
        tokenClient.requestAccessToken({ prompt: 'consent' });
      } else {
        tokenClient.requestAccessToken({ prompt: '' });
      }
    };
  
    const handleSignoutClick = () => {
      const token = window.gapi.client.getToken();
      if (token !== null) {
        window.google.accounts.oauth2.revoke(token.access_token);
        window.gapi.client.setToken('');
        setIsSignedIn(false);
        setEvents([]);
        document.getElementById('authorize_button').innerText = 'Authorize';
        // document.getElementById('signout_button').style.visibility = 'hidden';//
      }
    };
  
    const listUpcomingEvents = async () => {
      let response;
      try {
        const request = {
          'calendarId': 'primary',
          'timeMin': (new Date()).toISOString(),
          'showDeleted': false,
          'singleEvents': true,
          'maxResults': 10,
          'orderBy': 'startTime',
        };
        setIsSignedIn(false);

        response = await window.gapi.client.calendar.events.list(request);
      } catch (err) {
        console.error(err.message);
        return;
      }
  
      const events = response.result.items;
      if (!events || events.length === 0) {
        setEvents(['No events found.']);
        return;
      }
      setEvents(events.map(event => `${event.summary} (${event.start.dateTime || event.start.date})`));
    };
  
    const handleAddEvent = () => {
      const requestId = Math.random().toString(36).substr(2, 10);

      const timeStringStart = startTime; // Your input time string
          const timeWithSecondsStart = new Date("1970-01-01 " + timeStringStart).toLocaleTimeString([], { hour12: false });

          const timeStringEnd = endTimes; // Your input time string
          const timeWithSecondsEnd = new Date("1970-01-01 " + timeStringEnd).toLocaleTimeString([], { hour12: false });
  
      const event = {
        'summary': formValues.batch_name, // Event summary
        'start': {
          'dateTime': startDate+'T'+timeWithSecondsStart, // Start time of the event
          'timeZone': 'UTC', // Time zone
        },
        'end': {
          'dateTime': startDate+'T'+timeWithSecondsEnd, // End time of the event
          'timeZone': 'UTC', // Time zone
        },
        'conferenceData': {
          'createRequest': {
            'requestId': requestId // Unique request ID for conference data
          }
        }
      };
  
      window.gapi.client.calendar.events.insert({
        'calendarId': 'primary', // Calendar ID where you want to add the event
        'resource': event
      }).then(response => {
        const createdEvent = response.result;
        const eventId = createdEvent.id; // Get the eventId from the created event
        console.log('Event created:', eventId);
  
        // const meetLink = createdEvent.hangoutLink; // Retrieve the Meet link from the created event
        // console.log('Meet link:', meetLink);
  
  
        // Now you can use the eventId for further operations if needed
        // For example, if you want to patch the event with conference data
        patchEventWithConferenceData(eventId);
      }).catch(error => {


        console.error('Error creating event:', error);

       
      });
    };
  
    const patchEventWithConferenceData = (eventId) => {
      const requestId = Date.now().toString() + Math.random().toString(36).substr(2, 9);
  
      const eventPatch = {
        'conferenceData': {
          'createRequest': {
            'requestId': requestId // Unique request ID for conference data
          }
        }
      };
  
      window.gapi.client.calendar.events.patch({
        'calendarId': 'primary',
        'eventId': eventId, // Use the eventId of the created event
        'resource': eventPatch,
        'sendNotifications': true,
        'conferenceDataVersion': 1
      }).then(response => {
        const patchedEvent = response.result;
  
        const meetLink = patchedEvent.hangoutLink; // Retrieve the Meet link from the created event
        console.log('Meet_link:', meetLink);
        setFormValues({...formValues,['meeting_link']:meetLink})
        form.setFieldsValue({['meeting_link']:meetLink})


        const filteredNumbers = studentd?.filter(num => !isNaN(num));

        let createPayload={
          "operation_type": "insert",
          "op_field_details": {
              "batch_name":formValues.batch_name,
              "course_category_id":formValues.course_category_id,
              "course_id": formValues.course_id,
              "instructor_id":formValues.instructor_id,
              "batch_start_date": startDate,
              "batch_end_date": endDate,
              "start_time":startTime,
              "end_time": endTimes,
              "week_days": formValues.week_days,
              "meeting_link": meetLink,
              "tenant_id": window.localStorage.getItem("tenant_Id"),
              
          },
          "details": studentDetailList
      }
       dispatch(createBatch(createPayload))

      console.log(createPayload)


       let notificationPayload= {
         "notification_type":"batch_enrollment",
         "project_name":"lms",
         "student_id":filteredNumbers ,
         "instructor_id":formValues.instructor_id,
         "tenant_id":window.localStorage.getItem("tenant_Id")
     }
 
     dispatch(batchCreateNotification(notificationPayload));
 
     setShowForm(!showForm);
     dispatch(retrieveBatches());

     setStudentsList([])
     console.log("editForm",formValues)
     setCourseData([]);
     setInstructorDetails([])
     setFormValues({...formValues,["batch_name"]:""})
     setFormValues({...formValues,["course_category_id"]:"Select"});
     setFormValues({...formValues,["course_id"]:"Select"})
     setFormValues({...formValues,["instructor_id"]:"Select"})
     setFormValues({...formValues,["meeting_link"]:""})
     setFormValues({...formValues,["week_days"]:""})
     setStartDate("");
     setEndDate("");
     setStartTime("");
     setEndTimes("");
 
     form.setFieldsValue({["instructor_id"]:""});
     form.setFieldsValue({["course_id"]:""});
     form.setFieldsValue({["course_category_id"]:""})
     form.setFieldsValue({["batch_name"]:""})
     form.setFieldsValue({["startDate"]:""});
     form.setFieldsValue({["endTimes"]:""});
     form.setFieldsValue({["meeting_link"]:""});
     form.setFieldsValue({["week_days"]:""});
     form.setFieldsValue({["startDate"]:""});
     form.setFieldsValue({["endDate"]:""})
     // form.setFieldsValue({["startDate"]:""});
     // form.setFieldsValue({["endTimes"]:""})
     setFormValues({...formValues,"course_id":"Select","instructor_id":"Select","course_category_id":"Select"});
     form.setFieldsValue({["startTime"]:""})
     form.setFieldsValue({["endTimes"]:""})
  
        console.log('Conference created for event:', patchedEvent.htmlLink);
      }).catch(error => {
        console.error('Error patching event with conference data:', error);
      });
    };
  
  

















    let submitBatch = (values) =>{



    

      // handleAuthClick();

 



      if (modalType != "Edit Batch") {

       
          handleAddEvent();
         
          
    
 
     }


    if (modalType == "Edit Batch") {
      let editPayload = {
        "operation_type": "update",
        "op_field_details": {
            "batch_id":batchId,
            "batch_name": formValues.batch_name,
            "course_category_id":formValues.course_category_id,
            "course_id": formValues.course_id,
            "instructor_id":formValues.instructor_id,
            "batch_start_date": startDate,
            "batch_end_date": endDate,
            "start_time": insertStartTime,
            "end_time": insertEndTime,
            "week_days": formValues.week_days,
            "meeting_link": formValues.meeting_link,
            "tenant_id": window.localStorage.getItem("tenant_Id"),
        },
        "details":studentDetailList
    }
    dispatch(updateBatch(editPayload))

    let pushPayload={
      "notification_type":"batch_enrollment",
      "batch_name":formValues.batch_name,
      "course_id":formValues.course_id,
      "batch_start_date":startDate,
      "student_id":studentDetailList,
      "project_name":"lms",
      "tenant_id":window.localStorage.getItem("tenant_Id")
    }
   dispatch(batchPushNotification(pushPayload))









    setShowForm(!showForm);
    dispatch(retrieveBatches());
    // setFormValues({})
    setStudentsList([])
    console.log("editForm",formValues)
    setCourseData([]);
    setInstructorDetails([])
    setFormValues({...formValues,["batch_name"]:""})
    setFormValues({...formValues,["course_category_id"]:"Select"});
    setFormValues({...formValues,["course_id"]:"Select"})
    setFormValues({...formValues,["instructor_id"]:"Select"})
    setFormValues({...formValues,["meeting_link"]:""})
    setFormValues({...formValues,["week_days"]:""})
    setStartDate("");
    setEndDate("");
    setStartTime("");
    setEndTimes("");

    form.setFieldsValue({["instructor_id"]:""});
    form.setFieldsValue({["course_id"]:""});
    form.setFieldsValue({["course_category_id"]:""})
    form.setFieldsValue({["batch_name"]:""})
    form.setFieldsValue({["startDate"]:""});
    form.setFieldsValue({["endTimes"]:""});
    form.setFieldsValue({["meeting_link"]:""});
    form.setFieldsValue({["week_days"]:""});
    form.setFieldsValue({["startDate"]:""});
    form.setFieldsValue({["endDate"]:""})
    // form.setFieldsValue({["startDate"]:""});
    // form.setFieldsValue({["endTimes"]:""})
    setFormValues({...formValues,"course_id":"Select","instructor_id":"Select","course_category_id":"Select"});
    form.setFieldsValue({["startTime"]:""})
    form.setFieldsValue({["endTimes"]:""})
  }



    }

    let searchStudentName = (data)=>{
      // setStudentsList
      let name = data.target.value.toLowerCase();
      let objStudent = [];
      setStudentSearch(name)
      let filteredStudents =allStudents && allStudents.filter((record)=>
      record.fullname.toLowerCase().includes(name));
 
      filteredStudents && Object.values(filteredStudents).map((i,Index)=>{
        let newObj= {};
        newObj["student_name"] = i.fullname;
        newObj["student_id"] = i.student_id;
        objStudent.push(newObj)
      setStudentsList(objStudent)
      })
      // setStudentsList(filteredStudents)
    }

    const searchBatchName = (e)=>{
      let data = e.target.value;
      setSearchEntry(data)
    }
  const columns = [

    {
      title: 'ID',
      dataIndex: 'batch_id',
      sorter: true,
      filteredValue:[searchEntry],
      onFilter: (value,record)=>{
        return String(record.batch_id).toLowerCase().includes(value.toLowerCase())||
               String(record.batch_name).toLowerCase().includes(value.toLowerCase())||
               String(record.course_name).toLowerCase().includes(value.toLowerCase())||
               String(record.instructor_name).toLowerCase().includes(value.toLowerCase())||
               String(record.student_count).toLowerCase().includes(value.toLowerCase())||
               String(record.batch_start_date).toLowerCase().includes(value.toLowerCase())||
               String(record.batch_end_date).toLowerCase().includes(value.toLowerCase())
             },
       sorter: (a, b) => a.batch_id - b.batch_id,

    },
    {
      title: 'Name',
      dataIndex: 'batch_name',
      sorter:true,
      sorter: (a, b) => a.batch_name .localeCompare(b.batch_name),

    },
    {
        title: 'Course Name',
        dataIndex: 'course_name',
        sorter: true,
        sorter: (a, b) => a.course_name .localeCompare(b.course_name),

      },
      {
        title: 'Instructor Name',
        dataIndex: 'instructor_name',
        sorter: true,
      //   onFilter: (value, record) => record.category.indexOf(value) === 0,
      sorter: (a, b) => a.instructor_name .localeCompare(b.instructor_name),

  
      },
      {
        title: 'Student Count',
        dataIndex: 'student_count',
        sorter: true,
      //   onFilter: (value, record) => record.category.indexOf(value) === 0,
      sorter: (a, b) => a.student_count - b.student_count,

  
      },
      {
        title: 'Start Date',
        dataIndex: 'batch_start_date',
        sorter: true,
      
      sorter: (a, b) => a.batch_start_date .localeCompare(b.batch_start_date),
  
    },
      {
        title: 'End Date',
        dataIndex: 'batch_end_date',
        sorter: true,
      //   onFilter: (value, record) => record.category.indexOf(value) === 0,
      // render: (record) => {
      //   return (
      //     <div> 
      //       {(record.batch_end_date)}
      //     </div>
      //   );
      // },
      sorter: (a, b) => a.batch_end_date .localeCompare(b.batch_end_date),

      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (text, record) => (
          <div className="actionIcons">
            <img src={edit} onClick={() => handleEditModal("Edit Batch",record.batch_id)} />
            <img src={deleteIcon} onClick={() => handleDeleteBatch(record.batch_id)} />
            <img src={TableEyeIcon} onClick={()=> handleViewStudent(record.batch_id)}/>   

          </div>
        ),
      }
  ]

  const [api, contextHolder] = notification.useNotification();

  const [controlBatchName,setControlBatchName] = useState("");
  const [controlBatchId,setControlBatchId] = useState("");

  const[controlDuration,setControlDuration] = useState("");

  const [controlStartTime,setControlStartTime] = useState("")

  useEffect(()=>{
    if (adminBatchCrud) {
      setControlBatchName(adminBatchCrud?.batch_data?.batch_name);
      setControlBatchId(adminBatchCrud?.batch_data?.batch_id);
      setControlDuration(120)
      setControlStartTime("2024-03-04T21:%252019")
      openNotification(adminBatchCrud?.msg,adminBatchCrud?.res_status,adminBatchCrud?.batch_data);
      dispatch(batchCrudStatus(undefined))
    }
  },[adminBatchCrud]);



  

  useEffect(()=>{
    if (adminBatchCrud) {

      if(adminBatchCrud?.res_status == true){


      
          
      const payload = {
          "notification_type":"update_batch_enrollment",
          "project_name":"lms",
          "student_id":allStudentId,
          "batch_id":allBatchId,
          "tenant_id":window.localStorage.getItem("tenant_Id")
      }

      dispatch(updateBatchNotification(payload))

      }
    
    }
  },[adminBatchCrud]);

  useEffect(()=>{
    if (batchUpdateNotification) {



      
          
      const fcmToken = batchUpdateNotification?.data&&Object.values(batchUpdateNotification?.data).map(item => item.fcmtoken);
    
      const usernames = batchUpdateNotification?.data&&Object.values(batchUpdateNotification?.data).map(item => item.username).join(',');
      
  

    let payloadFireBase = {
      "sender_name": window.localStorage.getItem("adminEmail"),
      "receiver_name":usernames,
      "peer_name": 'Batch Enrollment!',//formValues.batch_name
      "peer_chat_type": "batches create",
      "input": "You have been added to "+formValues.batch_name,
      "firebase_tokens": fcmToken, 
      "msg_type": "insert",
      "peer_type": "insert"
    };
    dispatch(insertFireBaseChartDetails(payloadFireBase));

    
    
    }
  },[batchUpdateNotification]);


  const openNotification = (msg,adminBatchCrudStatus,batchData) => {
    // if (msg && adminBatchCrudStatus) {
    //     api.info({
    //         description:
    //         `${msg}`,
  
    //       });
    // }

    if (msg && adminBatchCrudStatus == true ) {
      api.info({
        description:
        `${msg}`,

      });

    }

    if (msg && adminBatchCrudStatus == false ) {
      api.info({
        description:
        `${msg}`,

      });

    }

    
    
  }

  //Zoom integration starts
   const [code,setCode] = useState("")
  const [meetingLink,setMeetingLink] = useState("")

  const [sampleObject,setSampleObject] = useState();
  useEffect(() => {

    const regex = /[?&]code=([^&#]*)/;
    const match = regex.exec(window.location.href);

    if (match && match[1]) {
      const codeValue = decodeURIComponent(match[1].replace(/\+/g, ' '));
      console.log("Code parameter is present in the URL with value:", codeValue);
      setCode(codeValue)
    } else {
      console.log("Code parameter is not present in the URL.");
    }

  }, []); 

  useEffect(()=>{
    if (code !== "") {
          
      let dataObj = {
        zoomCode:code
      }
          const delayDispatch = async (dispatch, dataObj) => {

            await new Promise(resolve => setTimeout(resolve, 1000));
          
             dispatch(createZoomLinkForBatch(dataObj));
          };
          
          // Usage:
          delayDispatch(dispatch, dataObj);
        // dispatch(createZoomLinkForBatch(dataObj))

      
    }
   
  },[code,sampleObject])

  useEffect(()=>{
    if (batchMeeting?.meeting_link) {
      
      setCode("")
      navigate("/allbatches")
      console.log(batchMeeting,"mee")
    }
  },[batchMeeting])
  //Zoom integration  ends

  let [studentId, setStudentId] = useState();
  let [studentDetails,setStudentDetails] = useState(false);


  let handleViewStudent = (Id) =>{
    setStudentId(Id);
    allBatches.filter((item,index)=>{
      if(item.batch_id == Id){
        setStudentRecord(item)
      }
    })
    setStudentDetails(true)
    }

    let handleStudentCancel = () =>{
      setStudentDetails(false)
    
    }



    let handleDeleteModal = (id) =>{
      setStudentId(id)
      setIsDeleteModalVisile(true)
      // setIsModalVisible(false)
      // setModalType("Delete Student")
    }

    let  closeDeleteModal = () =>{
      setIsDeleteModalVisile(false);
    }

    let handleDeleteCancel= ()=>{
      setIsDeleteModalVisile(false)
    } 


    
    const handleDeleteOk = (student_id) => {
      let deletePayload ={
        "operation_type": "delete",
        "op_field_details": {
        "batch_id":isSelectedBatchId
        }
    }

    dispatch(deleteBatch(deletePayload))
    dispatch(retrieveBatches())  
        setIsDeleteModalVisile(false);
  }

  let deleteModalFooter = [
    <div className="editCourse">
      {/* <div>
          <CheckboxComponent/>
      </div> */}
      <div>
        <Button type={"secondary"} default value={'Cancel'} className={'cancel ant-btn-default'} onClick={closeDeleteModal}>
          Cancel</Button>

        </div>
      <div className="alignRight">
        
        <div>
        <Button type={"primary"}  danger value={'Delete'} className={'submit'} onClick={handleDeleteOk}>Delete</Button>
          
        </div>
     
      </div>
    </div>
]


const exportToExcel = (data, fileName) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};


const transformedItems = allBatches?.map((item) => {
 

  return { 
     BATCH_ID: item.batch_id,
     BATCH_NAME:  item.batch_name,
    COURSE_NAME:  item.course_name,
    INSTRUCTOR_NAME: item.instructor_name,
    STUDENT_COUNT:  item.student_count,
   BATCH_START_DATE:  item.batch_start_date,
   BATCH_END_ADTE:  item.batch_end_date
  
  };
});

let handleCategoryModal1 = (type,id) =>{
   
  exportToExcel(transformedItems, 'batches');

  
}
 
  return (
    <>
        {!showForm ?
        <><div className='batchGrid'>

                  <h3>All Batches</h3>
                  {contextHolder}
                  <div>
                  {/* <div><InputSelect defaultValue={"Select"}/></div> */}

                  </div>

                  <div className='searchBatch'>
                      <div>
                          <InputComponent 
                          onChange={(e)=>searchBatchName(e)} className='searchField' placeholder={'Search'}/>
                          <img src={searchIcon} className="searchIconBatches" style={{marginBottom:'3px'}}/>

                      </div>

                      <div className="exportCourseIcons" style={{marginTop:"8px"}}>
                          <img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("")}/>
                          <img src={plus} className="exportIcons" onClick={() => handleModal("Add Batch")} />
                          <img src={upload} className="exportIcons" />
                      </div>
                  </div>
              </div><div className="batchesTable">
                      <TableComponent columns={columns} data={allBatches} sorter={true} loading={!allBatches
                      ? true : false} />
                  </div></>
      : <div>
            <div className='ToogleScreenContainer'>
              <div><h2>{modalType ? modalType : "New Batch"}</h2></div>
              <div className='toogleAlignRight'>
              <Button type={"secondary"} default value={'Cancel'}
               className={'cancel ant-btn-default'} onClick={handleViewTable}>
          Cancel</Button>
          {/* <Form.Item>
          <Button type={"primary"} htmlType='submit'  primary value={'Save'}  onClick={submitBatch} className={'saveBatch'} >Save</Button>
          </Form.Item> */}
              </div>
            </div>
            <div>
              <Form
              autoComplete="off"
              layout="vertical"
              form={form}
              onFinish={submitBatch}
              >
                <div className='searchBatchFilters'>
                <h4 className='borderBatch'><h4>Batch Details</h4></h4>
                <div className='showSelection'>
                 
                <div>
                    <Form.Item
                    label="Batch Name"
                    name="batch_name"
                     rules={[
                       { required: true, message: 'Please enter batch name' }
                       ]}
                    >
                    <InputComponent placeholder={"Batch Name"}
                    name={"batch_name"}
                    onChange={(e)=>handleCategoryChange(e,{name:"batch_name"})}
                    value={formValues?.batch_name}

                    />
                    </Form.Item>
                   
                    </div>
                    
                    <div>
                      <Form.Item
                       label="Course Category"
                       name="course_category_id"
                        rules={[
                          { required: true, message: 'Please select course category' }
                          ]}>
                      <div className='SelectWidth'>
                      <Select name={"course_category_id"} value={formValues.course_category_id} 
                        defaultValue={formValues.course_category_id  ? formValues.course_category_id :"Select"}
                         onChange={(e)=>handleCategoryChange(e,{name:"course_category_id"})}>
                          {categoryDropdown && categoryDropdown.map((item,index)=>{
                          return  <Select.Option value={item.course_category_id}>{item.course_category}</Select.Option>
                          })}
                          placeholder={'Select'}
                        </Select>

                      </div>
                      </Form.Item>
                    </div>
                        
                    <div>
                      <Form.Item
                       label="Course Name"
                       name="course_id"
                        rules={[
                          { required: true, message: 'Please select course name' }
                          ]}>
                      <div className='SelectWidth'>
                        <Select name={"course_id"} value={formValues.course_id} 
                        defaultValue={formValues.course_id  ? formValues.course_id :"Select"}
                         onChange={(e)=>handleCategoryChange(e,{name:"course_id"})}>
                          {courseData && courseData.map((item,index)=>{
                          return  <Select.Option value={item.course_id}>{item.course_name}</Select.Option>
                          })}
                                                    placeholder={'Select'}

                        </Select>
            
                      </div>
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                       label="Instructor Name"
                       name="instructor_id"
                        rules={[
                          { required: true, message: 'Please select instructor name' }
                          ]}>
                      <div className='SelectWidth'>
                       <Select name={"instructor_id"} value={formValues.instructor_id} 
                        defaultValue={formValues.instructor_id  ? formValues.instructor_id :"Select"}
                         onChange={(e)=>handleCategoryChange(e,{name:"instructor_id"})}>
                         
                          {coursRelatedInstructors && coursRelatedInstructors.map((item,index)=>{
                            return <Select.Option value={item.instructor_id}>
                            {item.instructor_name}</Select.Option>  
                            })}
                        </Select>

                      </div>
                      </Form.Item>
                    </div>

                    <div>
                        <Form.Item
                         label="Start Date"
                          name="startDate"
                          rules={[{ required: true, message: 'Please select start date.' }]}>
                          <div className='batchDate'>
                        <DatePicker name='startDate'
                        disabledDate={disabledDate}
                      onChange={(e,dateString)=>handleDateChange(dateString,{name:"start_date"})}

                        //  onChange={(date,dateString)=>setStartDate(dateString)}
                        defaultValue={(startDate)}
                        // value={startDate ? (startDate) : ""}

                        placeholder="Select Date"

                          />

                        </div>
                        </Form.Item>
                    </div>

                    
                    <div>
                        <Form.Item
                         label="End Date"
                           name="endDate"
                          //  rules={[
                          //    { required: true, message: 'Please select end date' }
                          //    ]}
                             
                             >
                        <div className='batchDate'>
                        <DatePicker name={"end_date"}
                        mode='date'
                        disabledDate={(current) => {
                          let customDate = moment().format("YYYY-MM-DD");
                          return current && current < moment(startDate, "YYYY-MM-DD");
                        }}                       
                          defaultValue={(endDate)} 
                     onChange={(e,dateString)=>handleDateChange(dateString,{name:"end_date"})}
                    // onChange={(date,dateString)=>setEndDate(dateString)}
                    placeholder="Select Date"

                      //  value={endDate ?  moment(endDate).format("DD-MM-YYYY") : ""}
                        />

                          </div>
                          </Form.Item>
                    </div>


                    <div className='CourseStart'>
                   <Form.Item
                    label="Start Time"
                     name="startTime"
                      rules={[
                        { required: true, message: 'Please enter start time' }
                        ]}>
                    <TimePicker use12Hours format="hh:mm A" name={"startTime"}
                     onChange={(e)=>handleCategoryChange(e,{name:"startTime"})}
                    // defaultValue={startTime ? startTime : null}
                    // // defaultValue={startTime ? moment(startTime, 'hh:mm A') : null}
                    // value={startTime} 
                    value={(startTime)} 

                    defaultValue={(startTime)} 
                    placeholder="Select Time"

                     />
                      
                    </Form.Item>
                    </div>

                    <div className='CourseStart'>
                    <Form.Item
                    label="End Time"
                     name="endTimes"
                      rules={[
                        { required: true, message: 'Please select end time' }
                        ]}>
                   
                    <TimePicker  format="hh:mm A" name={"endTimes"}
                     onChange={(e)=>handleCategoryChange(e,{name:"endTimes"})}
                    // defaultValue={endTimes ? endTimes : null}
                    value={(endTimes)} 
                    defaultValue={(endTimes)} 
                    placeholder="Select Time"

                     />
                      
                        </Form.Item>
                    </div>

                    <div className='SelectWidth weekDaySelect'>
                    <Form.Item
                    label="Week Days"
                    name="week_days"
                     rules={[
                       { required: true, message: 'Please select week days' }
                       ]}>
                    <div>
                      <Select name={"week_days"} value={formValues.week_days}  mode="multiple"  style={{width: '100%'}}
                      virtual={false}
                          defaultValue={formValues.week_days  ? formValues.week_days :"Select"}
                         onChange={(e)=>handleCategoryChange(e,{name:"week_days"})}>
                           {weekDays && weekDays.map((item,index)=>{
                          return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                          })}

                        </Select>
                        </div>
    </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                      label="Meeting Link"
                      name="meeting_link"
                       rules={[
                        //  { required: true, message: 'Please enter meeting link' }
                         ]}>
                    <InputComponent placeholder={"Meeting Link"}
                    name={"meeting_link"}
                    // onChange={(e)=>handleInputChange(e,{name:"meeting_link"})}
                    onChange={(e)=>setFormValues({...formValues,"meeting_link":e.target.value})}

                    value={formValues.meeting_link}
                    />
               </Form.Item>
                    </div>

                    { isSignedIn && (
        <div className='batchSaveBtn'>

<Button id="authorize_button" type={"primary"} htmlType='Authorize'  primary value={'Authorize'}  onClick={handleAuthClick}  className={'saveAuthorizeBatch'} >Authorize</Button>

        {/* <Form.Item>
</Form.Item> */}
</div>
      )}

{/* <button id="authorize_button" onClick={handleAuthClick} style={{ visibility: 'hidden' }}>
        Authorize
       </button>

       <button id="authorize_button" onClick={handleAuthClick}>Authorize</button>
      <button id="signout_button" onClick={handleSignoutClick} style={{ visibility: 'hidden' }}>
        Sign Out
      </button> */}
     

     


{/*                   

                    {!isSignedIn && (
        <div className='batchSaveBtn'>
        <Form.Item>
<Button type={"primary"} htmlType='Authorize'  primary value={'Authorize'}  onClick={handleAuthClick}  className={'saveAuthorizeBatch'} >Authorize</Button>
</Form.Item>
</div>
    )} */}

                        <div className='batchSaveBtn'>
                        <Form.Item>
          <Button type={"primary"} htmlType='submit'  primary value={'Save'}   className={'saveBatch'} >Save</Button>
          </Form.Item>
                        </div>
                </div>
                </div>
                </Form>
                <div className='BatchResultContainer'>
                        <div className='studentsSelected'>
                                <Card
                                title={`Available Students (${allStudents ? allStudents.length : ""})`}
                                bordered={true}
                                style={{
                                  width: 400,
                                }}
                              >
                                 <div className='studentsList'>
                                <InputComponent placeholder={"Search"} 
                                value={studentSearch} onChange={(e)=>searchStudentName(e)}/>
                            </div>
                                <div className='studentRecord'>

                                    <div className='RecordList'>

                                     {studentsList && studentsList.map((item,index)=>{
                                       return <div className='alignBatchSelection'>

                                    <>                                          
                                       <div>
                                       <CheckboxComponent 
                                       onChange = {()=>changeCheckbox(item.student_id)}
                                       checked={selectedStudents[item.student_id] || false}
                                       />
                                       </div>
                                       <div>{item.student_name}</div>
                                       </>
                                       </div>

                                     }) } 

                                    </div>
                                    </div>

                                    <div className='addStudentBatch'>
                                      <Button type={"primary"} primary value={'Cancel'}
                                        onClick={addStudentList}
                                        className={' ant-btn-primary'}>Add Student</Button> 
                                  </div>
                                </Card>
                        </div>


                        <div className='availableStudents'>
                            <div className='studentsList'>
                                <Card
                                title={`Selected Students (${(addStudents ? addStudents.length : "")})`}
                                bordered={true}
                                style={{
                                  width: 400,
                                }}
                              >
                                <div className='RecordListSelected'>

                                {addStudents && Object.values(addStudents).map((item,index)=>{
                                  return <div className='studentSelectedList'>
                                          <div className=''>{item.fullname}</div>
                                          <div className='alignRight'>
                                            <img onClick={()=>removeFormStudentList(item.student_id)} src={cancelImage} className='cancelimg'/>
                                          </div>
                                        </div>
                                })}
                                </div>
                                <div className='alignRight clearRecords'>
                                 {studentd ?
                                 <div className='clearAllStudents'>
                                  <Button  onClick={clearAllRecords} className='primary'  type='primary'>Clear All</Button>
                                  </div>
                                   : ""} 
                                </div>
                                </Card>
                            </div>
                        </div>
                </div>
            </div>
        </div>}


        {isDeleteModalVisible ?
              <ModalComponent title={"Delete Student"} 
                  footer={deleteModalFooter} content={"Are you sure you want to delete this batch?"}
                   open={isDeleteModalVisible} handleOk={handleDeleteOk} closeModal={closeDeleteModal}
                  showModal={handleDeleteModal} handleCancel={handleDeleteCancel}/>: " "}


        {studentDetails ? 
        <ModalComponent title={"Batch Details"} content = {<ViewBatchesDetails studentRecord = {studentRecord}/>}
        open={handleViewStudent} handleCancel={handleStudentCancel}/>
    : ""}
        </>
  )
}

export default Allbatches