import { ADMIN_COURSE_FEE_CREATION_SUCCESS,ADMIN_COURSE_FEE_CREATION_FAIL,
    GET_ADMIN_COURSE_FEEDATA,ADMIN_COURSE_FEE_DELETE ,COURSE_FEE_CRUD_STATUS,
    GET_PRICE_BASED_ON_COURSE,
    GET_COURSES_BASED_ON_BATCH,GET_BATCH_BASED_ON_STUDENT_ID} from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';

export const getAdminCourseCreationSuccess = (data) =>{
    return {
        type: ADMIN_COURSE_FEE_CREATION_SUCCESS,
        payload: data
    }
}

export const getAdminCourseCreationFail = (data) =>{
    return {
        type: ADMIN_COURSE_FEE_CREATION_FAIL,
        payload: data
    }
}

export const getAllAdminCourseFeeData = (data) =>{
    console.log(data,"not coming")
     return {
        type:GET_ADMIN_COURSE_FEEDATA,
        payload: data
     }
}


export const deleteAdminCourseFee = (data)=>{
    return {
        type: ADMIN_COURSE_FEE_DELETE,
        payload:data
    }
}

export const courseFeeCrudStatus = (data)=>{
    return {
        type:COURSE_FEE_CRUD_STATUS,
        payload:data
    }
}

export const getPriceForCourse = (data)=>{
    return{
        type:GET_PRICE_BASED_ON_COURSE,
        payload:data
    }
}

export const getCoursesBasedOnBatch = (data)=>{
        return {
            type: GET_COURSES_BASED_ON_BATCH,
            payload: data
        }
}

export const retrievePriceBasedOnCourse = (data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_fee",data
        ).then((res)=>{
            if (res.data?.res_status == true) {
               dispatch(getPriceForCourse(res?.data))
            }
            if (res.data?.res_status == false) {
                dispatch(getPriceForCourse(res?.data))

            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}


export const retrieveAllAdminCourseFee = ()=>{
    let payload = {
        "operation_type":"view_course_fee",
        "tenant_id": parseInt(window.localStorage.getItem("tenant_Id"))
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_fee",payload
        ).then((res)=>{
            console.log(res?.data,"people")
            if (res.data?.res_status == true) {
                dispatch(getAllAdminCourseFeeData((res?.data)));
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}

export const createAdminCourseFee = (data) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_fee",data
        ).then((res)=>{
            if (res.data?.res_status == true) {
                // dispatch(getAdminCourseCreationSuccess(res.data?.res_status));
               dispatch(courseFeeCrudStatus(res?.data))
               dispatch(retrieveAllAdminCourseFee())
            }
            if (res.data?.res_status == false) {
                // dispatch(getAdminCourseCreationSuccess(res.data?.res_status));
                dispatch(courseFeeCrudStatus(res?.data))

            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}




export const AdminCourseFeeDelete = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_fee",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(deleteAdminCourseFee(res.data?.res_status));
                dispatch(retrieveAllAdminCourseFee())
                dispatch(courseFeeCrudStatus(res?.data))

            }
           
        }).catch(err=>{
            console.log(err)
        })
}
}

export const updateAdminCourseFee = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_fee",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(retrieveAllAdminCourseFee());
                // dispatch(getAdminCourseCreationSuccess(res.data));
                dispatch(courseFeeCrudStatus(res?.data))

            }
           
        }).catch(err=>{
            console.log(err)
        })
}
}

export const  retrieveCoursesBasedOnBatchId = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_fee",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getCoursesBasedOnBatch(res?.data));

            }
           
        }).catch(err=>{
            console.log(err)
        })
}
}


export const basedOnStudentIdGetBatchDetailsction = (data)=>{
    console.log(data,"not coming")

    return {
        type: GET_BATCH_BASED_ON_STUDENT_ID,
        payload: data
    }
}

export const  basedOnStudentIdGetBatchDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_fee",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(basedOnStudentIdGetBatchDetailsction(res?.data));

            }
            if (res.data?.res_status == false) {
                dispatch(basedOnStudentIdGetBatchDetailsction([]));

            }
        }).catch(err=>{
            console.log(err)
        })
}
}