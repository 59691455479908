import React ,{useEffect} from 'react';
import { InputComponent, TextAreaComponent } from '../../commonComponents';

import { Button, Form, TimePicker } from 'antd';
import ReactDatePicker from 'react-datepicker';
const InstructorContactUsAdd = (props) => {


    
   

    return (
      
        <div className='instructorCancelClassContainer'>
         
            <div className='cancelClassContainer'>
        <Form
         initialValues={props.cancelClassDetails}

        form={props.form2}
        onFinish={props?.onFinish}
        autoComplete="off"
        layout="vertical">

<div className="studentNames">
                <div className="studentInput">
                  <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[
                    { required: true, 
                      message: 'Please enter first name'
                     }
                    ]}>
                    <div className="InstructorInput">
                      <InputComponent placeholder={"First Name"}
                       name={"first_name"} 
                       value={props.first_name}
                        onChange={(e)=>props.handleClassChange(e,{name:"first_name"})}/>
                    </div>
                    </Form.Item>
                </div>
                <div className="studentInput">
                  <Form.Item
                  label="Last Name"
                  name={"last_name"}
                  rules={[
                    { required: true, message: 'Please enter last name' }
                    ]}>
                    <div className="InstructorInput">
                      <InputComponent placeholder={"Last Name"}
                       name={"last_name"} value={props.last_name}
                       onChange={(e)=>props.handleClassChange(e,{name:"last_name"})}/></div>
                </Form.Item>
                    </div>
            </div>


            <div className="studentNames">
                <div className="studentInput">
                  <Form.Item
                  label="Mobile Number"
                  name="phone"
                  rules={[
                    {
                        required: true,
                        message: 'Phone number is required only numbers allowed',
                        pattern: new RegExp(/^[0-9]+$/),
                       
                      },
                      {
                       max: 10,
                       min:10,
                       message: "10 digit mobile number is required",
                     },
                    ]}>
                    <div className="InstructorInput">
                      <InputComponent placeholder={"Mobile Number"}
                       name={"phone"} 
                       value={props.phone}
                        onChange={(e)=>props.handleClassChange(e,{name:"phone"})}/>
                    </div>
                    </Form.Item>
                </div>
                <div className="studentInput">
                  <Form.Item
                  label="Email"
                  name={"email"}
                  rules={[
                    { required: true, message: 'Please enter email' }
                    ]}>
                    <div className="InstructorInput">
                      <InputComponent placeholder={"Email"}
                       name={"email"} value={props.email}
                       onChange={(e)=>props.handleClassChange(e,{name:"email"})}/></div>
                </Form.Item>
                    </div>
            </div>
                
            <div>



            <Form.Item
             label="Message"
             name="message"
              rules={[
                { required: true, message: 'Please enter message' }
                ]}>
            <TextAreaComponent name={"message"} 

            
                       value={props.cancelClassDetails?.message == ''  ? props.cancelClassDetails?.message : ""}
                       onChange={(e)=>props.handleClassChange(e,{name:"message"})}/>
            </Form.Item>

            </div>
            
            
            <div className='submitCancelClass'>
            <Form.Item>
            <Button htmlType='submit' primary type='primary'>Submit</Button>
            </Form.Item>
            </div>
        </Form>
            
            </div>
            
        </div>
    );
}

export default InstructorContactUsAdd;
