export const CREATE_INSTRUCTOR_CANCEL_CLASS = "CREATE_INSTRUCTOR_CANCEL_CLASS";


export const GET_ALL_INSTRUCTOR_CANCEL_CLASS = "GET_ALL_INSTRUCTOR_CANCEL_CLASS";

export const CHANGE_CANCEL_CLASS_STATUS = "CHANGE_CANCEL_CLASS_STATUS"; 


export const CANCEL_CALSS_STUDENT_NOTIFICATION = "CANCEL_CALSS_STUDENT_NOTIFICATION"; 

export const CANCEL_CLASS_REJECTED = "CANCEL_CLASS_REJECTED"; 

