import React, { useEffect, useState } from 'react'

import { useDispatch,useSelector } from 'react-redux';
import { chessSample,carouselLeft,carouselRight,static1,static2,static3,Group } from '../../../images';

import { useNavigate } from 'react-router-dom';
import { Card,Spin } from 'antd';

import './instructorDashboardCarousel.css';
import { retrieveRegCourses } from '../../../redux/studentRegisteredCourses/action';
import { retrieveCurrentInstructorBatches } from '../../../redux/instructorDashboard/action';
import viewSingleBatch from '../viewSingleBatch/viewSingleBatch';
let InstructorDashboardCarousel=() =>{

    let dispatch = useDispatch();

    let navigate = useNavigate();
    let regCourses = useSelector((state)=>state.instructorDashboardReducer?.instructorCurrentBatches);

    let [liveCoursesData,setLiveCoursesData] = useState([])
    useEffect(()=>{
        let payload = 
        {
            "operation_type": "view_current_batch",
            "instructor_id":window.localStorage.getItem("instructor_id")
        }
       dispatch(retrieveCurrentInstructorBatches(payload))
    },[])

    useEffect(()=>{
        if (regCourses) {
            console.log(regCourses.data,"instructorDashboard");
            setLiveCoursesData(regCourses?.data)
        }
    },[regCourses])
        
          const [currentSlide, setCurrentSlide] = useState(0);
          const slidesPerPage = 4;
        
          const nextSlide = () => {
            setCurrentSlide((prevSlide) => prevSlide + slidesPerPage);
          };

          useEffect(()=>{
            if(currentSlide-1 >= liveCoursesData.length){
                setCurrentSlide(0)
            }
      },[currentSlide])

      useEffect(()=>{
        if ( currentSlide == liveCoursesData.length) {
            setCurrentSlide(0)
        }
      },[currentSlide])
        
          const prevSlide = () => {
            setCurrentSlide((prevSlide) => Math.max(0, prevSlide - slidesPerPage));
          };

    let viewSingleBatch = (data)=>{
        console.log(data,"batch_id")
         navigate(`/instructorViewBatch/${data}`);
    }
        
  return (
    <div>
            <div className='carouselText'>
                <div>
                <h3>CURRENT BATCHES</h3>
                </div>

                <div className='carouselNavigation alignRight'>
                <div onClick={prevSlide} className='carouselLeft'><img src={carouselLeft}/></div>
                <div onClick={nextSlide} className='courselRight'><img src={carouselRight}/></div>
                </div>
            </div>
            {liveCoursesData ? 
            <div className='coursesCarousel'>
            {liveCoursesData.slice(currentSlide, currentSlide + slidesPerPage).map((key, index) =>{
                return(
                    <div className='carouselImagesInstructorDashboard' onClick={()=>viewSingleBatch(key.batch_id)}>
                <Card
                hoverable
                style={{height:"100px",width:"220px"}}
                cover={<img alt="example" src={key.new_course_thumbnail} className='cardImageLive' />}
                className='dashboardCard'
            >
                <div className='cardBody'>
                    <div className='bodyContent'>
                        <div style={{fontSize:'15px'}}>
                            {key.batch_name}
                        </div>
                        <div className='courseTimings' style={{fontSize:"15px"}}>
                            Timings {key.new_week_days} : {key.course_time} 
                        </div>
                        {/* <div className='courseDetailBtn' > */}

                       

                        <div style={{backgroundColor:'#ffffff', borderRadius: '8px',marginLeft:'10px',marginRight:'10px'
                        ,marginTop:'5px',marginBottom:'10px',padding:'0px',flexDirection:'column',display: 'flex'
   }} >



                              
                          

                                <div style={{ display: 'flex', alignItems: 'center', marginLeft:'10px',padding:'0px'}}>
                                <img src={Group}/>
                                <p className='courseDetailText'>{key.student_count} students</p>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center' ,marginLeft:'10px',flexDirection:'row'}}>
                                <img src={Group}/>
                                <p className='courseDetailText'>90 days</p>
                                </div>

                           
                           
                        </div>
                    </div>
                </div>
                </Card>
                </div>
                )
            })}
                

            </div>
            :<div className='alignSpinnerLiveCourses'>
            <Spin/>
            </div>}
    </div>
  )
}

export default InstructorDashboardCarousel;