import React from 'react';
import { Switch } from 'antd';

import './switch.css';

const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};
const SwitchComponent = () => <Switch  onChange={onChange} />; //defaultChecked
export default SwitchComponent;
