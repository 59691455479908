export const GET_INSTRUCTORS_AND_COURSES = "GET_INSTRUCTORS_AND_COURSES";

export const SET_BATCHES = "SET_BATCHES";

export const GET_BATCHES = "GET_BATCHES";

export const BATCH_CRUD_STATUS = "BATCH_CRUD_STATUS";

export const GET_INSTRUCTORS_FOR_COURSE = "GET_INSTRUCTORS_FOR_COURSE";

export const CREATE_ZOOM_MEETING_FOR_BATCH = "CREATE_ZOOM_MEETING_FOR_BATCH";

export const BATCH_CREATE_NOTIFICATION = "BATCH_CREATE_NOTIFICATION";

export const BATCH_UPDATE_NOTIFICATION = "BATCH_UPDATE_NOTIFICATION";