import React from "react";

import moment from "moment";

let UpcomingCoursersDetails = (props) =>{
              
    console.log(props.studentRecord,'studentRecord')
    return (
        <div className="retriveStudent">
                <div>
                    <p>Batch ID  : {props?.studentRecord.batch_id}</p>

                    <p>Course Name   : {props?.studentRecord.course_name}</p>
                    <p>Course Category    : {props?.studentRecord.course_category}</p>
                    <p>Instructor Name        : {props?.studentRecord.instructor_name}</p>
                    <p>Batch Duration        : {props?. studentRecord.batch_duration}</p>
                    <p>Course Time: {props?.studentRecord.course_time}</p>
                    <p>Week Day          : {props?. studentRecord.new_week_days}</p>
                  
                   
                </div>
        </div>
    )
}

export default UpcomingCoursersDetails