import React from "react";

import './studentDetail.css'
import moment from "moment";

let InStructoreDetail = (props) =>{
              
    console.log(props.studentRecord)
    return (
        <div className="retriveStudent">
                <div>
                    <p>Instructor ID  : {props?.studentRecord.instructor_id}</p>

                    <p>First Name   : {props?.studentRecord.instructor_first_name}</p>
                    <p>Last Name    : {props?.studentRecord.instructor_last_name}</p>
                    <p>Email        : {props?.studentRecord.email}</p>
                    <p>Phone        : {props?. studentRecord.phone_no}</p>
                    <p>Age          : {props?. studentRecord.calculated_age}</p>
                    <p>Gender       : {props?.studentRecord.gender}</p>
                    <p>Course Name  : {props?.studentRecord.course_name}</p>
                    <p>DOB          : { (moment(props?.studentRecord.date_of_birth, "DD-MM-YYYY")).format("DD-MM-YYYY")}</p>

                    <p>Address      : {props?.studentRecord.address}</p>
                    <p>Status          : {props?.studentRecord.is_active == 'true'
                                         ? 'Active' : props?.studentRecord.is_active == 'false' ? 'In Active'  :  'Pending'}</p>

                </div>
        </div>
    )
}

export default InStructoreDetail