import React ,{useEffect} from 'react';
import { InputComponent, TextAreaComponent } from '../../commonComponents';

import { Button, Form, TimePicker } from 'antd';
import ReactDatePicker from 'react-datepicker';
const UpdatePrivacyPolicyDetails = (props) => {


    
   

    return (
      
        <div className='instructorCancelClassContainer'>
         
            <div className='cancelClassContainer'>
        <Form
         initialValues={props.cancelClassDetails}

        form={props.form}
        onFinish={props?.onFinish}
        autoComplete="off"
        layout="vertical">


<div>



<Form.Item
 label="Terms And Condition"
 name="terms_condition"
  rules={[
    { required: true, message: 'Please enter terms and condition' }
    ]}>
<TextAreaComponent name={"terms_condition"} 


           value={props.cancelClassDetails?.terms_condition == ''  ? props.cancelClassDetails?.terms_condition : ""}
           onChange={(e)=>props.handleClassChange(e,{name:"terms_condition"})}     style={{ height: "500px" }}
           />
</Form.Item>

</div>

                
            <div>



            <Form.Item
             label="Privacy Policy"
             name="privacy_policy"
              rules={[
                { required: true, message: 'Please enter privacy policy' }
                ]}>
            <TextAreaComponent name={"privacy_policy"} 

            
                       value={props.cancelClassDetails?.privacy_policy == ''  ? props.cancelClassDetails?.privacy_policy : ""}
                       onChange={(e)=>props.handleClassChange(e,{name:"privacy_policy"})}/>
            </Form.Item>

            </div>
            
            
            <div className='submitCancelClass'>
            <Form.Item>
            <Button htmlType='submit' primary type='primary'>Submit</Button>
            </Form.Item>
            </div>
        </Form>
            
            </div>
            
        </div>
    );
}

export default UpdatePrivacyPolicyDetails;
