import {GET_ADMIN_STUDENTS_REPORTS} from "./actionTypes";

import axios from "axios";
import apiConfig from '../../../apiConfig/api';

export const getAdminStudentReports = (data) =>{
    return {
        type: GET_ADMIN_STUDENTS_REPORTS,
        payload:data
    }
}

export const retrieveStudentReports = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/reports",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getAdminStudentReports(res?.data));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}