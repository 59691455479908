import { GET_LIVE_ONGOING_COURSES} from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const liveStudentCoursesReducer = (state = initialStates,action)=>{
    switch (action.type) {
        case  GET_LIVE_ONGOING_COURSES:
            return {
                liveCourses: action.payload,
            }
        
        default:
            return state;
    }
}

export default  liveStudentCoursesReducer;