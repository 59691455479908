import { GET_INSTRUCTOR_SCHEDULES } from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';

export const getInstructorSchedules = (data)=>{
    return{
        type: GET_INSTRUCTOR_SCHEDULES,
        payload: data
    }
}

export const retireveInstructorSchedules = (data)=>{
    let payload =
    {
        "operation_type":"view_schedules",
         "instructor_id": window.localStorage.getItem("instructor_id")
      }
   
        return(dispatch)=>{
            axios.post(apiConfig.baseUrl+"e_lms/v1/instructor_page",payload
            ).then((res)=>{
                if (res.data?.res_status == true) {
                    dispatch(getInstructorSchedules(res.data));    
                }
                
            }).catch(err=>{
                console.log(err)
            })
    }
}