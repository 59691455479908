import React from 'react';

import { Divider, Radio, Table } from 'antd';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { useState } from 'react';
import './tableComponent.css';

const TableComponent = ({pageSize,columns,data,rowSelection,selectedRowKeys,defaultSortOrder,
  onChange,onSelect,loading,sortOrder,rowKey,pageOptions,expandable,onRow,pagination}) => {

    const [perPage,setPerPage] = useState("10")
    const handlePageSizeChange = (current, size) => {
      // Here you can perform any actions when the page size changes
      console.log("Page size changed to:", size);
      setPerPage(size)
    };

    
return(
    <>
  <Table rowSelection={rowSelection}  rowKey={rowKey} expandable={expandable} onRow={onRow}
  selectedRowKeys={selectedRowKeys} sortOrder={sortOrder} defaultSortOrder= {defaultSortOrder}
    pagination= { {pageSizeOptions:['4', '10', '20', '50'], showSizeChanger: true, pageSize: pageSize ? pageSize : perPage,
      onShowSizeChange: handlePageSizeChange}}
   columns={columns} dataSource={data} bordered scroll={{ x: 400 }}  loading={loading}
    // pagination= {pagination} 
   />
  </>
)
}

export default TableComponent;