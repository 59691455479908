export const ADMIN_LOGIN = "ADMIN_LOGIN";

export const ADMIN_MENUS = "ADMIN_MENUS";

export const SET_ADMIN_LOGIN_ROLE = "SET_ADMIN_LOGIN_ROLE";

export const SET_CURRENT_ADMIN_GROUPS = "SET_CURRENT_ADMIN_GROUPS";

export const GET_CURRENT_ADMIN_GROUPINFORMATION = "GET_CURRENT_ADMIN_GROUPINFORMATION";

export const ADMIN_INSERT_GROUP_CHART = "ADMIN_INSERT_GROUP_CHART"

export const ADMIN_INSERT_GROUP_CHART_FIRE_BASE = "ADMIN_INSERT_GROUP_CHART_FIRE_BASE";

export const RETRIEVE_GROUP_CHART_DETAILS = "RETRIEVE_GROUP_CHART_DETAILS";
