import { GET_SUPER_ADMIN_TOKEN,GET_SUPER_ADMIN_LOGIN_STATUS,SUPER_ADMIN_LOGIN, GET_SUPER_ADMIN_MENUS } from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const superAdminReducer = (state = [],action)=>{
    switch (action.type) {
        case  GET_SUPER_ADMIN_TOKEN:
            return {
                superAdminToken: action.payload,
            }
        
            case GET_SUPER_ADMIN_LOGIN_STATUS:
                return{
                    superAdminloginStatus: action.payload
                }
            
        case GET_SUPER_ADMIN_MENUS:
            return {
                superAdminMenus: action.payload
            }
        default:
            return state;
    }
}

export  default superAdminReducer;