import React, { useEffect, useState } from "react";

import { chessSample,adminStudent,
    adminInstructor,
    adminScheduleEvent,
    adminNewSubmissions, 
    maskgroup,
    plus} from "../../images";

import './dashboard.css';
import { useDispatch, useSelector } from "react-redux";
import { createAdminTodoRecords, deleteAdminTodo, retrieveAdminCourseProgress, retrieveAdminNumberRecords, retrieveAdminTodos,retrieveAdminClassDetails } from "../../redux/adminDashboard/action";
import {  Checkbox, DatePicker, Form, Progress, notification } from "antd";

import ReactDatePicker from "react-datepicker";
import { Calendar, momentLocalizer } from 'react-big-calendar';
// import { useHistory } from 'react-router-dom'; // Import useHistory from React Router
import { useNavigate } from 'react-router-dom';

import moment from "moment";
import { InputSelect, ModalComponent } from "../../commonComponents";
import {Chart, ArcElement, Tooltip, Legend, Title} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import AdminTodo from "./adminTodo/adminTodo";
import { type } from "@testing-library/user-event/dist/type";
import dayjs from "dayjs";

import {  insertFirebaseTokenForAdmin } from '../../redux/admin/action';
import { createRecordByFirebaseTokenStudent } from "../../redux/studentLogin/action";


let Dashboard = () =>{
  
    const localizer = momentLocalizer(moment);
    Chart.register(ArcElement);

    let dispatch = useDispatch();


    let [studentInstructorDetails,setStudentInstructorDetails] = useState({})

    let [adminCourseProgress,setAdminCourseProgress] = useState("");
    let adminNumberRecords = useSelector((state)=>state.AdminDashboardReducer?.adminNumberRecords);

    let adminCourseProgressdata = useSelector((state)=>state.AdminDashboardReducer?.adminCourseProgress)
    useEffect(()=>{
        let payload ={
            "operation_type":"view_admin_dashboard",
            "tenant_id": window.localStorage.getItem("tenant_Id")
        }
        dispatch(retrieveAdminNumberRecords(payload))

        let progressPayload ={
            "operation_type": "view_admin_course_progress",
            "tenant_id": window.localStorage.getItem("tenant_Id")
        }

        dispatch(retrieveAdminCourseProgress(progressPayload))



        let data ={
          "username": window.localStorage.getItem("adminEmail"),
          "fcmtype": "web",
          "fcmtoken": window.localStorage.getItem("fcmToken"),
          "tenant_id": window.localStorage.getItem("tenant_Id"),
          "project_name": "lms",
          "full_name":window.localStorage.getItem("adminFullName")
      }
      dispatch(insertFirebaseTokenForAdmin(data))
      dispatch(createRecordByFirebaseTokenStudent(data))


    },[]);



    // useEffect(() => {
    //   // Check if the browser supports notifications
    //   if (!('Notification' in window)) {
    //     console.log('This browser does not support desktop notification');
    //   } else {
    //     // Check if permission is granted for notifications
    //     if (Notification.permission === 'granted') {
    //       // setNotificationPermission('granted');
    //       console.log('granted');

    //     } else if (Notification.permission !== 'denied') {
    //       // If permission is not denied, request permission
    //       Notification.requestPermission().then(permission => {
    //         if (permission === 'granted') {
    //           // setNotificationPermission('granted');
    //           console.log('granted');

    //         } else {
    //           // setNotificationPermission('denied');
    //           console.log('denied');

    //         }
    //       });
    //     } else {
    //       console.log('denied');

    //       // setNotificationPermission('denied');
    //     }
    //   }
    // }, []);


    useEffect(()=>{
        if (adminNumberRecords) {
            setStudentInstructorDetails(adminNumberRecords?.data);
        }
    },[adminNumberRecords])

    useEffect(()=>{
            if (adminCourseProgressdata) {
                setAdminCourseProgress(adminCourseProgressdata)
                console.log(adminCourseProgress,"progress")
            }
    },[adminCourseProgressdata]);


  //   const highlightClass = (date) => {
  //     const dateString = date.toISOString().split('T')[0]; // Convert date to YYYY-MM-DD
  //     return highlightDates.includes(dateString) ? 'highlighted-date' : undefined;
  // };

    function renderExtraFooter() {
        return (
          <div style={{ textAlign: 'center', height:"450px" ,overflow:"auto"}}>
            <Calendar
             localizer={localizer}
            views={['day']} // Set the views prop to only include the day view
            defaultView='day' // Set the default view to day view
            selectable={true} // Optional: Allow selecting dates
            events={[]} // Optional: Provide events data if needed
          />      
          </div>
        );
      }

      const data = {
        datasets: [
          {
            // data: [3, 10, 10, 10, 10, 10, 10, 10, 10, 10],
            data: [studentInstructorDetails?.active_students,studentInstructorDetails?.inactive_students,studentInstructorDetails?.pending_students],

            backgroundColor: [
              "#c6fcc5",
              "#e1e1e1",
              "#f59494",
            //   "#999933",
            //   "#666699",
            //   "#CC9933",
            //   "#006666",
            //   "#3399FF",
            //   "#993300",
            //   "#CCCC99",
            //   "#666666",
            //   "#FFFFFF",
            //   "#FFFFFF",
            //   "#FFFFFF"
            ],
            display: true,
            borderColor: "#D1D6DC"
          }
        ]
      };

      console.log(window.localStorage.getItem("fcmToken"),'fcmTokenDAMODAR');


      const [api, contextHolder] = notification.useNotification();

      const [todoModalVisible,setTodoModalVisible] = useState(false);

      const [roleType,setRoleType] = useState("admin");
      const [todoDate,setTodoDate] = useState("");

      const [checkedStatus,setCheckedStatus] = useState([])

      const [todoDescription,setTodoDescription] = useState("");
      const [form] = Form.useForm();

      let allAdminTodos = useSelector((state)=>state?.AdminDashboardReducer?.adminTodoList);

      let crudStatus = useSelector((state)=>state?.AdminDashboardReducer?.todoCrudStatus);

      const [adminTodoList,setAdminTodoList] = useState("")

      useEffect(()=>{
        dispatch(retrieveAdminTodos())
      },[])

      useEffect(()=>{
        if (allAdminTodos) {
            setAdminTodoList(allAdminTodos?.data)
        }
      },[allAdminTodos]);

      useEffect(()=>{
        if (crudStatus) {
            if (crudStatus.res_status == true) {
                openNotification(crudStatus?.msg,crudStatus)
                setTodoModalVisible(false)
            }
        }
      },[crudStatus])
      const handleOnchangeTodo = (e,type)=>{
        if (type.name == "todoDate") {
            setTodoDate(e)
        }
        
        if (type.name == "todoDescription") {
            setTodoDescription(e?.target?.value);
            form.setFieldsValue({[type.name]:e?.target?.value})
        }
      }
      const addAdminTodo = (data) =>{
        setTodoModalVisible(true)
      }

      const closeModal = ()=>{
        setTodoModalVisible(false)
        setTodoDate("")
        setTodoDescription("")
        form.setFieldsValue({["todoDate"]:""});
        form.setFieldsValue({["todoDescription"]:""})
        setScheduleModal(false)

      }

      const onFinish = (values)=>{
        //action
        let payload ={
            "operation_type":"insert",
            "op_field_details":{
                "role_type": roleType,
                "date": todoDate,
                "description":todoDescription,
                "tenant_id": window.localStorage.getItem("tenant_Id")
            }
        }
        dispatch(createAdminTodoRecords(payload))
        setTodoDate("")
        setTodoDescription("")
        form.setFieldsValue({["todoDate"]:""});
        form.setFieldsValue({["todoDescription"]:""})
      }

      const deleteTodo = (id)=>{
        let payload = {
            "operation_type":"delete",
            "op_field_details":{
                "to_do_id":[id]
            }
        }
        dispatch(deleteAdminTodo(payload))
        dispatch(retrieveAdminTodos());
        setCheckedStatus(id)
      }

      const openNotification = (msg,response)=>{
        if (msg && response?.res_status== true) {
          api.info({
              description:
              `${msg}`,
            });
      }
    }







    let[studentEvents,setStudentEvents] = useState();
    let [schedulesDates,setSchedulesDates] = useState();
    let [singleEvent,setSingleEvent] = useState("");
    let [scheduleModal,setScheduleModal] = useState(false);
    let [calendarData,setCalendarData] = useState();


    let dateTableData = useSelector((state)=>state.AdminDashboardReducer?.retrieveAdminClass);

useEffect(()=>{

const currentDate = new Date();
const day1 = currentDate.getDate();
const month1 = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
const year1 = currentDate.getFullYear();

// Format day and month to have leading zeroes if needed
const formattedDay = day1 < 10 ? '0' + day1 : day1;
const formattedMonth = month1 < 10 ? '0' + month1 : month1;

// Construct the date string in the desired format
const formattedDate = `${formattedDay}-${formattedMonth}-${year1}`;

const dateString = formattedDate;
const [day, month, year] = dateString.split('-');
const dateObject = new Date(`${year}-${month}-${day}`);

setDisplayedDate(dateObject);

let payload={


    "operation_type":"view_admin_dashboard_schedules",
    "tenant_id":window.localStorage.getItem("tenant_Id"),
"date":formattedDate
}
dispatch(retrieveAdminClassDetails(payload))
},[])

    const handleDateChange = (date) => {
        const newDateFormat = formatDate(date);

        const dateString = newDateFormat;
        const [day, month, year] = dateString.split('-');
        const dateObject = new Date(`${year}-${month}-${day}`);

        setDisplayedDate(dateObject);

      
        // Generate new date format and update the localizer
      
        // Update the localizer state
        // setLocalizer(newLocalizer);
      
        // Dispatch API call with the new date format
        let payload = {
            "operation_type":"view_admin_dashboard_schedules",
            "tenant_id": window.localStorage.getItem("tenant_Id"),
          "date": newDateFormat
        };
        dispatch(retrieveAdminClassDetails(payload));
      
        console.log("Selected Date:", newDateFormat); // Output the selected date to the console
      };


      const [displayedDate, setDisplayedDate] = useState(new Date()); // Initialize with current date

      const formatDate = (date) => {
        return date.toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-');
      };


      useEffect(()=>{
        if (dateTableData) {
          setStudentEvents();
      
          setSchedulesDates(dateTableData?.data)
      
      
         }
      },[dateTableData])
      
      useEffect(()=>{
        if (schedulesDates) {
          // setSchedulesDates(dateTableData?.data)
      
      
      
          let eventsData = []
          schedulesDates && schedulesDates?.map((item,index)=>{
           
            eventsData.push({ start: (item.date)+" "+item.course_start_time, end: item.date+" "+item.course_end_time ,event:item.course_name,batch_name:item.batch_name,meeting_link: item.meeting_link})
          })
          setStudentEvents(eventsData) 
         }
      },[schedulesDates])
      
      const events = [];
      
      
      useEffect(()=>{
        if (studentEvents) {
      
          studentEvents && studentEvents.forEach(dateObj => {
            const { start: startDateString, end: endDateString ,event} = dateObj;
            
            // Convert date strings to Date objects
            const startDate = parseDateString(startDateString);
            const endDate = parseDateString(endDateString);
          
            // Create a new event object
            const newEvent = {
              id: events.length + 1, 
              title: event,
              start: startDate,
              end: endDate,
              batch_name: dateObj?.batch_name,
              meeting_link: dateObj?.meeting_link
            };
            // Add the new event to the events array
            events.push(newEvent);
          });
      
          setCalendarData(events);
      
         }
      },[studentEvents])
      
      
       
      
        function parseDateString(dateString) {
          const dateParts = dateString.split(' ');
          const dateComponents = dateParts[0].split('-');
          const timeComponents = dateParts[1].split(':');
          const year = parseInt(dateComponents[2], 10);
          const month = parseInt(dateComponents[1], 10) - 1;
          const day = parseInt(dateComponents[0], 10);
          const hours = parseInt(timeComponents[0], 10) + (dateParts[2] === 'PM' ? 12 : 0);
          const minutes = parseInt(timeComponents[1], 10);
        
          return new Date(year, month, day, hours, minutes);
        }

     
 // Preprocess calendarData to filter out one of the events if there are three events at the same time
//  const processedCalendarData = calendarData.reduce((acc, event) => {
//   const eventDateTime = new Date(event.start).getTime();
//   acc[eventDateTime] = acc[eventDateTime] ? [...acc[eventDateTime], event] : [event];
//   return acc;
// }, {});

// const filteredCalendarData = Object.values(processedCalendarData).flatMap((events) => {
//   if (events.length > 1) {
//     // If there are more than 2 events at the same time, keep only the first two events
//     return events.slice(0, 2);
//   }
//   return events;
// });


const [notificationPermission, setNotificationPermission] = useState('');

useEffect(() => {
  // Check if the browser supports notifications
  if (!('Notification' in window)) {
    console.log('This browser does not support desktop notification');
  } else {
    // Check if permission is granted for notifications
    if (Notification.permission === 'granted') {
      setNotificationPermission('granted');
    } else if (Notification.permission !== 'denied') {
      // If permission is not denied, request permission
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          setNotificationPermission('granted');
        } else {
          setNotificationPermission('denied');
        }
      });
    } else {
      setNotificationPermission('denied');
    }
  }
}, []);

// const history = useHistory(); 


const navigate = useNavigate();

function openNotificationSettings() {
  // window.open('chrome://settings/content/notifications');

  const url = 'cedge://settings/content/notifications';
    window.open(url, '_blank');
}


const handleRequestPermission = () => {
  Notification.requestPermission().then(permission => {
    if (permission === 'granted') {
      setNotificationPermission('granted');
    } else {

      openNotificationSettings()
      setNotificationPermission('denied');
      // history.push('/site-information');



      // Use navigate instead of history
      // navigate('/site-information', { replace: true });

    }

    console.log(permission,'DAMODAR')
  });


};




let openModalPopUp = (event) =>{
  setSingleEvent(event)
  setScheduleModal(true)
}



    return (   
      
   
      

    <div className="adminDashoardContainer">
            {contextHolder}
            <div className="totalNumberOfRecords">
                <div className="adminNumberOfstudent">
                    <div className="adminDahoardIcon">
                    <img src={adminStudent} />
                    </div>

                    <div>
                        <b style={{fontSize:"20px"}}>{studentInstructorDetails.total_students}</b>
                        <div><p >Students</p></div>
                    </div>
                </div>

                <div className="adminNumberOfstudent">
                    <div>
                    <img src={adminInstructor}/>

                    </div>
                    <div>
                        <b  style={{fontSize:"20px"}}>{studentInstructorDetails.total_instructors}</b>
                        <div>
                            <p>Instructors</p>
                        </div>
                    </div>
                </div>

                <div className="adminNumberOfstudent">
                    <div>
                    <img src={adminScheduleEvent}/>

                    </div>
                    <div>
                        <b style={{fontSize:"20px"}}>{studentInstructorDetails.scheduled_batches}</b>
                        <div>
                            <p>Scheduled Events</p>
                        </div>
                    </div>
                </div>

                {/* <div className="adminNumberOfstudent">
                <img src={adminNewSubmissions}/>
                </div> */}
            </div>

            <div className="adminRowSecond">
            <div className="adminDashboardSecondRow">
                <div className="adminSingleCard">
                  <h4>{studentInstructorDetails.total_students} Users</h4>
                    {/* <p>{studentInstructorDetails?.active_students+studentInstructorDetails?.inactive_students} Users</p> */}
                    <hr></hr>
                 <div className="chartLearnersCount">
                <div className="bb1">
                <span className="dotActiveAdmin"></span>
                    <p>{studentInstructorDetails?.active_students}</p>
                    <p>Active Students</p>
                </div>

                <div className="bb1">
                <span className="dotInactiveAdmin"></span>
                    <p>{studentInstructorDetails?.inactive_students}</p>
                    <p>Inactive Students</p>
                </div>

                <div className="bb1">
                <span className="dotInactiveAdminPending"></span>
                    <p>{studentInstructorDetails?.pending_students}</p>
                    <p>Pending Students</p>
                </div>


                </div>  
                <div className="doNutAdmin">
                <Doughnut
        data={data}
        options={{
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          },
          rotation: -90,
          circumference: 180,
          cutout: "60%",
          maintainAspectRatio: true,
          responsive: true
        }}
      />
                    </div> 
        
                </div>

                <div className="adminSingleCard">
                    
                    <div className="todoContainer">
                        <div><h4>To Do List</h4></div>
                        <div style={{marginTop:"10dp"}}><img src={plus} onClick={addAdminTodo} className="addTodoIcon"/></div>
                        
                    </div>
                        <hr></hr>
                    <div className="todoList">
                    {adminTodoList && adminTodoList.map((item,index)=>{
                     return(
                        <div className="adminTodoRecords">
                            <div>
                                <span className="adminTodoRecords"><Checkbox className="adminTodoCheckBox" checked={item.to_do_id ==checkedStatus[0] ? true :false}
                             onClick={()=>deleteTodo(item.to_do_id)}/>
                                <div className="todoDescription">
                                    <div>
                                        {item.description}
                                    </div>
                                    {/* <div>
                                        {(item.created_date)}
                                    </div> */}
                                </div>
                             </span></div>
                     </div>
                     )
                     
                    })}
                    </div>
                </div>

                <div className="adminSingleCard">
                <div className='grid1'>
                    <div className='gridHeaderAdmin'>
                        <div>
                            <h4 className="headerVedicMath">Vedic Math</h4>
                        </div>
                        <div className='adminVedicMathSelect' style={{marginLeft:"auto",display:"flex",flexDirection:"row"}}>
                            <InputSelect defaultValue={"Today"}/>
                        </div>
                    </div>

                    <div className='gridContent'>
                        <div className='gridContent1'>
                            <p className='contentPara'>Monday,28June 2023</p>
                            <p className='contentPara'>02.00-03.00 PM</p>
                        </div>
                        <div className='gridContet2'>
                            <p className='contentPara'>Mr.Mark Jefferson</p>
                            <p className='contentPara'>Zoom</p>
                        </div>
                        {/* <div className='gridBody'>
                            <div className='gridContentImg'>
                                <img src={maskgroup} className='smallImg'/>
                                <img src={maskgroup} className='smallImg'/>
                            </div>
                            <div className='gridContet2'>
                            <p>25+ people joined in this class</p>
                        </div>          
                        </div> */}
                    </div>

                    
                </div>
                </div>

                <div className="adminSingleCard">
                <div>
                    <h4>Task Progress</h4>
                </div>
                <div className='courseProgressAdmin'>

          {adminCourseProgress && Object.values(adminCourseProgress?.data).map((item,index)=>{
                  return (
                    <>
                    <Progress percent={item.course_completion_percentage} />
                    <span>{item.batch_name}</span>
                    </>
                  )
          })}
                
                 
          </div>
                </div>

                <div className="adminDashboardNewCourseContent">
                    {/* New Course Content */}
                </div>
            </div>
            <>
            <div className="adminDashboardCaledar">
            <div className='admindashboardDatePicker'>
        <ReactDatePicker
              onChange={handleDateChange}

            format="YYYY-MM-DD HH:mm"
            open={true}
            renderExtraFooter={renderExtraFooter}
            style={{height:"50px",position:"static",overflow:"auto"}}
            showToday={false}
            size="100px"
            // dayClassName={highlightClass}

            
        />
        </div>
        <div style={{ textAlign: 'center', height:"450px" ,overflow:"auto",backgroundColor:"#fff"}}>
        <Calendar
         localizer={localizer}
         events={calendarData }
        views={['day']} 
        defaultView='day' 
        selectable={true} 
        toolbar={false}
        date={displayedDate} 
        onSelectEvent={openModalPopUp}

      />   



      </div>
        <div>
        </div>
            </div>

            {scheduleModal ?
        <ModalComponent title={`Batch name: ${(singleEvent?.batch_name).toUpperCase()}`}
      content={<div>
        <b>Title:</b> {singleEvent?.title}
        <div><b>Meeting: </b><a href={singleEvent?.meeting_link}>
        {singleEvent?.meeting_link}</a></div>
      </div>}
      open={scheduleModal} 
      handleCancel={closeModal}
     />
     : ""}
       
       </>





                {/* <div className="adminDashboardCaledar">
                <div className='admindashboardDatePicker'>
            <ReactDatePicker
                format="YYYY-MM-DD HH:mm"
                open={true}
                renderExtraFooter={renderExtraFooter}
                style={{height:"50px",position:"static",overflow:"auto"}}
                showToday={false}
                size="100px"
                
            />
            </div>
            <div style={{ textAlign: 'center', height:"450px" ,overflow:"auto",backgroundColor:"#fff"}}>
            <Calendar
             localizer={localizer}
            views={['day']} // Set the views prop to only include the day view
            defaultView='day' // Set the default view to day view
            selectable={true} // Optional: Allow selecting dates
            events={[]} // Optional: Provide events data if needed
            toolbar={false}
          />      
          </div>
            <div>
            </div>
                </div> */}
            </div>
            {todoModalVisible?
            <ModalComponent title="Add To do"
            content={<AdminTodo onFinish={onFinish} form={form}
            todoDate={todoDate}
            todoDescription={todoDescription}
            handleOnchangeTodo={handleOnchangeTodo}/>}
            open={todoModalVisible}
            closeModal={closeModal}/>
        :""}
        </div>
      
      
      
    )
}

export default Dashboard