import { RETRIEVE_DEMO_CLASS,DELETE_CLASS_DETAILS,RETRIEVE_ALL_COURSES,
    RETRIEVE_DEMO_CLASS_REGISTARTION
} from "./actionTypes";

 const AdminDemoClassDetails = (state = [],action)=>{

    switch (action.type) {
            
        case RETRIEVE_DEMO_CLASS:
            return {
                retrieveDemoClassList: action.payload
            }
            case DELETE_CLASS_DETAILS:
                return {
                    deleteClassResponse: action.payload
                }

                case RETRIEVE_ALL_COURSES:
                    return {
                        retrieveAllCourses: action.payload
                    }

                    case RETRIEVE_DEMO_CLASS_REGISTARTION:
                        return {
                            retrieveDemoClassRegistration: action.payload
                        }
          

        default:
            return state;
    }
}

export  default AdminDemoClassDetails;