import React, { useEffect, useState } from 'react'

import { useDispatch,useSelector } from 'react-redux';
import {Progress} from 'antd'
import { studentCourseProgress } from '../../../redux/studentDashboard/taskProgress/action';
import './taskProgress.css';
let TaskProgress=()=> {
  let dispatch = useDispatch();

  let taskProgress = useSelector((state)=>state.studentCourseProgressReducer?.courseProgress);
  let [progress,setProgress] = useState()
  useEffect(()=>{
    let payload = 
    {
        "operation_type":"view_course_progress",
        "student_id": window.localStorage.getItem("student_id")
    }
    dispatch(studentCourseProgress(payload))
  },[])

  useEffect(()=>{
    console.log(taskProgress,"progress")
    if (taskProgress) {
      setProgress(taskProgress.data)
    }
  },[taskProgress])
  return (
        <div className='courseProgress'>
          <p>Course Progress</p>
          <hr></hr>
          {progress && Object.values(progress).map((item,index)=>{
                  return (
                    <>
                    <Progress percent={item.course_completion_percentage} />
                    <span>{item.course_name}</span>
                    </>
                  )
          })}
                
                 
          </div>
  )
}
export default TaskProgress