import { SET_INSTRUCTOR_LOGIN,GET_INSTRUCTOR_LOGIN,SET_INSTRUCTOR_TOKEN,GET_INSTRUCTOR_MENUS, GET_INSTRUCTOR_LOGIN_ROLE,
     SET_CURRENT_INSTRUCTOR_GROUPS, 
     SET_CURRENT_INSTRUCTOR_GROUP_INFORMATION,
     INSTRUCTOR_INSERT_GROUP_CHART,
     INSTRUCTOR_INSERT_GROUP_CHART_FIRE_BASE,
     FETCH_INSTRUCTOR_MESSAGES,
     GET_GROUP_NAME,
     RETRIEVE_GROUP_CHART_DETAILS,INSTRUCTORE_CONTCAT_US} from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const instructorLoginReducer = (state = initialStates,action)=>{

    switch (action.type) {
        case  GET_INSTRUCTOR_LOGIN:
            return {
                studentLogin: action.payload,
            }

            case SET_INSTRUCTOR_TOKEN:
                return{
                    token: action.payload
                }
            
            case GET_INSTRUCTOR_MENUS:
                return{
                    instructorMenus: action.payload
                }

            case GET_INSTRUCTOR_LOGIN_ROLE:
                return{
                    instructorLoginRole: action.payload
                }
            case SET_CURRENT_INSTRUCTOR_GROUPS:
                return{
                    instructorGroups: action.payload
                }

            case SET_CURRENT_INSTRUCTOR_GROUP_INFORMATION:
                return{
                    instructorGroupInformation: action.payload
                }

            case INSTRUCTOR_INSERT_GROUP_CHART:
                return{
                    insertGroupChartDetailsList: action.payload
                }

            case INSTRUCTOR_INSERT_GROUP_CHART_FIRE_BASE:
                return{
                    insertFireBaseGroupChartDetailsList: action.payload
                }
            
            case FETCH_INSTRUCTOR_MESSAGES:
                return {
                    instructorMessages: action.payload
                }

                case RETRIEVE_GROUP_CHART_DETAILS:
                    return{
                        retrieveGroupChartDetails: action.payload
                    }

                    case INSTRUCTORE_CONTCAT_US:
                    return{
                        instructorContactUs: action.payload
                    }
        default:
            return state;
    }
}

export  default instructorLoginReducer;