import { EMAIL_EXISTS, GET_STUDENTS, SET_STUDENTS } from "./actionTypes"
import axios from "axios";
import apiConfig from '../../apiConfig/api';

const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    };

export const createStudent = (data) =>{
    console.log(data,'check')
    let emailCheck = {}
    if (data == false) {
        emailCheck.err = "Email exists"
    }else{
        emailCheck.err = " "
    }
    return{
        type: SET_STUDENTS,
        payload: data
    }
}


const getStudents = (data) =>{
    console.log(data,'main')
    return{
        type:GET_STUDENTS,
        payload: data
    }
}

export const emailExistsStudent = (data)=>{
    
    return{
        type: EMAIL_EXISTS,
        payload: data
    }
}

//validate Email
export const validateEmail = (email) =>{
    // alert(email)
    let verifyPayload ={
        "operation_type": "retrieve",
        "filter_string": `email  = '${email}'`
    }
    console.log(verifyPayload,'payload')
    return (dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/student",verifyPayload,{headers}).then((res)=>{
            if (res?.data) {
                dispatch(emailExistsStudent(res.data.res_status))

            }
        })
    }
}
  


//CREATE STUDENT
export const createStudents = (data) =>{
    return (dispatch)=>{

            axios.post(apiConfig.baseUrl+"e_lms/v1/student",data, {headers}
            ).then((res)=>{
                console.log(res.data.res_status,'hello')
                
                    if (res.data?.res_status == false) {
                        console.log(res.data,'validate')
                        dispatch(emailExistsStudent(res.data?.res_status))


                    }else{
                        dispatch(createStudent(res));
                        dispatch(loadStudents())
                        dispatch(emailExistsStudent(res.data?.res_status))

                }
                // dispatch(loadStudents())
    
            }).catch(err=>{
                console.log(err)
            })
           
    }
}

//Edit Student by id

export const editStudent = (data)=>{
    return (dispatch)=>{

        axios.post(apiConfig.baseUrl+"e_lms/v1/student",data, {headers}
        ).then((res)=>{
            console.log(res.data.res_status)
            // dispatch(createStudent(res))
            // if (res.data.res_status == false) {
            // }else{
            //     dispatch(createStudent(res))
            //     dispatch(loadStudents())
            //     dispatch(emailExistss(res.data?.res_status))

            // }
            if (res.data.res_status == true) {
                // dispatch(createStudent(res))
                dispatch(loadStudents())
                dispatch(emailExistsStudent(res.data?.res_status))
            } 

        }).catch(err=>{
            console.log(err)
        })
       
}

}

//GET ALL STUDENTS
// export const loadStudents = () =>{
//     console.log("loading")
//     return (dispatch)=>{ //student
//         axios.post("https://botpi.arkatiss.com/e_lms/v1/student",{"operation_type": "retrieve"}
//         ).then((res)=>{
//             console.log(res.data)
//             dispatch(getStudents(res.data))
//         }).catch(err=>{
//             console.log(err)
//         })
//     }
// }

export const loadStudents = () =>{
    console.log("loading")
    return (dispatch)=>{ //student
        axios.post(apiConfig.baseUrl+"e_lms/v1/batch",{  "operation_type": "student_retrieve",
        "tenant_id": window.localStorage.getItem("tenant_Id")}

        ).then((res)=>{
            console.log(res.data)
            dispatch(getStudents(res.data))
        }).catch(err=>{
            console.log(err)
        })
    }
}
// DELETE STUDENT BY ID

export const deleteStudent = (student_id) =>{
        let deletePayload = 
        {
            "operation_type": "delete",
            "op_field_details": {
                "student_id": [student_id]
            }
        }
        return (dispatch)=>{
            axios.post(apiConfig.baseUrl+"e_lms/v1/student",deletePayload).then((res)=>{
                if(res.data?.res_status == true){
                    dispatch(emailExistsStudent(res.data?.res_status));
                    dispatch(loadStudents())

                }
            }).catch(err=>{
                console.log(err)
            })
        }
}