import React, { useEffect, useState } from 'react'

import { jwtDecode } from "jwt-decode";

import  banner from '../../images/Object.svg';
import './adminLogin.css';

import { Button, Form, Input,notification } from "antd";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonComponent, InputComponent } from '../../commonComponents';
import { faceBookIcon, googleIcon, logo } from '../../images';
import AdminRegistration from './adminRegistration/adminRegistration';
import { getAdminLoginRole, insertFirebaseTokenForAdmin, loginAsAdmin } from '../../redux/admin/action';
import { createRecordByFirebaseToken, createRecordByFirebaseTokenStudent, getLoginRole, setToken, studentLogin, studentMenus, studentRecordDetails } from "../../redux/studentLogin/action";

let AdminLogin=()=> {


    
    
    
    const [form] = Form.useForm();
    let navigate = useNavigate();
    let jwtConvert = jwtDecode 

    let dispatch = useDispatch();

    const [api, contextHolder] = notification.useNotification();

    let checkAdminLoginData = useSelector((state)=>state.adminReducer.adminLoginData)
    let [loginDetails,setLoginDetails] = useState({
        email:"",
        password:""
    });

    let onFinish = (values) =>{
            console.log(values,"adminFormValues")
        let loginPayload = {
            "operation_type":"tenant_admin_login",
            "op_field_details":{
                "username": loginDetails?.email?.toLowerCase(),
                "password": loginDetails?.password
            }
        }
        
        dispatch(loginAsAdmin(loginPayload))
    }

    useEffect(()=>{

        console.log(checkAdminLoginData,'fcmToken');

        if (checkAdminLoginData) {

            console.log(checkAdminLoginData?.msg,'fcmToken');
            console.log(checkAdminLoginData?.res_status,'fcmToken');


            if(checkAdminLoginData?.data?.res_status == true){
                openNotification(checkAdminLoginData?.data?.msg,checkAdminLoginData?.data?.res_status)

            }

            if(checkAdminLoginData?.res_status == false){
                openNotification(checkAdminLoginData?.msg,checkAdminLoginData?.res_status)

            }


           

            window.localStorage.setItem("token",checkAdminLoginData?.data?.access_token);

            console.log(window.localStorage.getItem("fcmToken"),'SAIANIL');


            //new
            if (checkAdminLoginData?.data?.access_token ?.access_token) {
                setTimeout(()=>{
                    let decryptData = jwtConvert(checkAdminLoginData?.data?.tenant_user_data)
                    let {email,role_name,tenant_id} = decryptData
                    console.log(decryptData?.data[0]?.role_name,'role_name')

                    console.log(decryptData?.data,"decrypt",role_name,tenant_id)
                    window.localStorage.setItem("tenant_Id",decryptData?.data[0]?.tenant_id)
                    window.localStorage.setItem("role_Type",decryptData?.data[0]?.role_name)
                    window.localStorage.setItem("adminEmail",decryptData?.data[0]?.email)
                    // window.localStorage.setItem("user_id",decryptData?.data[0]?.user_id)

                    window.localStorage.setItem("adminFullName",decryptData?.data[0]?.first_name+" "+decryptData?.data[0]?.last_name)
                    dispatch(getAdminLoginRole(window.localStorage.getItem("role_Type")))

                    
                    console.log("hhh",decryptData,decryptData?.data[0]?.first_name+" "+decryptData?.data[0]?.last_name)
                     // FirebaseInsert
                     
                        let data ={
                            "username": window.localStorage.getItem("adminEmail"),
                            "fcmtype": "web",
                            "fcmtoken": window.localStorage.getItem("fcmToken"),
                            "tenant_id": window.localStorage.getItem("tenant_Id"),
                            "project_name": "lms",
                            "full_name":window.localStorage.getItem("adminFullName")
                        }
                        dispatch(insertFirebaseTokenForAdmin(data))
                        dispatch(createRecordByFirebaseTokenStudent(data))

                        // navigate("/main")
                        // window.location.reload()

                        // console.log("MAIN")
                        // end

                },1000)

                setTimeout(()=>{
                     navigate("/main")
                    window.location.reload()
                },1000)
                
            }else{
                // openNotification(checkAdminLoginData?.data?.msg,checkAdminLoginData?.data)

                // navigate("/")
            }
            //end



            
            // console.log(decryptData,decryptData?.data[0].tenant_id,decryptData?.data[0].role_name,"decrypted");
            
            // window.localStorage.setItem("tenant_Id",decryptData?.data[0]?.tenant_id)
            // window.localStorage.setItem("role_Type",decryptData?.data[0]?.role_name)

            // openNotification(checkAdminLoginData?.msg,checkAdminLoginData?.res_status)
            
            // if (checkAdminLoginData?.msg,checkAdminLoginData.res_status == true) {
            //     openNotification(checkAdminLoginData?.msg,checkAdminLoginData)
            //     navigate("/main");
            //    window.location.reload()
            // }
        }
        console.log(checkAdminLoginData?.msg,checkAdminLoginData,"ff")
    },[checkAdminLoginData])

        const openNotification=(msg,status)=>{
            if (status == true) {    
                api.info({
                  description:
                    `${msg}`,
                });
              }
              if (status == false) {
                api.info({
                  description:
                    `${msg}`,
                });
              }
        }
   

    let [adminReg,setAdminReg] = useState(false);
    let handleChange = (e,type) =>{
        setLoginDetails({...loginDetails,[type.name]:e.target.value})
    }

    const adminRegistration = ()=>{
        navigate("/registration")
    }

  return (

    <div className='adminLoginContainer'>
        {contextHolder}
        <div className='adminLoginBanner'>
        <img src= {banner} className='bannerImage'/>

        </div>

        <div className='adminLoginForm'>
            <div className='adminLogo'>
            <img src={logo}/>
            </div>
        <Form 
        autoComplete="off"
        layout="vertical"
        form={form}
          onFinish={onFinish}
          >
        
        <><div className='fields'>
                          <Form.Item label="Email"
                              name="email"
                              rules={[
                                  {
                                      required: true,
                                      message: 'Email is required',
                                      type: "email",
                                  },
                              ]}>
                              <InputComponent value={loginDetails.email} name={"email"} className='inputField'
                                  placeholder="Enter email"
                                  onChange={(e) => handleChange(e, { name: "email" })} />

                          </Form.Item>

                      </div><div className='fields'>
                              <Form.Item label="Password"
                                  name="password"
                                  rules={[
                                      {
                                          required: true,
                                          message: 'Password is required',
                                      },
                                  ]}>
                                  <Input.Password value={loginDetails.password} name={"password"} className='inputField adminInputField'
                                      placeholder="Enter password"
                                      onChange={(e) => handleChange(e, { name: "password" })} />

                              </Form.Item>

                          </div><div className='fields'>
                              <Form.Item>
                                  <Button htmlType='submit' primary type='primary' className='loginAdmin'>Login</Button>
                              </Form.Item>
                          </div></>
        
        </Form>


        


            
        </div>
        
    </div>
  )
}

export default AdminLogin