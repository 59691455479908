import { GET_ADMIN_ANNOUNCEMENTS,SET_ADMIN_ANNOUNCEMENTS,ANNOUNCEMENT_CRUD_STATUS, GET_FCMDATA } from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const adminAnnouncementReducer = (state = initialStates,action)=>{

    switch (action.type) {
        case  GET_ADMIN_ANNOUNCEMENTS:
            return {
                ...state,
                adminAnnouncements: action.payload,
            }

            case SET_ADMIN_ANNOUNCEMENTS:

            return{
                announcementCreateStatus: action.payload,
            }

            case ANNOUNCEMENT_CRUD_STATUS:
                return{
                    announcementCrudStatus: action.payload
                }
            
            case GET_FCMDATA:
                return{
                    fcmData: action.payload
                }
        default:
            return state;
    }
}

export  default adminAnnouncementReducer;