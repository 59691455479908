import React from "react";

import moment from "moment";

let AdminDemoClassRegistrationView = (props) =>{
              
    console.log(props.studentRecord)

    return (
        <div className="retriveStudent">
                <div>
                    <p> ID  : {props?.studentRecord.id}</p>

                    <p>First Name   : {props?.studentRecord.first_name}</p>
                    <p>Last Name        : {props?. studentRecord.last_name}</p>

                    <p>Course Name    : {props?.studentRecord.course_name}</p>
                    <p>Class Date        : {moment(props?.studentRecord.date, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY')}</p>
                    <p>Email        : {props?. studentRecord.email}</p>
                    <p>Phone Number        : {props?. studentRecord.phone}</p>
                    <p>Remark       : {props?. studentRecord.remark}</p>

                    <p>Status       : {props?. studentRecord.status == 'pending' ? 'Pending':'Completed'}</p>

                  
                   
                </div>
        </div>
    )
}

export default AdminDemoClassRegistrationView