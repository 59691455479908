import React, { useDebugValue, useEffect, useState } from "react";


import {privacyPolicyDetailsAll,privacyPolicyUpdateDetails} from "../../redux/studentRegister/action";
import { useDispatch,useSelector } from "react-redux";
import { edit } from "../../images";

import { ButtonComponent,SwitchComponent ,ModalComponent} from "../../commonComponents";
import { Checkbox, Form, Input,notification } from "antd";

import UpdatePrivacyPolicyDetails from '../Settings/updatePrivacyPolicyDetails';


let AllSettings=()=> {

  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  let dispatch = useDispatch()


  let privacyPolicyDetails = useSelector((state)=>state.studentRegisterReducer.privacyPolicy);

  let privacyPolicyUpdate = useSelector((state)=>state.studentRegisterReducer.studentReg);



let [privacyPolicyData,setPrivacyPolicyData] = useState();

let [privacyId,setPrivacyId] = useState();


useEffect(()=>{

  if(privacyPolicyUpdate){

    openNotification(privacyPolicyUpdate.msg)

    if(privacyPolicyUpdate?.res_status == true){
      setCancelClassModal(false)

      form.setFieldsValue({["privacy_policy"]:""})
      form.setFieldsValue({["terms_condition"]:""})
      form.setFieldsValue({["role_type"]:""})
    
    
    
    
    
      setCancelClassDetails({
       "privacy_policy":"",
       "terms_condition":"",
       "role_type":""
      })
    }

    // openNotification(privacyPolicyUpdate.msg)
  }

  
},[privacyPolicyUpdate])

const openNotification = (status) => {
  
  
  if (status) {
    api.info({
        description:
        `${status}`,

      });
}




};

useEffect(()=>{
  let registrationPayload = {
    "operation_type":"retrieve_all",
    "tenant_id":window.localStorage.getItem("tenant_Id")
}

 dispatch(privacyPolicyDetailsAll(registrationPayload)) 
  
},[])

useEffect(()=>{

  if(privacyPolicyDetails){
    setPrivacyPolicyData(privacyPolicyDetails)

    console.log(privacyPolicyData,'privacyPolicyData')
  }
  
},[privacyPolicyDetails])
 

let handleEditModal = (type,id) =>{

  setPrivacyId(id)
  privacyPolicyData?.data.filter((item,index)=>{
    if(item.id == id){

      setCancelClassDetails({
        "privacy_policy":item.privacy_policy,
        "terms_condition":item.terms_and_condition,
        "role_type":item.role_type,
       })

      form.setFieldsValue({["privacy_policy"]:item.privacy_policy})
      form.setFieldsValue({["terms_condition"]:item.terms_and_condition})
      form.setFieldsValue({["role_type"]:item.role_type})
 

      }
  })
  setCancelClassModal(true)

}

let [cancelClassModal,setCancelClassModal] = useState(false);

let[cancelClassDetails,setCancelClassDetails] = useState({
  "privacy_policy":"",
  "terms_condition":"",
  "role_type":"",

})





let handleCloseModal = () =>{

  setCancelClassModal(false)

  form.setFieldsValue({["privacy_policy"]:""})
  form.setFieldsValue({["terms_condition"]:""})
  form.setFieldsValue({["role_type"]:""})





  setCancelClassDetails({
   "privacy_policy":"",
   "terms_condition":"",
   "role_type":""
  })


}

const handleClassChange = (e,type) =>{

  if (type.name == "privacy_policy") {
    setCancelClassDetails({...cancelClassDetails,[type.name]:e.target?.value})
}

if (type.name == "terms_condition") {
  setCancelClassDetails({...cancelClassDetails,[type.name]:e.target?.value})
}



}


let onFinish1 = (values) =>{


  let cancelClassPayload ={

    
    "operation_type":"update",
    "op_field_details":{
      "id":privacyId,
        "terms_and_condition":cancelClassDetails?.terms_condition,
        "privacy_policy":cancelClassDetails?.privacy_policy,
        "tenant_id":1,
        "role_type":cancelClassDetails?.role_type
    }
   
}


console.log(cancelClassPayload,'cancelClassPayload')


dispatch(privacyPolicyUpdateDetails(cancelClassPayload))


}

let [checked, setChecked] = useState(false);


let handleInstructor = (e) =>{

  setChecked(e.target.checked);

  }


  return (

    <>
            {contextHolder}

<div>

  <h style={{ fontSize: '20px' ,fontWidget : 'bold',color:'black'}}> Terms And Condition And Privacy Policy :- </h>
    
<div style={{height:"15px"}}>
{privacyPolicyData?.data.map(item => (

  <div key={item.id} style={{ display: "flex", flexDirection: "row", 
  alignItems: "center", border: "1px solid", 
  borderRadius: "5px", borderBlockColor: "black", padding: "5px", margin: '5px',background:'white' }}>
  <div style={{ flex: 1 }}>
    <p>Role Type           : {item.role_type}</p>
    <p>Terms And Condition : {item.terms_and_condition}</p>
    <p>Privacy Policy      : {item.privacy_policy}</p>
  </div>

  <img src={edit} onClick={()=>handleEditModal("",item.id)} style={{ marginLeft: "10px", marginRight:"10px"}}/>

</div>
))}



<div  style={{ display: "flex", flexDirection: "row", 
  alignItems: "center", border: "1px solid", 
  borderRadius: "5px", borderBlockColor: "black", padding: "5px", margin: '5px',background:'white' }}>
  <div style={{ flex: 1 ,marginLeft:"10px"}}>
    <h style={{ fontSize: '20px' ,fontWidget : 'bold',color:'black'}}>Allow Instructors to publish courses</h>
    <p>Enable publisher to publish the course directly. If disabled admins will be able to review course content before publishing.</p>
  </div>


   <div className="terms" style={{marginRight:"10px"}}>
                  <div className='switch'>
                  <div><SwitchComponent /></div>
  
                  </div>
              </div>

 
</div>


<div  style={{ display: "flex", flexDirection: "row", 
  alignItems: "center", border: "1px solid", 
  borderRadius: "5px", borderBlockColor: "black", padding: "5px", margin: '5px',background:'white' }}>
  <div style={{ flex: 1 ,marginLeft:"10px"}}>
    <h style={{ fontSize: '20px' ,fontWidget : 'bold',color:'black'}}>Profile Completion</h>
    <p>Enabling this feature will show a notification bar to students & instructors to complete their profile information.</p>
  </div>


   <div className="terms" style={{marginRight:"10px"}}>
                  <div className='switch'>
                  <div><SwitchComponent /></div>
  
                  </div>
              </div>

 
</div>


<div  style={{ display: "flex", flexDirection: "row", 
  alignItems: "center", border: "1px solid", 
  borderRadius: "5px", borderBlockColor: "black", padding: "5px", margin: '5px',background:'white' }}>
  <div style={{ flex: 1 ,marginLeft:"10px"}}>
    <h style={{ fontSize: '20px' ,fontWidget : 'bold',color:'black'}}>Erase upon Uninstallation</h>
    <p>Delete all data during uninstallation.</p>
  </div>


   <div className="terms" style={{marginRight:"10px"}}>
                  <div className='switch'>
                  <div><SwitchComponent /></div>
  
                  </div>
              </div>

 
</div>

</div>

      
    </div> 
    
    {cancelClassModal ? 
      <ModalComponent title={"Terms And Condition And Privacy Policy"}
     
      content={<UpdatePrivacyPolicyDetails 
       form={form} onFinish={onFinish1} 
       handleClassChange={handleClassChange}
       cancelClassDetails = {cancelClassDetails}/>}
    open={cancelClassModal} 
     handleCancel={handleCloseModal}
  //  footer= {categoryFooter}
  style={{ minHeight: `500px` }}
     ></ModalComponent>
 :""}

</>
  )
}

export default AllSettings