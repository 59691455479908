import { GET_ALL_INSTRUCTOR_BATCHES, GET_BATCH_RELATED_STUDENTS } from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';


export const getAllInstructorBatches = (data)=>{
    return {
        type: GET_ALL_INSTRUCTOR_BATCHES,
        payload: data
    }
}

export const batchRelatedStudents = (data)=>{
    return {
        type: GET_BATCH_RELATED_STUDENTS,
        payload:data
    }
}

export const retrieveAllInstructorBatches = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor_page",payload
        ).then((res)=>{
            dispatch(getAllInstructorBatches(res.data));
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveBatchRelatedStudents = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor_page",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(batchRelatedStudents(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(batchRelatedStudents([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}