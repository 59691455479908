import { GET_STUDENT_ALL_COURSES,GET_STUDENT_SCHEDULES,GET_SINGLE_COURSE ,RETRIEVE_STUDENT_DETAILS_CALENDAR} from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';

const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    };

export const getViewAllCourses = (data) =>{
    return{
        type: GET_STUDENT_ALL_COURSES,
        payload: data
    }
}

export const getStudentSchedules = (data)=>{
     return{
        type:GET_STUDENT_SCHEDULES,
        payload:data
     }
}

export const getSingleCourse = (data)=>{
    console.log(data,"uuu")
    return{
        type:GET_SINGLE_COURSE,
        payload:data
    }
}

export const retrieveStudentAllCourses=(data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/student_page",data
        ).then((res)=>{
            dispatch(getViewAllCourses(res.data));
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retriveStudentSchedules = ()=>{
    let payload =
    {
        "operation_type":"view_schedules",
        "student_id":window.localStorage.getItem("student_id")
    }
   
        return(dispatch)=>{
            axios.post(apiConfig.baseUrl+"e_lms/v1/student_page",payload
            ).then((res)=>{
                if (res.data?.res_status == true) {
                    dispatch(getStudentSchedules(res.data));    
                }
                
            }).catch(err=>{
                console.log(err)
            })
    }
    
}

export const retrieveStudentCalenderDetail = (data)=>{
    return{
      type:RETRIEVE_STUDENT_DETAILS_CALENDAR,
      payload:data
    }
  }

export const retrieveStudentCalenderDetails = (payload)=>{
  
    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/student_page",payload
      ).then((res)=>{
  
  
        console.log(payload, "DAMODAR BHUYAN");

          if (res?.data?.res_status === true) {
  
            // console.log(res, "DAMODAR BHUYAN");
  
            // console.log(res?.data.res_status,'AVNI B')
  
            // console.log(res?.data)
              dispatch(retrieveStudentCalenderDetail(res.data))
          }
  
          if (res?.data?.res_status === false) {
              dispatch(retrieveStudentCalenderDetail([]))
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }