import { SET_STUDENT_REGISTRATION,GET_STUDENT_REGISTRATION,SET_VERIFY_EMAIL, SET_EMAIL,
     SET_PASSWORDS,RETRIEVE_INTERESTED_COURSES,RETRIEVE_COURSE_DETAILS,PRIVACY_POLICY_DETAILS

 } from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const studentRegisterReducer = (state = initialStates,action)=>{

    switch (action.type) {
        case  SET_STUDENT_REGISTRATION:
            return {
                ...state,
                studentReg: action.payload,
            }

        case SET_VERIFY_EMAIL:
            return{
                ...state,
                emailStatus:action.payload
            }
        case SET_EMAIL:
            return{
                ...state,
                email: action.payload
            }

        case SET_PASSWORDS:
            return{
                ...state,
                passwordMatch: action.payload
            }

            case RETRIEVE_INTERESTED_COURSES:
                return{
                    ...state,
                    retrieveInterestedCourses: action.payload
                }

                case RETRIEVE_COURSE_DETAILS:
                    return{
                        ...state,
                        retrieveCoursesDetails: action.payload
                    }

                    
                case PRIVACY_POLICY_DETAILS:
                    return{
                        ...state,
                        privacyPolicy: action.payload
                    }
        default:
            return state;
    }
}

export  default studentRegisterReducer;