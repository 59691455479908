import React, { useState } from 'react'
import { InputComponent, InputSelect } from '../../../../commonComponents'
import './addCourseBasic.css'
import Item from 'antd/es/list/Item'
let  AddCourseBasicDetails=(props)=> {
  let {courseName,courseCategory,courseTopic,
    courseLanguage,courseLevel,courseDuration} = props.basicDetails

    let obj=[]
    props.categoryDropdown && Object.values(props.categoryDropdown).map((i,Index)=>{
     let newObj= {};
     newObj["value"] = i.course_category_id;
     newObj["label"] = i.course_category;
   obj.push(newObj)
   
   })
   

  return (
    <div className='basicDetailsadminAddCourse'>
      <div>
        <div className='labelName'><label>Course Name <span className="formStar">*</span></label></div>
        <InputComponent placeholder={"Course Name"} value={courseName}
        onChange={(e)=>props.handlebasicCourseDetails(e)} name={'courseName'}/>
        <p className='text-danger'>{props.basicDetailsError?.courseNameError ? "Course name is required" : ""}</p>

  {/* <p className='text-danger'>{props.basicDetailsError?.courseNameError ? props.basicDetailsError?.courseNameError : ""}</p>         */}
      </div>
      <div>
      <div className='labelName'><label >Course Category<span className="formStar">*</span></label></div>
        <div className='basicCategory'>
          <InputSelect 
         defaultValue={props.basicDetails.courseCategory ? props.basicDetails.courseCategory :"Select"}
         placeholder={"Course Category"}
          value={courseCategory}
         name= {"courseCategory"}
         onChange={(e)=>props.handlebasicCourseDetails(e,{name: "courseCategory"})}
         options={obj}
          
           /> 
           </div>
    <p className='text-danger'>{props.basicDetailsError?.courseCategoryError ? props.basicDetailsError?.courseCategoryError : ""}</p>        
       
      </div>

      <div>
      <div className='labelName'><label >Course Topic <span className="formStar">*</span></label></div>
        <InputComponent placeholder={"Course Topic"} value={props.basicDetails.courseTopic ? props.basicDetails.courseTopic :""}
        onChange={(e)=>props.handlebasicCourseDetails(e)} name={"courseTopic"}/>    
      <p className='text-danger'>{props.basicDetailsError?.courseTopicError ? props.basicDetailsError?.courseTopicError : ""}</p>        
   
      </div>

      <div className='courseSelectionDetail'>
            <div className='basicSelect'>
            <div className='labelName'><label>Course Language <span className="formStar">*</span></label></div>
              <div >
                <InputSelect defaultValue={props.basicDetails.courseLanguage ? props.basicDetails.courseLanguage :"Select"}
                onChange={(e)=>props.handlebasicCourseDetails(e,{name: "courseLanguage"})}
                options={[
                    {
                        value: "Hindi",
                        label: 'Hindi',
                      },
                      {
                        value: "English",
                        label: 'English',
                      },
                  
                  ]}/>
                  </div>
              <p className='text-danger'>{props.basicDetailsError?.courseLanguageError ? props.basicDetailsError?.courseLanguageError : " "}</p>        

            </div>

            <div className='basicSelect'>
            <div className='labelName'><label >Course Level <span className="formStar">*</span></label></div>
              <div ><InputSelect 
              defaultValue={props.basicDetails.courseLevel ? props.basicDetails.courseLevel :"Select"}
                onChange={(e)=>props.handlebasicCourseDetails(e,{name: "courseLevel"})}
                options={[
                    {
                        value: "Beginner",
                        label: 'Beginner',
                      },
                      {
                        value: "Intermediate",
                        label: 'Intermediate',
                      },
                      {
                        value: "Advanced",
                        label: 'Advanced',
                      },
                  
                  ]}/>
                  </div>
                <p className='text-danger'>{props.basicDetailsError?.courseLevelError ? props.basicDetailsError?.courseLevelError : " "}</p>        

            </div>

            <div className='basicSelect'>
            <div className='labelName'><label >Course Duration <span className="formStar"></span></label></div>
              <div>
                <InputSelect defaultValue={props.basicDetails.courseDuration ? props.basicDetails.courseDuration :"Select"} name={"courseDuration"}
               onChange={(e)=>props.handlebasicCourseDetails(e,{name: "courseDuration"})}
               options={[
                   {
                       value: "12 months",
                       label: '12 months',
                     },
                     {
                       value: "6 months",
                       label: '6 months',
                     },
                     {
                       value: "3 months",
                       label: '3 months',
                     },
                 
                 ]}/>
                 </div>
                  <p className='text-danger'>{props.basicDetailsError?.courseDurationError ? props.basicDetailsError?.courseDurationError : ""}</p>        

            </div>
      </div>
    </div>
  )
}

export default AddCourseBasicDetails