import {GET_ADMIN_NUMBER_RECORDS,GET_ADMIN_COURSE_PROGRESS,GET_ADMIN_TODOS, TODO_CRUD_STATUS,RETRIEVE_CLASS_FOR_ADMIN} from './actionTypes';

import axios from 'axios';
import apiConfig from '../../apiConfig/api';

export const getAdminNumberRecords = (data)=>{
    return{
        type: GET_ADMIN_NUMBER_RECORDS,
        payload: data
    }
}

export const getAdminCourseProgress = (data)=>{
    return{
        type:GET_ADMIN_COURSE_PROGRESS,
        payload: data
    }
}

export const todoCrudStatus = (data)=>{
    return{
        type:TODO_CRUD_STATUS,
        payload: data
    }
}

export const getAdminTodos = (data)=>{
    return {
        type: GET_ADMIN_TODOS,
        payload: data
    }
}


export const getAdminClassDetails = (data)=>{
    return {
        type: RETRIEVE_CLASS_FOR_ADMIN,
        payload: data
    }
}



export const retrieveAdminNumberRecords = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/admin_dashboard",payload
        ).then((res)=>{
            console.log(res?.data?.data)
            if (res.data?.res_status == true) {
                dispatch(getAdminNumberRecords(res.data));
            }
           
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveAdminCourseProgress = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/admin_dashboard",payload
        ).then((res)=>{
            console.log(res?.data?.data)
            if (res.data?.res_status == true) {
                dispatch(getAdminCourseProgress(res.data));
            }
           
        }).catch(err=>{
            console.log(err)
        })
}
}

export const createAdminTodoRecords = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/to_do",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(todoCrudStatus(res?.data))
                 dispatch(retrieveAdminTodos());
            }
           
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveAdminTodos = ()=>{
    let payload = {
        "operation_type":"view_todo",
        "op_field_details":{
            "role_type":"admin",
            "tenant_id": window.localStorage.getItem("tenant_Id")
        }
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/to_do",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getAdminClassDetails(res.data));
                dispatch(getAdminTodos(res.data));

            }
           
        }).catch(err=>{
            console.log(err)
        })
}
}

export const deleteAdminTodo = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/to_do",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getAdminTodos(res.data));
                dispatch(todoCrudStatus(res?.data))
            }
           
        }).catch(err=>{
            console.log(err)
        })
}
}


export const retrieveAdminClassDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/admin_schedules",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getAdminClassDetails(res.data));
                // dispatch(todoCrudStatus(res?.data))
            }
           
        }).catch(err=>{
            console.log(err)
        })
}
}