import { EMAIL_EXISTS, GET_STUDENTS, SET_STUDENTS } from "./actionTypes";

const initialState = {
    loading:true,
    data:[],
    studentsRecords:[],
    error:" ",
}

 const studentReducer = (state = initialState,action)=>{

    switch (action.type) {
        case  SET_STUDENTS:
            return {
                ...state,
                data: action.payload,
            }

        case GET_STUDENTS:

        return{
            ...state,
            students: action.payload,
            loading: false,
        }

        case EMAIL_EXISTS:
            return{
                ...action.payload,
               emailIN: action.payload
            }
        default:
            return state;
    }
}

export  default studentReducer