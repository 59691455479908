
let  courseAdditionalDetailsValidation=(values,courseDescription)=> {
        let additionalDetailsValid = true;
        let detailsErrors = {};

        if (values.uploadImg == "") {
            detailsErrors.imgError = "Please Select image";
            additionalDetailsValid = false
        }else{
            detailsErrors.imgError = ""
        }

        if (values.courseDescriptionText == "") {
            detailsErrors.courseDescriptionError = "Course description is required";
            additionalDetailsValid = false
        }else{
            detailsErrors.courseDescriptionError = ""
        }

        if (values.course_teach == "") {
            detailsErrors.learnCourseError = "This field is required";
            additionalDetailsValid = false
        }else{
            detailsErrors.learnCourseError = ""
        }

        if (values.course_requirements == "") {
            detailsErrors.courseReqError = "Course requirement is required";
            additionalDetailsValid = false
        }else{
            detailsErrors.courseReqError = ""
        }

        return {
            additionalDetailsValid,
            detailsErrors
        }
}

export default courseAdditionalDetailsValidation;