import React, { useEffect, useState } from 'react';

import { Breadcrumb } from 'antd';

import { getSingleCourse, retrieveStudentAllCourses } from '../../../redux/studentViewAllCourses/action';

import './singleCourse.css';
import { useDispatch, useSelector } from 'react-redux';

import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { carouselLeft } from '../../../images';
let SingleCourse=(props)=> {
    console.log(props,"anil")
    let [singleCourse,setSingleCourse] = useState([])

    let dispatch = useDispatch();

    let navigate = useNavigate();



    let result = useSelector((state)=>state.studentAllCoursesReducer?.viewAllCourses?.data);
    const params = useParams()
  console.log(params)

  useEffect(()=>{
    let payload = 
    {
        "operation_type":"view_all_courses",
        "tenant_id":window.localStorage.getItem("tenant_Id")
    }
    dispatch(retrieveStudentAllCourses(payload))
},[])

    useEffect(()=>{
      if (result) {
        console.log(result,"data",params.id)
        let filtered =result && result.filter((item,index)=>item.course_id ==params.id)
        setSingleCourse(filtered)
        console.log(filtered,"llllllllll")
      }
    },[params,result])

    let navigateToStudentCourses = ()=>{
      alert("pp")
      navigate('/studentAllcourses');

    }
  return (
    <div>
   <NavLink to={"/studentAllcourses"}> 
   <div className='singleCourseBack'>

   <div>
    <img src={carouselLeft}/>
    </div>
    <div className='dashboardText'>
       Back
    </div>
    </div>
    
  </NavLink>
        <Breadcrumb>

            <Breadcrumb.Item>
              {/* <NavLink to="/studentAllcourses">All Courses</NavLink> */}
            </Breadcrumb.Item>
          </Breadcrumb>

  <Breadcrumb>
        <Breadcrumb.Item href="/dashboard" name="Dashboard" />
        <Breadcrumb.Item href="/brand" name="Brands" />
        <Breadcrumb.Item href="/brand/new" name="New brands" />
      </Breadcrumb>

    <div className='singleCourseContainer'>
        <div className='coursesFirstColumn'>

        
        <div>
            <h3>{singleCourse[0]?.course_category}-{singleCourse[0]?.course_name}</h3>
        </div>

        <div className='singleCourseImg'>
            <img src={singleCourse[0]?.course_thumbnail} className='sCourseImage'/>
        </div>

        <div className='singleCourseDesc'>
        <p>{singleCourse[0]?.course_description}</p>
        </div>

        <div>
        <h2>What you will learn in this course</h2>
            <p>{singleCourse[0]?.what_you_will_teach_the_course}</p>
        </div>

        <div>
            <h2>Course Requirements</h2>
            <p>{singleCourse[0]?.course_requirements}</p>
        </div>

        <div>
            <h2>Course Instructor {singleCourse[0]?.intructor_names ? (singleCourse[0]?.intructor_names.length): ""}</h2>
       {/* <h2>{singleCourse[0]?.intructor_names && singleCourse[0]?.intructor_names.map((item,index)=>{
          return (
            <div>{item}</div>
          )
       })}</h2> */}
       <p>{singleCourse[0]?.instructor_names && singleCourse[0]?.instructor_names.map((item,index)=>{
          return <p>{item}</p>
       })}</p>
       {/* <p>{singleCourse[0]?.instructor_names}</p> */}
        </div>

        </div>

        <div className='coursesSecondColumn'>

        </div>
    </div>

    </div>
  )
}

export default SingleCourse;