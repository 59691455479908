import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { changeStatusToReject, getFcmDataForRescheduleClass, getPushNotificationForRescheduleClass, retrieveFcmDataForRescheduleClass, retrieveRescheduleClasses,retrieveFcmDataForRescheduleClassRejected } from '../../../redux/instructorRescheduleClass/action';
import { PopOverComponent, TableComponent,InputComponent } from '../../../commonComponents';
import {insertFireBaseChartDetails} from '../../../redux/studentLogin/action';
import { course,edit,excel,plus,upload,deleteIcon,searchIcon,TableEyeIcon } from "../../../images";
import { NavLink, Outlet } from "react-router-dom";

import './adminRescheduleClass.css';
import moment from 'moment';

import * as XLSX from 'xlsx';
import ExportToExcel from '../../../commonComponents/XlsxFile/ExportToExcel'



const AdminRescheduleClass = () => {

  let dispatch = useDispatch();
  let [rescheduledClasses,setRescheduledClasses] = useState([])
  let [fcmData,setFcmData] = useState([]);
  let[userNames,setUserNames] = useState([]);
  let [batchName,setBatchName] = useState();

  let [students,setStudents] = useState();
  let[searchEntry,setSearchEntry] = useState("");
  const [popoverVisible, setPopoverVisible] = useState(false);


  let allRescheduleClasses = useSelector((state)=>state.instructorRescheduleClassReducer?.reScheduledClasses);

  let rejectedReSchedules = useSelector((state)=>state.instructorRescheduleClassReducer?.rejectedRescheduledClasses);
  
  let fcmDataForRescheduleClass = useSelector((state)=>state.instructorRescheduleClassReducer?.fcmDataForRescheduleClass)
  

  let fcmDataForRescheduleClassRejected = useSelector((state)=>state.instructorRescheduleClassReducer?.fcmDataForRescheduleClassRejected)


  useEffect(()=>{
  

    if(fcmDataForRescheduleClassRejected){

      const fcmToken = fcmDataForRescheduleClassRejected?.data&&Object.values(fcmDataForRescheduleClassRejected?.data).map(item => item.fcmtoken);
  
      const usernames = fcmDataForRescheduleClassRejected?.data&&Object.values(fcmDataForRescheduleClassRejected?.data).map(item => item.username).join(',');
      
  

    let payloadFireBase = {
      "sender_name": window.localStorage.getItem("adminEmail"),
      "receiver_name":usernames,
      "peer_name": batchName,
      "peer_chat_type": "reschedule",//group chat
      "input": "Reschedule Rejected",
      "firebase_tokens": fcmToken, 
      "msg_type": "insert",
      "peer_type": "insert"
    };
    dispatch(insertFireBaseChartDetails(payloadFireBase));

    
    }else{

    }

  },[fcmDataForRescheduleClassRejected])


  useEffect(()=>{
  

    if(fcmDataForRescheduleClass){

      const fcmToken = fcmDataForRescheduleClass?.data&&Object.values(fcmDataForRescheduleClass?.data).map(item => item.fcmtoken);
  
      const usernames = fcmDataForRescheduleClass?.data&&Object.values(fcmDataForRescheduleClass?.data).map(item => item.username).join(',');
      
  

    let payloadFireBase = {
      "sender_name": window.localStorage.getItem("adminEmail"),
      "receiver_name":usernames,
      "peer_name": batchName,
      "peer_chat_type": "reschedule",//group chat
      "input": "Reschedule Approved",
      "firebase_tokens": fcmToken, 
      "msg_type": "insert",
      "peer_type": "insert"
    };
    dispatch(insertFireBaseChartDetails(payloadFireBase));

    }else{

    }

  },[fcmDataForRescheduleClass])

  // useEffect(()=>{
  //     if (fcmDataForRescheduleClass) {
  //       setFcmData(fcmDataForRescheduleClass)
  //       console.log(fcmDataForRescheduleClass,"entry")
  //     }
  // },[fcmDataForRescheduleClass])

  useEffect(()=>{
    dispatch(retrieveRescheduleClasses())
  },[]);

  useEffect(()=>{
    if (allRescheduleClasses) {
      setRescheduledClasses(allRescheduleClasses?.data);
      console.log(rescheduledClasses,"reshceduledClasses")   
    }
   
  },[allRescheduleClasses]);


  useEffect(()=>{
    if (rejectedReSchedules) {
      console.log(rejectedReSchedules,"hey")
      dispatch(retrieveRescheduleClasses())

    }
  },[rejectedReSchedules])

  const handleApprove = (id,batch_id,batch_name) =>{

    setBatchName(batch_name)

    let payload={
      "operation_type":"update",
      "op_field_details":{
            "reschedule_id":id,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "status":"approved"
      }
   }
   dispatch(changeStatusToReject(payload))

   let newPayload={
    "notification_type":"reschedule_class_notification",
    "project_name":"lms",
    "batch_id":batch_id,
    "tenant_id":window.localStorage.getItem("tenant_Id"),
    "reschedule_id":id
}
    dispatch(retrieveFcmDataForRescheduleClass(newPayload))
    setPopoverVisible(false);
  }

  const hanldeReject = (id,batch_id,batch_name) =>{

    setBatchName(batch_name)

    console.log(id,"hey")
    let payload={
      "operation_type":"update",
      "op_field_details":{
            "reschedule_id":id,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "status":"rejected",
      }
   }
   dispatch(changeStatusToReject(payload))


   

   let pushPayload =
    {
      "notification_type":"reschedule_class_rejection_notification",
      "project_name":"lms",
      "batch_id":batch_id,
      "reschedule_id":id,

      "tenant_id":window.localStorage.getItem("tenant_Id"),
   }
    dispatch(retrieveFcmDataForRescheduleClassRejected(pushPayload))
    setPopoverVisible(false);
  }

    let columns = [
        
        {
            title: 'Resch ID',
            dataIndex: 'reschedule_id',
            sorter: true,
            sorter: (a, b) => a.reschedule_id - b.reschedule_id,
            filteredValue: [searchEntry],
          onFilter: (value,record)=>{
            return String(record.instructor_name).toLowerCase().includes(value.toLowerCase())||
                   String(record.course_name).toLowerCase().includes(value.toLowerCase())||
                   String(record.batch_name).toLowerCase().includes(value.toLowerCase())||
                   String(record.scheduled_date).toLowerCase().includes(value.toLowerCase())||
                   String(record.request_date).toLowerCase().includes(value.toLowerCase())||
                   String(record.reschedule_timing).toLowerCase().includes(value.toLowerCase())||
                   String(record.status).toLowerCase().includes(value.toLowerCase())
                 }
                 
    
          },
          {
            title: 'Instructor Name',
            dataIndex: 'instructor_name',
            sorter: true,
            sorter: (a, b) => a.instructor_name .localeCompare(b.instructor_name),

          },
          {
            title: 'Course Name',
            dataIndex: 'course_name',
            sorter: true,
            sorter: (a, b) =>a.course_name .localeCompare(b.course_name),

          },
          {
            title: 'Batch Name',
            dataIndex: 'batch_name',
            sorter: true,
            sorter: (a, b) => a.batch_name .localeCompare(b.batch_name),

          },
          {
            title: 'Scheduled Date',
            dataIndex: 'scheduled_date',
            sorter: true,
            sorter: (a, b) => a.scheduled_date .localeCompare(b.scheduled_date),
            render: (text, record) => moment(record.scheduled_date, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY'),
          },
          {
            title: 'Requested Date',
            dataIndex: 'request_date',
            sorter: true,
            sorter: (a, b) => a.request_date .localeCompare(b.request_date),
            render: (text, record) => moment(record.request_date, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY'),   
          },
          {
            title: 'Timings',
            dataIndex: 'reschedule_timing',
            sorter: true,
            sorter: (a, b) => a.reschedule_timing .localeCompare(b.reschedule_timing),

          },
          {
            title: 'Status',
            dataIndex: 'status',
            sorter: true,
            sorter: (a, b) => b.status - a.status,
            render: (text, record) => (

               
              <div>

<div className="actionIconsReschedule">
       

        {record.status === 'pending' ? (
      <div className="actionIconsReschedule">
                        <span className={record.status == "approved" ? "approveReScheduele" : record.status == "rejected" ? " rejectReScheduele" : "pedingSchedule"}>{record.status.toUpperCase()}</span>

        <PopOverComponent
      popUp={popUpDataOriginal(record.reschedule_id,record.batch_id,record.batch_name)}          element={img}
          trigger="click"
          visible={popoverVisible}
          onVisibleChange={setPopoverVisible}
        />
      </div>
    ):  <span className={record.status === "approved" ? "approveReScheduele" : record.status === "rejected" ? "rejectReScheduele" : "pendingSchedule"}>
    {record.status.toUpperCase()}
  </span>  }
       
      </div>
   
  </div>
          
             
             ),
          },
    ]

    let img = [
      <div className='statusClick'>
                  <span className='dotPopUp'></span>
                  <span className='dotPopUp'></span>
                  <span className='dotPopUp'></span>
    </div>
    ]

    const popUpDataOriginal = (reScheduleId,batch_id,batch_name) =>{
        return(
          <div className='popUpContainer'>
 <NavLink  title='Approve' className={'menutitle'}>

          <p  style={{color:"black"}} onClick={()=>handleApprove(reScheduleId,batch_id,batch_name)} className='approveClick'>Approve</p>
          </NavLink>
          <NavLink  title='Reject' className={'menutitle'}>

          <p  style={{color:"black"}} onClick={()=>hanldeReject(reScheduleId,batch_id,batch_name)} className='approveClick'>Reject</p>
          </NavLink>
 </div>
        )
    }


    const searchStudentByEmail = (e) =>{
      let data = e.target.value;
  setSearchEntry(data)
 
    }


    useEffect(()=>{
      if (searchEntry) {
        setStudents(allRescheduleClasses?.data)
      }
  },[searchEntry])

  const exportToExcel = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };
  
  
  const transformedItems = rescheduledClasses?.map((item) => {
   
  
    return { 
       RESCHEDULE_ID: item.reschedule_id,
       INSTRUCTOR_NAME:  item.instructor_name,
      COURSE_NAME:  item.course_name,
      BATCH_NAME: item.batch_name,
      SCHEDULE_ADTE:  item.scheduled_date,
     REQUEST_DATE:  item.request_date,
     RE_SCHEDULE_ADTE:  item.reschedule_timing,
     STATUS: item.status == 'pending' ? 'Pending' :
     item.status == 'approved' ? 'Approved' : 'Rejected',
   
   
    
    };
  });
  
  let handleCategoryModal1 = (type,id) =>{
     
    exportToExcel(transformedItems, 'reschedule_class');
  
    
  }
    
    return (
        <div>

<div className="allStudentsHeader">

            <div><h3 className="allStudents"></h3></div>
            {/* <div><InputSelect defaultValue={"Select"}/></div> */}

            <div className="searchCourse">
                <div className="searchC"><InputComponent
                 className='searchField' placeholder={'Search'}
                 onChange={(e)=>searchStudentByEmail(e)}/></div>
                <img src={searchIcon} className="searchIconStudents"/>
            </div>

            <div style={{marginTop:"10px"}}>

<img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

</div>
        </div>


            <TableComponent columns={columns} data={rescheduledClasses}/>
        </div>
    );
}

export default AdminRescheduleClass;
