import React from 'react';

import './addTenant.css';
import { InputComponent, InputSelect } from '../../../../commonComponents';
import { Button, Form } from 'antd';
import ReactDatePicker from 'react-datepicker';
const AddTenant = (props) => {
    return (
        <div className='addTenantModalForm'>
            <Form
             autoComplete="off"
             layout="vertical"
             form={props.form}
             onFinish={props.onFinish}>
            <div className='addTenantBasicDetails'>
                <div className='addTenantField'>
                    <Form.Item
                    label="Tenant Name"
                    name="tenant_name"
                    rules={[
                        { required: true, message: 'Please enter tenant ID!' }
                        ]}>
                    
                    <InputComponent placeholder={"Unique ID"} name={"tenant_name"}
                    value={props.tenantName}
                    onChange={(e)=>props.handleChange(e,{name:"tenant_name"})}/>
                    </Form.Item>
                </div>
            </div>

            <div className='addTenantBasicDetails'>
                <div className='addTenantField'>
                <Form.Item
                      label="Joined date"
                      name= "join_date"
                      rules={[
                          { required: true, message: 'Please select Join date' }
                          ]}>
                    
                    {/* <ReactDatePicker 
                        mode='date'
                         selected={(props?.addTenantData?.tenant_joined_date)} 
                     onChange={(e)=>props.handleChange(e,{name:"tenant_joined_date"})}

                        /> */}
                         <ReactDatePicker 
                        mode='date'
                         selected={(props?.joinedDate)} 
                    //      value={props?.courseFeeDetails?.due_date}
                     onChange={(e)=>props.handleChange(e,{name:"join_date"})}

                        />
                </Form.Item>
                   
                </div>
                <div className='addTenantField'>
                <Form.Item
                    label="Expiry date"
                    name= "expirey_date"
                    rules={[
                        { required: true, message: 'Please select expiry date' }
                        ]}>
                    
                    <ReactDatePicker 
                        mode='date'
                         selected={(props?.addTenantData?.expirey_date)} 
                    //      value={props?.courseFeeDetails?.due_date}
                     onChange={(e)=>props.handleChange(e,{name:"expirey_date"})}

                        />
                </Form.Item>
                </div>
            </div>
            
            {props.modalType == "Edit tenant" ? 
            <div className='tenantStatus'>
                <Form.Item
                 label="Tenant Status"
                 name= "tenantStatus"
                 rules={[
                     { required: true, message: 'Please select Status' }
                     ]}>
                <InputSelect
                     selected={props.status}
                     onChange={(e)=>props.handleChange(e,{name:"tenantStatus"})}
                     defaultValue={props.status == "true" ? "Active" : "Inactive"}
                     options={[
                       {
                         value: true,
                         label: 'Active',
                       },
                       {
                         value: false,
                         label: 'Inactive',
                       },
                      
                     
                     ]}/>
                </Form.Item>
            </div>
            :""}

                <Form.Item>
                <div className='addTenantFooterBtns'>
                <div>
                <Button type={"secondary"}  className="cancel">Cancel</Button>
                </div>
                <div className='alignRight'>
                <Button type={"primary"}  className="submit" htmlType="Submit">Submit</Button>
                </div>
                </div>
                </Form.Item>
            </Form>
        </div>
    );
}

export default AddTenant;
