import React, { useEffect } from "react";

import { PopOverComponent,InputComponent,TableComponent,ModalComponent, CheckboxComponent, ButtonComponent } from "../../commonComponents";

import { useState } from "react";
import CourseTypes from "../courseTypes/courseTypes";
import dayjs from 'dayjs';

import { course,edit,excel,plus,upload,deleteIcon,searchIcon,TableEyeIcon } from "../../images";
import { Button, Form, Table, notification } from "antd";
import moment from "moment";
import { useSelector,useDispatch } from "react-redux";
import { loadStudents } from "../../redux/student/action";
import ContactUppopUpDetails from '../contactUs/contactUsPopUp';

import { bulkAdminStudentDelete, createAdminStudent, deleteAdminStudent,retrieveAllContactUs, retrieveAllAdminStudents, studentBulkActions, updateStudentAdmin,studentRequestApproved,studentRequestRejected,deleteContactUs,
    updateContactUs,bulkDeleteeleteContactUs,bulkUpdateContactUs
 } from "../../redux/adminStudent/action";

 import * as XLSX from 'xlsx';
import ExportToExcel from '../../commonComponents/XlsxFile/ExportToExcel'


let ContactUsDetails = () =>{
  

  
  let dispatch = useDispatch();

  const [api, contextHolder] = notification.useNotification();

  let [students,setStudents] = useState();

  let [studentId, setStudentId] = useState();

  const [popoverVisible, setPopoverVisible] = useState(false);


  let [status,setStatus] = useState()
  let [studentDetails,setStudentDetails] = useState(false);

  let [studentRecord,setStudentRecord] = useState([]);

  const [selectedRowsKeys, setSelectedRowKeys] = useState([]);

  const [selectedDropdown,setSelectedDropdown] = useState("Select")

  const [initialpage,setInitialPage] = useState("")
  // let data1 = useSelector((state) => state.student.students?.data);


  // let imp = useSelector((state)=> state.student?.emailIN)
  // console.log('imp',imp)
  
  
  // useEffect(()=>{
  //   setStudents(data1)
  // },[data1])

 
  // useEffect(()=>{
  //     dispatch(loadStudents())
  // },[])
 

  


// useEffect(()=>{
//   setStudents(allAdminStudents?.data)
// },[actionType])

let [studentIdType,setStudentIdType] = useState();


  let onChangeBulkActions = (type) =>{

  

  


   if(selectedRowsKeys.length != 0){

    if (type !=="Delete") {


      

      let payload ={
        "operation_type":"status_update",
        "op_field_details":{
            "id":selectedRowsKeys[0],
            "status":true,
            "tenant_id": window.localStorage.getItem("tenant_Id")
        
        }
    }
    dispatch(bulkUpdateContactUs(payload))

    setSelectedDropdown("Select");



    }else{
      let deletePayload = {
        "operation_type": "delete",
        "op_field_details":{
            "id": selectedRowsKeys[0],
            "tenant_id": window.localStorage.getItem("tenant_Id")
        }
    }
    dispatch(bulkDeleteeleteContactUs(deletePayload))
    }
   }else{
    openNotification('Select Enquiries id')

   }

    
  }
  
  //state
  let studentCrudStatus = useSelector((state)=>state.AdminStudent?.studentCrudStatus);

  let allAdminStudents = useSelector((state)=>state.AdminStudent?.contactUsDetailsList);

  let studentRequestApprovedMsg = useSelector((state)=>state.AdminStudent?.approvedStatus);
  let studentRequestRejectedMsg = useSelector((state)=>state.AdminStudent?.rejectedStatus);

 
  const [activeTypes,setActiveTypes] = useState([]);

  const [inactiveTypes,setInactiveTypes] = useState([]);


  useEffect(()=>{
    if(studentRequestApprovedMsg){

      console.log(studentRequestApprovedMsg,'studentRequestApprovedMsg')
      openNotification(studentRequestApprovedMsg.msg)

    }else{

    }
  },[studentRequestApprovedMsg])

  useEffect(()=>{

    if(studentRequestRejectedMsg){
      console.log(studentRequestRejectedMsg,'studentRequestRejectedMsg')

      openNotification(studentRequestRejectedMsg.msg)

    }else{

    }
  },[studentRequestRejectedMsg])
  

  const openNotification = (status) => {
  
  
      if (status) {
        api.info({
            description:
            `${status}`,
  
          });
    }
  
    if (status == false) {
      api.info({
        // message: `Category ${details.course_category_name}`,
        description:
          'Email already exists!',
      });
    }
  
  };
 
  useEffect(()=>{
    dispatch(retrieveAllContactUs())
  },[])

  useEffect(()=>{
      if (allAdminStudents) {
        setStudents(allAdminStudents?.data)
      }else{
        setStudents([])

      }
  },[allAdminStudents])

  let bulkActions = [
    // {label: "PENDING",value:true},
    {label: "COMPLETED",value:false},
    {label: "DELETE",value: "Delete"}
  ]


  const courseTypeValues = [
    {label:"ALL",value:allAdminStudents?.data?.length},
    {label:"COMPLETED",value:allAdminStudents?.data?.filter((item)=> item.status == true).length},
    {label:"PENDING",value:allAdminStudents?.data?.filter((item)=> item.status == false).length},

      // {label:"TRASH",value:0},
  ]

  const [actionType,setActionType] = useState("");

  const handleCourseTypes = (type) =>{
    setActionType(type.target?.getAttribute("label"))
    if (type.target?.getAttribute("label") == "ACTIVE") {
       let array=[]
      allAdminStudents?.data && allAdminStudents?.data.filter((item,index)=>{
         if (item.is_active == 'true') {
          array.push(item)
         }
      })
      setStudents(array)
      }

    if (type.target?.getAttribute("label") == 'COMPLETED') {
      let array = []
      allAdminStudents?.data && allAdminStudents?.data.filter((item,index)=>{
        if (item.status == true) {
          array.push(item)
        }
     })
     setStudents(array)
    }

    if (type.target?.getAttribute("label") == "PENDING") {
      let array = []
      allAdminStudents?.data && allAdminStudents?.data.filter((item,index)=>{
        if (item.status == false) {
          array.push(item)
        }
     })
     setStudents(array)
    }

    if (type.target?.getAttribute("label") == "ALL") {
      setStudents(allAdminStudents?.data)
    }
  }

  const [form] = Form.useForm();
  let [isModalVisible,setIsModalVisible] = useState(false);

  let [isDeleteModalVisible,setIsDeleteModalVisile] = useState(false);

  let[modalType,setModalType] = useState(" ");
  let [firstName,setFirstName] = useState("");
  let [lastName,setLastName] = useState("");
  let [gender,setGender] = useState("");

  let [studentDOb,setStudentDob] = useState("");
  let [studentCourseName,setStudentCourseName] = useState("");


  let [phNumber,setPhNumber] = useState("");
  
  let [email,setEmail] = useState("");

  let [address,setAddress] = useState("");

  let [age,setAge] = useState("");

  let [guardianName,setGuardianName] = useState("");

  let [studentStatus,setStudentStatus] = useState("");

  let [topic,setTopic] = useState("");


  let[searchEntry,setSearchEntry] = useState("");

  let [loader,setLoader] = useState(false)

//state ends

  let handleModal = (type,id) =>{
    setIsModalVisible(true)
    setIsDeleteModalVisile(false)
    setModalType(type)
  }

  let handleCancel= ()=>{
    setIsModalVisible(false)
    setFirstName("");
    setLastName("");
    setGender("");
    setAge("");
    setPhNumber("");
    setTopic("");
    setEmail("");
    setAddress("");
    setGuardianName("");
    dispatch(loadStudents())
  }  

  let handleEmailChange = (e,type) =>{
      if (type.name == "email") {
        setEmail(e.target.value)
      }
  }

  let handlePhoneNumberChange = (e,type) =>{
    if (type.name == "phNumber") {
      setPhNumber(e.target.value)
    }
  }



  let handleAddressChange = (e,type) =>{
    if (type.name == "address") {
      setAddress(e.target.value)
    }
  }
  let handleInputChange=(e,type)=>{
      if (type.name == "firstName") {
        setFirstName(e.target.value)
        form.setFieldsValue({[type.name]:e?.target.value})
      }

      if (type.name == "lastName") {
        setLastName(e.target.value);
        form.setFieldsValue({[type.name]:e?.target.value})

      }

      if (type.name == "guardianName") {
        setGuardianName(e.target.value);
        form.setFieldsValue({[type.name]:e?.target.value})
      }
    }

    let handleDropdownChange = (e,type)=>{
      if (type.name == "age") {
        setAge(e)
        form.setFieldsValue({[type.name]:e})

      }

      if (type.name == "gender") {
        setGender(e);
        form.setFieldsValue({[type.name]:e})

      }
      if (type.name == "studentStatus") {
        setStudentStatus(e);
        form.setFieldsValue({[type.name]:e})

      }

      if (type.name == "course_name") {
        setStudentCourseName(e);
        form.setFieldsValue({[type.name]:e})

      }

      if (type.name == "dob") {

        console.log(e)
        setStudentDob(e);
        form.setFieldsValue({[type.name]:e})

      }
    }


  const onFinish = () => {
    if (modalType !="Edit Student") {
      console.log(studentDOb,"createPayload");
     

        let payload= {
          "operation_type": "student_enquiry",
          "op_field_details": {
              "first_name": firstName,
              "last_name": lastName,
              "gender": gender,
              "address": address,
              "tenant_id": window.localStorage.getItem("tenant_Id"),
              "email": email,
              "phone_no": phNumber,
              // "guardian_name": guardianName,
              "course_id":studentCourseName,
              "birth_date":studentDOb
              // "is_active":true
              
          }
        }
        dispatch(createAdminStudent(payload))
        console.log(payload,"createPayload");
        // setLoader(true)

        setIsModalVisible(false)
        
       
      }

      
        else  {
          
         let editPayload= {
          "operation_type":"update",
          "op_field_details": {
              "student_id":studentId,
              "first_name": firstName,
              "last_name": lastName,
              "gender": gender,
              "address": address,
              "tenant_id": 1,//window.localStorage.getItem("tenant_Id")
              "email": email,
              "phone_no": phNumber,
              "is_active": (studentStatus),
              "course_id":studentCourseName,
              "birth_date":studentDOb,


          }
        }

        console.log(editPayload,'editPayload')
           
          dispatch(updateStudentAdmin(editPayload))
          setIsModalVisible(false)
          setFirstName("");
          setLastName("");
          setGender("");
          setAge("");
          setPhNumber("");
          setTopic("");
          setEmail("");
          setAddress("");
          setGuardianName("");
          setStatus("");
        }
  };

  useEffect(()=>{
    if (studentCrudStatus) {
      openNotification(studentCrudStatus?.msg,studentCrudStatus)
    }
  },[studentCrudStatus])

  // const openNotification = (msg,studentCrudStatus) => {
  //   // if (msg !="" && studentCrudStatus?.res_status == false) {
  //   //   api.info({
  //   //     type: 'error',
  //   //     description:
  //   //       `${msg}`,
  //   //   });
  //   // }
    
  //   if (msg && studentCrudStatus) {
  //       if (studentCrudStatus?.res_status) {
  //         setIsModalVisible(false);
  //         setLoader(false)
  //         setFirstName("");
  //         setLastName("");
  //         setGender("select");
  //         setAge("select");
  //         setPhNumber("");
  //         setEmail("");
  //         setAddress("");
  //         setGuardianName("");
  //         form.setFieldsValue({["age"]:"select"})
  //         form.setFieldsValue({["gender"]:""})
  //       }
        
  //       api.info({
  //           description:
  //           `${msg}`,
  
  //         });
  //   }
  // };

  
  let closeModal = () =>{
    setIsModalVisible(false)
    setFirstName("");
    setLastName("");
    setGender("");
    setAge("");
    setPhNumber("");
    setTopic("");
    setEmail("");
    setAddress("");
    setGuardianName("");
    setStatus("")
    dispatch(loadStudents())
  }
 
 

  //EditModal
  let handleEditModal = (type,id,first_name,last_name,phone,email,message) =>{

  
    let  editPayload= {
        "operation_type":"status_update",
        "op_field_details":{
            "id":[id],
            "status":true,
            "tenant_id":1
        }
      }

  

    dispatch(updateContactUs(editPayload))
    // setIsDeleteModalVisile(false);
  }

      //DeleteModal
      let handleDeleteModal = (id) =>{
        setStudentId(id)
        setIsDeleteModalVisile(true)
        setIsModalVisible(false)
        setModalType("Delete Student")
      }
    
      let  closeDeleteModal = () =>{
        setIsDeleteModalVisile(false);
      }
    
      let handleDeleteCancel= ()=>{
        setIsDeleteModalVisile(false)
      } 
    
      const handleDeleteOk = (student_id) => {
          dispatch(deleteContactUs(studentId))
          setIsDeleteModalVisile(false);
      };



      //singleStudent

    let handleViewStudent = (Id) =>{
      setStudentId(Id);
      students.filter((item,index)=>{
        if(item.id == Id){
          setStudentRecord(item)
        }
      })
      setStudentDetails(true)
      }

    let handleStudentCancel = () =>{
      setStudentDetails(false)
      setFirstName("");
      setLastName("")
      setGender("");
      setAge("");
      setGuardianName("");
      setPhNumber("");
      setEmail("");
      setAddress("");
      setStudentRecord("")
      setStatus("")
    }

    useEffect(()=>{
      if (searchEntry) {
        setStudents(allAdminStudents?.data)
      }
  },[searchEntry])

  const searchStudentByEmail = (e) =>{
    let data = e.target.value;
    // setSearchEntry(allAdminStudents?.data)
setSearchEntry(data)
// if (data == "") {
//   setStudents(allAdminStudents?.data)
// }

// if (!data) return; // Handle empty search term
// const filteredArray = students.filter(record => 
//   record.email.toLowerCase().includes(data?.toLowerCase())||
//   record.first_name.toLowerCase()?.includes(data?.toLowerCase())||
//   record.last_name.toLowerCase()?.includes(data?.toLowerCase())||
//   record.phone_no.includes(data)||
//   record.guardian_name != null && record.guardian_name.toLowerCase()?.includes(data?.toLowerCase())

// );
// setStudents(filteredArray); 
  }
    const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: true,
          sorter: (a, b) => a.id - b.id,
          filteredValue: [searchEntry],
          onFilter: (value,record)=>{
            return String(record.first_name).toLowerCase().includes(value.toLowerCase())||
                   String(record.last_name).toLowerCase().includes(value.toLowerCase())||
                   String(record.phone).toLowerCase().includes(value.toLowerCase())||
                   String(record.email).toLowerCase().includes(value.toLowerCase())||
                   String(record.role_type).toLowerCase().includes(value.toLowerCase())||
                   String(record.message).toLowerCase().includes(value.toLowerCase())||
                   String(record.is_active).toLowerCase().includes(value.toLowerCase())||
                   String(record.age).includes(value)||
                   String(record.student_id).includes(value)
                 }

        },
        {
            title: 'Student Name',
            dataIndex: 'first_name',
            sorter: true,
            // onFilter: (value, record) => record.category.indexOf(value) === 0,
            sorter: (a, b) => a.first_name.localeCompare(b.first_name),
            render: (text, record) => (
              <div className="actionIcons">
                  <span>{record.first_name+" "+record.last_name}</span>
              </div>
             ),
          },
          
        {
          title: 'Phone',
          dataIndex: 'phone',
          // sorter: true,
          sorter: (a, b) => a.phone .localeCompare(b.phone),
        },
        {
          title: 'Email',
          dataIndex: 'email',
          // sorter: true,
          sorter: (a, b) => a.email .localeCompare(b.email),
         

        },

        {
            title: 'Role Type',
            dataIndex: 'role_type',
            // sorter: true,
            sorter: (a, b) => a.role_type .localeCompare(b.role_type),
          },
          {
            title: 'Message',
            dataIndex: 'message',
            // sorter: true,
            sorter: (a, b) => a.message .localeCompare(b.message),
           
  
          },

     

        {
          title: 'Date and Time',
          dataIndex: 'created_date',
          // sorter: true,
          render: (text, record) => (
            <div className="actionIcons">
                <span>{(moment(record.created_date, "DD-MM-YYYY HH:mm:ss"))
                ? (moment(record.created_date, "DD-MM-YYYY HH:mm:ss")).format("DD-MM-YYYY hh:mm:ss A") : "" }</span>
            </div>
           ),
           sorter: (a, b) => a.created_date.localeCompare(b.created_date),

        },
        // {
        //   title: 'Guardian Name',
        //   dataIndex: 'guardian_name',
        //    sorter: true,
        //   sorter: (a, b) => a.guardian_name .localeCompare(b.guardian_name),

        // },
      
          {
            title: 'Status',
            dataIndex: 'status',
            sorter: true,


            
            sorter: (a, b) => a.status - b.status,



            render: (text, record1) => (
              <div className="actionIcons">
                {record1.status == false ? 
                    <div className={"statusInactivePending"}><span class="dotInactivepending"></span> Pending</div>:
              
                    <div className={"statusActive"}><span class="dotActive" ></span> Completed</div>
}
              </div>
             ),
          },
        {
          title:'Action',
          dataIndex: 'is_active',
          render: (text, record) => (
            <div className="actionIcons">


            {record.status == false ? 
            <> 
 <img src={edit} onClick={()=>handleEditModal("Edit Student",record.id,record.first_name
                    ,record.last_name,record.phone,record.email,record.message
                )}/>
                <img src={deleteIcon} onClick={()=>handleDeleteModal(record.id)}/>  
                <img src={TableEyeIcon} onClick={()=> handleViewStudent(record.id)}/>
                                  
              </>  :   <> 

                <img src={deleteIcon} onClick={()=>handleDeleteModal(record.id)}/>  
                <img src={TableEyeIcon} onClick={()=> handleViewStudent(record.id)}/>
                                  
              </> 
            }
              

                
            </div>
           ),
        }
      ];

      const handleApprove = (student_id,first_name,last_name,email,tenant_id) =>{


  
  
       let payload1={
        "operation_type": "student_approval_action",
  "action": "approved",
  "op_field_details": [
    {
      "student_id": student_id,
      "first_name": first_name,
      "last_name": last_name,
      "email":email,
      "tenant_id": window.localStorage.getItem("tenant_Id")
    }
  ]
     }
     dispatch(studentRequestApproved(payload1))
     setPopoverVisible(false);
      }
    
      const hanldeReject = (student_id,first_name,last_name,email,tenant_id) =>{
  
      
  
      let payload={
        "operation_type": "student_approval_action",
        "action": "rejected",
        "student_id":[student_id],
        "tenant_id": window.localStorage.getItem("tenant_Id")
     }
     dispatch(studentRequestRejected(payload))
     setPopoverVisible(false);

      }

      const popUpDataOriginal = (student_id,first_name,last_name,email,tenant_id) =>{
        return(
          <div className='popUpContainer'>
          <p onClick={()=>handleApprove(student_id,first_name,last_name,email,tenant_id)} className='approveClick'>Approve</p>
          <p onClick={()=>hanldeReject(student_id,first_name,last_name,email,tenant_id)} className='approveClick'>Reject</p>
      </div>
        )
    }

      const rowSelection = {
        // selectedRowKeys: students && students.filter(item => item.is_active).map((item=>item.student_id)),
        onSelectAll: (selected, selectedRows, changeRows) => {
          if (selectedRowsKeys.length !== 0) {
            setSelectedRowKeys([selected]);
            
          }
        },
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRowKeys([selectedRowKeys]);
         
        }
      };

      let img = [
        <div className='statusClick'>
                    <span className='dotPopUp'></span>
                    <span className='dotPopUp'></span>
                    <span className='dotPopUp'></span>
      </div>
      ]

      let deleteModalFooter = [
        <div className="editCourse">
          {/* <div>
              <CheckboxComponent/>
          </div> */}
          <div>
            <Button type={"secondary"} default value={'Cancel'} className={'cancel ant-btn-default'} onClick={closeDeleteModal}>
              Cancel</Button>
  
            </div>
          <div className="alignRight">
            
            <div>
            <Button type={"primary"}  danger value={'Delete'} className={'submit'} onClick={handleDeleteOk}>Delete</Button>
              
            </div>
         
          </div>
        </div>
    ]


    const exportToExcel = (data, fileName) => {
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    };
    
    
    const transformedItems = students?.map((item) => {
     
    
      return { 
         ID: item.id,
         STUDENT_NAME:  item.first_name,
        PHONE_NO:  item.phone,
        EMAIL: item.email,
        ROLE_TYPE:  item.role_type,
       MESSAGE:  item.message,
      
     
        CREATED_DATE: item.created_date,
        STATUS:  item.status == true ? 'Completed' : 'Pending',
      
      };
    });
    
    let handleCategoryModal1 = (type,id) =>{
       
      exportToExcel(transformedItems, 'enquiries');
    
      
    }


    return (
        <>
        <div className="allStudentsHeader">
        {contextHolder}

            <div><h3 className="allStudents">Enquiries</h3></div>
            {/* <div><InputSelect defaultValue={"Select"}/></div> */}

            <div className="searchCourse">
                <div className="searchC"><InputComponent
                 className='searchField' placeholder={'Search'}
                 onChange={(e)=>searchStudentByEmail(e)}/></div>
                <img src={searchIcon} className="searchIconStudents"/>
            </div>
        </div>
            <div className="allCoursesSelections">
                <CourseTypes handleModal={()=>handleModal("Add Student")}
                handleModal1={()=>handleCategoryModal1("")}
                bulkActions={bulkActions} onChangeBulkActions={onChangeBulkActions}
                selectedDropdown={selectedDropdown} courseTypeValues={courseTypeValues}
                handleCourseTypes={handleCourseTypes} actionType = {actionType}/>
            </div>

            <div className="studentsTable">
              <TableComponent columns={columns} selectedRowKeys= {selectedRowsKeys}
               data={students} sorter={true} current={initialpage}
                rowKey={"id"}  rowSelection={rowSelection}  sortOrder={["descend", "ascend"]}
                defaultSortOrder ={'descend'}
                 pageOptions={["10","20","30"]}/>
            </div>
            {/* loading={!students ? true : false } */}
        
            {isDeleteModalVisible ?
              <ModalComponent title={"Delete Enquiries"} 
                  footer={deleteModalFooter} content={"Are you sure you want to delete this Enquiries?"}
                   open={isDeleteModalVisible} handleOk={handleDeleteOk} closeModal={closeDeleteModal}
                  showModal={handleDeleteModal} handleCancel={handleDeleteCancel}/>: " "}
        
       
        {studentDetails ? 
        <ModalComponent title={"Enquiries Details"} content = {<ContactUppopUpDetails studentRecord = {studentRecord}/>}
        open={handleViewStudent} handleCancel={handleStudentCancel}/>
    : ""}
                
  
        </>
    )
}


export default ContactUsDetails;