import {GET_ENROLLED_REPORTS} from "./actionTypes";

 const EnrolledReportsReducer = (state = [],action)=>{

    switch (action.type) {
        case GET_ENROLLED_REPORTS:
            
            return {
                enrolledReportsData: action.payload
            }

         default:
            return state;
    }
}

export  default EnrolledReportsReducer;