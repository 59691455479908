import React, { useEffect, useState } from 'react'

import { TableComponent } from '../../commonComponents';
import { useDispatch,useSelector } from 'react-redux';
import './instructorStudents.css';
import { retrieveAllInstructorStudents } from '../../redux/instructorStudents/action';
import moment from 'moment';
let IntructorStudents=()=> {

  let dispatch = useDispatch();

  let [students,setStudents] = useState();
  let allInstructorStudents = useSelector((state)=>state.instructorStudentReducer?.allStudents?.data);

  useEffect(()=>{
    let payload={
      "operation_type": "view_student_strength",
      "instructor_id": window.localStorage.getItem("instructor_id")
  }
     dispatch(retrieveAllInstructorStudents(payload))
  },[])

  useEffect(()=>{
    if (allInstructorStudents) {
      setStudents(allInstructorStudents);
      console.log(allInstructorStudents,"allinsStudents");
    }
  },[allInstructorStudents])
  
    const columns = [

        {
          title: 'Student ID',
          dataIndex: 'student_id',
          sorter: true,
          sorter: (a, b) => a.student_id - b.student_id,

        },

        {
            title: 'Student Name',
            dataIndex: 'student_name',
            sorter: true,
            sorter: (a, b) => a.student_name .localeCompare(b.student_name),

          },
          
        {
            title: 'Batch Name',
            dataIndex: 'batch_name',
            sorter: true,
            sorter: (a, b) => a.batch_name .localeCompare(b.batch_name),

          },

          {
            title: 'Batch Start Date',
            dataIndex: 'batch_start_date',
            render: (text, record) => (
              <div className="actionIcons">
                  <span>{moment(record.batch_start_date).format("MM-DD-YYYY")}</span>
              </div>
             ),
             sorter:true,
             sorter: (a, b) => a.batch_start_date - (b.batch_start_date),
            },
          {
            title: 'Batch End Date',
            dataIndex: 'batch_end_date',
            render: (text, record) => (
              <div className="actionIcons">
                  <span>{moment(record.batch_end_date).format("MM-DD-YYYY")}</span>
              </div>
             ),
             sorter:true,
             sorter: (a, b) => a.batch_end_date - (b.batch_end_date),

          },
          {
            title: 'Progress',
            dataIndex: 'progress',
            render: (text, record) => (
              <div className="actionIcons">
                  <span>{(record.progress)+"%"}</span>
              </div>
             ),
             sorter: (a, b) => a.progress -(b.progress),

          }
        ]
  return (
    <div>
        <TableComponent columns={columns} data={students}/>
    </div>
  )
}

export default IntructorStudents