import React from 'react'

import { TableComponent } from '../../commonComponents'
let StudentAssignments=()=> {
    const columns = [

        {
          title: 'CourseId',
          dataIndex: 'course_id',
          sorter: true,
        //   onFilter: (value, record) => record.title.indexOf(value) === 0,
    
        },
        {
          title: 'Assignment',
          dataIndex: 'assignment',
          sorter: true,
        //   onFilter: (value, record) => record.category.indexOf(value) === 0,
    
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
            sorter: true,
      
          },
          {
            title: 'Submission Date',
            dataIndex: 'submission_date',
            sorter: true,
          //   onFilter: (value, record) => record.category.indexOf(value) === 0,
      
          },
          {
            title: 'End Date',
            dataIndex: 'end_date',
            sorter: true,
          //   onFilter: (value, record) => record.category.indexOf(value) === 0,
      
          },
          {
            title: 'Attachments',
            dataIndex: 'attachments',
            sorter: true,
          //   onFilter: (value, record) => record.category.indexOf(value) === 0,
                
        },
          {
            title: 'Status',
            dataIndex: 'status',
            sorter: true,
          //   onFilter: (value, record) => record.category.indexOf(value) === 0,
          
          },
      ]
    
  return (
    <div className='studentAssignments'>
        <h3>ASSIGNMENTS</h3>
        <TableComponent columns={columns}/>
    </div>
  )
}

export default StudentAssignments