import React, { useEffect } from "react";

import { useState} from "react";
import { useNavigate,useParams } from "react-router-dom";

import { useSelector,useDispatch } from "react-redux";
import { faceBookIcon,googleIcon,logo,bannerIcon,congratsIcon } from "../../images";

import { InputPassword,ButtonComponent,SwitchComponent,InputSelect } from "../../commonComponents";

import { Form, Input, notification } from "antd";
import LoginComponent from "../Login/login";
import { passwordConfirmation } from "../../redux/studentRegister/action";
import { useLocation } from 'react-router-dom';

import './confirmPassword.css';
import { resetStudentAdminPassword } from "../../redux/adminStudent/action";
import { resetInstructorAdminPassword } from "../../redux/adminInstructor/action";

let ConfirmInstructorPassword = () =>{
    const [form] = Form.useForm();

    const [api, contextHolder] = notification.useNotification();


    let [email,setEmail] = useState("");

    let [tenandId,setTenantId]=  useState("");

    let dispatch = useDispatch();
    

    let ispasswordResetCompleted = useSelector((state)=>state.AdminInstructor?.instructorCrudStatus);

    let[passwordDetails,setPasswordDetails] = useState({
      password:"",
      confirm_password:""
    })

    let [passwordSuccess,setPasswordSuccess] = useState(false)
    const navigate = useNavigate();

    let handleChange = (e,type) =>{
      if (e) {
        setPasswordDetails({...passwordDetails,[type.name]:e.target.value})
      }
    }
    const routeParams = useParams();

    useEffect(() => {
    const fragment = window.location.hash.substring(1);

    const paramsArray = fragment.split('&');

    let email = null;
    let tenantid = null;

    paramsArray.forEach(param => {
      console.log('Param:', param);
      const [key, value] = param.split('=');
      if (key === '/confirmInstructorPassword/id') {
        email = decodeURIComponent(value); 
      } else if (key === 'tenant_id') {
        tenantid = value;
        console.log(value,"heytenant")
        setTenantId(tenantid)
      }
});

console.log('Email:', email);
console.log('Tenant ID:', tenantid);
setEmail(email)
    }, []);
    
    const onFinish = (values) => {
      console.log('Success:', values);
      // setVerifyPage(true)
      if (values) {
       let passwordPayload= {
          "operation_type": "reset_password",
          "op_field_details":{
          "username": email,
          "confirm_password":passwordDetails.password,
          "re_enter_password":passwordDetails.confirm_password,
          "tenant_id": parseInt(tenandId)
          }
      }
      console.log(passwordPayload,"payload")
      //  dispatch(resetStudentAdminPassword(passwordPayload))
      // dispatch(passwordConfirmation(passwordPayload))
      dispatch(resetInstructorAdminPassword(passwordPayload))
      }
      
    };

    useEffect(()=>{
        if (ispasswordResetCompleted?.res_status == true) {
          navigate("/")
        }else{
          openNotification(ispasswordResetCompleted?.msg,ispasswordResetCompleted)
        }
    },[ispasswordResetCompleted])

    const openNotification = (msg,ispasswordResetCompleted) => {
      if (msg !="" && ispasswordResetCompleted?.res_status == false) {
        api.info({
          type: 'error',
          description:
            `${msg}`,
        });
      }
    };
    
    return (
      <>
      {!passwordSuccess ?
        <div className="App">
      <div className='logo'>
         <img src= {bannerIcon} className='bannerImage'/>
         {contextHolder}

      </div>
      <div className='login'>
        <div className='form'>
          <div className='formLogo'>
          <img src={logo}/>
          <img src= {congratsIcon} className=''/>
        <span>{email} have successfully registered</span>
        <span>let's create password and proceed to your account</span>
          </div>

            <>
            <Form 
              autoComplete="off"
              layout="vertical"
              form={form}
                onFinish={onFinish}>
              <div className='fields'>
              <Form.Item
                label="Password"
                name="password"
                
             rules={[
                    {
                      required: true,
                      message: 'Password is required',
                     
                    },
                    {
                      required:true,
                      min:8,
                      message: "Password should atleast 8 characters with special and uppercase",
                    }
                  ]}
                >
                      <div>
                        <Input.Password 
                        value={passwordDetails.password}
                         name={"password"}
                        className='inputField' placeholder="Enter password"
                        onChange={(e)=>handleChange(e,{name:"password"})}/>
                      </div>
                  </Form.Item>
                  </div>

                <div className='fields'>

                <Form.Item
                label="Confirm Password"
                name="confirm_password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The new password that you entered do not match!'));
                    },
                  }),
                ]}
                    >
                    {/* <div> */}
                    <Input.Password 
                    value={passwordDetails.confirm_password}
                     name={"confirm_password"}
                    //  className='inputField'
                     onChange={(e)=>handleChange(e,{name:"confirm_password"})}
                      placeholder="Enter password"/>
                    {/* </div> */}
                </Form.Item>
                  </div>

                    <div className='fields'>
                        <div className='switchInConfirm'>
                            <div><SwitchComponent /><span className='rememberMe'>Remember me</span></div>
                            <div className='forgotpassword'>
                                
                            </div>
                        </div>
                    </div>
                    <div className='fields'>
              <ButtonComponent type="primary" className='btnLogin' htmlType={"Submit"} value={'Proceed To Login'}></ButtonComponent>
             
                   </div>
                   </Form>
            </>
        </div>
      </div>
    </div>
    : <LoginComponent/>}
    </>
    )
}

export default ConfirmInstructorPassword