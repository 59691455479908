export const GET_ADMIN_NUMBER_RECORDS = "GET_ADMIN_NUMBER_RECORDS"; 

export const GET_ADMIN_COURSE_PROGRESS = "GET_ADMIN_COURSE_PROGRESS";

export const GET_ADMIN_TODOS = "GET_ADMIN_TODOS";

export const TODO_CRUD_STATUS = "TODO_CRUD_STATUS";

export const DELETE_ADMIN_TODO = "DELETE_ADMIN_TODO"

export const RETRIEVE_CLASS_FOR_ADMIN = "RETRIEVE_CLASS_FOR_ADMIN"