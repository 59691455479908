export const  SET_INSTRUCTOR_LOGIN = "SET_INSTRUCTOR_LOGIN";

export const GET_INSTRUCTOR_LOGIN = "GET_INSTRUCTOR_LOGIN";

export const SET_INSTRUCTOR_TOKEN = "SET_INSTRUCTOR_TOKEN";

export const GET_INSTRUCTOR_MENUS = "GET_INSTRUCTOR_MENUS";

export const GET_INSTRUCTOR_LOGIN_ROLE = "GET_INSTRUCTOR_LOGIN_ROLE";

export const SET_CURRENT_INSTRUCTOR_GROUPS = "SET_CURRENT_INSTRUCTOR_GROUPS";

export const SET_CURRENT_INSTRUCTOR_GROUP_INFORMATION = "SET_CURRENT_INSTRUCTOR_GROUP_INFORMATION";

export const INSTRUCTOR_INSERT_GROUP_CHART = "INSTRUCTOR_INSERT_GROUP_CHART"

export const INSTRUCTOR_INSERT_GROUP_CHART_FIRE_BASE = "INSTRUCTOR_INSERT_GROUP_CHART_FIRE_BASE";

//sample
export const FETCH_INSTRUCTOR_MESSAGES = "FETCH_INSTRUCTOR_MESSAGES"

//ends

//Today 17-04-2024
export const RETRIEVE_GROUP_CHART_DETAILS = "RETRIEVE_GROUP_CHART_DETAILS";
//end


export const INSTRUCTORE_CONTCAT_US = "INSTRUCTORE_CONTCAT_US"
