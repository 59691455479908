import React, { useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";

import studentMenuDetails from './index'

import {bell,
    messageIcon,
    profileIcon,
    searchIcon,logo,dashboard,
    allStudents,
    allInstructors,
    announcements,
    accounts,
    roles,
    settings,
    schedules,
    allCourses,
    batchIcon} from '../../../images';
    
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import './sidermenu.css';
const { Header, Content, Sider } = Layout;

let SiderMenuComponent = (props) =>{
  const { handleClick } = props;

  // let stdentList =props?.menus;

  // useEffect(()=>{
  // },[stdentList])

  // useEffect(()=>{

  // },[props?.menus])

  // console.log(props.adminMenus,"sidermenu111",props?.menus)


    return (
      //Admin menus
      <>
                {/* {JSON.stringify(props?.menus)} */}

        {props?.menus && props.menus.map((item,index)=>{
            const isActive = props.current === item.link; // Check if the current item is active

          return<>
           <Menu theme="light" selectedKeys={[props.current]} mode="inline" onClick={props.handleClick}>
          <Menu.Item key={item.link} selectedKeys={[item.uId]} mode="inline" >
            <NavLink to={item.link} title={item.name} className={'menutitle'}>
             

<div className="menuItemContent">
<img
              src={studentMenuDetails[item.name]}
              className={`menuIcon ${isActive ? 'active' : ''}`}
              alt={`${item.name} icon`}
            />
              {/* <img src={studentMenuDetails[item.name]} className="menuIcon" alt={`${item.name} icon`} /> */}
              <div style={{flexDirection:"column"}}>
              {/* <span className="menuText">{item.name}</span> */}
              <span className={`menuText ${isActive ? 'active' : ''}`}>{item.name}</span>


              </div>
            </div>
          
            </NavLink>
          </Menu.Item>
          </Menu>
          </>

        })}


  

















      {/* </Menu> */}
        <div>
        <Menu theme="light" defaultSelectedKeys={["1"]} mode="inline">
        {/* <Menu.Item key="1"  onClick={handleClick}>
          <NavLink to={'/courseFee2'} title="Course Fee" className={'menutitle'}> 
          <img src={allCourses} className='menuIcon'/>
            <span>Course Fee2</span>
          </NavLink>
        </Menu.Item> */}

        
        {/* <Menu.Item key="3"  onClick={handleClick} >
          <NavLink to={'/courseCategory'} title="All Courses" className={'menutitle'}>
          <img src={dashboard} className='menuIcon'/>

            <span>Course Category</span>
          </NavLink>
        </Menu.Item> */}

        {/* <Menu.Item key="2"  onClick={handleClick} >
          <NavLink to={'/allCourses'} title="All Courses" className={'menutitle'}>
          <img src={dashboard} className='menuIcon'/>

            <span> Courses</span>
          </NavLink>
        </Menu.Item> */}



        {/* <Menu.Item key="4"  onClick={handleClick} >
          <NavLink to={'/allbatches'} title="All Batches" className={'menutitle'}>
          <img src={batchIcon} className='menuIcon'/>
            <span>Batches</span>
          </NavLink>
        </Menu.Item> */}

        {/* <Menu.Item key="5"  onClick={handleClick}>
          <NavLink to={'/allStudents'} title="All Students" className={'menutitle'}>
          <img src={allStudents} className='menuIcon'/>
            <span>Students</span>
          </NavLink>
        </Menu.Item> */}

        {/* <Menu.Item key="6" onClick={handleClick}>
          <NavLink to={'/allInstructors'} title="All Instructors" className={'menutitle'}>
          <img src={allInstructors} className='menuIcon'/>
            <span>Instructors</span>
          </NavLink>
        </Menu.Item> */}

        {/* <Menu.Item key="7" onClick={handleClick}>
          <NavLink to={'/announcements'} title="Announcements" className={'menutitle'}>
          <img src={announcements} className='menuIcon'/>
            <span>Announcements</span>
          </NavLink>
        </Menu.Item> */}

        {/* <Menu.Item key="8" onClick={handleClick}>
          <NavLink to={"/courseFee"} title="Accounts" className={'menutitle'}>
          <img src={accounts} className='menuIcon'/>
            <span>Course Fee</span>
          </NavLink>
        </Menu.Item> */}

      

        {/* <Menu.Item key="9" onClick={handleClick}>
          <NavLink to={"/settings"} title="Settings" className={'menutitle'}>
          <img src={settings} className='menuIcon'/>
            <span>Settings</span>
          </NavLink>
        </Menu.Item> */}

        {/* <Menu.Item key="10" onClick={handleClick}>
          <NavLink to={"/schedules"} title="Schedules" className={'menutitle'}>
          <img src={schedules} className='menuIcon'/>
            <span>Schedules</span>
          </NavLink>
        </Menu.Item> */}

        {/* <Menu.Item key="11" onClick={handleClick}>
          <NavLink to={"/rolesandPermissions"} title="Roles and permissions" className={'menutitle'}>
          <img src={roles} className='menuIcon'/>
            <span>Roles and  permissions</span>
          </NavLink>
        </Menu.Item> */}


        {/* <Menu.Item key="13" onClick={handleClick}>
          <NavLink to={"/studentDashboard"} title="Studentdashboard" className={'menutitle'}>
          <img src={roles} className='menuIcon'/>
            <span>StudentDashboard</span>
          </NavLink>
        </Menu.Item> */}
        
        {/* <Menu.Item key="12" onClick={handleClick}>
          <NavLink to={"/messages"} title="Roles and permissions" className={'menutitle'}>
          <img src={roles} className='menuIcon'/>
            <span>Messages</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="13" onClick={handleClick}>
          <NavLink to={"/allStudentCourses"} title="Student courses" className={'menutitle'}>
          <img src={roles} className='menuIcon'/>
            <span>All Student Coures</span>
          </NavLink>
        </Menu.Item>

        
 */}
        {/* <Menu.Item key="13" onClick={handleClick}>
          <NavLink to={"/courseFee"} title="Course feee" className={'menutitle'}>
          <img src={roles} className='menuIcon'/>
            <span>Course fee</span>
          </NavLink>
        </Menu.Item> */}


        {/* <Menu.Item key="14" onClick={handleClick}>
          <NavLink to={"/classesInfo"} title="Course feee" className={'menutitle'}>
          <img src={roles} className='menuIcon'/>
            <span>ClassesInfo</span>
          </NavLink>
        </Menu.Item> */}

        {/* <Menu.Item key="15" onClick={handleClick}>
          <NavLink to={"/adminSchedules"} title="Admin Schedule" className={'menutitle'}>
          <img src={roles} className='menuIcon'/>
            <span>Admin Schedule</span>
          </NavLink>
        </Menu.Item> */}
      </Menu>

        </div>

       
        {/* <div>
        <Menu theme="light" defaultSelectedKeys={["1"]} mode="inline">
        <Menu.Item key="1"  onClick={handleClick}>
          <NavLink to={'/main'} title="Dashboard" className={'menutitle'}> 
          <img src={allCourses} className='menuIcon'/>
            <span>Dashboard</span>
          </NavLink>
        </Menu.Item>
       

        <Menu.Item key="2"  onClick={handleClick}>
          <NavLink to={''} title="All courses" className={'menutitle'}> 
          <img src={allCourses} className='menuIcon'/>
            <span>All Courses</span>
          </NavLink>
        </Menu.Item>


        <Menu.Item key="3"  onClick={handleClick}>
          <NavLink to={''} title="Registered courses" className={'menutitle'}> 
          <img src={allCourses} className='menuIcon'/>
            <span>Registered Courses</span>
          </NavLink>
        </Menu.Item>


        <Menu.Item key="4"  onClick={handleClick}>
          <NavLink to={''} title="Schedules" className={'menutitle'}> 
          <img src={allCourses} className='menuIcon'/>
            <span>Schedules</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="4"  onClick={handleClick}>
          <NavLink to={''} title="Assignments" className={'menutitle'}> 
          <img src={allCourses} className='menuIcon'/>
            <span>Assignments</span>
          </NavLink>
        </Menu.Item>

        </Menu>
        </div> */}
        
        </>
    )
}
export default SiderMenuComponent