import { GET_INSTRUCTOR_SCHEDULES} from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const instructorScheduleReducer = (state = initialStates,action)=>{
    switch (action.type) {
       
            case GET_INSTRUCTOR_SCHEDULES:
                return{
                    instructorSchedules: action.payload
                }
        default:
            return state;
    }
}

export  default instructorScheduleReducer;