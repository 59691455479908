import React from 'react';

import { Tabs, Switch } from 'antd';
import AdminDemoClassDetails from './adminDemoclassDetails';
import AdminDemoClassRegistrationDetails from './adminDemoclassRegistrationDetails';
import './demoClass.css';


const { TabPane } = Tabs;







const AdminDemoClassTab = () => {
    return (
        <div className='classesTabContainer'>
             <div className='classesInfoSearch alignRight'>
              <div><h3>Demo Class</h3></div>
              <div><h3></h3></div>
          </div>
            <div>
        <Tabs defaultActiveKey="1" className='custom-tabs'>
        
          <TabPane tab="     DEMO CLASS      " key="2">
            <AdminDemoClassDetails/>

          </TabPane>
          <TabPane tab="DEMO CLASS REGISTRATION" key="3">
            <AdminDemoClassRegistrationDetails/>
            
          </TabPane>
          
        </Tabs>
        </div>
       
        </div>
      );
}

export default AdminDemoClassTab;
