let validateBatch = (fields)=>{
    let isbatchValid = true;
    let batchErrors = {}

    if (fields.batch_name == "") {
        batchErrors.batchNameError = "Batch name is required";
        isbatchValid = false
    }else{
        batchErrors.batchNameError = ""
    }
   
    if (fields.course_category_id == "") {
        batchErrors.categoryError = "Category is required";
        isbatchValid = false
    }else{
        batchErrors.categoryError = ""
    }

    if (fields.course_id == "") {
        batchErrors.courseNameError = "Course name is required";
        isbatchValid = false
    }else{
        batchErrors.courseNameError = ""
    }


    if (fields.instructor_id == "") {
        batchErrors.instructorNameError = "Instructor name is required";
        isbatchValid = false
    }else{
        batchErrors.instructorNameError = ""
    }


    if (fields.start_date == "") {
        batchErrors.startDateError = "Start date  is required";
        isbatchValid = false
    }else{
        batchErrors.startDateError = ""
    }

    if (fields.end_date == "") {
        batchErrors.endDateError = "End date  is required";
        isbatchValid = false
    }else{
        batchErrors.endDateError = ""
    }

    if (fields.class_time == "") {
        batchErrors.classTimeError = "Class time  is required";
        isbatchValid = false
    }else{
        batchErrors.classTimeError = ""
    }

    if (fields.week_days == "") {
        batchErrors.weekdaysError = "Weekdays  is required";
        isbatchValid = false
    }else{
        batchErrors.weekdaysError = ""
    }
    

    if (fields.meeting_link == "") {
        batchErrors.meetingError = "Meeting   is required";
        isbatchValid = false
    }else{
        batchErrors.meetingError = ""
    }

    return {
        isbatchValid,
        batchErrors
    }
}
export default  validateBatch;