import { ADMIN_COURSE_PRICE_CRUD,GET_ALL_COURSE_PRICES } from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';



export const getAllCoursePrices = (data)=>{
    console.log(data?.data,"actions")
    return{
        type: GET_ALL_COURSE_PRICES,
        payload: data?.data
    }
}

export const  coursePriceCrud = (data)=>{
    return {
        type:ADMIN_COURSE_PRICE_CRUD,
        payload:data
    }
}

//retrieve

export const retrieveAdminCoursePrices = ()=>{
    let payload = {
        "operation_type":"select",
        "tenant_id": window.localStorage.getItem("tenant_Id")
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/coursefees',payload
        ).then((res)=>{
            console.log(res?.data,"sai")
            if (res.data?.res_status == true) {
                dispatch(getAllCoursePrices(res.data));
               
            }
            if (res.data?.res_status == false) {
                dispatch(getAllCoursePrices([]));
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}


//Create
export const createAdminCoursePrice = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/coursefees',payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(coursePriceCrud(res?.data))
                dispatch(retrieveAdminCoursePrices(res.data));
               
            }
            if (res.data?.res_status == false) {
                dispatch(getAllCoursePrices([]));
                dispatch(coursePriceCrud(res?.data))

            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}


//update

export const updateAdminCoursePrice = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/coursefees',payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(coursePriceCrud(res?.data))
                dispatch(retrieveAdminCoursePrices(res.data));
               
            }
            if (res.data?.res_status == false) {
                dispatch(getAllCoursePrices([]));
                dispatch(coursePriceCrud(res?.data))

            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}

//delete

export const deleteAdminCoursePrice = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/coursefees',payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(coursePriceCrud(res?.data))
                dispatch(retrieveAdminCoursePrices(res.data));
               
            }
            if (res.data?.res_status == false) {
                dispatch(getAllCoursePrices([]));
                dispatch(coursePriceCrud(res?.data))

            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}