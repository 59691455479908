import { type } from "@testing-library/user-event/dist/type";
import { GET_ADMIN_ANNOUNCEMENTS,SET_ADMIN_ANNOUNCEMENTS,ANNOUNCEMENT_CRUD_STATUS, GET_FCMDATA} from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';


export const getAdminAnnouncements = (data) =>{
    return{
        type: GET_ADMIN_ANNOUNCEMENTS,
        payload: data
    }
}

export const getAnnouncementStatus = (data)=>{
     return {
        type: SET_ADMIN_ANNOUNCEMENTS,
        payload: data
     }
}

export const announcementCrudStatuss = (data)=>{
    return {
        type:ANNOUNCEMENT_CRUD_STATUS,
        payload:data
    }
}

export const createAdminAnnouncement = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/announcement",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getAnnouncementStatus(res.data?.res_status));
                let payload = 
                {
                    "operation_type":"retrieve"
                }
                dispatch(retrieveAdminAnouncements())
                dispatch(announcementCrudStatuss(res?.data))
            }
            if (res.data?.res_status == false) {
                dispatch(getAnnouncementStatus(res.data?.res_status));
                dispatch(announcementCrudStatuss(res?.data))

            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveAdminAnouncements = ()=>{
    let payload = 
        {
            "operation_type":"retrieve",
            "tenant_id":window.localStorage.getItem("tenant_Id")
        }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/announcement",payload
        ).then((res)=>{

            dispatch(getAdminAnnouncements(res.data));
            // console.log(res.data,'adminAnnouncements')

            // if (res.data?.res_status == true) {
            //     dispatch(getAdminAnnouncements(res.data));
            // }
            // if (res.data?.res_status == false) {
            //     dispatch(getAdminAnnouncements(res.data));
            // }
            
        }).catch(err=>{
            console.log(err)
        })
}
}


export const getFcmData= (data)=>{
    console.log(data,"checkAction")
    return{
        type:GET_FCMDATA,
        payload:data
    }
}

export const retrievemenetFcmDataForAnnouncements = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/notification",payload
        ).then((res)=>{
        //    if (res?.data?.res_status == true) {
              dispatch(getFcmData(res?.data))
        //    }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const announcementInserPushDetails = (payload)=>{

    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/groupchat",payload
      ).then((res)=>{
  
         console.log(res,"basic")
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }

  export const announcementFirebaseInsert = (payload) => {
    return (dispatch) => {
      axios.post(apiConfig.baseUrl+"ecomm/v2/firebase_chat", payload)
        .then((res) => {
          if (res?.data?.res_status == true) {
            console.log(res,"fbResponse")            
          }
  
         
        })
        .catch(err => {
          console.log(err);
        });
    };
  };


  //delete announcement
  export const deleteAnnouncementRecord = (payload) => {
    return (dispatch) => {
      axios.post(apiConfig.baseUrl+"e_lms/v1/announcement", payload)
        .then((res) => {
          if (res?.data?.res_status == true) {
            dispatch(announcementCrudStatuss(res?.data))
            dispatch(retrieveAdminAnouncements())     
          }
  
         
        })
        .catch(err => {
          console.log(err);
        });
    };
  };


  export const retrievemenetFcmDataForAnnouncementss = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/notification",payload
        ).then((res)=>{
        //    if (res?.data?.res_status == true) {
              dispatch(getFcmData(res?.data))
        //    }
        }).catch(err=>{
            console.log(err)
        })
}
}

  //end