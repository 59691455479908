import React, { useEffect, useState } from 'react'
import { InputComponent, ModalComponent, TableComponent } from '../../commonComponents'
import { course, deleteIcon, edit, plus, searchIcon,excel } from '../../images'
import { Form, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AddCourseFee from './addCourseFee/addCourseFee';
import dayjs from 'dayjs';

import moment from 'moment';
import { getCourses } from '../../redux/allCourses/action';
import { retrieveBatches } from '../../redux/batches/action';
import { type } from '@testing-library/user-event/dist/type';
import { AdminCourseFeeDelete, createAdminCourseFee, retrieveAllAdminCourseFee, retrieveCoursesBasedOnBatchId, retrievePriceBasedOnCourse, 
  updateAdminCourseFee ,basedOnStudentIdGetBatchDetails} from '../../redux/adminCourseFee/action';
import { loadStudents } from '../../redux/student/action';
import './courseFee.css';

import * as XLSX from 'xlsx';
import ExportToExcel from '../../commonComponents/XlsxFile/ExportToExcel'


let CourseFee=()=> {

  const [api, contextHolder] = notification.useNotification();
  let [searchEntry,setSearchEntry] = useState("")

  let courseFeeCrudStatus = useSelector((state)=>state.AdminCourseFeeReducer?.adminCourseFeeCrudStatus);
  let basedOnStudentIDBatchedId = useSelector((state)=>state.AdminCourseFeeReducer?.getAllBatchesBasedOnStudentId);


  useEffect(()=>{

    console.log(basedOnStudentIDBatchedId,'basedOnStudentIDBatchedId')
    if(basedOnStudentIDBatchedId){
      setAllBatches(basedOnStudentIDBatchedId?.data)
    }
  },[basedOnStudentIDBatchedId])



  const searchCourseFee = (e) =>{
    setSearchEntry(e?.target?.value)
  }

  let columns = [
    {
      title: 'ID',
      dataIndex: 'course_fee_id',
      sorter: true,
      sorter: (a, b) => a.course_fee_id - b.course_fee_id,
      filteredValue: [searchEntry],
            onFilter: (value,record)=>{
               return String(record.course_fee_id).toLowerCase().includes(value.toLowerCase())||
                      String(record.student_id).toLowerCase().includes(value.toLowerCase())||
                      String(record.student_name).toLowerCase().includes(value.toLowerCase())||
                      String(record.first_name).toLowerCase().includes(value.toLowerCase())||
                      String(record.last_name).toLowerCase().includes(value.toLowerCase())||
                      String(record.batch_names).toLowerCase().includes(value.toLowerCase())||
                      String(record.course_names).toLowerCase().includes(value.toLowerCase())||
                      String(record.course_fee).toLowerCase().includes(value.toLowerCase())||
                      String(record.discount_rupees).toLowerCase().includes(value.toLowerCase())||
                      String(record.discounted_fee).toLowerCase().includes(value.toLowerCase())||
                      String(record.paid_amount).toLowerCase().includes(value.toLowerCase())||
                      String(record.due_amount).toLowerCase().includes(value.toLowerCase())

                    }

    },
    {
      title: 'Student ID',
      dataIndex: 'student_id',
      sorter: true,
      sorter: (a, b) => a.student_id - b.student_id,
      
    },
    {
      title: 'Student Name',
      dataIndex: 'student_name',
      sorter: true,
      sorter: (a, b) => a.student_name .localeCompare(b.student_name),
    },
    
    {
      title: 'Batch Name',
      dataIndex: 'batch_names',
      sorter: true,
      sorter: (a, b) => a.batch_names .localeCompare(b.batch_names),

    },
    {
      title: 'Course Name',
      dataIndex: 'course_names',
      sorter: true,
      sorter: (a, b) => a.course_names .localeCompare(b.course_names),

    },
    {
      title: 'Course Fee',
      dataIndex: 'course_fee',
      sorter: true,
      sorter: (a, b) => a.course_fee - (b.course_fee),


    },
    {
      title: 'Discount',
      dataIndex: 'discount_rupees',//discount_in_percentage
      sorter: true,
      sorter: (a, b) => a.discount_rupees - b.discount_rupees,

    },
    {
      title: 'Discounted Fee',
      dataIndex: 'discounted_fee',
      sorter: true,
      sorter: (a, b) => a.discounted_fee - b.discounted_fee,

    },
    {
      title: 'Paid Amount',
      dataIndex: 'paid_amount',
      sorter: true,
      sorter: (a, b) => a.paid_amount - b.paid_amount,

    },
    {
      title: 'Due Amount',
      dataIndex: 'due_amount',
      sorter: true,
      sorter: (a, b) => a.due_amount - b.due_amount,

    },
    
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <div className="actionIcons">
           <img src={edit} onClick={()=>handleEditCourse("Edit Student Fee",record.course_fee_id)} />
           <img src={deleteIcon} onClick={() => handleDeleteModal(record.course_fee_id)} />
        </div>
      ),
    }
  ]
  let [courseFeeModal,setCourseFeeModal] = useState(false);

  let [courseFee,setCourseFee] = useState([]);
  let [courses,setCourses] = useState();

  let [editCourseFee,setEditCourseFee] = useState("");

  let [allBatches,setAllBatches] = useState("");

  let [students,setStudents] = useState();

  let [modalType,setModalType] = useState();

  let [editCourseFeeId,setEditCourseFeeId] = useState("");


  let getAllCourses = useSelector((state)=>state.courses.courses);

  let retrieveBatcheRecords = useSelector((state)=>state.batchcourseDetails?.batches?.data.data)

  let allAdminCourseFees =  useSelector((state)=>state.AdminCourseFeeReducer)

  let adminCourseCreate = useSelector((state)=>state.AdminCourseFeeReducer?.adminCourseFeeCreation);

  let allStudents = useSelector((state) => state.student.students?.data);

  
  // let isCourseCrudSuccess = useSelector((state)=>state.AdminCourseFeeReducer?.adminCourseFeeCreation)
  
  let getCoursesFromBatchId = useSelector((state)=>state.AdminCourseFeeReducer?.getCoursesFromBatchId);
  
  let dispatch = useDispatch()
  const [form] = Form.useForm();

  useEffect(()=>{
    dispatch(getCourses())
    dispatch(loadStudents())
  },[])

  useEffect(()=>{
    dispatch(retrieveBatches())
  },[])

  useEffect(()=>{
    dispatch(retrieveAllAdminCourseFee())
  },[])

useEffect(()=>{
  if (allAdminCourseFees) {
     setCourseFee(allAdminCourseFees?.allAdminCourseFee?.data)
    console.log(allAdminCourseFees?.data,"courseFee")
    console.log(allAdminCourseFees,"datat")
  }else{
    setCourseFee([])

  }
},[allAdminCourseFees])

// useEffect(()=>{ 
//   if (getAllCourses) {
//     setCourses(getAllCourses?.data)
//   }
// },[getAllCourses])

// useEffect(()=>{
//     if (retrieveBatcheRecords) {
//       setAllBatches(retrieveBatcheRecords)
//     }
// },[retrieveBatcheRecords])


// useEffect(()=>{
//       if (adminCourseCreate) {
//         setCourseFeeModal(false);
//         dispatch(retrieveAllAdminCourseFee())
//       }
// },[adminCourseCreate])

useEffect(()=>{
    if (allStudents) {
      setStudents(allStudents)
    }
},[allStudents])

let handleEditCourse= (type,id) =>{
  setCourseFeeModal(true);
  setModalType("Edit Student Fee");
  setEditCourseFeeId(id)
  // setCourseFeeDetails({["course_fee"]:""});
  console.log(courseFee,"ed",id)
  courseFee.filter((item,index)=>{
    if (item.course_fee_id == id) {
      console.log(item.batch_id,item.course_id,item)
      setCourseFeeDetails({
        first_name: item.first_name,
        last_name : item.last_name,
        course_fee: item.course_fee,
        discount_rupees: item.discount_rupees,
        due_date: item.due_date,
        paid_amount: item.paid_amount,
        course_name: item.course_id,
        batch_name: item?.batch_id,
        student_id: item?.student_id
      })  

      let payload ={
        "operation_type":"view_student_batch",
        "student_id":item?.student_id,
        "tenant_id":window.localStorage.getItem("tenant_Id"),
    }
 
    dispatch(basedOnStudentIdGetBatchDetails(payload))

      form.setFieldsValue({["first_name"]:item.first_name})
      form.setFieldsValue({["last_name"]:item.last_name})
      form.setFieldsValue({["course_fee"]:item.course_fee})
      form.setFieldsValue({["discount_rupees"]:item.discount_rupees})
      form.setFieldsValue({["due_date"]:item.due_date})
      form.setFieldsValue({["paid_amount"]:item.paid_amount})
      form.setFieldsValue({["course_name"]:item.course_id})
      form.setFieldsValue({["batch_name"]:item.batch_id})
      form.setFieldsValue({["student_id"]:item.student_id})
    }
})
}

let handleDeleteModal = (id)=>{
      let payload =
      {
          "operation_type":"delete",
          "op_field_details":{
              "course_fee_id":[id]
          }
      }
      dispatch(AdminCourseFeeDelete(payload));
}

  
  let handleModal = ()=>{
    setModalType("Add Student Fee")

    setCourseFeeModal(true)
  }

  let closeModal = ()=>{
    setCourseFeeModal(false)
    dispatch(retrieveAllAdminCourseFee())

    setCourseFeeDetails({first_name:"",
    last_name: "",
    batch_name:"",
    course_name:"",
    course_fee:"",
    discount_rupees:"",
    paid_amount:"",
    // due_date:"",
    student_id:""
})

  }
  let [ courseFeeDetails,setCourseFeeDetails] = useState({
    first_name:"",
    last_name: "",
    batch_name:"",
    course_name:"",
    course_fee:"",
    discount_rupees:"",
    paid_amount:"",
    // due_date:"",
    student_id:""
})
 
let handleChange = (e,type)=>{
  console.log(e)
  // if (e) {
  //     setCourseFeeDetails({...courseFeeDetails,[type.name]:e});
  //     form.setFieldsValue({[type.name]: e})
  //     }
      

      if (type.name == "course_fee") {
        setCourseFeeDetails({...courseFeeDetails,[type.name]:e});
         form.setFieldsValue({[type.name]: e})
      }

      if (type.name == "student_id") {
        setCourseFeeDetails({...courseFeeDetails,[type.name]:e});
        form.setFieldValue({[type.name]:e})

        let payload ={
          "operation_type":"view_student_batch",
          "student_id":e,
          "tenant_id":window.localStorage.getItem("tenant_Id"),
      }
      setCourseFeeDetails({...courseFeeDetails,["batch_name"]:"Select"});
      form.setFieldsValue({["batch_name"]:"Select"})
      dispatch(basedOnStudentIdGetBatchDetails(payload))



      }

      if (type.name == "due_date") {
        const date = new Date(e);
        const formattedDate = moment(date).format('YYYY-MM-DD');

      setCourseFeeDetails({...courseFeeDetails,[type.name]:formattedDate});

      }

      if(type.name == "batch_name"){
        setCourseFeeDetails({...courseFeeDetails,[type.name]:e})
        form.setFieldsValue({[type.name]:e})

        let payload ={
          "operation_type":"retrieve_course_on_batch",
          "batch_id":e,
          "tenant_id":window.localStorage.getItem("tenant_Id")
      }
      console.log(payload)
      setCourseFeeDetails({...courseFeeDetails,["course_name"]:"select"});
      form.setFieldsValue({["course_name"]:"select"})
      dispatch(retrieveCoursesBasedOnBatchId(payload))
      }
 
  if (e.target !== undefined) {
      const { name, value } = e.target;
      setCourseFeeDetails({ ...courseFeeDetails, [name]: value });
      form.setFieldsValue({[type.name]: e})
      
    } else {
      setCourseFeeDetails({ ...courseFeeDetails, [type.name]: e })
      form.setFieldsValue({[type.name]: e})

    }
    console.log(courseFeeDetails,"vaues")
}


useEffect(()=>{
  if (getCoursesFromBatchId) {
    console.log(getCoursesFromBatchId,"getCoursesFromBatchId")
    setCourses(getCoursesFromBatchId?.data)
  }
},[getCoursesFromBatchId])
//Fetch course price based on course id
const price = useSelector((state)=>state?.AdminCourseFeeReducer?.getCoursePrice);

const [adminCoursePrice,setAdminCoursePrice] = useState("")
  useEffect(()=>{
      if (courseFeeDetails?.course_name) {
        let payload={
          "operation_type":"view_course_price",
          "op_field_details":{
              "course_id":courseFeeDetails?.course_name,
               "tenant_id":window.localStorage.getItem("tenant_Id")
          }
      }
      dispatch(retrievePriceBasedOnCourse(payload))
      }
  },[courseFeeDetails?.course_name])


  useEffect(()=>{
    if (price) {
      // setAdminCoursePrice(price?.data?.course_fee);
      console.log(price)
      if (price?.res_status == true) {
         setCourseFeeDetails({...courseFeeDetails,["course_fee"]:price?.data[0]?.course_fee})
         form.setFieldsValue({["courseFee"]:price?.data[0]?.course_fee})


      }else{
         setCourseFeeDetails({...courseFeeDetails,["course_fee"]:"Fee not available"})
        
      }
      // console.log(price?.data[0]?.course_fee,price?.data?.course_fee,"ppppppppppppp")
    }
  },[price])
//fetch course price end



let [selectedDate,setSelectedDate] = useState("")
let handleDueDate = (e,type) =>{
  // setCourseFeeDetails({...courseFeeDetails,"due_date":e})
const date = new Date(e);
const formattedDate = moment(date).format('YYYY-MM-DD');

setCourseFeeDetails({...courseFeeDetails,[type.name]:formattedDate});
  // console.log(selectedDate,"datepicker")
  form.setFieldsValue({[selectedDate]: formattedDate})

}


let handleOnlyDigits = (e,type) =>{
  setCourseFeeDetails({...courseFeeDetails,[type.name]:e.target?.value})
  form.setFieldsValue({["course_fee"]:e?.target?.value})
}
let onFinish= (values)=>{
  console.log(values,"finishvalues")
  if (modalType != "Edit Student Fee") {
    let payload = {


      // "operation_type": "insert",
      // "op_field_details": {
      //     "student_id": 44,
      //     "first_name": "",
      //     "last_name": "",
      //     "batch_id": 321,
      //     "course_id": 80,
      //     "course_fee": 15000,
      //     "discount_in_percentage": 500,
      //     "due_date": "2024-05-31",
      //     "tenant_id": "1",
      //     "paid_amount": 100
      // }


      "operation_type":"insert",
      "op_field_details":{
          "student_id":courseFeeDetails?.student_id,
          "first_name": courseFeeDetails.first_name,
          "last_name": courseFeeDetails.last_name,
          "batch_id": courseFeeDetails.batch_name,
          "course_id": courseFeeDetails.course_name,
          "course_fee": courseFeeDetails.course_fee,
          "discount_in_percentage":parseInt(courseFeeDetails.discount_rupees),
          "paid_amount": courseFeeDetails.paid_amount,

          "due_date":  moment(courseFeeDetails.due_date ).format('YYYY-MM-DD'),

          "tenant_id":window.localStorage.getItem("tenant_Id"),
      }
  }
  console.log(payload,"courseFeepayload")
  
     dispatch(createAdminCourseFee(payload))  
  }else{
    let payload = {
      "operation_type":"update",
      "op_field_details":{
          "course_fee_id":editCourseFeeId,
          "student_id":courseFeeDetails?.student_id,
          "first_name": courseFeeDetails.first_name,
          "last_name": courseFeeDetails.last_name,
          "batch_id":courseFeeDetails.batch_name,
          "course_id":courseFeeDetails.course_name,
          "course_fee":courseFeeDetails.course_fee,
          "discount_in_percentage":parseInt(courseFeeDetails.discount_rupees),
          "paid_amount":courseFeeDetails.paid_amount,
          "due_date":moment(courseFeeDetails.due_date ).format('YYYY-MM-DD'),
          "tenant_id":parseInt(window.localStorage.getItem("tenant_Id")),
      }
  }
  console.log(payload,"editCourseFee")
  dispatch(updateAdminCourseFee(payload))
  setCourseFeeDetails({
    first_name: "",
    last_name : "",
    course_fee: "",
    discount_rupees: "",
    due_date: "",
    paid_amount: "",
    course_name: "",
    batch_name: "",
    student_id: ""
  })  
  }
  
}

useEffect(()=>{
  if (courseFeeCrudStatus) {
    openNotification(courseFeeCrudStatus?.msg,courseFeeCrudStatus?.res_status)
  }
},[courseFeeCrudStatus])

const openNotification = (msg,announcementCrudStatus) => {
if (msg && announcementCrudStatus== false) {
    api.info({
        description:
        `${msg}`,
      });
}

if (msg && announcementCrudStatus == true) {
  api.info({
      description:
      `${msg}`,
    });
    setCourseFeeModal(false)
    dispatch(retrieveAllAdminCourseFee())

    setCourseFeeDetails({first_name:"",
    last_name: "",
    batch_name:"",
    course_name:"",
    course_fee:"",
    discount_rupees:"",
    paid_amount:"",
     due_date:"",
    student_id:""
})

}
}
const exportToExcel = (data, fileName) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};


const transformedItems = courseFee?.map((item) => {
 

  return { 
     COURSE_FEE_ID: item.course_fee_id,
     STUDENT_ID:  item.student_id,
    STUDENT_NAME:  item.student_name,
    BATCH_NAME: item.batch_names,
    COURSE_NAME:  item.course_names,
   COURSE_FEE:  item.course_fee,
  
   DISCOUNT_PRICE:  item.discount_rupees,
   DISCOUNT_FEE:  item.discounted_fee,
   PAID_AMOUNT: item.paid_amount,
   DUE_AMOUNT:  item.due_amount,
  };
});

let handleCategoryModal1 = (type,id) =>{
   
  exportToExcel(transformedItems, 'student_fee');

  
}

return (
    <div>
            {contextHolder}

      <div className='courseFeeHeader'>
              <div>
                  <h3>Student Fee</h3>
                </div>
             
                <div className='alignRight'>
                <div className='searchBatch alignRight'>
                          <InputComponent onChange={(e)=>searchCourseFee(e)} 
                          className='searchField' placeholder={'Search'}/>
                          <img src={searchIcon} className="searchIconBatches" style={{marginBottom:'15px',}}/>

                      </div>
                  <img src={plus} className="exportIcons" onClick={handleModal} />

                  <div style={{marginTop:"10px"}}>

<img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

</div>
                </div>
              
                
                {/* <img src={upload} className="exportIcons" /> */}
            </div>
      <TableComponent columns={columns} data={courseFee}/>
      
      {courseFeeModal?
      <ModalComponent title={modalType}
      content={<AddCourseFee form={form} handleChange ={handleChange}
      courseFeeDetails={courseFeeDetails} onFinish={onFinish} selectedDate={selectedDate}
       handleDueDate ={handleDueDate} courses={courses} allBatches={allBatches}
       handleOnlyDigits={handleOnlyDigits} students={students} closeModal={closeModal}/>}
      open={courseFeeModal}
      handleCancel={closeModal}
    //  footer= {categoryFooter}
    style={{ minHeight: `500px` }}

     > </ModalComponent>
     : ""} 
    </div>
  )
}

export default CourseFee