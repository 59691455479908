import {GET_INSTRUCTORS,SET_INSTRUCTORS,EMAIL_EXISTS,GET_INSTRUCTORS_BY_CATEGORY, SET_INSTRUCTORS_BY_CATEGORY} from './actionTypes';

import axios from 'axios';
import apiConfig from '../../apiConfig/api';


const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    };

export const setInstructorDetail =(data)=>{
    return {
        type: SET_INSTRUCTORS,
        payload: data
    }
}


export const getAllInstructors = (data)=>{
    return {
        type: GET_INSTRUCTORS,
        payload: data
    }
}

export const emailExistsInstructors = (data)=>{
    return {
        type: EMAIL_EXISTS,
        payload: data
    }
}

export const setInstructorsForCategory = (data)=>{
        return {
            type: SET_INSTRUCTORS_BY_CATEGORY,
            payload: data
        }
}

export const createInstructor = (data) =>{
    return (dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor",data, {headers}
            ).then((res)=>{
                console.log(res.data.res_status,'hello')
                
                    if (res.data.res_status == false) {
                        console.log(res.data,'validate')
                        dispatch(emailExistsInstructors(res.data?.res_status))
                    }else{
                        // dispatch(setInstructorDetail(res));
                        dispatch(getInstructors())
                        dispatch(emailExistsInstructors(res.data?.res_status))

                }
                // dispatch(loadStudents())
    
            }).catch(err=>{
                console.log(err)
            })
    }
}


//Get all instructors
export const  getInstructors = () =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor",{"operation_type": "retrieve",
            "tenant_id": window.localStorage.getItem("tenant_Id")
        }
        ).then((res)=>{
            dispatch(getAllInstructors(res.data));

        }).catch(err=>{
            console.log(err)
        })
}
    
}

// Delete instructor by id

export const deleteInstructor=(data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor",data
        ).then((res)=>{
            console.log(res.data.res_status,'hello')
            dispatch(getInstructors());

            if (res.data.res_status == true) {
                dispatch(emailExistsInstructors(res.data?.res_status))
            }
        }).catch(err=>{
            console.log(err)
        })
    }
}

//update instructor

export const updateInstructor = (data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor",data
        ).then((res)=>{
            console.log(res.data.res_status,'hello')

            if (res.data.res_status == true) {
                dispatch(emailExistsInstructors(res.data?.res_status));
                dispatch(getInstructors());
            }

        }).catch(err=>{
            console.log(err)
        })
    }
}





export const getInstructorsForCategory = (data)=>{
    console.log(data,"catIdpayload")
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/courses",data
        ).then((res)=>{
            console.log(res.data.res_status,'hello')
            dispatch(setInstructorsForCategory(res.data));

        }).catch(err=>{
            console.log(err)
        })
    }
}


