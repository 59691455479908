import {GET_ACTIVE_USER_REPORTS,GET_STUDENT_ATTENDANCE} from "./actionTypes";

 const ActiveUserReportsReducer = (state = [],action)=>{

    switch (action.type) {
        case GET_ACTIVE_USER_REPORTS:
            
            return {
                adminActiveUserReports: action.payload
            }

            case GET_STUDENT_ATTENDANCE:
            
            return {
                adminStudentAttendance: action.payload
            }
         default:
            return state;
    }
}

export  default ActiveUserReportsReducer;