import { ADMIN_COURSE_FEE_CREATION_SUCCESS,ADMIN_COURSE_FEE_CREATION_FAIL,
    GET_ADMIN_COURSE_FEEDATA ,COURSE_FEE_CRUD_STATUS, GET_PRICE_BASED_ON_COURSE,
    GET_COURSES_BASED_ON_BATCH,GET_BATCH_BASED_ON_STUDENT_ID} from "./actionTypes";

 const AdminCourseFeeReducer = (state = [],action)=>{

    switch (action.type) {
            
        case GET_ADMIN_COURSE_FEEDATA:
            return {
                allAdminCourseFee: action.payload
            }

            case COURSE_FEE_CRUD_STATUS:
                return{
                    adminCourseFeeCrudStatus: action.payload
                }

            case GET_PRICE_BASED_ON_COURSE:
                return{
                    getCoursePrice:action.payload
                }

            case GET_COURSES_BASED_ON_BATCH:
                return{
                    getCoursesFromBatchId: action.payload
                }

                case GET_BATCH_BASED_ON_STUDENT_ID:
                    return{
                        getAllBatchesBasedOnStudentId: action.payload
                    }

        default:
            return state;
    }
}

export  default AdminCourseFeeReducer;