import { COURSE_SUCCESS, GET_COURSES, SET_COURSES ,ADMIN_COURSE_CRUD_STATUS,
    ALL_BATCHES
} from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    courseRecords:[],
    error:" ",
}

 const courseReducer = (state = initialStates,action)=>{

    switch (action.type) {
        
        case  SET_COURSES:
            return {
                ...state,
                data: action.payload,
            }

            case GET_COURSES:
            return{
                ...state,
                courses: action.payload,
                loading: false,
            }

            case COURSE_SUCCESS:
            return{
                course_success : action.payload
            }

            case ADMIN_COURSE_CRUD_STATUS:

            return {
                adminCourseCrudStatus: action.payload
            }

            case ALL_BATCHES:

            return {
                allBatches: action.payload
            }
        
        default:
            return state;
    }
}

export  default courseReducer;