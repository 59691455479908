import {GET_ADMIN_SCHEDULES} from './actionTypes';

import axios from 'axios';

import apiConfig from '../../apiConfig/api';

export const getAdminSchedules = (data)=>{
    return {
        type: GET_ADMIN_SCHEDULES,
        payload:data
    }
}

export const retrieveAdminSchedules = ()=>{
    let payload = {
        "operation_type":"view_batch_schedules",
         "tenant_id": window.localStorage.getItem("tenant_Id"),
    }

    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/admin_schedules",payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(getAdminSchedules(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}