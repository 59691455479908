import React, { useState } from 'react';
import { Button, Modal } from 'antd';
const ModalComponent = (props) => {
    // const [open, setOpen] = useState(false);
    // const showModal = () => {
    //   setOpen(props.open);
    // };
    // const handleOk = (e) => {
    //   console.log(e);
    //   setOpen(false);
    // };
    // const handleCancel = (e) => {
    //   console.log(e);
    //   setOpen(false);
    // };
    return (
      <>
        <Modal
          title={props.title}
          width={props.width}
          height = {props.height}
          open={props.open}
          onOk={props.handleOk}
          onCancel={props.handleCancel? props.handleCancel : props.closeModal}
          footer= {props.footer ? props.footer : ""}
          maskClosable={false}
          afterClose= {props.handleCancel}
          style={{zIndex:1000}}
        >
            <div>
                {props.content}
            </div>
        </Modal>
      </>
    );
};
export default ModalComponent;