import { GET_SINGLE_COURSE, GET_STUDENT_ALL_COURSES,GET_STUDENT_SCHEDULES,RETRIEVE_STUDENT_DETAILS_CALENDAR} from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const studentAllCoursesReducer = (state = initialStates,action)=>{
    switch (action.type) {
        case  GET_STUDENT_ALL_COURSES:
            return {
                viewAllCourses: action.payload,
            }
        
            case GET_STUDENT_SCHEDULES:
                return{
                    viewAllSchedules: action.payload
                }
            
            case GET_SINGLE_COURSE:
                return{
                    viewSingleCourse: action.payload
                }

                case RETRIEVE_STUDENT_DETAILS_CALENDAR:
                    return{
                        retrieveStudentDetailsCalender: action.payload
                    }
        default:
            return state;
    }
}

export  default studentAllCoursesReducer;