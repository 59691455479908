import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import  {retrieveStudentAttendance}  from '../../../redux/adminReports/activeUserReports/action.js';

// import { retrieveStudentAttendance } from '../../../redux/adminReports/activeUserReports/action.js';
import { excel, plus, upload, searchIcon } from "../../../images";

import  TableComponent from '../../../commonComponents/TableComponent/TableComponent.js'
import { Table } from 'antd';

import * as XLSX from 'xlsx';
import ExportToExcel from '../../../commonComponents/XlsxFile/ExportToExcel'
const StudentAttendanceReports = (props) => {

    let dispatch = useDispatch();

    let adminActiveUserReports = useSelector((state)=>state?.ActiveUserReportsReducer?.adminActiveUserReports);

    const [adminStudentReports,setAdminStudentReports] = useState();

    useEffect(()=>{
        let payload = {
            "operation_type":"student_join_class_count",
            "month":props.selectedMonth,
            "year":props.selectedYear,
        "tenant_id": window.localStorage.getItem("tenant_Id")
        }
        dispatch(retrieveStudentAttendance(payload))
    },[props.selectedYear])

    useEffect(()=>{
        let payload = {
            "operation_type":"student_join_class_count",
            "month":props.selectedMonth,
            "year":props.selectedYear,
        "tenant_id": window.localStorage.getItem("tenant_Id")
        }
        dispatch(retrieveStudentAttendance(payload))
    },[props.selectedMonth])

    useEffect(()=>{

        console.log(adminActiveUserReports?.data,"activeReprts")

        if (adminActiveUserReports) {
            setAdminStudentReports(adminActiveUserReports?.data)
            console.log(adminActiveUserReports?.data,"activeReprts")
        }
    },[adminActiveUserReports])

    const columns = [
        {
            title: 'Student Id',
            dataIndex: 'student_id',
            sorter:true,
            sorter: (a, b) => a.student_id -(b.student_id),
        },
          {
            title: 'Batch Name',
            dataIndex: 'batch_name',
            sorter:true,
           sorter: (a, b) => a.batch_name .localeCompare(b.batch_name),
      
          },
          {
            title: 'Student Name',
            dataIndex: 'fullname',
            sorter:true,
           sorter: (a, b) => a.fullname .localeCompare(b.fullname),
      
          },
          {
            title: 'Month - Year',
            dataIndex: 'month_name',
            sorter:true,
           sorter: (a, b) => a.month_name .localeCompare(b.month_name),
      
          },
          {
            title: 'Class Count',
            dataIndex: 'class_count',
            sorter:true,
           sorter: (a, b) => a.class_count - b.class_count,
      
          },
         

    ]


    const exportToExcel = (data, fileName) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
      };
      
      
      const transformedItems = adminStudentReports?.map((item) => {
       
      
        return { 
           STUDENT_ID: item.student_id,
           BATCH_NAME:  item.batch_name,
          STUDENT_NAME:  item.fullname,
          MONTH_NAME: item.month_name,
          CLASS_COUNT:  item.class_count,
        
        
        };
      });
    let handleCategoryModal1 = (type,id) =>{
        exportToExcel(transformedItems, 'student_attendance_report');

  
  
    }



    return (
        <div>

           
<div style={{display:'flex' ,justifyContent:'flex-end',marginBottom:'10px'}}>

<img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

</div>
             <TableComponent columns={columns} data={adminStudentReports}/>
         </div>
    );
}

export default StudentAttendanceReports;
