import { CREATE_INSTRUCTOR_CANCEL_CLASS,GET_ALL_INSTRUCTOR_CANCEL_CLASS
,CHANGE_CANCEL_CLASS_STATUS,CANCEL_CALSS_STUDENT_NOTIFICATION,CANCEL_CLASS_REJECTED} from "../instructorCancelClass/actionTypes";
import apiConfig from '../../apiConfig/api';

import axios from "axios";

export const getCreateCancelClassStatus = (data)=>{
    return {
        type:CREATE_INSTRUCTOR_CANCEL_CLASS,
        payload:data
    }
}

export const getAllInstructorCanceledClass = (data)=>{
    return{
        type: GET_ALL_INSTRUCTOR_CANCEL_CLASS,
        payload:data
    }
}

export const updateCancelClassStatus = (data) =>{
        return {
            type:CHANGE_CANCEL_CLASS_STATUS,
            payload:data
        }
}

export const calcellClassStudentNotification = (data) =>{
    return {
        type:CANCEL_CALSS_STUDENT_NOTIFICATION,
        payload:data
    }
}


export const cancelClassRejected = (data) =>{
    return {
        type:CANCEL_CLASS_REJECTED,
        payload:data
    }
}





export const createInstructorCancelClass = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/cancel_class",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getCreateCancelClassStatus(res.data));  
                
                console.log(res.data,'hari')
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}


export const retrieveCancelledClasses = ()=>{
    let payload = {
        "operation_type": "retrieve",
        "tenant_id":window.localStorage.getItem("tenant_Id")
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/cancel_class",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getAllInstructorCanceledClass(res.data));    
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}

export const changeCancelClassStatus = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/cancel_class",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(updateCancelClassStatus(res.data)); 
                dispatch(retrieveCancelledClasses())   
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}


export const cancelClassNotificationToStudents = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/notification",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(calcellClassStudentNotification(res.data)); 
                // dispatch(retrieveCancelledClasses())   
            }else{

            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}

export const cancelClassRejectedNotification = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/notification",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(cancelClassRejected(res.data)); 
                // dispatch(retrieveCancelledClasses())   
            }else{

            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}