import React, { useEffect, useState } from 'react'
import { ButtonComponent, TableComponent } from '../../../commonComponents';
import './upcomingCourses.css';
import { Card,Spin,Button ,notification} from 'antd';
import { useDispatch,useSelector } from 'react-redux';

import {addClassStudnetAttendance} from '../../../redux/studentDashboard/relatedCourses/action.js';


let UpcomingCourses=(props)=> {
  let dispatch = useDispatch();

        console.log(props?.list?.data,"upcomingCourses")
        let [data,setData] = useState([]);
    const columns = [
        
        {
            title: 'Course Name',
            dataIndex: 'course_name',
            sorter: true,
            // sorter: (a, b) => a.course_category_id - b.course_category_id,
    
          },
        {
            title: 'Course Category',
            dataIndex: 'course_category',
            sorter: true,
            // sorter: (a, b) => a.course_category_id - b.course_category_id,
    
          },
        {
          title: 'Course Time',
          dataIndex: 'course_time',
          sorter: true,
        //   sorter: (a, b) => a.course_category_id - b.course_category_id,
  
        },

        {
            title: 'Instructor Name',
            dataIndex: 'instructor_name',
            sorter: true,
            sorter: (a, b) => a.instructor_name .localeCompare(b.instructor_name),
          },

          {
            title: 'Week Day',
            dataIndex: 'new_week_days',
            sorter: true,
            sorter: (a, b) => a.new_week_days .localeCompare(b.new_week_days),
          },
          
        {
            title: 'Meeting Link',
            dataIndex: 'status',
            sorter: true,
            render: (text, record) => (
              <div className="">
                    <Button type="primary" onClick={(e) => joinClass(e, { batch_id: record?.batch_id,meeting_link: record?.meeting_link })}>
        Join class
      </Button>
{/* <Button type="primary" primary>
                <a href={record?.meeting_link}>Join class</a>
                </Button>          */}
              </div>
            ),
          },

          
          
    ]

    const [api, contextHolder] = notification.useNotification();

    let addStudentAttendance = useSelector((state)=>state.relatedCoursesReducer?.addAttendanceStudent);
    let [meetingLink,setMeetingLink] = useState();
    let [isActive,setIsActive] = useState(false);

    useEffect(()=>{
      if(addStudentAttendance){
          //res_status
          console.log(addStudentAttendance?.msg,'addStudentAttendance')

          openNotification(addStudentAttendance?.msg,addStudentAttendance?.res_status)
      }
    },[addStudentAttendance])

    const openNotification=(msg,status)=>{
      if (status == true) {   

          console.log(meetingLink,'meetingLink')
          

          if(isActive == true){
              window.location.href = meetingLink;

          }else{

          }

          setIsActive(false)
        }
        if (status == false) {

          if(isActive == true){
              api.info({
                  description:
                    `${msg}`,
                });
          }else{

          }
         

          setIsActive(false)

        }
  }


  const joinClass = (e,idd) => {

    setMeetingLink(idd.meeting_link)
    setIsActive(true)
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns month from 0-11
    const day = String(today.getDate()).padStart(2, '0');
  
    const formattedDate = `${year}-${month}-${day}`;

    console.log(formattedDate,'formattedDate')


    const today1 = new Date();
    let hours = today1.getHours();
    const minutes = String(today1.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;

    console.log(formattedTime,'formattedTime')


    let payload =
    {
       

        "operation_type": "check_in",
        "op_field_details": {
            "role_type": "student",
            "student_id": window.localStorage.getItem("student_id"),
            "batch_id": idd.batch_id,
            "tenant_id": window.localStorage.getItem("tenant_Id"),
            "date":formattedDate,
            "check_in_time":formattedTime
        }
    }
    dispatch(addClassStudnetAttendance(payload)) 
  };
  return (
    <div>
       {contextHolder}
        <h3>UPCOMING CLASSES</h3>
        <div>
            <TableComponent columns={columns} data={props?.list?.data}/>
        </div>
    </div>
  )
}

export default UpcomingCourses