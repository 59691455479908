export const GET_ADMIN_COURSE_FEEDATA = "GET_ADMIN_COURSE_FEEDATA";

export const ADMIN_COURSE_FEE_CREATION_SUCCESS = "ADMIN_COURSE_FEE_CREATION_SUCCESS";

export const ADMIN_COURSE_FEE_CREATION_FAIL = "ADMIN_COURSE_FEE_CREATION_FAIL";


export const ADMIN_COURSE_FEE_DELETE = "ADMIN_COURSE_FEE_DELETE";

export const COURSE_FEE_CRUD_STATUS = "COURSE_FEE_CRUD_STATUS";

export const GET_PRICE_BASED_ON_COURSE = "GET_PRICE_BASED_ON_COURSE";

export const GET_COURSES_BASED_ON_BATCH = "GET_COURSES_BASED_ON_BATCH";

export const GET_BATCH_BASED_ON_STUDENT_ID = "GET_BATCH_BASED_ON_STUDENT_ID";