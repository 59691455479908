import { GET_SUPER_ADMIN_TOKEN,GET_SUPER_ADMIN_LOGIN_STATUS,SUPER_ADMIN_LOGIN, GET_SUPER_ADMIN_MENUS } from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';

export const getSuperAdminLoginStatus = (data) =>{
    console.log(data,"data")
    return{
        type: GET_SUPER_ADMIN_LOGIN_STATUS,
        payload: data
    }
}

export const getSuperAdminLoginData = (data)=>{
    console.log(data,"dtahereee")
    return {
        type: GET_SUPER_ADMIN_TOKEN,
        payload: data
    }
}

export const getSuperAdminmenus = (data)=>{
        return {
            type:GET_SUPER_ADMIN_MENUS,
            payload: data.result?.auth_objects
        }
}

export const superAdminLogin = (data)=>{
    console.log(data,"superAdminPayload")
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/login",data
        ).then((res)=>{
            if (res?.data?.res_status) {
                // dispatch(getSuperAdminLoginData(res?.data))
                dispatch(getSuperAdminLoginStatus(res?.data?.res_status))
            }
            if(res?.data){
                 dispatch(getSuperAdminLoginData(res?.data))
                // dispatch(getSuperAdminLoginStatus(res?.data?.res_status))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveSuperAdminMenus = (data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl1+"security_auth/authorization/list_menus",data
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(getSuperAdminmenus(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}