

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalComponent, TableComponent,InputComponent } from '../../commonComponents';
import {retrieveAllDemoClassRegistartion,deleteClassRegistration,retrieveAllCourse,addClass,updateClassRegistration
} from '../../redux/adminDemoClass/action';
import {insertFireBaseChartDetails} from '../../redux/studentLogin/action';
import { course,edit,excel,plus,cancelImage,deleteIcon,searchIcon,TableEyeIcon } from "../../images";
import moment from 'moment';
import { NavLink, Outlet } from "react-router-dom";
import * as XLSX from 'xlsx';
import ExportToExcel from '../../commonComponents/XlsxFile/ExportToExcel';
// import { course, deleteIcon, edit, plus, searchIcon,excel } from '../images'
import dayjs from 'dayjs';

import AdminDemoClassRegistrationView from '../../components/adminDemoClass/adminDemoClassRegistration';
import { Button, Form, DatePicker, notification,Select,TimePicker,Card } from "antd";

import {fetchInstructorCourseDetails} from '../../redux/batches/action';
import './demoClass.css';



const AdminDemoClassRegistrationDetails = () => {

  let dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const [form1] = Form.useForm();



  const [gapiInited, setGapiInited] = useState(false);
  const [gisInited, setGisInited] = useState(false);
  const [tokenClient, setTokenClient] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(true);
  const [events, setEvents] = useState([]);

  const CLIENT_ID = '184526489019-g3v8chejamc09fu2ctefuvv8vuheukpl.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyDTQNTRbeHEa-wpa3lM0OIBoX9Z9_QbvmE';
  const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
  const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events';


  let[startTime,setStartTime] = useState("");


  let [instructorCancelClass,setInstructorCancelClass] = useState();
  let [studentsClassCancelNotification,setStudentsClassCancelNotification] = useState();
  let [batchName,setBatchName] = useState();
  let [students,setStudents] = useState();
  let[searchEntry,setSearchEntry] = useState("");
  let [courseData,setCourseData] = useState([]);
  const [coursRelatedInstructors,setCourseRelatedInstructors] = useState()
  let [studentd,setStudentd] = useState();
  let[endTimes,setEndTimes] = useState("");


  let allInstructorCancelledClass = useSelector((state)=>(state.AdminDemoClassDetails?.retrieveDemoClassRegistration));
  let deleteResponse = useSelector((state)=>(state.AdminDemoClassDetails?.deleteClassResponse));
  let allCourseInstructorDetails = useSelector((state)=>(state.AdminDemoClassDetails?.retrieveAllCourses));
  let allCourseInstructors = useSelector((state)=>state.batchcourseDetails?.data);

  let [formValues,setFormValues] = useState({
    batch_name:"",
    course_category_id:"",
    course_id:"",
    instructor_id:"",
    start_date:"",
    end_date:"",
    course_time:"",
    start_time:"",
    end_time:"",
    week_days:"",
    meeting_link:"",
    course_name:"",
    instructor_name:"",
    course_end_time:"",
    course_name: '',

  })


  let [showForm,setShowForm] = useState(false);
  let [modalType,setModalType] = useState();
      let [startDate,setStartDate] = useState("");

      const todayDate = moment();

      const disabledDate = (current) => {
        // Disable dates before today
        return current && current < todayDate.startOf('day');
      }
  const [popoverVisible, setPopoverVisible] = useState(false);

  useEffect(()=>{
    dispatch(retrieveAllDemoClassRegistartion())
    dispatch(retrieveAllCourse())

    // dispatch(cancelClassNotificationToStudents())
  },[])



  useEffect(()=>{

    console.log(allCourseInstructorDetails,'allCourseInstructorDetails')
    if (allCourseInstructorDetails) {
      setCourseData(allCourseInstructorDetails?.data)
    }
  },[allCourseInstructorDetails])

  useEffect(()=>{

    if(allInstructorCancelledClass){
        console.log(allInstructorCancelledClass?.data,'TESTING')
        setInstructorCancelClass(allInstructorCancelledClass?.data)
    }


  },[allInstructorCancelledClass])


  useEffect(()=>{
    if (allCourseInstructors) {
      console.log(allCourseInstructors,"DAMODARTESTING")
      setCourseRelatedInstructors(allCourseInstructors?.data)
    }else{
      setCourseRelatedInstructors([])
    }
  },[allCourseInstructors])





    let columns = [
        
        {
            title: 'Id',
            dataIndex: 'id',
            sorter: true,
            sorter: (a, b) => a.course_id - b.course_id,
            filteredValue: [searchEntry],
            onFilter: (value,record)=>{
              return String(record.first_name).toLowerCase().includes(value.toLowerCase())||
                     String(record.course_name).toLowerCase().includes(value.toLowerCase())||
                     String(record.last_name).toLowerCase().includes(value.toLowerCase())||
                     String(record.email).toLowerCase().includes(value.toLowerCase())||
                     String(record.phone).toLowerCase().includes(value.toLowerCase())||
                     String(record.remark).toLowerCase().includes(value.toLowerCase())||

                     String(record.date).toLowerCase().includes(value.toLowerCase())||
                     String(record.created_date).toLowerCase().includes(value.toLowerCase())
                   }
    
          },
          {
            title: 'First Name',
            dataIndex: 'first_name',
            sorter: true,
            sorter: (a, b) => a.first_name .localeCompare(b.first_name),

          },
          {
            title: 'Last Name',
            dataIndex: 'last_name',
            sorter: true,
            sorter: (a, b) => a.last_name .localeCompare(b.last_name),

          },
          {
            title: 'Email',
            dataIndex: 'email',
            sorter: true,
            sorter: (a, b) => a.email .localeCompare(b.email),

          },
          {
            title: 'Phone',
            dataIndex: 'phone',
            sorter: true,
            sorter: (a, b) => a.phone .localeCompare(b.phone),

          },
          {
            title: 'Course Name',
            dataIndex: 'course_name',
            sorter: true,
            sorter: (a, b) => a.course_name .localeCompare(b.course_name),

          },
          {
            title: 'Created Date',
            
            dataIndex: 'created_date',
            sorter: true,
            sorter: (a, b) => a.created_date .localeCompare(b.created_date),
            render: (text, record) => moment(record.created_date, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY'),

          },
          {
            title: 'Class Date',
            
            dataIndex: 'date',
            sorter: true,
            sorter: (a, b) => a.date .localeCompare(b.date),
            render: (text, record) => moment(record.date, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY'),

          },

          {
            title: 'Remark',
            dataIndex: 'remark',
            sorter: true,
            sorter: (a, b) => a.remark .localeCompare(b.remark),

          },

          {
            title: 'Status',
            dataIndex: 'status',
            sorter: true,


            
            sorter: (a, b) => a.status - b.status,



            render: (text, record1) => (
              <div className="actionIcons">
                {record1.status == 'pending' ? 
                    <div className={"statusInactivePending"}><span class="dotInactivepending"></span> Pending</div>:
              
                    <div className={"statusActive"}><span class="dotActive" ></span> Completed</div>
}
              </div>
             ),
          },

          {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
              <div className="actionIcons">
                 <img   src={TableEyeIcon} onClick={()=> handleViewStudent(record.id)} />   
                    <div style={{marginLeft:"10px"}}></div>
                 <img src={edit} onClick={()=>handleEditCourse("Edit Student Fee",record.id)} />
                 <div style={{marginLeft:"10px"}}></div>

                 <img src={deleteIcon} onClick={() => handleDeleteModal(record.id)} />
              </div>
            ),
          }
    ]

    let img = [
      <div className='statusClick'>
                  <span className='dotPopUp'></span>
                  <span className='dotPopUp'></span>
                  <span className='dotPopUp'></span>
    </div>
    ]

  
  
  

    const searchStudentByEmail = (e) =>{
      let data = e.target.value;
  setSearchEntry(data)
 
    }


    useEffect(()=>{
      if (searchEntry) {
        setStudents(allInstructorCancelledClass?.data)
      }
  },[searchEntry])

 



  const exportToExcel = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };
  
  
  const transformedItems = instructorCancelClass?.map((item) => {
   
  
    return { 
       CANCEL_ID: item.cancel_id,
       INSTRUCTOR_NAME:  item.instructor_name,
      COURSE_NAME:  item.course_name,
      BATCH_NAME: item.batch_name,
      SCHEDULE_DATE:  item.scheduled_date,
     CANCEL_CLASS_TIME:  item.cancel_class_timing,
    
     REASON:  item.reason,
     STATUS: item.status == 'pending' ? 'Pending' :
     item.status == 'approved' ? 'Approved' : 'Rejected',
    };
  });
  
  let handleCategoryModal1 = (type,id) =>{
     
    exportToExcel(transformedItems, 'cancel_class');
  
    
  }

  let [studentDetails,setStudentDetails] = useState(false);
  let [studentRecord,setStudentRecord] = useState([]);
  let [isDeleteModalVisible,setIsDeleteModalVisile] = useState(false);
  let [classId,setClassId] = useState();
  let [updateClassId,setUpdateClassId] = useState();

  

  
  let handleDeleteModal = (id)=>{
    setIsDeleteModalVisile(true);
    setClassId(id)
  }


  let handleViewStudent = (Id) =>{
    instructorCancelClass.filter((item,index)=>{
      if(item.id == Id){
        setStudentRecord(item)
      }
    })
    setStudentDetails(true)
    }

    let handleStudentCancel = () =>{
        setStudentDetails(false)
       
      }

      useEffect(()=>{
        if(deleteResponse){
            console.log(deleteResponse,'msgDa')
            openNotification(deleteResponse?.msg)
        }else{

        }
      },[deleteResponse])


      const openNotification = (msg) => {
        
      
        api.info({
            description:
            `${msg}`,
  
          });
    
      };

      const handleDeleteOk = (student_id) => {

        let payload = {
            "operation_type":"delete",
            "op_field_details":{
                "id":[classId],
                "tenant_id": parseInt(window.localStorage.getItem("tenant_Id"))
            }
        }


        dispatch(deleteClassRegistration(payload))



        console.log('okkkkkk')
        setIsDeleteModalVisile(false);
    };

    let  closeDeleteModal = () =>{
        setIsDeleteModalVisile(false);
      }

      let handleDeleteCancel= ()=>{
        setIsDeleteModalVisile(false)
      } 


      
      let deleteModalFooter = [
        <div className="editCourse">
          {/* <div>
              <CheckboxComponent/>
          </div> */}
          <div>
            <Button type={"secondary"} default value={'Cancel'} className={'cancel ant-btn-default'} onClick={closeDeleteModal}>
              Cancel</Button>
  
            </div>
          <div className="alignRight">
            
            <div>
            <Button type={"primary"}  danger value={'Delete'} className={'submit'} onClick={handleDeleteOk}>Delete</Button>
              
            </div>
         
          </div>
        </div>
    ]


    let handleAddClass= (type,id) =>{
     
        form.resetFields();

        setShowForm(true)
      
        setModalType('Add Class')


        setFormValues({
            course_id: '',
            instructor_id: '',
            meeting_link: '',
            course_name:''
          })

          form.setFieldsValue({["meeting_link"]: ''})

          form.setFieldsValue({["course_id"]:''});

          form.setFieldsValue({["instructor_id"]:''});

          setStartDate('')
          setTimeList([])
      }

      let handleViewTable = ()=>{
        setShowForm(false)

      }

      let submitBatch = (values) =>{

        if (modalType == "Add Class") {

            if(timeList.length == 0){
                openNotification('Add Timing')

            }else{
                let editPayload = 
                {
                    "operation_type":"insert",
                    "op_field_details":{
                        "course_id": formValues.course_id,
                        "instructor_id": formValues.instructor_id,
                        "timings": timeList,
                        "date":startDate,
                        "meeting_link": formValues.meeting_link,
                        "tenant_id": parseInt(window.localStorage.getItem("tenant_Id"))
                    }
                }
          

                console.log(editPayload,'editPayload')
            dispatch(addClass(editPayload))
            setShowForm(false)
            form.setFieldsValue({['course_id']:''})
            form.setFieldsValue({['instructor_id']:''})
            setStartDate('')
            form.setFieldsValue({['meeting_link']:''})
            setTimeList([])




            }
       
           
           
            
      
   
       }

       if (modalType == "Update Class") {

        if(timeList.length == 0){
            openNotification('Add Timing')

        }else{
            let editPayload = 
            {
                "operation_type":"update",
                "op_field_details":{
                    "id":"",
                    "course_id": formValues.course_id,
                    "instructor_id": formValues.instructor_id,
                    "timings": timeList,
                    "date":startDate,
                    "meeting_link": formValues.meeting_link,
                    "tenant_id": parseInt(window.localStorage.getItem("tenant_Id"))
                }
            }
      

            console.log(editPayload,'editPayload')
        // dispatch(updateClass(editPayload))
        setShowForm(false)
        form.setFieldsValue({['course_id']:''})
        form.setFieldsValue({['instructor_id']:''})
        setStartDate('')
        form.setFieldsValue({['meeting_link']:''})
        setTimeList([])




        }
   
       
       
        
  

   }

      }

      const [timeList, setTimeList] = useState([]);



      let addTime = (values) =>{


        const newTimeRange = `${startTime} - ${endTimes}`;
        setTimeList([...timeList, newTimeRange]);


        
        setStartTime("")
        form1.setFieldsValue({['startTime']:''})

        setEndTimes("");
        form1.setFieldsValue({['endTimes']:''})

      }

      let handleDateChange = (e,type)=>{

       
        if (type.name == "start_date") {
          setStartDate(e)
          form.setFieldsValue({ startDate: e })  
        }
          
      }

      let handleCategoryChange = (e,type) =>{
      
        console.log(e,'type')
        console.log(type,'type')

        if (type.name == "startTime") {

            if (e) {
              const startTime  = dayjs(e).format('hh:mm A')
              setStartTime(startTime)
              form.setFieldsValue({[type.name]:e})
            }
          
    
          }
    
          if (type.name == "endTimes") {
    
            if (e) {
              const endTimee  = dayjs(e).format('hh:mm A')
    
            setEndTimes(endTimee);
            // setInsertEndTime(endTimee);
            form.setFieldsValue({[type.name]:e})
            }
          
          }

        if (type.name == "course_id") {
            setFormValues({...formValues,[type.name]:e})
            form.setFieldsValue({[type.name]:e})
            setFormValues({...formValues,"instructor_id":"Select","course_id":e});
    
            console.log(type.name,'valueAvni')
            console.log(e,'valueAvni')


            let payload={  
               

                
    "operation_type": "view_instructor_on_course",
    "course_id": e,
    "tenant_id":  parseInt(window.localStorage.getItem("tenant_Id"))
              }
              dispatch(fetchInstructorCourseDetails(payload))
          }


          if (type.name == "instructor_id") {
            setFormValues({...formValues,[type.name]:e})
            form.setFieldsValue({[type.name]:e})
    
          }

    }














    useEffect(() => {

        // const authorizeButton = document.getElementById('authorize_button');
        // if (authorizeButton) {
        //   authorizeButton.style.visibility = 'hidden';
        // } else {
        //   console.warn('Authorize button not found');
        // }
        // Load the gapi script
        const gapiScript = document.createElement('script');
        gapiScript.src = 'https://apis.google.com/js/api.js';
        gapiScript.onload = () => gapiLoaded();
        document.body.appendChild(gapiScript);
    
        // Load the GIS script
        const gisScript = document.createElement('script');
        gisScript.src = 'https://accounts.google.com/gsi/client';
        gisScript.onload = () => gisLoaded();
        document.body.appendChild(gisScript);
      }, []);
    
      const gapiLoaded = () => {
        window.gapi.load('client', initializeGapiClient);
      };
    
      const initializeGapiClient = async () => {
        await window.gapi.client.init({
          apiKey: API_KEY,
          discoveryDocs: [DISCOVERY_DOC],
        }).then(() => {
          checkSignInStatus();
        });
        setGapiInited(true);
        maybeEnableButtons();
      };
  
      const checkSignInStatus = () => {
        if (window.gapi.client.getToken() === null) {
          console.log('No DAMODAR');
          setIsSignedIn(true);
        } else {
          console.log('Yes DAMODAR');
          setIsSignedIn(false);
        }
      };
  
  
  
    
      const gisLoaded = () => {
        const client = window.google.accounts.oauth2.initTokenClient({
          client_id: CLIENT_ID,
          scope: SCOPES,
          callback: '', // defined later
        });
        setTokenClient(client);
        setGisInited(true);
        maybeEnableButtons();
      };
    
      const maybeEnableButtons = () => {
        if (gapiInited && gisInited) {
          document.getElementById('authorize_button');//.style.visibility = 'visible'
        }
      };



    const handleAuthClick = () => {
        tokenClient.callback = async (resp) => {
          if (resp.error !== undefined) {
            throw (resp);
          }
          setIsSignedIn(false);
  
          document.getElementById('signout_button');//.style.visibility = 'visible'
          // document.getElementById('authorize_button').innerText = 'Refresh';
          await listUpcomingEvents();
        };
    
        if (window.gapi.client.getToken() === null) {
          tokenClient.requestAccessToken({ prompt: 'consent' });
        } else {
          tokenClient.requestAccessToken({ prompt: '' });
        }
      };
    
      const handleSignoutClick = () => {
        const token = window.gapi.client.getToken();
        if (token !== null) {
          window.google.accounts.oauth2.revoke(token.access_token);
          window.gapi.client.setToken('');
          setIsSignedIn(false);
          setEvents([]);
          document.getElementById('authorize_button').innerText = 'Authorize';
          // document.getElementById('signout_button').style.visibility = 'hidden';//
        }
      };
    
      const listUpcomingEvents = async () => {
        let response;
        try {
          const request = {
            'calendarId': 'primary',
            'timeMin': (new Date()).toISOString(),
            'showDeleted': false,
            'singleEvents': true,
            'maxResults': 10,
            'orderBy': 'startTime',
          };
          setIsSignedIn(false);
  
          response = await window.gapi.client.calendar.events.list(request);
        } catch (err) {
          console.error(err.message);
          return;
        }
    
        const events = response.result.items;
        if (!events || events.length === 0) {
          setEvents(['No events found.']);
          return;
        }
        setEvents(events.map(event => `${event.summary} (${event.start.dateTime || event.start.date})`));
      };
    
      const handleAddEvent = () => {
        const requestId = Math.random().toString(36).substr(2, 10);
  
        const timeStringStart = '10:00 AM'; // Your input time string
            const timeWithSecondsStart = new Date("1970-01-01 " + timeStringStart).toLocaleTimeString([], { hour12: false });
  
            const timeStringEnd = '11:00 AM'; // Your input time string
            const timeWithSecondsEnd = new Date("1970-01-01 " + timeStringEnd).toLocaleTimeString([], { hour12: false });
    
        const event = {
          'summary':  formValues.course_name, // Event summary
          'start': {
            'dateTime': startDate+'T'+timeWithSecondsStart, // Start time of the event
            'timeZone': 'UTC', // Time zone
          },
          'end': {
            'dateTime': startDate+'T'+timeWithSecondsEnd, // End time of the event
            'timeZone': 'UTC', // Time zone
          },
          'conferenceData': {
            'createRequest': {
              'requestId': requestId // Unique request ID for conference data
            }
          }
        };

    
        window.gapi.client.calendar.events.insert({
          'calendarId': 'primary', // Calendar ID where you want to add the event
          'resource': event
        }).then(response => {
          const createdEvent = response.result;
          const eventId = createdEvent.id; // Get the eventId from the created event
          console.log('Event created:', eventId);
    
          // const meetLink = createdEvent.hangoutLink; // Retrieve the Meet link from the created event
          // console.log('Meet link:', meetLink);
    
    
          // Now you can use the eventId for further operations if needed
          // For example, if you want to patch the event with conference data
          patchEventWithConferenceData(eventId);
        }).catch(error => {
  
  
          console.error('Error creating event:', error);
  
         
        });
      };
    
      const patchEventWithConferenceData = (eventId) => {
        const requestId = Date.now().toString() + Math.random().toString(36).substr(2, 9);
    
        const eventPatch = {
          'conferenceData': {
            'createRequest': {
              'requestId': requestId // Unique request ID for conference data
            }
          }
        };
    
        window.gapi.client.calendar.events.patch({
          'calendarId': 'primary',
          'eventId': eventId, // Use the eventId of the created event
          'resource': eventPatch,
          'sendNotifications': true,
          'conferenceDataVersion': 1
        }).then(response => {
          const patchedEvent = response.result;
    
          const meetLink = patchedEvent.hangoutLink; // Retrieve the Meet link from the created event
          console.log('Meet_link:', meetLink);
          setFormValues({...formValues,['meeting_link']:meetLink})
          form.setFieldsValue({['meeting_link']:meetLink})
  
  
          const filteredNumbers = studentd?.filter(num => !isNaN(num));
  
        //   let createPayload={
        //     "operation_type": "insert",
        //     "op_field_details": {
        //         "batch_name":formValues.batch_name,
        //         "course_category_id":formValues.course_category_id,
        //         "course_id": formValues.course_id,
        //         "instructor_id":formValues.instructor_id,
        //         "batch_start_date": startDate,
        //         "batch_end_date": endDate,
        //         "start_time":startTime,
        //         "end_time": endTimes,
        //         "week_days": formValues.week_days,
        //         "meeting_link": meetLink,
        //         "tenant_id": window.localStorage.getItem("tenant_Id"),
                
        //     },
        //     "details": studentDetailList
        // }
        //  dispatch(createBatch(createPayload))
  
        // console.log(createPayload)
  
  
         let notificationPayload= {
           "notification_type":"batch_enrollment",
           "project_name":"lms",
           "student_id":filteredNumbers ,
           "instructor_id":formValues.instructor_id,
           "tenant_id":window.localStorage.getItem("tenant_Id")
       }
   
    //    dispatch(batchCreateNotification(notificationPayload));
   
    //    setShowForm(!showForm);
    //    dispatch(retrieveBatches());
  
     
     
      
      
    
          console.log('Conference created for event:', patchedEvent.htmlLink);
        }).catch(error => {
          console.error('Error patching event with conference data:', error);
        });
      };


      let removeFormStudentList = (timeRange) =>{
        const updatedTimeList = timeList.filter(time => time !== timeRange);
        setTimeList(updatedTimeList);
        }


        const getMeetingLink = () => {
            handleAddEvent();

          };

          let handleCategoryChangeCourse = (course) => {
            const { course_id, course_name } = course;
          
            setFormValues({
              ...formValues,
              course_id,
              instructor_id: "Select"
            });
            
            form.setFieldsValue({ course_id });
          
            console.log(course_name, 'valueAvni');
            console.log(course_id, 'valueAvni');
          
            let payload = {
              "operation_type": "view_instructor_on_course",
              "course_id": course_id,
              "tenant_id": parseInt(window.localStorage.getItem("tenant_Id"))
            };
            
            dispatch(fetchInstructorCourseDetails(payload));
          };
          



          let handleEditCourse= (type,id) =>{
   
            let payload ={
              "operation_type":"update_status",
              "op_field_details":{
                  "id": id,
                  "status": "completed",
                  "tenant_id":parseInt(window.localStorage.getItem("tenant_Id"))
              }
          }
        
          //   let payload = {
          //     "operation_type":"delete",
          //     "op_field_details":{
          //         "id":[classId],
          //         "tenant_id": 
          //     }
          // }
  
  
          dispatch(updateClassRegistration(payload))
          
              
          }
    return (
        <>
        <div>

<div className="allStudentsHeader">
{contextHolder}
<div><h3 className="allStudents"></h3></div>
{/* <div><InputSelect defaultValue={"Select"}/></div> */}

<div className="searchCourse">
    <div className="searchC"><InputComponent
     className='searchField' placeholder={'Search'}
     onChange={(e)=>searchStudentByEmail(e)}/></div>
    <img src={searchIcon} className="searchIconStudents"/>
</div>

<div style={{marginTop:"10px"}}>

<img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

</div>
</div>
            <TableComponent columns={columns} data={instructorCancelClass}/>


          
             
    {studentDetails ? 
        <ModalComponent title={"Registration Demo Class"} content = {<AdminDemoClassRegistrationView studentRecord = {studentRecord}/>}
        open={handleViewStudent} handleCancel={handleStudentCancel}/>
    : ""}




{isDeleteModalVisible ?
              <ModalComponent title={"Delete Registration Class"} 
                  footer={deleteModalFooter} content={"Are you sure you want to delete this registration class?"}
                   open={isDeleteModalVisible} handleOk={handleDeleteOk} closeModal={closeDeleteModal}
                  showModal={handleDeleteModal} handleCancel={handleDeleteCancel}/>: " "}

        </div>
        </>

    );
}

export default AdminDemoClassRegistrationDetails;
