import { GET_ADMIN_NUMBER_RECORDS ,GET_ADMIN_COURSE_PROGRESS, GET_ADMIN_TODOS, TODO_CRUD_STATUS,RETRIEVE_CLASS_FOR_ADMIN} from "./actionTypes";
const initialStates = {
    loading:true,
    data:[],
    categoryRecords:[],
    error:" ",
}

 const AdminDashboardReducer = (state = [],action)=>{

    switch (action.type) {
        case  GET_ADMIN_NUMBER_RECORDS:
            return {
                ...state,
                adminNumberRecords: action.payload,
            }

        case GET_ADMIN_COURSE_PROGRESS:
            return{
                adminCourseProgress: action.payload
            }

        case GET_ADMIN_TODOS:
            return{
                adminTodoList: action.payload
            }
       
        case TODO_CRUD_STATUS:
            return{
                todoCrudStatus: action.payload
            }

            case RETRIEVE_CLASS_FOR_ADMIN:
                return{
                    retrieveAdminClass: action.payload
                }
            
        default:
            return state;
    }
}

export  default AdminDashboardReducer;