import { GET_INSTRUCTORS, SET_INSTRUCTORS,EMAIL_EXISTS, SET_INSTRUCTORS_BY_CATEGORY } from "./actionTypes";

const initialStates = {
    loading:true,
    data:[],
    instructorrecords:[],
    error:" ",
}

 const instructorReducer = (state = initialStates,action)=>{

    switch (action.type) {
        case  SET_INSTRUCTORS:
            return {
                ...state,
                data: action.payload,
            }

            case GET_INSTRUCTORS:

            return{
                ...state,
                instructor: action.payload,
                loading: false,
            }

            case EMAIL_EXISTS:
                return{
                    ...action.payload,
                    verify: action.payload
                }
            case SET_INSTRUCTORS_BY_CATEGORY:
                return{
                    instructors: action.payload
                }
        default:
            return state;
    }
}

export  default instructorReducer;