import React, { useEffect } from "react";

import { useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { faceBookIcon,googleIcon,logo,bannerIcon,congratsIcon,emailVerifyIcon } from "../../../images";

import { InputPassword,ButtonComponent,SwitchComponent,InputSelect } from "../../../commonComponents";

import { useDispatch,useSelector } from "react-redux";

import { verifyInstructorRegisterEmail } from "../../../redux/instructorRegister/action";

import './verifyEmail.css';
import { Button, notification } from "antd";

let InstructorVerifyEmail = () =>{

    // let [password,setPassword] = useState("");

    // let [confirmPassword,setConfirmPassword] = useState("");
    const navigate = useNavigate();

    const location = useLocation();

    let dispatch = useDispatch();


    let isEmailVerified = useSelector((state)=>state.instructorRegisterReducer.emailStatus);

    let registrationEmail = useSelector((state)=>state.instructorRegisterReducer.instructorReg?.email);

    const [api, contextHolder] = notification.useNotification();

console.log(registrationEmail,"ins")
    let emailVerification=()=>{
      let verifyPayload ={
        "operation_type": "send_email",
      "email": location.state.email
      }
      dispatch(verifyInstructorRegisterEmail(verifyPayload))
    }
    
    useEffect(()=>{
      if (isEmailVerified) {
        // navigate("/confirmPassword/id")
        let type = "Verify Email"
        openNotification(type)
      }
  },[isEmailVerified])

  
  const openNotification = (modalType) => {
    if (modalType == "Verify Email") {
      api.info({
        // message: `Category ${details.course_category_name}`,
        description:
          'Please check your email for verification link',
      });
    }

  };

    return (
        <div className="App">
          {contextHolder}
      <div className='logo'>
         <img src= {bannerIcon} className='bannerImage'/>

      </div>
      <div className='login'>
        <div className='form'>
          <div className='formLogo'>
          <img src={logo}/>
          <img src= {emailVerifyIcon} className=''/>
       

          </div>
          <div className="abc">
          <h1>Verify your Email Address</h1>
        <span>Welcome to Akalaivan.com</span>
        <span>Please check your Email address we sent confirmation mail</span>
        <span>Click below to proceed your Email</span>
        
        <div className="verifyContainer">
        <ButtonComponent type="primary" value={'VERIFY'} primary className={"verifyEmail primary"} onClick={emailVerification}/>
        </div>      
          </div>
         
        </div>
      </div>
    </div>

    )
}

export default InstructorVerifyEmail