import { CREATE_ADMIN_INSTRUCTOR,INSTRUCTOR_CRUD_STATUS,GET_ALL_ADMIN_INSTRUCTORS,
    INSTRUCTORS_APPROVED,INSTRUCTORS_REJECTED
 } from "./actionTypes";


 const AdminInstructor = (state = [],action)=>{

    switch (action.type) {
        case  GET_ALL_ADMIN_INSTRUCTORS:
            return {
                ...state,
                adminInstructors: action.payload,
            }

        case INSTRUCTOR_CRUD_STATUS:
            return {
                instructorCrudStatus: action.payload
            }
            case INSTRUCTORS_APPROVED:
                return {
                    instructorApprovedStatus: action.payload
                }
                case INSTRUCTORS_REJECTED:
                    return {
                        instructorRejectedStatus: action.payload
                    }
        default:
            return state;
    }
}

export  default AdminInstructor;