import React, { useEffect, useRef, useState } from 'react'

import './instructorMessages.css';

import InstructorList from './instructorList/instructorList';
import InstructorChat from './instructorChat/instructorChat';
import { useDispatch } from 'react-redux';
import { getGroupName, retrieveCurrentInstructorGroups, retrieveInstructorMessages, retrireveInstructorGroupInformation } from '../../redux/instructorLogin/action';
import { useSelector } from 'react-redux';
import InstructorChatBlank from './instructorChat/instructorChatBlank';
const InstructorMessages=()=> {


  
  

  

  let dispatch = useDispatch();

  const [instructorGroups,setInstructorGroups] = useState("");

  let instructorGroupData = useSelector((state)=>state?.instructorLoginReducer?.instructorGroups)

  let instructorGroupsDataInformation = useSelector((state)=>state?.instructorLoginReducer?.instructorGroupInformation)

  let chatMessages = useSelector((state)=>state?.instructorLoginReducer?.instructorMessages)

  const [messages,setMessages] = useState();

  const [selectedGroup,setSelectedGroup] = useState();

  const [instructorGroupInfo,setInstructorGroupInfo] = useState();

  const [batchname,setbatchName] = useState("");

  const [loading, setLoading] = useState(false); // Add loading state

  const [fcmTokenList,setFcmTokenList] = useState([])
  const [usernameList,setUserNameList] = useState("")

  const [fullName,setFullName] = useState([])
  const [groupSearch,setGroupSearch] = useState()
  useEffect(()=>{

    if(window.localStorage.getItem("role_Type") == 'student'){
      let payload={
        "operation_type":"view_batch_name",
        "student_id":
         window.localStorage.getItem("student_id"),
        "tenant_id":window.localStorage.getItem("tenant_Id")
    }
    dispatch(retrieveCurrentInstructorGroups(payload))
    }else if(window.localStorage.getItem("role_Type") == 'instructor'){
      let payload={
        "operation_type":"view_batch_name",
        "instructor_id": window.localStorage.getItem("instructor_id"),
        "tenant_id":window.localStorage.getItem("tenant_Id")
    }
    dispatch(retrieveCurrentInstructorGroups(payload))
    }else if(window.localStorage.getItem("role_Type") == 'admin'){
      let payload ={
        "operation_type":"view_batch_name",
        "tenant_id":window.localStorage.getItem("tenant_Id")
    }
    dispatch(retrieveCurrentInstructorGroups(payload))
    }

  //   let payload={
  //     "operation_type":"view_batch_name",
  //     "instructor_id":window.localStorage.getItem("role_Type") == 'student' ?
  //      window.localStorage.getItem("student_id") : window.localStorage.getItem("instructor_id"),
  //     "tenant_id":window.localStorage.getItem("tenant_Id")
  // }
  // dispatch(retrieveCurrentInstructorGroups(payload))



  },[])

  useEffect(()=>{
    if (instructorGroupData) {
      console.log(instructorGroupData,"groups")
      setInstructorGroups(instructorGroupData?.data)
    }
  },[instructorGroupData])


  useEffect(()=>{
    if (instructorGroupsDataInformation) {
      console.log(instructorGroupsDataInformation,"groupInfro")
      setInstructorGroupInfo(instructorGroupsDataInformation?.data)
    }
},[instructorGroupsDataInformation])

  const handleGroupChat = (data)=>{
    setSelectedGroup(data)

    setbatchName(data?.batch_name)
    
    let payload = {
      "operation_type":"retrieve_fcm_token",
      "batch_id": data?.batch_id,
      "tenant_id":window.localStorage.getItem("tenant_Id"),
      "project_name":"lms"
  }
    dispatch(retrireveInstructorGroupInformation(payload))

    let getPayload={
      "peer_type": "retrieve",
      "peer_name": data?.batch_name
  }
  dispatch(retrieveInstructorMessages(getPayload))

  }

  const [chatData,setChatData] = useState();

  useEffect(()=>{


    console.log(chatMessages?.res_status,'statusCHECK')

      // if (chatMessages) {


      //   // console.log(chatMessages,"chatMessages")
      //   setChatData(chatMessages?.data)
      // }


      if (chatMessages?.res_status == true) {
        // console.log("yes","chatMessages")
        setChatData(chatMessages?.data)
      }else if (chatMessages?.res_status == false){
        setChatData([])

      }
      // else{
      //   setChatData([])
      // }

      // if(chatMessages?.data?.res_status == true){
      //   setChatData(chatMessages?.data)
      // }else{
      //   setChatData([])
      // }

  },[chatMessages])


// useEffect(()=>{
//     if (selectedGroup) {
//       let payload = {
//         "peer_type": "retrieve",
//         "peer_name": selectedGroup
//     }
//     dispatch(retrieveInstructorMessages(payload))
//     }
// },[selectedGroup])
  
  useEffect(()=>{
    if (instructorGroupsDataInformation?.data) {
      console.log(instructorGroupsDataInformation,"groupInfromation")

      setInstructorGroupInfo(instructorGroupsDataInformation?.data);
        const fcmToken = instructorGroupsDataInformation?.data&&Object.values(instructorGroupsDataInformation?.data).map(item => item.fcmtoken);
        setFcmTokenList(fcmToken);
       
    
        const usernames = instructorGroupsDataInformation?.data&&Object.values(instructorGroupsDataInformation?.data).map(item => item.username).join(',');
        setUserNameList(usernames);

        const usernamesList = instructorGroupsDataInformation?.data&&Object.values(instructorGroupsDataInformation?.data).map(item => item.full_name);
        setFullName(usernamesList);

        setLoading(true);
    }
},[instructorGroupsDataInformation?.data])



useEffect(()=>{
  if (groupSearch == "") {
    setInstructorGroups(instructorGroupData?.data)
  }
},[groupSearch])

useEffect(()=>{
  if (batchname == " ") {
    setInstructorGroups(instructorGroupData?.data)
    
  }
},[batchname])



const [unkown,setUnkown] = useState()
  useEffect(() => {
    setUnkown("")
    // Add event listener to handle messages from service worker
    const handleMessage = (event) => {
      
      if (event.data && event.data.type === 'background-message') {
        // Handle background message received from service worker
        const payload = event.data.payload;
        // console.log('Background message received in component:', payload);

        // Assuming payload structure matches your expected format
        const { body, sender_name, peer_time } = payload.data;

        // setDummy(payload?.data)
        const isCurrentUserSender = payload?.data?.sender_name === window.localStorage.getItem('userEmail');

        const payloadObject = {
          message: payload?.data?.body,
          name: payload?.data?.sender_name,
          isSender: isCurrentUserSender,
          time: (payload?.data?.peer_time), // Define formatTime function as needed
          listData: false
        };
        
        setUnkown(payload?.data)
       
      }
    };

    navigator.serviceWorker.addEventListener('message', handleMessage);
    
    // Clean up event listener when component unmounts
    return () => {
      navigator.serviceWorker.removeEventListener('message', handleMessage);
    };
    
  }, []);
  
  


  
  return (
    <div className='messageContainer'>
    <div className='usersLists'>
        <InstructorList instructorGroupList ={instructorGroups}
        handleGroupChat={handleGroupChat}  groupSearch={groupSearch}/>
    </div>

    <div className='singleUserChat'>


    {selectedGroup ?
        <InstructorChat selectedGroup={selectedGroup}
        instructorGroupInfo={instructorGroupInfo} batchname={batchname}
        loading={loading} fcmTokenList={fcmTokenList}
           usernameList={usernameList} result ={chatData}
           callObject={unkown} fullName={fullName}
        />
        :<InstructorChatBlank/>}
    </div>
</div>
  )
}


export default InstructorMessages;