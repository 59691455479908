import React,{useState} from "react";

import { ButtonComponent, InputComponent, InputSelect,TextAreaComponent } from "../../../commonComponents";
import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';

import { Button,Tag,Select, Input,Steps } from "antd";
import './addCourse.css';
import AddInstructor from "../../AllInstructors/addInstructor/addInstructor";


let AddCourseModal = (props) =>{

  const contentStyle = {
    
    // lineHeight: '260px',
    // textAlign: 'center',
    // color: token.colorTextTertiary,
    // backgroundColor: token.colorFillAlter,
    // borderRadius: token.borderRadiusLG,
    // border: `1px dashed ${token.colorBorder}`,
    // marginTop: 16,
  };
    let [isModalVisible,setIsModalVisible] = useState(false);

    // let [courseSelect,setCourseSelect] = useState(" ");
    let [courseDescription,setCourseDescription] = useState(" ")

      let handleModal = () =>{
        setIsModalVisible(true)
        // setIsDeleteModalVisile(false)
    
      }
    
      let handleCancel= ()=>{
        setIsModalVisible(false)
      }  
    
      const handleOk = () => {
        setIsModalVisible(false)
        console.log("hello000")
      };
      
      let closeModal = () =>{
        setIsModalVisible(false)
      }
     
      let  onFinish = (e) =>{
        // console.log(e)
        setIsModalVisible(false)

      }

    
     
  // console.log(props.errors.courseError ? props.errors.courseError : " ",'checkinggggg')
    

    return (
      <div className="addCourse">
    <>
      <Steps current={props.current} items={props.items} />
      <div style={contentStyle}>{props.steps[props.current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        
      </div>
    </>
           
        </div>

    )
}

export default AddCourseModal