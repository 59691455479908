import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { faceBookIcon,googleIcon,logo,bannerIcon } from "../../images";

import { InputComponent,ButtonComponent,SwitchComponent,InputSelect,ModalComponent } from "../../commonComponents";
import LoginComponent from "../Login/login";

import { useDispatch,useSelector } from "react-redux";
import { Col, Form, Row,notification,DatePicker ,Select,Checkbox} from 'antd';

import './register.css'
import { NavLink } from "react-router-dom";
import VerifyEmail from "./verifyEmail";
import moment from 'moment';

import confirmPassword from './confirmPassword';
import ConfirmPassword from "./confirmPassword";
import { studentRegistration, privacyPolicy ,retrieveInterestedCourses} from "../../redux/studentRegister/action";
import { type } from "@testing-library/user-event/dist/type";

import PrivacyPolicyStudent from '../Register/privacyPolicyStudent';

let RegisterComponent = () =>{

  let dispatch = useDispatch()
  const [api, contextHolder] = notification.useNotification();

  let registration = useSelector((state)=>state.studentRegisterReducer.studentReg?.res_status);

    let registrationEmail = useSelector((state)=>state.studentRegisterReducer.studentReg?.email);

    let interestedCoursesDetails = useSelector((state)=>state.studentRegisterReducer.retrieveInterestedCourses);
   
    let privacyPolicyDetails = useSelector((state)=>state.studentRegisterReducer.privacyPolicy);

    let [msg, setMsg] = useState(false);

    let enquiery = useSelector((state)=>state.studentRegisterReducer.studentReg);

    let[login,setLogin] = useState(false);

    const [form] = Form.useForm();

    let navigate = useNavigate();
    let studentLogin = () =>{

        setLogin(true)
    }
    let [categoryDropdown,setCategoryDropdown] = useState();

    let [interestedId,setInterestedId] = useState();


    let [startDate,setStartDate] = useState("");

  let [checked, setChecked] = useState(false);

  let [studentDetails,setStudentDetails] = useState(true);

  let [instructorDetails,setInstructorDetails] = useState(false);

  let [privacyPolicyData,setPrivacyPolicyData] = useState();


  let [email,setEmail] = useState(" ");

  let [password,setPassword] = useState(" ");

  let [studentRegisterForm,setStudentRegisterForm] = useState(false)

  let[verifyPage,setVerifyPage] = useState(false)

  let[navigateIs,setNavigateIs] = useState(false)
  const [termsError, setTermsError] = useState(false);
  const [date, setDate] = useState(null); // State to manage the selected date


  let [studentRegDetails,setStudentRegDetails] = useState({
    first_name:"",
    last_name:"",
    gender:"",
    age:"",
    phone_number:"",
    email:"",
    address:"",
    guardian_name:""
  })
  

  let handleChange =(e,type)=>{
    if (type.name !="gender" &&  type.name !="age") {
      setStudentRegDetails({...studentRegDetails,[type.name]:e.target.value})
    }else{
      setStudentRegDetails({...studentRegDetails,[type.name]:e})
      form.setFieldsValue({[type.name]: e})

    }
    
    console.log(studentRegDetails,"regForm")
  }

  const convertDateFormat = (dateString) => {
    // Split the date string by '-' to get [YYYY, MM, DD]
    const [year, month, day] = dateString.split('-');
    // Return the date in 'DD-MM-YYYY' format
    return `${day}-${month}-${year}`;
  };

  const onFinish = (values) => {

    console.log(values, 'values');

    if (!checked) {
      setTermsError(true);
      return;
  } else {
      setTermsError(false);
  }
    console.log('Success:', values);
    // setVerifyPage(true)
  //   setChecked(false)
  //   if (!checked) {
  //     setTermsError(true);
  //     return;
  // } else {
  //     setTermsError(false);
  // }
    if (values) {
      setMsg(true)

      if(checked == true){
        let registrationPayload = {
          "operation_type": "student_enquiry",
          "op_field_details": {
              "first_name": values.first_name,
              "last_name": values.last_name,
              "gender" :  values.gender,
              "address": values.address,
              "tenant_id": window.localStorage.getItem("login_tenant_id"),
              "birth_date":moment(startDate).format("YYYY-MM-DD")  ,
              "email": values.email?.toLowerCase(),
              "phone_no": values.phone_number,
              "course_id": interestedId,
              "guardian_name": values.guardian_name,
  
          }
      }
  
  
      console.log(registrationPayload,'registrationPayload')
       dispatch(studentRegistration(registrationPayload)) 
      }else{

      }


    }
  }; //

  useEffect(()=>{
    if (enquiery) {


       openNotification(enquiery)

      //  if(enquiery.res_status == true){

      //  }

      

      // navigate("/verifyEmail",{state:{email:registrationEmail}})
      console.log(enquiery.msg,"enquiery")
      
    }
    
    // if (registration == false){
    //   console.log(enquiery,"enquiery")

    //   openNotification(enquiery)
    //   // dispatch(studentRegistrtionSuccess(false))
    //  }
    
  },[enquiery])


  useEffect(()=>{
    let registrationPayload = {
      "operation_type":"retrieve",
      "role_type":"student",
      "tenant_id":window.localStorage.getItem("login_tenant_id")
  }

   dispatch(privacyPolicy(registrationPayload)) 
    
  },[])


  useEffect(()=>{
  
    if(privacyPolicyDetails){
      setPrivacyPolicyData(privacyPolicyDetails)
    }
    
  },[privacyPolicyDetails])



  // useEffect(()=>{
  //   if (registration) {
  //      openNotification('Enquiry successfully..')

  //     // navigate("/verifyEmail",{state:{email:registrationEmail}})
  //     console.log(registration,registrationEmail,"stages")
  //   }
    
  //   if (registration == false){
  //     console.log(registration,"stages")

  //     openNotification('Failds')
  //     // dispatch(studentRegistrtionSuccess(false))
  //    }
    
  // },[registration,registrationEmail])

  useEffect(()=>{
   
    let registrationPayload = {
      "operation_type":"course_retrieve",
      "tenant_id":window.localStorage.getItem("login_tenant_id")
  }

  console.log(registrationPayload,'registrationPayload')
   dispatch(retrieveInterestedCourses(registrationPayload)) 
  },[])


  useEffect(()=>{
    if (interestedCoursesDetails) {
      //  openNotification(registration)
      setCategoryDropdown(interestedCoursesDetails)
      // navigate("/verifyEmail",{state:{email:registrationEmail}})
      console.log(interestedCoursesDetails,"stages")
    }
  },[interestedCoursesDetails])

  // useEffect(()=>{
  //   if (registration == false) {
  //     openNotification(registration)
  //   }
  // },[registration == false])

  let dummy = ()=>{
    setVerifyPage(true)
  }

  let handleEmailChange = (e) =>{
      setEmail(e.target.value)
  }

  let handlePasswordChange = (e) =>{
    setPassword(e.target.value)
  }

  let studentForm = () =>{
    setInstructorDetails(false);
    setStudentDetails(true)
  }

  let instructorForm = () =>{
    setInstructorDetails(true);
    setStudentDetails(false)
  }

  let studentRegister = () =>{
    setStudentRegisterForm(true)
  }

  let registerStudent = () =>{
    // navigate("/confirmPassword")
    navigate("/verifyEmail")
  }

  let list = []
  for(let x=18;x<=50;x++){
   let newObj= {};
      newObj["value"] = x;
      newObj["label"] = x;
    list.push(newObj)
 }

 const openNotification = (status) => {

  if(msg == true){
    if(status.res_status == true){

      setTermsError(false);
      setChecked(false)

      form.resetFields();
  
      setStudentRegDetails({
        first_name:"",
        last_name:"",
        gender:"",
        age:"",
        phone_number:"",
        email:"",
        address:"",
        guardian_name:"",
    
      })
  
      setStartDate(null); // Clear the startDate state
      form.setFieldsValue({ startDate: null });

      setDate(null);

      setMsg(false)

  
        api.info({
          description:
          `${status.msg}`,
  
        });
  
  
  
  
  
  
      }
  
  
      if(status.res_status == false){

  
        api.info({
          description:
          `${status.msg}`,
  
        });
  
  
      }
  }else{

  }
 
 



      // if (status) {
    

  //  setFormValues({...formValues,["first_name"]:"select"});
    //  setFormValues({...formValues,["course_id"]:"select"})
    //  setFormValues({...formValues,["instructor_id"]:"select"})
    //  setFormValues({...formValues,["first_name"]:""})
    //  setFormValues({...formValues,["week_days"]:""})



          // "first_name": values.first_name,
          // "last_name": values.last_name,
          // "gender" :  values.gender,
          // "address": values.address,
          // "tenant_id": 1,
          // "birth_date": startDate,
          // "email": values.email,
          // "phone_no": values.phone_number,
          // "course_id": interestedId,
          // "guardian_name": values.guardian_name,
  
         
  
    // }
     

   
  
  //  }else{

  //  }

  

  if (status == false) {
    api.info({
      // message: `Category ${details.course_category_name}`,
      description:
        'Email already exists!',
    });
  }

};

const todayDate = moment();


const disabledDate = (current) => {

  console.log('Checking Date:', current);

  return current && current > moment().endOf('day');


}


let handleDateChange = (e,type)=>{

  setDate(e); // Update the state with the selected date

 
  
    setStartDate(e)
    console.log(e,"DOB")
    form.setFieldsValue({ startDate: e })  

    
}


let handleCategoryChange = (e,type) =>{
 

  setInterestedId(e)
  form.setFieldsValue({[type.name]:e})





}

let [tearmAndCondition,setTearmAndCondition] = useState(false);


const handleClick = () => {
  setTearmAndCondition(true)
};

let handleStudentCancel = () =>{
  setTearmAndCondition(false)
  setChecked(false);
  setTermsError(true);
 
}

let handleViewStudent = (Id) =>{
 
  setTearmAndCondition(true)


 
  }


  let handleViewStudent1 = (e) =>{

    setChecked(e.target.checked);
    setTermsError(!e.target.checked);
    setTearmAndCondition(true)

  //   setChecked(e.target.checked)

  //   if (!checked) {
  //     setTermsError(false);

  // } else {
  //   setTermsError(true);

  // }
    }

    let handleSubMit = () =>{
      setTearmAndCondition(false)
      setChecked(true);
      setTermsError(false);
     
    }

    return (
        <>

{contextHolder}
        {login == false ?
        <div className="">
                    

        {/* <div className='logo'>
           <img src= {bannerIcon} className='bannerImage'/>
        </div> */}
        <div className=''>
          <div className='form'>
            {/* <div className='formLogo'>
            <img src={logo}/>
              <div className='buttonSet'>
              <div className='buttonGroup'>
                <div onClick={studentForm}>
                  <ButtonComponent value={"Student"} className={studentDetails ? 'studentFormEnable':'studentForm'}></ButtonComponent>
                </div>
                <div  onClick={instructorForm}>
                <ButtonComponent value={"Instructor"}className={instructorDetails ? 'instructorFormEnable':'instructorForm'}></ButtonComponent>
                  </div>
              </div>
              </div>
            </div> */}
  
               <div className="registerForm">
                <Form 
                  autoComplete="off"
                  layout="vertical"
                  form={form}
                    onFinish={onFinish}
                    >
                    <Row  className="regForm" >
                    <Form.Item
                     label="First Name"
                     name="first_name"
                     rules={[
                       {
                         required: true,
                         message: 'First name is required',
                         
               
                       },
                     ]}>
                  <div> <Col xs={2} sm={4} md={6} lg={24} xl={24}>
                  <div className='regInput'><InputComponent name={"first_name"}
                  onChange={(e)=>handleChange(e,{name: "first_name"})} 
                  value={studentRegDetails.first_name} placeholder={"First Name"}/></div>
                  </Col>
                  </div>
                  </Form.Item>

                  <Form.Item
                     label="Last Name"
                     name="last_name"
                     rules={[
                       {
                         required: true,
                         message: 'Last name is required',
                         
               
                       },
                     ]}>
                  <div>
                 
                    <Col xs={2} sm={4} md={6} lg={24} xl={24}>
  
                  {/* <div className="formLabel"><label>Last Name <span className="star">*</span></label></div>  */}
                  <div className='regInput'><InputComponent name={"last_name"}
                      onChange={(e)=>handleChange(e,{name: "last_name"})} 
                      value={studentRegDetails.last_name}
                      placeholder={"Last Name"}/></div>
                    
                  </Col>
                  
                  </div>
                  </Form.Item>
              </Row>


              <Form.Item
                     label="Guardian Name"
                     name="guardian_name"
                     rules={[
                       {
                         required: true,
                         message: 'Guardian name is required',
                         
               
                       },
                     ]}>
                  <div> <Col xs={2} sm={4} md={6} lg={24} xl={24}>
                  <div className='regInput'><InputComponent
                   name={"guardian_name"}
                  onChange={(e)=>handleChange(e,{name: "guardian_name"})} 
                  value={studentRegDetails.guardian_name} placeholder={"Guardian Name"}/></div>
                  </Col>
                  </div>
                  </Form.Item>
  
              <Row className="genderContainer">
              <Form.Item
                     label="Gender"
                     name="gender"
                     rules={[
                       {
                         required: true,
                         message: 'Gender is required',
                         
               
                       },
                     ]}>    
                  <Col xs={2} sm={4} md={6} lg={8} xl={5}>
                 
                  {/* <div className="formLabel"><label>Gender <span className="star">*</span></label></div>  */}
                  <div className="selectGender">
                  <InputSelect 
                      name={"gender"}
                  onChange={(e)=>handleChange(e,{name: "gender"})}
                  defaultValue={studentRegDetails.gender ? studentRegDetails.gender : "Select"}
                  value={studentRegDetails.gender ? studentRegDetails.gender : ""}
                     options={[
                      {
                          value: "Male",
                          label: 'Male',
                        },
                        {
                          value: "Female",
                          label: 'Female',
                        },
                    
                    ]}/></div>
                  </Col>
                  </Form.Item>
                  <Form.Item
                       label="Course Name"
                       name="course_category_id"
                        rules={[
                          { required: true, message: 'Please select course name' }
                          ]}>
                      <Select name={"course_category_id"} 
                      // value={formValues.course_category_id} 
                        // defaultValue={formValues.course_category_id  ? formValues.course_category_id :"select"}
                         onChange={(e)=>handleCategoryChange(e,{name:"course_category_id"})}>
                          {categoryDropdown && categoryDropdown.data?.map((item,index)=>{
                          return  <Select.Option value={item.course_id}>{item.course_name}</Select.Option>
                          })}
                        </Select>

                      </Form.Item>
                

                
                  
              </Row>
  
              {/* <Row className="courseSelection">
                  <Col xs={2} sm={4} md={6} lg={24} xl={24}>
                  <div className="formLabel"><label>Course Selection</label></div> 
  
                  <div className="courseSelect"><InputSelect/></div>
                  </Col>
              </Row> */}

<Form.Item
            label="DOB"
            name="startDate"
            rules={[{ required: true, message: 'Please select dob.' }]}>
            <div className='batchDate'>
                <DatePicker
                    name='startDate'
                    disabledDate={disabledDate}
                    onChange={handleDateChange}
                    value={date} // Bind the DatePicker value to the state
                />
            </div>
        </Form.Item>


{/* <Form.Item
            label="DOB"
            name="startDate"
            rules={[{ required: true, message: 'Please select dob.' }]}>
            <div className='batchDate'>
                <DatePicker
                    name='startDate'
                    disabledDate={disabledDate}
                    onChange={(date, dateString) => {
                        handleDateChange(dateString);
                        setStartDate(date ? moment(date).format("YYYY-MM-DD") : null); // Ensure the state is updated correctly
                    }}
                    value={startDate ? moment(startDate, "YYYY-MM-DD") : null} // Ensure correct format
                />
            </div>
        </Form.Item> */}

{/* <Form.Item
                         label="DOB"
                          name="startDate"
                          rules={[{ required: true, message: 'Please select dob.' }]}>
                          <div className='batchDate'>
                        <DatePicker name='startDate'
                        disabledDate={disabledDate}
                      onChange={(e,dateString)=>handleDateChange(dateString,{name:"start_date"})}

                     

                        value={startDate ? moment(startDate) : null}
                          />

                        </div>
                        </Form.Item> */}
  
              <Row  className="regForm">
              <Form.Item
                     label="Phone number"
                     name="phone_number"
                     rules={[
                       {
                         required: true,
                         message: 'Phone number is required only numbers allowed',
                         pattern: new RegExp(/^[0-9]+$/),
                        
                       },
                       {
                        max: 10,
                        min:10,
                        message: "10 digit mobile number is required",
                      },

                     ]}>  
                  <div><Col xs={20} sm={16} md={12} lg={8} xl={8}>
                 
                  <div className='regInput'><InputComponent 
                   name={"phone_number"}
                   onChange={(e)=>handleChange(e,{name: "phone_number"})}
                     defaultValue={studentRegDetails.phone_number} 
                     placeholder={"Phone Number"} /></div>
                  </Col>
                  
                  </div>
                  </Form.Item>
                  <Form.Item
                     label="Email"
                     name="email"
                     rules={[
                       {
                         required: true,
                         message: 'Email is required',
                         type:"email"
               
                       },
                     ]}>
                  <div><Col xs={20} sm={16} md={12} lg={8} xl={8}>
                  <div className='regInput'><InputComponent name={"email"} 
                   onChange={(e)=>handleChange(e,{name: "email"})}
                   value={studentRegDetails.email}
                    placeholder={"Email"}/>
                   </div>
                  </Col>
                  </div>
                  </Form.Item>
              </Row>
  
              <Row className="addressSelection">
              <Form.Item
                     label="Address"
                     name="address"
                     rules={[
                       {
                         required: true,
                         message: 'Address is required',
                         
               
                       },
                     ]}>
                  <Col xs={2} sm={4} md={6} lg={24} xl={24}>
                  <div className="addressField"><InputComponent
                  name={"address"}
                  onChange={(e)=>handleChange(e,{name: "address"})}
                  value={studentRegDetails.address}
                  placeholder={"Enter Address"}/></div>
                  </Col>
                  </Form.Item>
              </Row>


              <Form.Item 
              >
              
                                        <div>
                                            <Checkbox checked={checked} onChange={(e) => handleViewStudent1(e)}>
                                             
                                            </Checkbox>

                                            <span onClick={handleViewStudent} 
                                                style={{ cursor: "pointer", color: "black", marginLeft:"10px"}}>I agree to the  Terms and Conditions</span>
                                            {termsError && <div style={{ color: "red" }}>You must agree to the terms and conditions</div>}
                                        </div>
                                    </Form.Item>
  
               {/* <div className="terms">
                  <div className='switch'>
                  <div><SwitchComponent /><span className='rememberMe underLine'         onClick={handleClick}
>Terms and conditions</span></div>
  
                  </div>
              </div> */}
                     
                     <Form.Item>
              <div className="regsiter">
                 {/* <NavLink to={'/confirmPassword'}> */}
                  <ButtonComponent value={'REGISTER'} htmlType={'Submit'} className={"btnLogin"} />
                  {/* </NavLink>  */}
              </div>
                </Form.Item>
                {/* <div className="textCenter loginNow">
                      <p><b>Register</b> with Others</p>
                  </div>
              <div className='socialIcons'>
                          <div className='facebookIcon'>
                              <img src={faceBookIcon} />
                          </div>
                          <div className='googleIcon'>
                              <img src={googleIcon} />
                          </div>
                      </div> */}
  
                  <div className="textCenter loginNow" style={{marginTop:"10px",marginBottom:"10px"}}>
                      <p >You have account ?<b className="underLine" onClick={studentLogin}>Login Now</b></p>
                  </div>
                  </Form>

         </div>
             
          </div>
        </div>
       </div>
       : <LoginComponent/>}


{tearmAndCondition ? 
        <ModalComponent title={""}  content = {<PrivacyPolicyStudent privacyPolicyData = {privacyPolicyData}     handleSubMit={handleSubMit}
/>}
        open={handleViewStudent} 
        handleCancel={handleStudentCancel}
        handleSubMit={handleSubMit}/>
    : ""}
       </>
    )
}

export default RegisterComponent