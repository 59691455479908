import { STUDENT_RELATED_COURSES,ADD_ATTENDANCE_STUDENT } from "./actionTypes";

import axios from "axios";
import apiConfig from '../../../apiConfig/api';


const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
};

export const getRelatedCourses=(data)=>{
    return{
        type:STUDENT_RELATED_COURSES,
        payload: data
    }
}

export const retrieveRelatedCourses = (data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/student_page",data
        ).then((res)=>{
            dispatch(getRelatedCourses(res.data));
        }).catch(err=>{
            console.log(err)
        })
}
}


export const addClassForStudent=(data)=>{
    return{
        type:ADD_ATTENDANCE_STUDENT,
        payload: data
    }
}

export const addClassStudnetAttendance = (data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/class_checkin",data
        ).then((res)=>{
            dispatch(addClassForStudent(res.data));
        }).catch(err=>{
            console.log(err)
        })
}
}